import React, { Component } from "react";

class Box extends Component {
  state = {
    size: this.props.size || "default",
    className: this.props.className
  };

  render() {
    return (
      <div
        className={this.boxClass()}
        style={this.props.style || undefined}
        onClick={this.props.onClick || undefined}
      >
        <div className="box-content">{this.props.children}</div>
      </div>
    );
  }

  boxClass() {
    const { size, className } = this.state;

    let classes = className;

    if (size === "nopadding") {
      classes += " no-padding box";
    } else if (size === "small") {
      classes += " small box";
    } else {
      classes += " box";
    }

    if (this.props.onClick) {
      classes += " clickable-box";
    }

    return classes;
  }
}

export default Box;
