import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import PTPopup from "../../ptPopup/PTPopup";
import axios from "axios";

import cloneDeep from "lodash/cloneDeep";
import { Grid, Row, Cell } from "../../foundation/_grid";
import { Icon } from "../../foundation/_buttons";
import Box from "../../layout/Box";
import ReactTooltip from "react-tooltip";

class LessonNotes extends Component {
  state = {
    lesson: this.props.lesson,
    showNotePopup: this.props.showNotePopup,
    selectedNote: this.props.selectedNote,
    newNote: {
      id: "new",
      course: 0,
      lesson: 0,
      seconds: 0,
      tmpHours: 0,
      tmpMinutes: 0,
      tmpSeconds: 0,
      text: "",
    },
    changesWereMade: false,
    secondsBuffer: 5, // On Click on new note -> how many seconds back?
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lesson !== prevProps.lesson) {
      this.setState({ lesson: this.props.lesson });
    }

    if (this.props.showNotePopup !== prevProps.showNotePopup) {
      this.setState({ showNotePopup: this.props.showNotePopup });
    }

    if (this.props.selectedNote !== prevProps.selectedNote) {
      let selectedNote = this.props.selectedNote;

      if (selectedNote === "new") {
        const { newNote, lesson, secondsBuffer } = this.state;
        selectedNote = cloneDeep(newNote);

        selectedNote.course = lesson.course_id;
        selectedNote.lesson = lesson.id;

        if (lesson && lesson.lessonStatus && lesson.lessonStatus.seconds) {
          selectedNote.seconds = lesson.lessonStatus.seconds;
          if (selectedNote.seconds > secondsBuffer) {
            selectedNote.seconds -= secondsBuffer;
          }

          selectedNote = this.convertSeconds(selectedNote);
        }
      }

      this.setState({ selectedNote });
    }
  }

  render() {
    const _c = this.props.context;

    return (
      <div className="course-lesson-notes">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        {this.view__showNotes()}
        {this.view__showNotePopup()}
      </div>
    );
  }

  view__showNotes() {
    const { lesson } = this.state;
    const _c = this.props.context;

    if (!lesson || !lesson.notes) {
      return _c.loading();
    }

    return (
      <React.Fragment>
        {lesson.notes.map((note, index) => {
          note = this.convertSeconds(note);

          let noteTime = `${
            note.tmpHours < 10 ? `0${note.tmpHours}` : note.tmpHours
          }:${note.tmpMinutes < 10 ? `0${note.tmpMinutes}` : note.tmpMinutes}:${
            note.tmpSeconds < 10 ? `0${note.tmpSeconds}` : note.tmpSeconds
          }`;

          return (
            <div className="course-lesson-note" key={index}>
              <Box>
                <Grid type="full">
                  <Row margin="y">
                    <Cell sm={22}>
                      <div className="note-time">
                        <button
                          data-tip="Zu dieser Stelle im Video springen"
                          onClick={() => {
                            this.handle__goToSecond(note.seconds);
                          }}
                        >
                          {noteTime}{" "}
                        </button>
                      </div>
                      <div className="note-text">
                        <div
                          dangerouslySetInnerHTML={{ __html: note.text_f }}
                        />
                      </div>
                    </Cell>
                    <Cell sm={2} className="note-buttons">
                      <div>
                        <button
                          className="tiny only-icon primary hollow button"
                          data-tip="Diese Notiz bearbeiten"
                          onClick={() => {
                            this.setState({
                              selectedNote: cloneDeep(note),
                              showNotePopup: true,
                            });
                          }}
                        >
                          <Icon icon="edit" />
                        </button>
                      </div>
                      <div>
                        <button
                          className="tiny only-icon primary hollow button"
                          data-tip="Diese Notiz löschen"
                          onClick={() => {
                            this.handle__deleteNote(note);
                          }}
                        >
                          <Icon icon="trash" />
                        </button>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Box>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  view__showNotePopup() {
    const { showNotePopup, selectedNote } = this.state;

    if (!showNotePopup || !selectedNote || !selectedNote.id) {
      return;
    }

    let v__title = "Notiz bearbeiten";
    if (selectedNote.id === "new") {
      v__title = "Notiz hinzufügen";
    }

    return (
      <PTPopup
        show={showNotePopup}
        size="small"
        handleClose={this.handle__closeNotePopup}
      >
        <div className="course-lesson-notes-popup">
          <h2>{v__title}</h2>
          <p>Diese Notiz ist privat und kann nur von Dir eingesehen werden.</p>
          <h3>Zeitpunkt</h3>
          <Grid type="full">
            <Row margin="x" className="course-lesson-notes-popup-time">
              <Cell sm={8}>
                <input
                  type="number"
                  value={selectedNote.tmpHours}
                  onChange={(e) => {
                    this.handle__editNote("tmpHours", e.target.value);
                  }}
                  placeholder="Std."
                />
                <label>Std.</label>
              </Cell>
              <Cell sm={8}>
                <input
                  type="number"
                  value={selectedNote.tmpMinutes}
                  onChange={(e) => {
                    this.handle__editNote("tmpMinutes", e.target.value);
                  }}
                  placeholder="Min."
                />
                <label>Min.</label>
              </Cell>
              <Cell sm={8}>
                <input
                  type="number"
                  value={selectedNote.tmpSeconds}
                  onChange={(e) => {
                    this.handle__editNote("tmpSeconds", e.target.value);
                  }}
                  placeholder="Sek."
                />
                <label>Sek.</label>
              </Cell>
            </Row>
          </Grid>
          <h3>Notiz</h3>
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <textarea
                  rows={8}
                  value={selectedNote.text}
                  onChange={(e) => {
                    this.handle__editNote("text", e.target.value);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
          <div className="popup-buttons">
            <button
              className="small primary hollow button"
              onClick={this.handle__closeNotePopup}
            >
              <Icon icon="times" left /> Abbrechen
            </button>
            <button
              className="small primary button"
              onClick={this.handle__saveNote}
            >
              <Icon icon="check" left /> Speichern
            </button>
          </div>
        </div>
      </PTPopup>
    );
  }

  handle__goToSecond(seconds) {
    this.props.onGoToSecond(seconds);
  }

  handle__editNote(property, value) {
    let { selectedNote } = this.state;

    if (
      value > 59 &&
      (property === "tmpSeconds" || property === "tmpMinutes")
    ) {
    } else {
      selectedNote[property] = value;
    }

    if (
      property === "tmpHours" ||
      property === "tmpMinutes" ||
      property === "tmpSeconds"
    ) {
      selectedNote = this.convertTime(selectedNote);
    }

    this.setState({ selectedNote, changesWereMade: true });
  }

  handle__deleteNote(note) {
    const previewLength = 100;
    let notePreview = note.text;
    if (notePreview.length > previewLength - 4) {
      notePreview = note.text.substr(0, previewLength) + "...";
    }

    if (
      window.confirm(
        `Möchtest Du die Notiz\r\n\r\n${notePreview}\r\n\r\nwirklich unwiderruflich löschen? Diese Aktion kann nicht rückgängig gemacht werden.`
      )
    ) {
      const _c = this.props.context;

      let url = _c.apiUrl("courseLessonNote", {});
      url = url + "/" + note.id;

      axios
        .delete(url, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            const { lesson } = response.data;

            if (_c.isDebug()) {
              console.log(response.data);
            }

            this.setState({ lesson, changesWereMade: false }, () => {
              this.props.onNoteSave(lesson);
              this.handle__closeNotePopup();
            });
          } catch {
            alert(
              "Delete CourseLessonNote - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
            );
          }
        })
        .catch((error) => {
          if (_c.isDebug()) {
            console.log("Error", error);
          }
        });
    }
  }

  handle__closeNotePopup = () => {
    let { changesWereMade, showNotePopup, selectedNote } = this.state;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Möchtest Du wirklich abbrechen? Änderungen werden nicht gespeichert und gehen unwiderruflich verloren."
        )
      ) {
        showNotePopup = false;
        selectedNote = null;
        changesWereMade = false;
      }
    } else {
      showNotePopup = false;
      selectedNote = null;
      changesWereMade = false;
    }

    this.setState({
      showNotePopup,
      selectedNote,
      changesWereMade,
    });

    this.props.onPopupClose(showNotePopup, selectedNote);
  };

  convertSeconds(note) {
    let tmpHours = 0;
    let tmpMinutes = 0;
    let tmpSeconds = 0;

    let { seconds } = note;

    tmpHours = Math.floor(seconds / 60 / 60);
    seconds -= tmpHours * 60 * 60;

    tmpMinutes = Math.floor(seconds / 60);
    seconds -= tmpMinutes * 60;

    tmpSeconds = Math.floor(seconds);

    note.tmpHours = tmpHours;
    note.tmpMinutes = tmpMinutes;
    note.tmpSeconds = tmpSeconds;

    return note;
  }

  convertTime(note) {
    let seconds = 0;

    seconds += note.tmpHours * 60 * 60;
    seconds += note.tmpMinutes * 60;
    seconds += note.tmpSeconds;

    note.seconds = seconds;

    return note;
  }

  handle__saveNote = () => {
    const { selectedNote } = this.state;
    const _c = this.props.context;

    let url = _c.apiUrl("courseLessonNote", {});

    axios
      .post(
        url,
        {
          note: selectedNote,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { notes } = response.data;

          if (_c.isDebug()) {
            console.log(response.data);
          }

          const { lesson } = this.state;
          lesson.notes = notes;

          this.setState({ lesson, changesWereMade: false }, () => {
            this.props.onNoteSave(lesson);
            this.handle__closeNotePopup();
          });
        } catch {
          alert(
            "CourseLessonNote - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };
}

export default withContext(LessonNotes);
