import React, { Component } from "react";
import axios from "axios";
import CommunityLayout from "./layouts/CommunityLayout";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
//import { Link } from "react-router-dom";
//import { Icon } from "../_foundation/_buttons";
import withContext from "../../../context/contextHOC";
import ClassicForumLayout from "./layouts/thread/ClassicForumLayout";
import CommunityBackButton from "./CommunityBackButton";

class CommunityThread extends Component {
  state = {
    boardSlug: this.props.match.params.boardSlug,
    threadSlug: this.props.match.params.threadSlug,
    board: null,
    thread: null,
    status: "INIT",
    breadcrumbs: ["home", "communityAreas"],
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let layout = _c.setting("community_main_layout");
    if (!layout) {
      layout = "layout1";
    }

    let v__content = (
      <div className={`community-${layout}`}>
        <CommunityLayout
          view="thread"
          layout={layout}
          breadcrumbs={breadcrumbs}
          user={_c.getUser()}
          help="communityThread"
        >
          {this.view__showContent()}
        </CommunityLayout>
      </div>
    );

    return <div className="community-thread">{v__content}</div>;
  }

  view__showContent() {
    const { status } = this.state;
    const _c = this.props.context;

    if (status === "LOADED") {
      return (
        <React.Fragment>
          {this.view__showTitle()}
          {this.view__showThread()}
        </React.Fragment>
      );
    } else {
      return _c.loading();
    }
  }

  view__showTitle() {
    const { board, thread, status } = this.state;
    const _c = this.props.context;

    if (status === "LOADED") {
      let v__threadAuthor;
      if (thread.author) {
        v__threadAuthor = (
          <div className="thread-author">
            {_c.translate("community.thread.by")} {thread.author.firstname}{" "}
            {thread.author.lastname}
          </div>
        );
      }

      let v__backButton = (
        <CommunityBackButton
          url={_c
            .custom("urls.communityBoard")
            .replace(":boardSlug", board.slug)
            .replace(":areaSlug", board.area.slug)}
          text={_c.translate("community.thread.backToBoard", {
            boardName: board.name,
          })}
        />
      );

      let v__deleteButton;
      if (_c.isCommunityModerator()) {
        v__deleteButton = (
          <button
            className="small primary button"
            onClick={this.handle__deleteThread}
          >
            <Icon icon="trash" left />{" "}
            {_c.translate("community.moderator.thread.delete.button")}
          </button>
        );
      }

      let v__lockButton;
      if (_c.isCommunityModerator()) {
        if (thread.locked === true) {
          v__lockButton = (
            <button
              className="small primary button"
              onClick={this.handle__unlockThread}
            >
              <Icon icon="unlock" left />{" "}
              {_c.translate("community.moderator.thread.unlock.button")}
            </button>
          );
        } else {
          v__lockButton = (
            <button
              className="small primary button"
              onClick={this.handle__lockThread}
            >
              <Icon icon="lock" left />{" "}
              {_c.translate("community.moderator.thread.lock.button")}
            </button>
          );
        }
      }

      return (
        <div className="community-header">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={12}>
                {v__backButton}
                <h1>{thread.name}</h1>
                {v__threadAuthor}
              </Cell>
              <Cell sm={24} md={12} className="text-right">
                <div className="community-header-buttons">
                  {v__deleteButton}&nbsp;&nbsp;
                  {v__lockButton}
                </div>
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }
  }

  view__showThread() {
    const { thread } = this.state;
    const _c = this.props.context;

    if (!thread || !thread.posts) {
      return;
    }

    let threadLayout = _c.setting("community_thread_layout");
    if (!threadLayout) {
      threadLayout = "classic_forum";
    }

    let v__content;

    if (threadLayout === "classic_forum") {
      v__content = (
        <ClassicForumLayout
          thread={thread}
          onUpdate={(thread) => {
            this.setState({ thread });
          }}
          onPostLike={(post, type) => {
            this.handle__likePost(post, type);
          }}
          onDeletePost={(postId) => {
            this.handle__deletePost(postId);
          }}
          onCreateBlockQuote={(post) => {
            return this.handle__createBlockQuote(post);
          }}
        />
      );
    }

    return <React.Fragment>{v__content}</React.Fragment>;
  }

  handle__createBlockQuote = (post) => {
    const _c = this.props.context;
    let blockQuoteHtml;

    let v__quote = _c.translate("community.reply.quote.info");
    v__quote = v__quote
      .replace("{date}", post.created_at_f)
      .replace("{name}", post.author.displayname);

    blockQuoteHtml = `<p>${v__quote}</p><blockquote><p>${post.text_html}</p></blockquote>`;

    return blockQuoteHtml;
  };

  handle__likePost(post, type) {
    const _c = this.props.context;

    post.like_loading = true;
    this.setState({ post });

    let apiUrl = _c.apiUrl("communityLikePost", {
      postId: post.id,
    });

    axios
      .patch(
        apiUrl,
        { type: type },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const communityData = response.data.data;
            const { thread } = communityData;

            this.setState({ thread });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:post:like:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:post:like");
      });
  }

  handle__deletePost = (postId) => {
    const _c = this.props.context;

    if (window.confirm(_c.translate("community.post.deletePopup.message"))) {
      let apiUrl = _c.apiUrl("communityPost", {
        postId: postId,
      });

      axios
        .delete(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const communityData = response.data.data;
              const { thread } = communityData;

              if (thread) {
                this.setState({ thread });
                _c.createNotifictation(
                  _c.translate("community.post.deletePopup.notification.title"),
                  _c.translate(
                    "community.post.deletePopup.notification.message"
                  ),
                  "success"
                );
              } else {
                return _c.handleError(
                  { status: "AXIOS_RESPONSE_ERROR" },
                  "community:post:delete:empty"
                );
              }
            }
          } catch (error) {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "community:post:delete:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "community:post:delete");
        });
    }
  };

  handle__unlockThread = () => {
    const { thread } = this.state;

    const _c = this.props.context;

    if (
      window.confirm(_c.translate("community.moderator.thread.unlock.confirm"))
    ) {
      let apiUrl = _c.apiUrl("communityModeratorThreadUnlock", {
        thread: thread.id,
      });

      axios
        .get(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const communityData = response.data.data;
              const { board, thread } = communityData;
              this.setState({ board, thread }, () => {
                _c.createNotifictation(
                  _c.translate("community.moderator.thread.unlock.saved.title"),
                  _c.translate("community.moderator.thread.unlock.saved.text"),
                  "success"
                );
              });
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "community:moderator:thread:unlock:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "community:moderator:thread:unlock");
          }
        });
    }
  };

  handle__lockThread = () => {
    const { thread } = this.state;

    const _c = this.props.context;

    if (
      window.confirm(_c.translate("community.moderator.thread.lock.confirm"))
    ) {
      let apiUrl = _c.apiUrl("communityModeratorThreadLock", {
        thread: thread.id,
      });

      axios
        .get(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const communityData = response.data.data;
              const { board, thread } = communityData;
              this.setState({ board, thread }, () => {
                _c.createNotifictation(
                  _c.translate("community.moderator.thread.lock.saved.title"),
                  _c.translate("community.moderator.thread.lock.saved.text"),
                  "success"
                );
              });
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "community:moderator:thread:lock:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "community:moderator:thread:lock");
          }
        });
    }
  };

  handle__deleteThread = () => {
    const { thread, board, boardSlug } = this.state;

    const _c = this.props.context;

    if (
      window.confirm(_c.translate("community.moderator.thread.delete.confirm"))
    ) {
      let apiUrl = _c.apiUrl("communityModeratorThreadDelete", {
        thread: thread.id,
      });

      axios
        .get(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              window.location.href = _c
                .custom("urls.communityBoard")
                .replace(":areaSlug", board.area.slug)
                .replace(":boardSlug", boardSlug);
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "community:moderator:thread:delete:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "community:moderator:thread:delete");
          }
        });
    }
  };

  init() {
    const { boardSlug, threadSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("communityThread", {
      boardSlug,
      threadSlug,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const communityData = response.data.data;
            const { board, thread } = communityData;

            if (!board || !thread) {
              _c.permissionDenied();
              return;
            }

            const newBreadcrumb1 = {
              to: _c
                .custom("urls.communityBoard")
                .replace(":areaSlug", board.area.slug)
                .replace(":boardSlug", board.slug),
              id: "communityBoard",
              name: board.name,
            };

            const newBreadcrumb2 = {
              to: "/",
              id: "communityThread",
              name: thread.name,
            };

            _c.setPageTitle(thread.name);

            let { breadcrumbs } = this.state;
            breadcrumbs.push(newBreadcrumb1);
            breadcrumbs.push(newBreadcrumb2);

            this.setState({
              thread,
              board,
              status: "LOADED",
              breadcrumbs,
            });
          }
        } catch (error) {
          console.log(error);
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:thread:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:thread:init");
      });
  }
}

export default withContext(CommunityThread);
