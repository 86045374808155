import React, { Component } from "react";
import withContext from "../../context/contextHOC";

class Footer extends Component {
  render() {
    const _c = this.props.context;

    return (
      <footer>
        <div className="default-footer">
          <p className="text-center">
            &copy; Welt von Beyond | v{_c.getVersion()} |{" "}
            <a href={_c.urls.impressum}>Impressum</a> |{" "}
            <a href={_c.urls.datenschutz}>Datenschutz</a>
          </p>
        </div>
      </footer>
    );
  }
}

export default withContext(Footer);
