import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell } from "../foundation/_grid";
import { Icon } from "../foundation/_buttons";
import { isUndefined, isObject } from "util";
import withContext from "../../context/contextHOC";

class Breadcrumbs extends Component {
  state = {
    breadcrumbs: this.props.breadcrumbs,
  };

  componentDidUpdate(prevProps) {
    if (this.props.breadcrumbs !== prevProps.breadcrumbs) {
      this.setState({ breadcrumbs: this.props.breadcrumbs });
    }
  }

  render() {
    const { breadcrumbs } = this.state;

    if (!breadcrumbs) {
      return null;
    }

    return (
      <React.Fragment>
        <div id="breadcrumbs">{this.showBreadcrumbs()}</div>
      </React.Fragment>
    );
  }

  showBreadcrumbs() {
    const breadcrumbs = this.getBreadcrums();

    return (
      <Grid>
        <Row>
          <Cell sm={24}>
            {/*<Icon icon="angle-double-right" breadcrumb />*/}
            {breadcrumbs.map((breadcrumb, index) => {
              let link = "";
              if (isUndefined(breadcrumb.to)) {
                link = <span>{breadcrumb.name}</span>;
              } else {
                link = <Link to={breadcrumb.to}>{breadcrumb.name}</Link>;
              }

              if (index !== 0) {
                return (
                  <React.Fragment key={index}>
                    <Icon icon="angle-right" breadcrumb />
                    {link}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={breadcrumb.id}>{link}</React.Fragment>
                );
              }
            })}
          </Cell>
        </Row>
      </Grid>
    );
  }

  getBreadcrums() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let bcs = [];
    if (!isUndefined(breadcrumbs) && breadcrumbs.length > 0) {
      breadcrumbs.map((breadcrumb, index) => {
        let bc = undefined;
        if (breadcrumb === "dashboard") {
          bc = {
            id: "dashboard",
            to: "/home",
            name: (
              <span>
                <Icon icon="home" left /> Dashboard
              </span>
            ),
          };
        } else if (breadcrumb === "account") {
          bc = {
            id: "account",
            to: "/account",
            name: (
              <span>
                <Icon icon="user" left /> Mein Account
              </span>
            ),
          };
        } else if (breadcrumb === "password") {
          bc = {
            id: "password",
            to: "/password",
            name: "Passwort ändern",
          };
        } else if (breadcrumb === "404") {
          bc = {
            id: "404",
            to: undefined,
            name: "Fehler 404 - Seite nicht gefunden",
          };
        } else if (breadcrumb === "achievements") {
          bc = {
            id: "achievements",
            to: "/achievements",
            name: (
              <span>
                <Icon icon="trophy" left /> Achievement Room
              </span>
            ),
          };
        } else if (breadcrumb === "communityAreas") {
          bc = {
            id: "communityareas",
            to: _c.custom("urls.communityAreas"),
            name: <span>Community</span>,
          };
        } else if (breadcrumb === "faq") {
          bc = {
            id: "faq",
            to: "/faq",
            name: "FAQ - Häufig gestellte Fragen",
          };
        } else if (breadcrumb === "admin_dashboard") {
          bc = {
            id: "admin_dashboard",
            to: "/admin",
            name: (
              <span>
                <Icon icon="key" left /> Administration
              </span>
            ),
          };
        } else if (breadcrumb === "admin_members") {
          bc = {
            id: "admin_members",
            to: "/admin/members",
            name: "Mitgliederverwaltung",
          };
        } else if (breadcrumb === "admin_courses") {
          bc = {
            id: "admin_courses",
            to: "/admin/courses",
            name: "Kurse",
          };
        } else if (breadcrumb === "admin_community") {
          bc = {
            id: "admin_community",
            to: "/admin/community",
            name: "Community",
          };
        } else if (breadcrumb === "admin_livecalls") {
          bc = {
            id: "admin_livecalls",
            to: "/admin/livecalls",
            name: "Live Calls",
          };
        } else if (breadcrumb === "admin_letters") {
          bc = {
            id: "admin_letters",
            to: "/admin/letters",
            name: "Briefe an die Mitglieder",
          };
        } else if (breadcrumb === "admin_backgrounds") {
          bc = {
            id: "admin_backgrounds",
            to: "/admin/backgrounds",
            name: "Hintergrundbilder",
          };
        } else if (breadcrumb === "admin_settings") {
          bc = {
            id: "admin_settings",
            to: "/admin/settings",
            name: "Einstellungen",
          };
        } else if (breadcrumb === "admin_faq") {
          bc = {
            id: "admin_faq",
            to: "/admin/faq",
            name: "FAQ - Häufig gestellte Fragen",
          };
        } else if (breadcrumb === "home") {
          bc = {
            id: "home",
            to: "/home",
            name: "Meine Welt von Beyond",
          };
        } else {
          if (isObject(breadcrumb)) {
            bc = breadcrumb;
          } else {
            bc = {
              id: "unknown",
              to: "/",
              name: "[Unbekannt]",
            };
          }
        }

        // Show last entry grey / not clickable
        if (index === breadcrumbs.length - 1) {
          bc.to = undefined;
        }

        bcs.push(bc);
        return null;
      });
    }

    return bcs;
  }
}

export default withContext(Breadcrumbs);
