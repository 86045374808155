import React, { Component } from "react";
import { Icon } from "../../foundation/_buttons";
import { Link } from "react-router-dom";
import withContext from "../../../context/contextHOC";

class CommunityBackButton extends Component {
  state = {};

  render() {
    const _c = this.props.context;

    return (
      <div className="admin-back-button">
        <Link to={this.props.url}>
          <small>
            <Icon icon="angle-double-left" left />{" "}
            {this.props.text || _c.translate("general.backToOverview")}
          </small>
        </Link>
      </div>
    );
  }
}

export default withContext(CommunityBackButton);
