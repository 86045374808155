import React, { Component } from "react";
import { Grid, Row, Cell } from "../../../foundation/_grid";
import MainTopbar from "../../../topbars/mainTopbar";
import Breadcrumbs from "../../../topbars/Breadcrumbs";
import withContext from "../../../../context/contextHOC";
import Footer from "../../../layout/Footer";
import Layout1 from "./main/Layout1";
import Layout2 from "./main/Layout2";
import MMPopup from "../../../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../../../foundation/_buttons";
import axios from "axios";
import { Link } from "react-router-dom";

class CommunityLayout extends Component {
  state = {
    _updatableProps: ["view", "layout", "breadcrumbs", "user"],
    view: this.props.view,
    //layout: this.props.layout,
    layout: "layout2",
    breadcrumbs: this.props.breadcrumbs,
    help: this.props.help,
    user: this.props.user,
    usernameFound: false,
    username: "",
    usernameStatus: "SUCCESS",
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });

        if (propKey === "user") {
          const user = this.props[propKey];
          if (user) {
            const { username } = user;
            if (!username) {
              this.setState({
                usernameFound: false,
                username: user.suggestedUsername,
              });
            } else {
              this.setState({ usernameFound: true });
            }
          }
        }
      }
      return null;
    });
  }

  render() {
    let { view, layout, breadcrumbs } = this.state;
    const _c = this.props.context;

    let v__breadcrumbs = null;
    let classesBreadcrumbs = "";
    if (breadcrumbs) {
      v__breadcrumbs = <Breadcrumbs breadcrumbs={breadcrumbs} />;
      classesBreadcrumbs = "page-with-breadcrumbs";
    }

    if (!view) {
      view = "areas";
    }

    let mainContentStyles = {};

    let backgroundUrl = _c.setting(`design_bg_community`);
    if (backgroundUrl) {
      mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
    }

    let v__content;
    if (layout === "layout1") {
      v__content = <Layout1>{this.props.children}</Layout1>;
    } else if (layout === "layout2") {
      v__content = <Layout2>{this.props.children}</Layout2>;
    }

    /*if (user && user.email && !usernameFound) {
      v__content = (
        <React.Fragment>{this.view__showPopupNoUsername()}</React.Fragment>
      );
    }*/

    return (
      <div className={`page page-community-${view} ${classesBreadcrumbs}`}>
        <MainTopbar type="page" />
        {v__breadcrumbs}
        <main className="content" style={mainContentStyles}>
          <Grid>
            <Row>
              <Cell sm={24} md={24}>
                {v__content}
              </Cell>
            </Row>
          </Grid>
        </main>
        <Footer />
      </div>
    );
  }

  view__showPopupNoUsername() {
    const { username, usernameStatus } = this.state;
    const _c = this.props.context;

    let v__icon, v__message;
    if (usernameStatus === "LOADING") {
      v__icon = (
        <span className="community-nousername-checkicon-loading">
          <Icon icon="circle-o-notch fa-spin" />
        </span>
      );
      v__message = (
        <span className="community-nousername-checkicon-loading">
          <Icon icon="circle-o-notch fa-spin" left /> Benutzername wird
          überprüft...
        </span>
      );
    } else if (usernameStatus === "SUCCESS") {
      v__icon = (
        <span className="community-nousername-checkicon-success">
          <Icon icon="check" />
        </span>
      );
      v__message = (
        <span className="community-nousername-checkicon-success">
          <Icon icon="check" left /> Benutzername ist noch nicht vergeben.
        </span>
      );
    } else if (usernameStatus === "ERROR") {
      v__icon = (
        <span className="community-nousername-checkicon-error">
          <Icon icon="exclamation-triangle" />
        </span>
      );
      v__message = (
        <span className="community-nousername-checkicon-error">
          <Icon icon="exclamation-triangle" left /> Benutzername ist zu kurz
          oder schon vergeben
        </span>
      );
    }

    return (
      <MMPopup
        show={true}
        size="small"
        handleClose={this.handleClose}
        showCloseButton={false}
      >
        <h3>Benutzername vergeben</h3>
        <p>
          Um die Community-Features nutzen zu können, musst Du einen
          Benutzernamen anlegen. Mit diesem Benutzernamen können Dich andere
          Mitglieder markieren. Außerdem wird der Benutzername benötigt, um Dein
          Profil anzulegen.
        </p>
        <p>
          Der Benutzername muss mindestens fünf Zeichen lang sein und darf
          Kleinbuchstaben, Zahlen, Unterstriche (\"_\") und Punkte (\".\")
          beinhalten.
        </p>
        <Grid type="full">
          <Row margin="y">
            <Cell sm={22} md={22}>
              <input
                type="text"
                value={username}
                onChange={(e) => {
                  this.handle__editUsername(e.target.value);
                }}
                placeholder="Dein Benutzername"
              />
            </Cell>
            <Cell sm={2} md={2} className="community-nousername-checkicon">
              {v__icon}
            </Cell>
          </Row>
          <Row margin="y">
            <Cell sm={22} md={22}>
              <div className="community-nousername-checkmessage">
                {v__message}
              </div>
            </Cell>
          </Row>
        </Grid>
        <div className="popup-buttons">
          <Link
            className="small primary hollow button"
            to={_c.custom("urls.home")}
          >
            <Icon icon="times" left /> Abbrechen
          </Link>
          <button
            className="small primary button"
            onClick={this.handle__save}
            disabled={usernameStatus === "SUCCESS" ? false : true}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      </MMPopup>
    );
  }

  handle__editUsername(newValue) {
    newValue = newValue.replace(/[^a-z0-9_.]+|\s+/gim, "").toLowerCase();

    this.setState({ username: newValue }, () => {
      const { username } = this.state;

      if (!username) {
        this.setState({ usernameStatus: "ERROR" });
      } else {
        this.handle__checkUsername();
      }
    });
  }

  handleClose = () => {
    //
  };

  handle__checkUsername() {
    const { username } = this.state;
    const _c = this.props.context;

    this.setState({ usernameStatus: "LOADING" });

    let apiUrl = _c.apiUrl("community.checkUsername", { username });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          this.setState({ usernameStatus: status });
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:checkusername:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:checkusername:init");
      });
  }

  handle__save = () => {
    const { username } = this.state;
    const _c = this.props.context;

    this.setState({ usernameStatus: "LOADING" });

    let apiUrl = _c.apiUrl("community.checkUsername", { username });

    axios
      .post(
        apiUrl,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            window.location.reload();
          } else {
            this.setState({ usernameStatus: status });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:saveusername:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:saveusername:init");
      });
  };

  init() {
    const _c = this.props.context;
    _c.init();
  }
}

export default withContext(CommunityLayout);
