import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import withContext from "../../context/contextHOC";
import LogoData from "../../images/logos/gold-topbar-small.png";
import { Line } from "rc-progress";
import Avatar from "../user/Avatar";
import IconLetter from "../../images/icons/letter.png";
import PTPopup from "../ptPopup/PTPopup";
import axios from "axios";

class MainTopbar extends Component {
  state = {
    type: this.props.type || "page",
    showMobileNav: false,
    showUserMenu: false,
    showLetters: false,
    selectedLetter: null,
  };

  render() {
    const { type } = this.state;

    return (
      <React.Fragment>
        <div id="mainTopbar" className={`topbar topbar-${type}`}>
          {this.view__showContent()}
        </div>
        {this.view__showMobileNav()}
        {this.view__showSavingModal()}
        {this.view__showLetter()}
      </React.Fragment>
    );
  }

  view__showContent() {
    const { type } = this.state;

    const v__loginButton = this.view__loginButton();
    const v__mobileNavButton = this.view__mobileNavButton();

    const _c = this.props.context;
    let logoUrl = "/";
    if (_c.isUserLoggedIn()) {
      logoUrl = "/home";
    }

    let letterMenuStyles;
    const { showLetters } = this.state;
    if (showLetters === true) {
      letterMenuStyles = { display: "block" };
    } else {
      letterMenuStyles = { display: "none" };
    }

    let v__letter;

    if (_c.isUserLoggedIn()) {
      const user = _c.getUser();

      let v__letterBadge;

      if (user.new_letters && user.new_letters > 0) {
        v__letterBadge = (
          <span className="alert badge letter-badge">{user.new_letters}</span>
        );
      }

      let v__letterMainContent = (
        <div className="text-center padding">
          <small>Dein Postfach ist leer.</small>
        </div>
      );

      if (user && user.letters && user.letters.length > 0) {
        v__letterMainContent = (
          <ul className="letters">
            {user.letters.map((letter, index) => {
              let v__letterNew;
              if (letter.is_new) {
                v__letterNew = <span className="letter-new">Neu</span>;
              }

              return (
                <li key={index}>
                  <button
                    className="letter"
                    onClick={() => {
                      this.setState(
                        { selectedLetter: letter, showLetters: false },
                        () => {
                          if (letter.is_new === true) {
                            this.handle__letterRead(letter.id);
                          }
                        }
                      );
                    }}
                  >
                    <div className="letter-subject">{letter.subject}</div>
                    <div className="letter-text">
                      {v__letterNew}
                      {letter.previewText}
                    </div>
                  </button>
                </li>
              );
            })}
          </ul>
        );
      }

      v__letter = (
        <div className="dropdown-menu-wrapper" style={{ display: "inline" }}>
          <strong
            className="dropdown-menu-trigger topbar-letter-icon"
            onClick={() => {
              let { showLetters } = this.state;
              if (showLetters === true) {
                showLetters = false;
              } else {
                showLetters = true;
              }
              this.setState({ showLetters });
            }}
          >
            <img src={IconLetter} alt="Briefe" />
            {v__letterBadge}
          </strong>
          <div
            className="dropdown-menu"
            id="letterMenu"
            style={letterMenuStyles}
          >
            <div className="menu-content">{v__letterMainContent}</div>
          </div>
        </div>
      );
    }

    let v__community;
    if (_c.isUserLoggedIn() === true) {
      v__community = (
        <Link to={_c.custom("urls.communityAreas")}>
          <Icon icon="comments-o" left /> Community
        </Link>
      );
    }

    if (type === "index") {
      return (
        <div className="topbar-index">
          <Grid type="full">
            <Row padding="x">
              <Cell sm={12} md={6}>
                <div className="show-for-small-only">{v__mobileNavButton}</div>
              </Cell>
              <Cell
                sm={24}
                md={13}
                className="text-left medium-text-right hide-for-small-only"
              >
                <div className="index-topbar-nav hide-for-small-only">
                  <Link to="/home">
                    <Icon icon="globe" left /> Meine Welt von Beyond
                  </Link>
                  <Link to="/courses">
                    <Icon icon="youtube-play" left /> Kurse
                  </Link>
                  {v__community}
                </div>
              </Cell>
              <Cell sm={12} md={5} classes="text-right">
                <div className="hide-for-small-only">{v__loginButton}</div>
                <Link to={logoUrl} className="show-for-small-only">
                  <img className="top-logo" src={LogoData} alt="Logo" />
                </Link>
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className="topbar-page">
          <Grid>
            <Row>
              <Cell sm={12} md={6}>
                <div className="show-for-small-only">{v__mobileNavButton}</div>
                <Link to={logoUrl} className="hide-for-small-only">
                  <img className="top-logo" src={LogoData} alt="Logo" />
                </Link>
              </Cell>
              <Cell sm={24} md={13} className="text-right hide-for-small-only">
                <div className="topbar-nav hide-for-small-only">
                  <Link to="/courses">
                    <Icon icon="youtube-play" left /> Kurse
                  </Link>
                  {v__community}
                  <Link to="/faq">
                    <Icon icon="question-circle" left /> FAQ
                  </Link>
                  {v__letter}
                </div>
              </Cell>
              <Cell sm={12} md={5} classes="text-right">
                <div className="hide-for-small-only">{v__loginButton}</div>
                <span
                  className="show-for-small-only"
                  style={{ marginRight: "30px" }}
                >
                  {v__letter}
                </span>
                <Link to={logoUrl} className="show-for-small-only">
                  <img className="top-logo" src={LogoData} alt="Logo" />
                </Link>
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }
  }

  view__showLetter() {
    const { selectedLetter } = this.state;

    if (!selectedLetter) {
      return null;
    }

    return (
      <PTPopup
        show={true}
        size="medium"
        nopadding
        handleClose={() => {
          this.setState({ selectedLetter: null });
        }}
      >
        <div className="popup-letter">
          <h3>{selectedLetter.subject}</h3>
          <div
            className="popup-letter-text"
            dangerouslySetInnerHTML={{ __html: selectedLetter.text_html }}
          ></div>
        </div>
      </PTPopup>
    );
  }

  view__showMobileNav() {
    const { showMobileNav } = this.state;

    if (showMobileNav === true) {
      return (
        <div className="show-for-small-only" id="mobileNav">
          <ul className="mobile-menu">
            <li>
              <Link to="/">
                <Icon icon="home" left /> Startseite
              </Link>
            </li>
            <li>
              <Link to="/home">
                <Icon icon="globe" left /> Meine Welt von Beyond
              </Link>
            </li>
            <li>
              <Link to="/courses">
                <Icon icon="youtube-play" left /> Kurse
              </Link>
            </li>
          </ul>
          <div>&nbsp;</div>
          <ul className="mobile-menu">{this.view__showUserNav()}</ul>
        </div>
      );
    }

    return null;
  }

  view__mobileNavButton() {
    return (
      <span
        id="mobileNavButton"
        onClick={() => {
          this.toggleNav();
        }}
      >
        <Icon icon="bars" />
      </span>
    );
  }

  view__loginButton() {
    const _c = this.props.context;
    const { user } = _c;

    if (_c.userIsLoaded === false) {
      return <Icon icon="circle-o-notch fa-spin" />;
    } else if (user && user.active && user.active === true) {
      let menuStyles;
      const { showUserMenu } = this.state;
      if (showUserMenu === true) {
        menuStyles = { display: "block" };
      } else {
        menuStyles = { display: "none" };
      }

      return (
        <div className="dropdown-menu-wrapper">
          <strong
            className="dropdown-menu-trigger topbar-username"
            onClick={this.toggleUserMenu}
          >
            <Avatar user={user} size="main-topbar" />
            {_c.getFullName(user)} <Icon icon="caret-down" right />
          </strong>
          <div className="dropdown-menu" id="userMenu" style={menuStyles}>
            <ul>{this.view__showUserNav()}</ul>
          </div>
        </div>
      );
    } else {
      return <React.Fragment>{this.view__showLoginButton()}</React.Fragment>;
    }
  }

  view__showUserNav() {
    const _c = this.props.context;

    let menuItemAdmin = null;
    if (_c.isAdmin() === true) {
      menuItemAdmin = (
        <React.Fragment>
          <li className="separator">
            <hr />
          </li>
          <li>
            <Link to="/admin">Administrationsbereich</Link>
          </li>
        </React.Fragment>
      );
    }

    if (_c.isUserLoggedIn()) {
      return (
        <React.Fragment>
          <li>
            <Link to="/account">Mein Account</Link>
          </li>
          <li>
            <Link to="/password">Passwort ändern</Link>
          </li>
          {menuItemAdmin}
          <li className="separator">
            <hr />
          </li>
          <li>
            <button
              onClick={() => {
                _c.handleLogout();
              }}
            >
              Abmelden
            </button>
          </li>
        </React.Fragment>
      );
    } else {
      return <li>{this.view__showLoginButton()}</li>;
    }
  }

  view__showLoginButton() {
    return (
      <button
        onClick={() => {
          localStorage.setItem("page_after_login", window.location.href);
          window.location.href = "/login";
        }}
        className="topbar-login-button"
      >
        <Icon icon="user-circle-o " left /> &nbsp;Anmelden
      </button>
    );
  }

  view__showSavingModal() {
    const _c = this.props.context;

    if (_c.isSaving()) {
      const savingType = _c.getSavingType();
      let extraText;
      if (savingType === "pdfmail") {
        extraText = (
          <div className="saving-popup-extra-text">
            Die Erstellung von PDFs und der Versand von E-Mails können ggfs. bis
            zu 20 Sekunden dauern.
          </div>
        );
      } else if (savingType === "pdf") {
        extraText = (
          <div className="saving-popup-extra-text">
            Die Erstellung von PDFs kann ggfs. bis zu 20 Sekunden dauern.
          </div>
        );
      } else if (savingType === "mail") {
        extraText = (
          <div className="saving-popup-extra-text">
            Der Versand von E-Mails kann ggfs. bis zu 20 Sekunden dauern.
          </div>
        );
      }

      let savingProgress = _c.getSavingProgress();

      let progressBar = (
        <div className="saving-popup-icon">
          <Icon icon="circle-o-notch fa-spin" />
        </div>
      );

      if (savingProgress !== null) {
        progressBar = (
          <div className="saving-popup-progressbar">
            <Line
              percent={savingProgress}
              strokeWidth="3"
              strokeColor="#d0a98f"
            />
          </div>
        );
      }

      return (
        <div className="saving-modal">
          <div className="saving-popup">
            <div className="saving-popup-content">
              {progressBar}
              <div className="saving-popup-text">Wird gespeichert ...</div>
              {extraText}
            </div>
          </div>
        </div>
      );
    }
  }

  toggleUserMenu = () => {
    const { showUserMenu } = this.state;
    if (showUserMenu === true) {
      this.setState({ showUserMenu: false });
    } else {
      this.setState({ showUserMenu: true });
    }
  };

  toggleNav() {
    const { showMobileNav } = this.state;

    if (showMobileNav === true) {
      this.setState({ showMobileNav: false });
    } else {
      this.setState({ showMobileNav: true });
    }
  }

  handle__letterRead(letterId) {
    const _c = this.props.context;

    const url = _c.apiUrl("letterRead", {
      letterId: letterId,
    });

    axios
      .patch(
        url,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { letter, user } = response.data;

          _c.updateUser(user);

          this.setState({
            selectedLetter: letter,
          });
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }
}

export default withContext(MainTopbar);
