import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../foundation/_grid";
import DefaultLayout from "../layout/DefaultLayout";
import axios from "axios";
import Box from "../layout/Box";
import { Icon } from "../foundation/_buttons";
import { Link } from "react-router-dom";

import Quiz1Shelf from "../../images/quiz/quiz1-shelf.jpg";
import Quiz2Table from "../../images/quiz/quiz2-table.jpg";

class Quiz extends Component {
  state = {
    devMode: false,
    baseUrl: "/quiz",
    slug: this.props.match.params.slug,
    quiz: null,
    userAnswers: [],
    lastQuestion: null,
    lastStep: 0,
    step: 1,
    quiz1Id: 1, // Mystische Kreaturen Quiz Id
    quiz2Id: 2, // Magische Objekte
    uppercase: [
      "",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ],
    lowercase: [
      "",
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.login();
    this.init();
  }

  render() {
    const { quiz } = this.state;

    if (!quiz || !quiz.id) {
      return null;
    }

    return (
      <div className={`page page-quiz quiz-${quiz.id}`}>
        <DefaultLayout>{this.view__showContent()}</DefaultLayout>
      </div>
    );
  }

  view__showContent() {
    const { quiz } = this.state;
    const _c = this.props.context;

    if (!quiz) {
      return _c.loading();
    }

    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={12} mdo={6}>
            {this.view__showTitle()}

            {this.view__showBackButton()}
            {this.view__showQuestion()}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTitle() {
    const { quiz, quiz1Id, step } = this.state;

    if (quiz.id === quiz1Id && step === "finished") {
      return null;
    } else {
      let v__description;
      if (quiz.description) {
        v__description = (
          <div
            className="quiz-description"
            dangerouslySetInnerHTML={{ __html: quiz.description }}
          />
        );
      }

      let v__pretitle;
      if (quiz.course) {
        v__pretitle = (
          <a href={`/course/${quiz.course.slug}`}>
            <Icon icon="angle-double-left" left /> Zurück zum Kurs
          </a>
        );
      }

      return (
        <React.Fragment>
          {v__pretitle}
          <h1>{quiz.title}</h1>
          {v__description}
        </React.Fragment>
      );
    }
  }

  view__showBackButton() {
    const { quiz, step } = this.state;

    if (!quiz) {
      return null;
    }

    if (quiz.allow_going_back !== true) {
      return null;
    }

    if (step === 1 || step === "last" || step === "finished") {
      return <div className="quiz-back-button">&nbsp;</div>;
    }

    return (
      <div className="quiz-back-button">
        <button onClick={this.handle__goBack}>
          <Icon icon="angle-left" left /> Zurück zur vorherigen Frage
        </button>
      </div>
    );
  }

  view__showQuestion() {
    const { quiz, step, lastStep, lastQuestion } = this.state;

    //return this.view__showResult();

    let question;
    if (step === "last") {
      question = lastQuestion;
    } else if (step > lastStep || step === "finished") {
      return this.view__showResult();
    }

    quiz.questions.map((tmpQuestion) => {
      if (tmpQuestion.position === step) {
        question = tmpQuestion;
      }
      return null;
    });

    const questionText = this.view__questionText(question);

    let v__answers;
    if (quiz.layout_answers === "two") {
      v__answers = (
        <div className="layout-two">
          <div className="quiz-question-answers">
            <Grid type="full">
              <Row margin="xy">
                {question.answers.map((answer, index) => {
                  let v__yourAnswer;
                  let buttonClasses;
                  if (this.isUserAnswer(question.id, answer.id)) {
                    buttonClasses = "selected";
                    v__yourAnswer = (
                      <span className="quiz-questions-answer-youranswer">
                        Deine Antwort
                      </span>
                    );
                  }

                  return (
                    <Cell sm={24} md={12} key={index}>
                      <div className="quiz-question-answer">
                        {v__yourAnswer}
                        <button
                          className={buttonClasses}
                          onClick={() => {
                            this.handle__answer(question.id, answer.id);
                          }}
                        >
                          {this.view__answerText(answer)}
                        </button>
                      </div>
                    </Cell>
                  );
                })}
              </Row>
            </Grid>
          </div>
        </div>
      );
    } else if (quiz.layout_answers === "three") {
      v__answers = (
        <div className="layout-three">
          <div className="quiz-question-answers">
            <Grid type="full">
              <Row margin="xy">
                {question.answers.map((answer, index) => {
                  let v__yourAnswer;
                  let buttonClasses;
                  if (this.isUserAnswer(question.id, answer.id)) {
                    buttonClasses = "selected";
                    v__yourAnswer = (
                      <span className="quiz-questions-answer-youranswer">
                        Deine Antwort
                      </span>
                    );
                  }

                  return (
                    <Cell sm={24} md={8} key={index}>
                      <div className="quiz-question-answer">
                        {v__yourAnswer}
                        <button
                          className={buttonClasses}
                          onClick={() => {
                            this.handle__answer(question.id, answer.id);
                          }}
                        >
                          {this.view__answerText(answer)}
                        </button>
                      </div>
                    </Cell>
                  );
                })}
              </Row>
            </Grid>
          </div>
        </div>
      );
    } else if (quiz.layout_answers === "four") {
      v__answers = (
        <div className="layout-four">
          <div className="quiz-question-answers">
            <Grid type="full">
              <Row margin="xy">
                {question.answers.map((answer, index) => {
                  let v__yourAnswer;
                  let buttonClasses;
                  if (this.isUserAnswer(question.id, answer.id)) {
                    buttonClasses = "selected";
                    v__yourAnswer = (
                      <span className="quiz-questions-answer-youranswer">
                        Deine Antwort
                      </span>
                    );
                  }

                  return (
                    <Cell sm={24} md={6} key={index}>
                      <div className="quiz-question-answer">
                        {v__yourAnswer}
                        <button
                          className={buttonClasses}
                          onClick={() => {
                            this.handle__answer(question.id, answer.id);
                          }}
                        >
                          {this.view__answerText(answer)}
                        </button>
                      </div>
                    </Cell>
                  );
                })}
              </Row>
            </Grid>
          </div>
        </div>
      );
    } else {
      v__answers = (
        <div className="layout-list">
          <ul className="quiz-question-answers">
            {question.answers.map((answer, index) => {
              let v__yourAnswer;
              let buttonClasses;
              if (this.isUserAnswer(question.id, answer.id)) {
                buttonClasses = "selected";
                v__yourAnswer = (
                  <span className="quiz-questions-answer-youranswer">
                    Deine Antwort
                  </span>
                );
              }

              return (
                <li className="quiz-question-answer" key={index}>
                  {v__yourAnswer}
                  <button
                    className={buttonClasses}
                    onClick={() => {
                      this.handle__answer(question.id, answer.id);
                    }}
                  >
                    {this.view__answerText(answer)}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return (
      <div className="quiz-question">
        <Box>
          {questionText}
          {v__answers}
        </Box>
      </div>
    );
  }

  view__questionText(question) {
    const { quiz, uppercase, lowercase, step } = this.state;

    let questionText = <h3>{question.text}</h3>;
    if (quiz.numbering_questions === "question_numbers") {
      if (step === "last") {
        questionText = (
          <React.Fragment>
            <div className="pretitle">Abschließende Frage</div>
            <h3>{question.text}</h3>
          </React.Fragment>
        );
      } else {
        questionText = (
          <React.Fragment>
            <div className="pretitle">Frage {question.position}</div>
            <h3>{question.text}</h3>
          </React.Fragment>
        );
      }
    } else if (quiz.numbering_questions === "question_numbers_all") {
      if (step === "last") {
        questionText = (
          <React.Fragment>
            <div className="pretitle">Abschließende Frage</div>
            <h3>{question.text}</h3>
          </React.Fragment>
        );
      } else {
        questionText = (
          <React.Fragment>
            <div className="pretitle">
              Frage {question.position} / {quiz.questions.length}
            </div>
            <h3>{question.text}</h3>
          </React.Fragment>
        );
      }
    } else if (quiz.numbering_questions === "numbers") {
      questionText = (
        <h3>
          {question.position}. {question.text}
        </h3>
      );
    } else if (quiz.numbering_questions === "uppercase") {
      questionText = (
        <h3>
          {uppercase[question.position]}. {question.text}
        </h3>
      );
    } else if (quiz.numbering_questions === "lowercase") {
      questionText = (
        <h3>
          {lowercase[question.position]}. {question.text}
        </h3>
      );
    }

    return questionText;
  }

  view__answerText(answer) {
    const { quiz, uppercase, lowercase, devMode } = this.state;

    let answerText = answer.text;
    if (quiz.numbering_answers === "numbers") {
      answerText = `${answer.position}. ${answer.text}`;
    } else if (quiz.numbering_answers === "uppercase") {
      answerText = `${uppercase[answer.position]}. ${answer.text}`;
    } else if (quiz.numbering_answers === "lowercase") {
      answerText = `${lowercase[answer.position]}) ${answer.text}`;
    }

    if (devMode === true) {
      const answerCategories = {
        Feuer: "🔥",
        Wasser: "💦",
        Erde: "🌳",
        Luft: "💨",
      };

      answer.answer_categories.map((answerCategory) => {
        answerText = answerCategories[answerCategory] + " " + answerText;
        return null;
      });
    }

    //return `${answerText} [${answer.answer_category}]`;
    return answerText;
  }

  view__showResult() {
    const { quiz, baseUrl } = this.state;

    if (quiz.show_pre_result === true) {
      return this.view__showPreResult();
    } else {
      this.props.history.push(`${baseUrl}/${quiz.slug}/result`);
    }
  }

  view__showPreResult() {
    const { quiz, baseUrl, quiz1Id, quiz2Id } = this.state;

    if (quiz.id === quiz1Id) {
      return this.view__showPreResultQuiz1();
    } else if (quiz.id === quiz2Id) {
      return this.view__showPreResultQuiz2();
    } else {
      return (
        <div className="quiz-pre-result">
          <Box>
            <h3>Geschafft!</h3>
            <p className="lead">
              Herzlichen Glückwunsch! Du hast alle Fragen beantwortet! Im
              nächsten Schritt wird Dein Ergebnis ermittelt.
            </p>
            <div className="quiz-preresult-button">
              <Link
                to={`${baseUrl}/${quiz.slug}/result`}
                className="small primary hollow button"
              >
                Mein Ergebnis anzeigen <Icon icon="angle-right" right />
              </Link>
            </div>
          </Box>
        </div>
      );
    }
  }

  view__showPreResultQuiz1() {
    const { quiz, baseUrl } = this.state;

    return (
      <div className="quiz-pre-result">
        <h1>
          Der Kreis der mystischen Kreaturen{" "}
          <br className="hide-for-small-only" />
          hat eine Entscheidung getroffen
        </h1>
        <p className="lead">
          Im nächsten Schritt wird Dir der Kreis der mystischen Kreaturen seine
          Entscheidung mitteilen.
        </p>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={14} mdo={5}>
              <Box size="nopadding">
                <img src={Quiz1Shelf} alt="" />
              </Box>
            </Cell>
          </Row>
        </Grid>

        <div className="quiz-preresult-button">
          <Link
            to={`${baseUrl}/${quiz.slug}/result`}
            className="large primary hollow button"
          >
            Zu Deinem Ergebnis <Icon icon="angle-right" right />
          </Link>
        </div>
      </div>
    );
  }

  view__showPreResultQuiz2() {
    const { quiz, baseUrl } = this.state;

    return (
      <div className="quiz-pre-result">
        <h1>
          William Morgan hat sich entschieden, welches magische Objekt er Dir
          anvertrauen wird.
        </h1>
        <p className="lead">
          Im nächsten Schritt wird Dir William Morgan seine Entscheidung
          mitteilen.
        </p>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={14} mdo={5}>
              <Box size="nopadding">
                <img src={Quiz2Table} alt="" />
              </Box>
            </Cell>
          </Row>
        </Grid>

        <div className="quiz-preresult-button">
          <Link
            to={`${baseUrl}/${quiz.slug}/result`}
            className="large primary hollow button"
          >
            Dein Ergebnis <Icon icon="angle-right" right />
          </Link>
        </div>
      </div>
    );
  }

  isUserAnswer(questionId, answerId) {
    const { userAnswers } = this.state;

    let isUserAnswer = false;

    if (userAnswers && userAnswers[questionId]) {
      if (userAnswers[questionId].answer === answerId) {
        isUserAnswer = true;
      }
    }

    return isUserAnswer;
  }

  handle__goBack = () => {
    let { step } = this.state;
    if (step > 1) {
      step--;
      this.setState({ step });
    }
  };

  handle__answer(questionId, answerId) {
    const { quiz } = this.state;
    let { step } = this.state;
    const _c = this.props.context;

    let url = _c.apiEndpoints.publicQuizAnswer;

    let postData = {};

    if (_c.isUserLoggedIn() === true) {
      url = _c.apiEndpoints.quizAnswer;
    } else {
      let tmpUserId = _c.getTmpUserId();
      if (tmpUserId) {
        postData.tmp_user = tmpUserId;
      }
    }

    axios
      .post(url + "/" + quiz.id + "/" + questionId + "/" + answerId, postData, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { userAnswers, result, user } = response.data;

          if (_c.isDebug()) {
            console.log(response.data);
          }

          if (user.type === "tmp") {
            _c.setTmpUserId(user.id);
          }

          if (
            result &&
            result.status &&
            result.status === "MULTIPLE" &&
            result.question
          ) {
            step = "last";
            const lastQuestion = result.question;

            this.setState({ step, lastQuestion, userAnswers });
          } else if (
            result &&
            result.status &&
            result.status === "FINISHED" &&
            result.result
          ) {
            step = "finished";
            this.setState({ step, userAnswers });
          } else {
            step++;
            this.setState({ step, userAnswers });
          }
        } catch {
          alert(
            "Quiz User Answer - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }

  init() {
    const { slug } = this.state;
    const _c = this.props.context;

    let url = _c.apiEndpoints.publicQuiz;

    if (_c.isUserLoggedIn() === true) {
      url = _c.apiEndpoints.quiz;
    }

    axios
      .get(url + "/" + slug, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { user, quiz } = response.data;

          let pageTitle = quiz.title;
          _c.setPageTitle(pageTitle);

          let lastStep = 0;
          quiz.questions.map((question) => {
            if (question.position > lastStep) {
              lastStep = question.position;
            }
            return null;
          });

          this.setState({ quiz, lastStep });

          if (_c.isDebug()) {
            console.log(response.data);
          }

          if (user === null) {
            _c.initFinished();
          } else {
            _c.updateUser(user, () => {
              _c.initFinished();
            });
          }
        } catch {
          alert(
            "Quiz - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(Quiz);
