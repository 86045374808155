import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Link } from "react-router-dom";
import DefaultLayout from "../layout/DefaultLayout";
import Box from "../layout/Box";

class ChangePassword extends Component {
  state = {
    showMsgSaved: false,
    showErrorEmpty: false,
    showErrorOldWrong: false,
    showErrorNewWrong: false,
    oldPassword: "",
    newPassword: "",
    newPassword2: ""
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <DefaultLayout
        breadcrumbs={["dashboard", "account", "password"]}
        page="password"
      >
        <Grid>
          <Row>
            <Cell sm={24} md={14}>
              <h2>Passwort ändern</h2>
              <p className="lead">
                Hier kannst Du Dein Passwort ändern. Gib dazu einfach Dein
                aktuelles Passwort und das anschließend das neue Passwort
                zweimal ein.
              </p>
              {this.view__showMsgSaved()}
              {this.view__showErrorEmpty()}
              {this.view__showErrorOldWrong()}
              {this.view__showErrorNewWrong()}
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={14}>
              <Box>
                <label>
                  Altes Passwort
                  <input
                    type="password"
                    placeholder="Dein altes Passwort"
                    value={this.state.oldPassword}
                    onChange={this.handle__edit("oldPassword")}
                  />
                </label>
              </Box>

              <Box>
                <label>
                  Neues Passwort
                  <input
                    type="password"
                    placeholder="Dein neues Passwort"
                    value={this.state.newPassword}
                    onChange={this.handle__edit("newPassword")}
                  />
                </label>

                <label>
                  Neues Passwort wiederholen
                  <input
                    type="password"
                    placeholder="Dein neues Passwort wiederholen"
                    value={this.state.newPassword2}
                    onChange={this.handle__edit("newPassword2")}
                  />
                </label>
              </Box>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={14}>
              <div className="page-buttons">
                <Link to="/dashboard" className="primary hollow button">
                  <Icon icon="times" left /> Abbrechen
                </Link>
                <button
                  className="primary button"
                  onClick={() => {
                    this.checkUserInput();
                  }}
                >
                  <Icon icon="check" left /> Passwort ändern
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }

  view__showMsgSaved() {
    if (this.state.showMsgSaved === true) {
      return (
        <div className="success callout">
          <strong>Dein Passwort wurde geändert!</strong>
        </div>
      );
    }
  }

  view__showErrorEmpty() {
    if (this.state.showErrorEmpty === true) {
      return (
        <div className="alert callout">
          <strong>Bitte fülle alle Felder aus.</strong>
        </div>
      );
    }
  }

  view__showErrorOldWrong() {
    if (this.state.showErrorOldWrong === true) {
      return (
        <div className="alert callout">
          <strong>
            Das von Dir eingegebene alte Passwort ist nicht korrekt.
          </strong>
        </div>
      );
    }
  }

  view__showErrorNewWrong() {
    if (this.state.showErrorNewWrong === true) {
      return (
        <div className="alert callout">
          <strong>Die beiden neuen Passwörter stimmen nicht überein.</strong>
        </div>
      );
    }
  }

  handle__edit = name => event => {
    this.setState({ [name]: event.target.value });
  };

  resetMessages(callback) {
    this.setState({
      showErrorEmpty: false,
      showErrorOldWrong: false,
      showErrorNewWrong: false,
      showMsgSaved: false
    });

    callback();
  }

  checkUserInput() {
    const { oldPassword, newPassword, newPassword2 } = this.state;

    if (!oldPassword || !newPassword || !newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorEmpty: true });
      });

      return;
    }

    if (newPassword !== newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorNewWrong: true });
      });
      return;
    }

    // If Old Password is correct will be checked in save()
    this.save();
  }

  save() {
    const { oldPassword, newPassword } = this.state;
    const _c = this.props.context;

    _c.setIsSaving(true, 1);

    axios
      .patch(
        _c.apiEndpoints.password,
        {
          oldPassword: oldPassword,
          newPassword: newPassword
        },
        { headers: _c.headers }
      )
      .then(response => {
        if (response.data === "oldWrong") {
          this.resetMessages(() => {
            this.setState({ showErrorOldWrong: true });
          });
        } else if (response.data === "OK") {
          _c.setIsSaving(false);

          this.resetMessages(() => {
            this.setState({
              showMsgSaved: true,
              oldPassword: "",
              newPassword: "",
              newPassword2: ""
            });
          });
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  init() {
    const _c = this.props.context;

    axios
      .get(this.props.context.apiEndpoints.index, {
        headers: _c.getHeaders()
      })
      .then(response => {
        try {
          const { user } = response.data;

          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.updateUser(user);
          _c.initFinished();
        } catch {
          alert(
            "Change Password - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch(error => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(ChangePassword);
