import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import { Icon } from "../../foundation/_buttons";
import ReactTooltip from "react-tooltip";

class AdminCourses extends Component {
  state = {
    courses: null,
    status: "INIT",
    breadcrumbs: ["admin_dashboard", "admin_courses"],
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Kurse");
  }

  render() {
    const { breadcrumbs } = this.state;

    return (
      <AdminLayout nav="courses" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        <div className="admin-courses">{this.view__showList()}</div>
      </AdminLayout>
    );
  }

  view__showHeadline() {
    return <h1>Kurse</h1>;
  }

  view__showPageActions() {
    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a href={"/admin/courses/new"} className="button">
            <Icon icon="plus" left /> Kurs hinzufügen
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showList() {
    const { courses } = this.state;
    const _c = this.props.context;

    if (!courses) {
      return _c.loading();
    }

    if (courses && isArray(courses) && courses.length === 0) {
      return <p className="text-center">Keine Kurse gefunden.</p>;
    }

    return (
      <React.Fragment>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showListDesktop(courses)}
          {this.view__showListMobile(courses)}
        </Box>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    const _c = this.props.context;

    return (
      <div className="admin-list-title">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <Grid type="full">
          <Row>
            <Cell md={3}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={13}>
              <div className="padding">Kurs</div>
            </Cell>
            <Cell md={4}>
              <div className="padding">Digistore Produkt IDs</div>
            </Cell>
            <Cell md={2} className="text-center">
              <div className="padding">
                <span data-tip="Kurs veröffentlicht?">
                  <Icon icon="unlock-alt" />
                </span>
              </div>
            </Cell>
            <Cell md={2} className="text-center">
              <div className="padding">
                <span data-tip="Kurs sichtbar für Nuchtkäufer?">
                  <Icon icon="eye" />
                </span>
              </div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(courses) {
    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {courses.map((course, index) => {
            let courseImage;
            if (course.banner) {
              courseImage = (
                <img
                  src={course.banner}
                  alt="Vorschau"
                  className="admin-course-image"
                />
              );
            }

            let v__description;
            if (course.description_preview) {
              v__description = (
                <div className="course-description">
                  {course.description_preview}
                </div>
              );
            }

            let v__published, v__visible;

            if (course.published === true) {
              v__published = <Icon icon="check" />;
            }

            if (course.visible === true) {
              v__visible = <Icon icon="check" />;
            }

            let v__digistoreIds;
            if (course.digistore_product_ids) {
              course.digistore_product_ids.map((digistoreId, index) => {
                if (index === 0) {
                  v__digistoreIds = digistoreId;
                } else {
                  v__digistoreIds = `${v__digistoreIds}, ${digistoreId}`;
                }
                return null;
              });
            }

            return (
              <li className="admin-list-item" key={index}>
                <Link
                  to={"/admin/courses/:id".replace(":id", course.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell md={3} className="text-center">
                        <div className="padding">{courseImage}</div>
                      </Cell>
                      <Cell md={13}>
                        <div className="padding">
                          <div className="course-name">{course.name}</div>
                          {v__description}
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="padding">{v__digistoreIds}</div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            {v__published}
                          </div>
                        </div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">{v__visible}</div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showListMobile() {
    return (
      <div className="show-for-small-only">
        <p>mobile</p>
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("adminCourses");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, user } = response.data;
          _c.updateUser(user);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { courses } = apiResponseData;
            this.setState(
              {
                courses,
                status: "LOADED",
              },
              () => {
                _c.initFinished();
              }
            );
          }
        } catch {}
      })
      .catch((error) => {});
  }
}

export default withContext(AdminCourses);
