import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import axios from "axios";
import AdminLayout from "./AdminLayout";
import Box from "../layout/Box";
import { Cell, Grid, Row } from "../foundation/_grid";
import { Icon } from "../foundation/_buttons";
import MMPopup from "../mmcomponents/mmpopup/MMPopup";
import Dropzone from "react-dropzone";
import PTTabs from "../pttabs/PTTabs";

class AdminBackgrounds extends Component {
  state = {
    settings: null,
    selectedSetting: null,
    tabs: {},
    selectedTab: "index",
  };

  componentDidMount() {
    this.setState({
      tabs: {
        index: {
          name: "Index (Startseite)",
          icon: "globe",
        },
        intern: {
          name: "Geschützter Bereich",
          icon: "key",
        },
      },
    });
    this.init();
    this.props.context.setPageTitle("Admin Hintergrundbilder");
  }

  render() {
    return (
      <AdminLayout
        nav="backgrounds"
        breadcrumbs={["admin_dashboard", "admin_backgrounds"]}
      >
        <h1>Hintergründe</h1>
        {this.view__showTabs()}
        {this.view__showBackgrounds()}
        {this.view__showPopup()}
      </AdminLayout>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <PTTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPopup() {
    const { selectedSetting } = this.state;
    const _c = this.props.context;

    if (!selectedSetting) {
      return null;
    }

    return (
      <MMPopup
        show={true}
        size="medium"
        nopadding
        handleClose={this.hidePopupImageUpload}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {
            const picture = acceptedFiles[0];

            const reader = new FileReader();

            reader.addEventListener(
              "load",
              function () {
                const formData = new FormData();

                formData.append("file", picture);
                formData.append("name", picture.name);
                formData.append("background_key", selectedSetting);

                const apiUrl = _c.apiUrl("adminBackground");

                axios
                  .post(apiUrl, formData, {
                    headers: _c.headers,
                  })
                  .then((response) => {
                    try {
                      if (_c.isDebug()) {
                        console.log("API RESPONSE", response.data);
                      }

                      this.hidePopupImageUpload();

                      _c.handleApiResponse(response.data, true);

                      const { app } = response.data;

                      this.setState({ settings: app.settings });

                      _c.createNotifictation(
                        "Hintergrund gespeichert",
                        "Der Hintergrund [" +
                          selectedSetting +
                          "] wurde gespeichert.",
                        "success"
                      );
                    } catch {
                      return _c.handleError(
                        { status: "AXIOS RESPONSE ERROR" },
                        "background:response"
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("ERROR", error);
                  });
              }
                .bind(this)
                .bind(selectedSetting),
              false
            );

            if (picture) {
              reader.readAsDataURL(picture);
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div id="avatarDropZone">
                  <h3>Bild hochladen</h3>
                  <div id="avatarDropZoneText">
                    <p>
                      <Icon icon="upload" />
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          "Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab oder<br />klicke hier, um ein Bild hochzuladen.",
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </MMPopup>
    );
  }

  view__showBackgrounds = () => {
    const { settings, selectedTab } = this.state;

    if (!settings) {
      return null;
    }

    const _c = this.props.context;

    let backgroundKeys = {};

    if (selectedTab === "index") {
      backgroundKeys = {
        design_bg_index_hero: "Hero Image (Großes Bild / Tür)",
        design_bg_index_williammorgan:
          "Was ist die Welt von Beyond (William Morgan)",
        design_bg_index_box1: "Box 1 (Quiz Mystische Kreatur)",
        design_bg_index_box2: "Box 2 (Quiz Magisches Objekt)",
        design_bg_index_box3: "Box 3 (Phönix und Magier)",
        design_bg_index_box4: "Box 4 (Membership)",
        design_bg_index_videos: "Videos",
      };
    } else if (selectedTab === "intern") {
      backgroundKeys = {
        design_bg_home: "Home-Seite",
        design_bg_courses: "Kursübersicht",
        design_bg_course: "Kurs Einzelseite",
        design_bg_community: "Community-Bereich",
        design_bg_faq: "FAQ-Bereich",
      };
    }

    return (
      <div className="admin-backgrounds">
        {Object.keys(backgroundKeys).map((backgroundKey, index) => {
          let settingValue = settings[backgroundKey].value;

          if (!settingValue) {
            settingValue = "";
          }

          return (
            <Box key={index}>
              <h3>{backgroundKeys[backgroundKey]}</h3>
              <Grid type="full">
                <Row>
                  <Cell sm={19} md={19}>
                    <input
                      type="text"
                      value={settingValue}
                      placeholder="URL zum Bild"
                      style={{ marginBottom: "0", height: "auto" }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        settingValue = newValue;
                        settings[backgroundKey].value = newValue;
                        this.setState({ settings });
                      }}
                    />
                  </Cell>
                  <Cell sm={5} md={5}>
                    <button
                      className="primary hollow button"
                      onClick={() => {
                        this.setState({ selectedSetting: backgroundKey });
                      }}
                    >
                      <Icon icon="upload" />
                    </button>
                    <button
                      className="primary button"
                      onClick={() => {
                        let apiUrl = _c.apiUrl("setting");

                        axios
                          .post(
                            apiUrl,
                            {
                              key: backgroundKey,
                              value: settingValue,
                            },
                            {
                              headers: _c.getHeaders(),
                            }
                          )
                          .then((response) => {
                            try {
                              if (_c.isDebug()) {
                                console.log("API RESPONSE", response.data);
                              }

                              _c.handleApiResponse(response.data, true);

                              _c.createNotifictation(
                                "Hintergrund gespeichert",
                                "Der Hintergrund [" +
                                  backgroundKey +
                                  "] wurde gespeichert.",
                                "success"
                              );
                            } catch {
                              return _c.handleError(
                                { status: "AXIOS RESPONSE ERROR" },
                                "setting:response"
                              );
                            }
                          })
                          .catch((error) => {
                            if (error.response) {
                              return _c.handleError(error, "setting");
                            }
                          });
                      }}
                    >
                      <Icon icon="check" /> Speichern
                    </button>
                  </Cell>
                </Row>
              </Grid>
            </Box>
          );
        })}
      </div>
    );
  };

  hidePopupImageUpload = () => {
    this.setState({ selectedSetting: null });
  };

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminDashboard, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);

          const { app } = response.data;

          this.setState({ settings: app.settings });
        } catch {
          alert(
            "Admin Backgrounds - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminBackgrounds);
