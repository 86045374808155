import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import MMPopup from "../../../mmcomponents/mmpopup/MMPopup";
import ReplyForm from "./ReplyForm";

class EditPopup extends Component {
  state = {
    _updatableProps: ["thread", "post"],
    thread: this.props.thread,
    post: this.props.post || null,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { thread, post } = this.state;
    const _c = this.props.context;

    return (
      <div className="community-post-edit-popup">
        <MMPopup
          show={post !== null && post !== undefined}
          size="medium"
          handleClose={this.handle__closePopup}
        >
          <h3>{_c.translate("community.post.editPopup.title")}</h3>
          <ReplyForm
            thread={thread}
            post={post}
            type="editPost"
            onSavePost={(thread) => {
              this.setState({ thread, post: null }, () => {
                _c.createNotifictation(
                  _c.translate("community.post.editPopup.notification.title"),
                  _c.translate("community.post.editPopup.notification.message"),
                  "success"
                );
              });
              this.props.onUpdate(thread);
            }}
          />
        </MMPopup>
      </div>
    );
  }

  handle__closePopup = () => {
    this.setState({ post: null });
  };
}

export default withContext(EditPopup);
