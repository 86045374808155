import React, { Component } from "react";
import { Grid, Row, Cell, Button, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import qs from "query-string";
import { isUndefined } from "util";
import LogoData from "../../images/logos/gold-with-text.png";
import { Link } from "react-router-dom";

class PasswordReset extends Component {
  state = {
    showMsgSaved: false,
    showErrorEmpty: false,
    showErrorNewWrong: false,
    newPassword: "",
    newPassword2: "",
    token: null,
    tokenIsChecked: false,
    tokenIsValid: false
  };

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.token)) {
      this.setState({ token: queryParams.token }, () => {
        this.check__token();
      });
    }
  }

  render() {
    return (
      <div className="page page-login">
        <main className="content">
          <Grid>
            <Row padding="x">
              <Cell sm={24} md={12} mdo={3}>
                <div className="login-logo">
                  <Link to="/">
                    <img src={LogoData} alt="Logo" />
                  </Link>
                </div>
                <div>&nbsp;</div>
                <h2>Passwort festlegen</h2>
                <p className="lead">
                  Nachfolgend kannst Du Dein Passwort festlegen. Gib dazu
                  einfach Dein gewünschtes Passwort zweimal ein.
                </p>
                {this.showErrorEmpty()}
                {this.showErrorNewWrong()}
              </Cell>
            </Row>
            {this.show__form()}
          </Grid>
        </main>
      </div>
    );
  }

  show__form() {
    if (this.state.showMsgSaved === true) {
      return (
        <Row padding="x">
          <Cell sm={24} md={12} mdo={3}>
            <div className="success callout">
              <p>
                <strong>Dein Passwort wurde gespeichert!</strong>
              </p>
              <p className="text-center">
                <a href="/login" className="primary button">
                  Jetzt einloggen
                </a>
              </p>
            </div>
          </Cell>
        </Row>
      );
    } else if (
      this.state.tokenIsChecked === true &&
      this.state.tokenIsValid === false
    ) {
      return (
        <Row padding="x">
          <Cell sm={24} md={12} mdo={3}>
            <div className="alert callout">
              <p>
                <strong>
                  Der Token ist nicht oder nicht mehr gültig. Bitte nutze die
                  Passwort vergessen - Funktion, um einen neuen Änderungs-Link
                  zu erhalten.
                </strong>
              </p>
            </div>
          </Cell>
        </Row>
      );
    } else {
      return (
        <React.Fragment>
          <Row padding="x">
            <Cell sm={24} md={12} mdo={3}>
              <div className="box">
                <div className="box-content">
                  <label>
                    Neues Passwort
                    <input
                      type="password"
                      placeholder="Dein neues Passwort"
                      value={this.state.newPassword}
                      onChange={this.handleChange("newPassword")}
                    />
                  </label>

                  <label>
                    Neues Passwort wiederholen
                    <input
                      type="password"
                      placeholder="Dein neues Passwort wiederholen"
                      value={this.state.newPassword2}
                      onChange={this.handleChange("newPassword2")}
                    />
                  </label>
                </div>
              </div>
            </Cell>
          </Row>
          <Row padding="x">
            <Cell sm={24} md={12} mdo={3}>
              <div className="page-buttons text-right">
                <Button
                  type="primary"
                  click={() => {
                    this.checkUserInput();
                  }}
                >
                  <Icon icon="check" left /> Passwort festlegen
                </Button>
              </div>
            </Cell>
          </Row>
        </React.Fragment>
      );
    }
  }

  showErrorEmpty() {
    if (this.state.showErrorEmpty === true) {
      return (
        <div className="alert callout">
          <strong>Bitte fülle alle Felder aus.</strong>
        </div>
      );
    }
  }

  showErrorNewWrong() {
    if (this.state.showErrorNewWrong === true) {
      return (
        <div className="alert callout">
          <strong>Die beiden Passwörter stimmen nicht überein.</strong>
        </div>
      );
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  resetMessages(callback) {
    this.setState({
      showErrorEmpty: false,
      showErrorNewWrong: false,
      showMsgSaved: false
    });

    callback();
  }

  checkUserInput() {
    const { newPassword, newPassword2 } = this.state;

    if (!newPassword || !newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorEmpty: true });
      });

      return;
    }

    if (newPassword !== newPassword2) {
      this.resetMessages(() => {
        this.setState({ showErrorNewWrong: true });
      });
      return;
    }
    this.save();
  }

  save() {
    const { newPassword, token } = this.state;

    axios
      .patch(this.props.context.apiEndpoints.passwordReset, {
        token: token,
        newPassword: newPassword
      })
      .then(response => {
        if (response.data === "OK") {
          this.setState({ showMsgSaved: true });
        } else if (response.data === "notfound") {
          this.setState({ tokenIsChecked: true, tokenIsValid: false });
        }
      })
      .catch(error => {
        console.log("Error", error);
      });
  }

  check__token() {
    const { token } = this.state;

    axios
      .get(
        this.props.context.apiEndpoints.passwordCheckToken + "?token=" + token
      )
      .then(response => {
        if (response.data === "OK") {
          this.setState({ tokenIsChecked: true, tokenIsValid: true });
        } else if (response.data === "notfound") {
          this.setState({ tokenIsChecked: true, tokenIsValid: false });
        }

        this.props.context.hideLoadingScreen();
      })
      .catch(error => {
        console.log("Error", error);
      });
  }
}

export default withContext(PasswordReset);
