import React, { Component } from "react";
import { Grid, Row, Cell } from "../foundation/_grid";
import DefaultLayout from "../layout/DefaultLayout";
import withContext from "../../context/contextHOC";
import AnneGoldKreis from "../../images/innerewelt/anne-gold-kreis.gif";
import ImageCreatures from "../../images/index/regal-kreaturen.jpg";
import ImageObjects from "../../images/index/tisch-objekte.jpg";
import ImageEbook3D from "../../images/innerewelt/ebook-3d.png";
import ImageAnnePhoenix from "../../images/innerewelt/anne-phoenix.jpg";
import { Link } from "react-router-dom";
import { Icon } from "../foundation/_buttons";
import ImageArrow from "../../images/arrow.png";

class PageInnereWelt extends Component {
  state = {};

  componentDidMount() {
    const _c = this.props.context;
    _c.login(() => {
      _c.initFinished();
    });
    _c.setPageTitle("Innere Welt");
  }

  render() {
    return (
      <DefaultLayout page={"text-page page-innerwelt"}>
        <div className="background-wrapper">
          <section>
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <div className="text-center">
                    <div className="pretitle">Welt von Beyond</div>
                    <h1>
                      Trägst Du in Dir eine innere Welt?
                      <br />
                      Und wenn ja, wo ist ihr Eingang?
                    </h1>
                    <div className="lead">
                      Ist die aufregendste Reise die wir antreten können, die
                      Reise in uns selbst? Und wenn ja - ist es möglich, dass
                      wir durch unsere inneren Augen die äußere Welt ganz anders
                      sehen?
                    </div>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </section>

          <section className="bild-anne">
            <Grid>
              <Row>
                <Cell sm={24} md={14} mdo={5}>
                  <img src={AnneGoldKreis} alt="Anne Vonjahr" />
                </Cell>
              </Row>
            </Grid>
          </section>

          <section>
            <Grid>
              <Row>
                <Cell sm={24} md={12} mdo={6}>
                  <h2>Herzlich Willkommen in der Welt von Beyond</h2>
                  <p className="lead">
                    Ich freue mich, dass Du unsere kleine magische Ecke im
                    Internet gefunden hast. Hier beginnt deine Reise in Deine
                    eigene innere Welt.
                  </p>

                  <p>
                    Tauche ein in die Geschichte der Menschheit, in das uralte
                    Wissen der alten Mystiker und Philosophen, in die Weisheit
                    Deiner inneren Energiesysteme, in die Weisheit der alten
                    Mythen und Legenden, und entdecke die Muster in den
                    Erfahrungen Deines Lebens. Wir alle begegnen auf unserem Weg
                    archetypischen Erfahrungen.
                  </p>
                  <p>
                    Das heißt, wir alle erleben Momente von großen Krisen, von
                    Liebe, Betrug, Kontrolle, Kreativität, Tod und Wiedergeburt.
                    Wir alle müssen durch ähnliche menschliche Erfahrungen
                    gehen, weil sie Teil unseres Seelenplans sind. Und all diese
                    Erfahrungen bringen uns in die Welt hinter unseren Augen.
                    Komm mit und tauche ein, in ein unglaubliches Universum, was
                    in Dir lebt.
                  </p>
                </Cell>
              </Row>
            </Grid>
          </section>
        </div>

        <section className="beginn-deiner-reise">
          <Grid>
            <Row>
              <Cell sm={24} md={18} mdo={3}>
                <h2>Der Beginn Deiner Reise</h2>
                <p className="quote">
                  &bdquo;Und wie der Phönix aus der Asche aufstieg, wirst auch
                  Du wieder auferstehen. Aus den Flammen wirst Du aufsteigen,
                  bekleidet mit Deiner eigenen Stärke und schöner als jemals
                  zuvor. Manchmal müssen wir ein Stück innerlich sterben, um
                  wieder geboren zu werden.&ldquo;
                </p>

                <h3>
                  Jeder, der in die Welt von Beyond eintritt, befindet sich von
                  diesem Moment an auf einer großen Reise!
                </h3>

                <p>
                  Und so wie jeder Held und jede Heldin, die auf ein großes
                  Abenteuer gehen, von einem weisen Gefährten die ersten
                  Schritte erzählt bekommen, so erzähle ich Dir hier das, was
                  Dich am Anfang Deiner Reise erwarten wird.
                </p>
              </Cell>
            </Row>
          </Grid>
        </section>

        <div className="background-wrapper">
          <section className="roadmap">
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <h2
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                    className="text-center"
                  >
                    Hier ist Dein kleiner Reiseführer in die Welt von Beyond
                  </h2>
                </Cell>
              </Row>
            </Grid>

            {/*<div className="small roadmap-line">
              <div className="roadmap-line-line"></div>
              <div className="roadmap-line-arrow"></div>
    </div>*/}

            <div>
              &nbsp;
              <br />
              &nbsp;
            </div>

            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <div className="roadmap-step roadmap-step-1">
                    <Grid type="full">
                      <Row margin="x">
                        <Cell sm={24} md={16} mdo={4} className="text-center">
                          <div className="pretitle">
                            Schritt 1 - Mystische Kreaturen
                          </div>
                          <h2>
                            Entdecke Deine stärkste innere Kraft, die Dich durch
                            die Zeiten der Veränderung tragen wird.
                          </h2>
                          <div className="subtitle">Kostenfreies Quiz</div>
                        </Cell>
                      </Row>
                      <Row margin="x">
                        <Cell
                          sm={24}
                          md={8}
                          className="small-order-1 medium-order-2"
                        >
                          <img src={ImageCreatures} alt="Mystische Kreaturen" />
                        </Cell>
                        <Cell
                          sm={24}
                          md={16}
                          className="small-order-1 medium-order-1"
                        >
                          <div className="wrapper">
                            <div className="center-vertically-">
                              <div className="roadmap-text">
                                <p>
                                  Wir lieben Geschichten von mystischen
                                  Kreaturen. Diese existieren seit Jahrhunderten
                                  in Legenden, in der Literatur und in Filmen.
                                  Wir lieben die mystischen Kreaturen nicht nur,
                                  weil sie so süß, so mutig oder so faszinierend
                                  sind, sondern weil sie für Energien stehen,
                                  die in uns leben und uns lehren, wie wir mit
                                  ihnen umgehen können.
                                </p>
                                <p>
                                  Wenn Du in der Welt von Beyond den mystischen
                                  Kreaturen begegnest - dem Drachen, der
                                  Meerjungfrau, dem Pegasus und der Dryade -
                                  dann sehe sie als Symbol für die Kräfte in
                                  Deiner Psyche. Sie stehen symbolisch für die
                                  stärkste Kraft, die irgendwo tief in Deiner
                                  inneren Welt lebt.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                  {/*<div className="roadmap-line">
                    <div className="roadmap-line-line"></div>
                    <div className="roadmap-line-arrow"></div>
</div>*/}
                  <div className="roadmap-arrow">
                    <img src={ImageArrow} alt="" />
                  </div>
                  <div className="roadmap-step roadmap-step-2">
                    <Grid type="full">
                      <Row margin="x">
                        <Cell sm={24} md={16} mdo={4} className="text-center">
                          <div className="pretitle">
                            Schritt 2 - Magische Objekte
                          </div>
                          <h2>Entdecke Deinen verwundbarsten Punkt</h2>
                          <div className="subtitle">Kostenfreies Quiz</div>
                        </Cell>
                      </Row>
                      <Row margin="x">
                        <Cell
                          sm={24}
                          md={8}
                          className="small-order-1 medium-order-1"
                        >
                          <img src={ImageObjects} alt="Magische Objekte" />
                        </Cell>
                        <Cell
                          sm={24}
                          md={16}
                          className="small-order-1 medium-order-2"
                        >
                          <div className="wrapper">
                            <div className="center-vertically-">
                              <div className="roadmap-text">
                                <p>
                                  Magische Objekte sind Symbole für Energien,
                                  die in uns verborgen liegen und mit denen wir
                                  noch nicht im Umgang geübt sind. Aus diesem
                                  Grund ranken sich sehr viele alte Legenden und
                                  Geschichten um sie. Der Mythos besagt, dass
                                  man vorsichtig mit ihrer Kraft umgehen muß.
                                </p>
                                <p>
                                  Die Herausforderungen und Schwierigkeiten in
                                  unserem Leben, bringen uns dazu, die Energien
                                  für die sie stehen in uns zu beherrschen und
                                  sie so einzusetzen, dass sie uns und der Welt
                                  dienen.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                  {/*<div className="roadmap-line">
                    <div className="roadmap-line-line"></div>
                    <div className="roadmap-line-arrow"></div>
</div>*/}
                  <div className="roadmap-arrow">
                    <img src={ImageArrow} alt="" />
                  </div>
                  <div className="roadmap-step roadmap-step-3">
                    <Grid type="full">
                      <Row margin="x">
                        <Cell sm={24} md={16} mdo={4} className="text-center">
                          <div className="pretitle">
                            Schritt 3 - Das kostenfreie E-Book
                          </div>
                          <h2>
                            Hole Dir das kostenfreie Ebook der magischen
                            Kreaturen und magischen Objekte.
                          </h2>
                        </Cell>
                      </Row>
                      <Row margin="x">
                        <Cell
                          sm={24}
                          md={8}
                          className="small-order-1 medium-order-2"
                        >
                          <img src={ImageEbook3D} alt="E-Book" />
                        </Cell>
                        <Cell
                          sm={24}
                          md={16}
                          className="small-order-1 medium-order-1"
                        >
                          <div className="wrapper">
                            <div className="center-vertically-">
                              <div className="roadmap-text">
                                <p>
                                  Erfahre die Botschaft jeder einzelnen der
                                  mystischen Kreaturen und wie ihre Kraft in
                                  Deinem Leben wirken kann, wenn Du ihre Energie
                                  in Dir aktivierst
                                </p>

                                <p>
                                  Erfahre den Ursprung eines jeden der magischen
                                  Objekte und entdecke, welche Prüfungen in
                                  Deinem Leben Dir helfen ihre Stärke in Dir zu
                                  erwecken.
                                </p>
                              </div>
                            </div>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                  {/*<div className="roadmap-line">
                    <div className="roadmap-line-line"></div>
                    <div className="roadmap-line-arrow"></div>
</div>*/}
                  <div className="roadmap-arrow">
                    <img src={ImageArrow} alt="" />
                  </div>
                  <div className="roadmap-step roadmap-step-4">
                    <Grid type="full">
                      <Row margin="x">
                        <Cell sm={24} md={16} mdo={4} className="text-center">
                          <div className="pretitle">
                            Schritt 4 - Kostenfreie Audio-/Videoserie
                          </div>
                          <h2>Der Phönix und die Rückkehr des Magiers</h2>
                        </Cell>
                      </Row>
                      <Row margin="x">
                        <Cell
                          sm={24}
                          md={6}
                          mdo={1}
                          className="small-order-1 medium-order-1"
                        >
                          <img src={ImageAnnePhoenix} alt="Phoenix" />
                        </Cell>
                        <Cell
                          sm={24}
                          md={16}
                          mdo={1}
                          className="small-order-1 medium-order-2"
                        >
                          <div className="wrapper">
                            <div className="center-vertically-">
                              <div className="roadmap-text">
                                <p>
                                  Erkenne die Kraft des Phönix in Deiner inneren
                                  Welt und erfahre, welche Botschaft der
                                  Archetyp des Magiers für Dich hat. Möge ihre
                                  Weisheit Dich in Zeiten der Veränderung
                                  stärken.
                                </p>
                                <p>
                                  <strong>
                                    Kostenfreie Audio-/Videoserie für Dich:
                                  </strong>
                                  <br />
                                  1. Video: „Der Phönix und Deine innere Welt“
                                  <br />
                                  2. Video: „Die Rückkehr des Magier Archetyps“
                                  <br />
                                  3. Audio: „Meine persönliche Phönix Erfahrung
                                  und meine Begegnung mit dem Magier“
                                </p>
                              </div>
                            </div>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </div>
                  {/*<div className="small roadmap-line">
                    <div className="roadmap-line-line"></div>
                    <div className="roadmap-line-arrow"></div>
</div>*/}
                  <div className="roadmap-arrow">
                    <img src={ImageArrow} alt="" />
                  </div>
                  <div className="button-reise-starten">
                    <h2>Bist Du bereit für die Reise in Deine innere Welt?</h2>
                    <Link
                      to="/quiz/mystische-kreatur"
                      className="large primary hollow button"
                    >
                      <Icon icon="globe" left /> Beginne Deine magische Reise
                    </Link>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </section>
        </div>
      </DefaultLayout>
    );
  }
}

export default withContext(PageInnereWelt);
