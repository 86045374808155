import React, { Component } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import { Grid, Row, Cell } from "../foundation/_grid";
import withContext from "../../context/contextHOC";
import Box from "../layout/Box";

class Datenschutz extends Component {
  state = {};

  componentDidMount() {
    const _c = this.props.context;
    _c.initFinished();
    _c.setPageTitle("Datenschutz");
  }

  render() {
    return (
      <DefaultLayout page={"text-page page-datenschutz"}>
        <Grid>
          <Row>
            <Cell sm={24} md={24}>
              <h1>Datenschutz</h1>

              <Box>
                <p>1. Zielsetzung und verantwortliche Stelle</p>
                <p>
                  Diese Datenschutzerklärung klärt über die Art, den Umfang und
                  Zweck der Verarbeitung (u.a. Erhebung, Verarbeitung und
                  Nutzung sowie Einholung von Einwilligungen) von
                  personenbezogenen Daten innerhalb unseres Onlineangebotes und
                  der mit ihm verbundenen Webseiten, Funktionen und Inhalte
                  (nachfolgend gemeinsam bezeichnet als "Onlineangebot" oder
                  "Website") auf. Die Datenschutzerklärung gilt unabhängig von
                  den verwendeten Domains, Systemen, Plattformen und Geräten
                  (z.B. Desktop oder Mobile) auf denen das Onlineangebot
                  ausgeführt wird. Anbieter des Onlineangebotes und die
                  datenschutzrechtlich verantwortliche Stelle ist Rabl Vonjahr
                  OG, Inhaber: Rabl Marc, Katzianergasse 2, 8010 Graz
                  (nachfolgend bezeichnet als "AnbieterIn", "wir" oder "uns").
                  Für die Kontaktmöglichkeiten verweisen wir auf unser Impressum
                  Der Begriff "Nutzer" umfasst alle Kunden und Besucher unseres
                  Onlineangebotes. Die verwendeten Begrifflichkeiten, wie z.B.
                  "Nutzer" sind geschlechtsneutral zu verstehen.
                </p>

                <p>2. Grundsätzliche Angaben zur Datenverarbeitung</p>
                <p>
                  Wir verarbeiten personenbezogene Daten der Nutzer nur unter
                  Einhaltung der einschlägigen Datenschutzbestimmungen
                  entsprechend den Geboten der Datensparsamkeit- und
                  Datenvermeidung. Das bedeutet die Daten der Nutzer werden nur
                  beim Vorliegen einer gesetzlichen Erlaubnis, insbesondere wenn
                  die Daten zur Erbringung unserer vertraglichen Leistungen
                  sowie Online-Services erforderlich, bzw. gesetzlich
                  vorgeschrieben sind oder beim Vorliegen einer Einwilligung
                  verarbeitet. Wir treffen organisatorische, vertragliche und
                  technische Sicherheitsmaßnahmen entsprechend dem Stand der
                  Technik, um sicher zu stellen, dass die Vorschriften der
                  Datenschutzgesetze eingehalten werden und um damit die durch
                  uns verarbeiteten Daten gegen zufällige oder vorsätzliche
                  Manipulationen, Verlust, Zerstörung oder gegen den Zugriff
                  unberechtigter Personen zu schützen. Sofern im Rahmen dieser
                  Datenschutzerklärung Inhalte, Werkzeuge oder sonstige Mittel
                  von anderen Anbietern (nachfolgend gemeinsam bezeichnet als
                  "Dritt-Anbieter") eingesetzt werden und deren genannter Sitz
                  im Ausland ist, ist davon auszugehen, dass ein Datentransfer
                  in die Sitzstaaten der Dritt-Anbieter stattfindet. Die
                  Übermittlung von Daten in Drittstaaten erfolgt entweder auf
                  Grundlage einer gesetzlichen Erlaubnis, einer Einwilligung der
                  Nutzer oder spezieller Vertragsklauseln, die eine gesetzlich
                  vorausgesetzte Sicherheit der Daten gewährleisten.
                </p>

                <p>3. Verarbeitung personenbezogener Daten</p>
                <p>
                  Die personenbezogenen Daten werden, neben den ausdrücklich in
                  dieser Datenschutzerklärung genannten Verwendung, für die
                  folgenden Zwecke auf Grundlage gesetzlicher Erlaubnisse oder
                  Einwilligungen der Nutzer verarbeitet: - Die
                  Zurverfügungstellung, Ausführung, Pflege, Optimierung und
                  Sicherung unserer Dienste-, Service- und Nutzerleistungen; -
                  Die Gewährleistung eines effektiven Kundendienstes und
                  technischen Supports. Wir übermitteln die Daten der Nutzer an
                  Dritte nur, wenn dies für Abrechnungszwecke notwendig ist
                  (z.B. an einen Zahlungsdienstleister) oder für andere Zwecke,
                  wenn diese notwendig sind, um unsere vertraglichen
                  Verpflichtungen gegenüber den Nutzern zu erfüllen (z.B.
                  Adressmitteilung an Lieferanten). Bei der Kontaktaufnahme mit
                  uns (per Kontaktformular oder Email) werden die Angaben des
                  Nutzers zwecks Bearbeitung der Anfrage sowie für den Fall,
                  dass Anschlussfragen entstehen, gespeichert. Personenbezogene
                  Daten werden gelöscht, sofern sie ihren Verwendungszweck
                  erfüllt haben und der Löschung keine Aufbewahrungspflichten
                  entgegenstehen.
                </p>

                <p>4. Erhebung von Zugriffsdaten</p>
                <p>
                  Wir erheben Daten über jeden Zugriff auf den Server, auf dem
                  sich dieser Dienst befindet (so genannte Serverlogfiles). Zu
                  den Zugriffsdaten gehören Name der abgerufenen Webseite,
                  Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge,
                  Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
                  das Betriebssystem des Nutzers, Referrer URL (die zuvor
                  besuchte Seite), IP-Adresse und der anfragende Provider. Wir
                  verwenden die Protokolldaten ohne Zuordnung zur Person des
                  Nutzers oder sonstiger Profilerstellung entsprechend den
                  gesetzlichen Bestimmungen nur für statistische Auswertungen
                  zum Zweck des Betriebs, der Sicherheit und der Optimierung
                  unseres Onlineangebotes. Wir behalten uns jedoch vor, die
                  Protokolldaten nachträglich zu überprüfen, wenn aufgrund
                  konkreter Anhaltspunkte der berechtigte Verdacht einer
                  rechtswidrigen Nutzung besteht.
                </p>

                <p>5. Cookies & Reichweitenmessung</p>
                <p>
                  Cookies sind Informationen, die von unserem Webserver oder
                  Webservern Dritter an die Web-Browser der Nutzer übertragen
                  und dort für einen späteren Abruf gespeichert werden. Über den
                  Einsatz von Cookies im Rahmen pseudonymer Reichweitenmessung
                  werden die Nutzer im Rahmen dieser Datenschutzerklärung
                  informiert. Die Betrachtung dieses Onlineangebotes ist auch
                  unter Ausschluss von Cookies möglich. Falls die Nutzer nicht
                  möchten, dass Cookies auf ihrem Rechner gespeichert werden,
                  werden sie gebeten die entsprechende Option in den
                  Systemeinstellungen ihres Browsers zu deaktivieren.
                  Gespeicherte Cookies können in den Systemeinstellungen des
                  Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
                  Funktionseinschränkungen dieses Onlineangebotes führen. Es
                  besteht die Möglichkeit, viele Online-Anzeigen-Cookies von
                  Unternehmen über die US-amerikanische Seite
                  http://www.aboutads.info/choices oder die EU-Seite
                  http://www.youronlinechoices.com/uk/your-ad-choices/ zu
                  verwalten.
                </p>

                <p>6. Google Analytics</p>
                <p>
                  Wir setzen Google Analytics, einen Webanalysedienst der Google
                  Inc. ("Google") ein. Google verwendet Cookies. Die durch das
                  Cookie erzeugten Informationen über Benutzung des
                  Onlineangebotes durch die Nutzer werden in der Regel an einen
                  Server von Google in den USA übertragen und dort gespeichert.
                  Google wird diese Informationen in unserem Auftrag benutzen,
                  um die Nutzung unseres Onlineangebotes durch die Nutzer
                  auszuwerten, um Reports über die Aktivitäten innerhalb dieses
                  Onlineangebotes zusammenzustellen und um weitere mit der
                  Nutzung dieses Onlineangebotes und der Internetnutzung
                  verbundene Dienstleistungen uns gegenüber zu erbringen. Dabei
                  können aus den verarbeiteten Daten pseudonyme Nutzungsprofile
                  der Nutzer erstellt werden. Wir setzen Google Analytics nur
                  mit aktivierter IP-Anonymisierung ein. Das bedeutet, die
                  IP-Adresse der Nutzer wird von Google innerhalb von
                  Mitgliedstaaten der Europäischen Union oder in anderen
                  Vertragsstaaten des Abkommens über den Europäischen
                  Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle
                  IP-Adresse an einen Server von Google in den USA übertragen
                  und dort gekürzt. Die von dem Browser des Nutzers übermittelte
                  IP-Adresse wird nicht mit anderen Daten von Google
                  zusammengeführt. Die Nutzer können die Speicherung der Cookies
                  durch eine entsprechende Einstellung ihrer Browser-Software
                  verhindern; die Nutzer können darüber hinaus die Erfassung der
                  durch das Cookie erzeugten und auf ihre Nutzung des
                  Onlineangebotes bezogenen Daten an Google sowie die
                  Verarbeitung dieser Daten durch Google verhindern, indem sie
                  das unter dem folgenden Link verfügbare Browser-Plugin
                  herunterladen und installieren:
                  http://tools.google.com/dlpage/gaoptout?hl=de. Weitere
                  Informationen zur Datennutzung zu Werbezwecken durch Google,
                  Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie auf
                  den Webseiten von Google:
                  https://www.google.com/intl/de/policies/privacy/partners
                  ("Datennutzung durch Google bei Ihrer Nutzung von Websites
                  oder Apps unserer Partner"),
                  http://www.google.com/policies/technologies/ads ("Datennutzung
                  zu Werbezwecken"), http://www.google.de/settings/ads
                  ("Informationen verwalten, die Google verwendet, um Ihnen
                  Werbung einzublenden") und
                  http://www.google.com/ads/preferences ("Bestimmen Sie, welche
                  Werbung Google Ihnen zeigt").
                </p>

                <p>7. Google-Re/Marketing-Services</p>
                <p>
                  Wir nutzen die Marketing- und Remarketing-Dienste (kurz
                  "Google-Marketing-Services") der Google Inc., 1600
                  Amphitheatre Parkway, Mountain View, CA 94043, USA,
                  ("Google"). Die Google-Marketing-Services erlauben uns
                  Werbeanzeigen für und auf unserer Website gezielter
                  anzuzeigen, um Nutzern nur Anzeigen zu präsentieren, die
                  potentiell deren Interessen entsprechen. Falls Nutzer z.B.
                  Anzeigen für Produkte angezeigt werden, für die er sich auf
                  anderen Webseiten interessiert hat, spricht man hierbei vom
                  "Remarketing". Zu diesen Zwecken wird bei Aufruf unserer und
                  anderer Webseiten, auf denen Google-Marketing-Services aktiv
                  sind, unmittelbar durch Google ein Code von Google ausgeführt
                  und es werden in die Website sog. (Re)marketing-Tags
                  (unsichtbare Grafiken oder Code, auch als "Web Beacons"
                  bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird
                  auf dem Gerät der Nutzer ein individuelles Cookie, d.h. eine
                  kleine Datei abgespeichert (statt Cookies können auch
                  vergleichbare Technologien verwendet werden). Die Cookies
                  können von verschiedenen Domains gesetzt werden, unter anderem
                  von google.com, doubleclick.net, invitemedia.com, admeld.com,
                  googlesyndication.com oder googleadservices.com. In dieser
                  Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht,
                  für welche Inhalte er sich interessiert und welche Angebote er
                  geklickt hat, ferner technische Informationen zum Browser und
                  Betriebssystem, verweisende Webseiten, Besuchszeit sowie
                  weitere Angaben zur Nutzung des Onlineangebotes. Es wird
                  ebenfalls die IP-Adresse der Nutzer erfasst, wobei wir im
                  Rahmen von Google-Analytics mitteilen, dass die IP-Adresse
                  innerhalb von Mitgliedstaaten der Europäischen Union oder in
                  anderen Vertragsstaaten des Abkommens über den Europäischen
                  Wirtschaftsraum gekürzt und nur in Ausnahmefällen ganz an
                  einen Server von Google in den USA übertragen und dort gekürzt
                  wird. Die IP-Adresse wird nicht mit Daten des Nutzers
                  innerhalb von anderen Angeboten von Google zusammengeführt.
                  Diese vorstehend genannten Informationen können auch mit
                  solchen Informationen aus anderen Quellen verbunden werden.
                  Wenn der Nutzer anschließend andere Webseiten besucht, können
                  ihm entsprechend seiner Interessen die auf ihn abgestimmten
                  Anzeigen angezeigt werden. Die Daten der Nutzer werden im
                  Rahmen der Google-Marketing-Services pseudonym verarbeitet.
                  D.h. Google speichert und verarbeitet z.B. nicht den Namen
                  oder E-Mailadresse der Nutzer, sondern verarbeitet die
                  relevanten Daten Cookie-bezogen innerhalb pseudonymer
                  Nutzer-Profile. D.h. aus der Sicht von Google werden die
                  Anzeigen nicht für eine konkret identifizierte Person
                  verwaltet und angezeigt, sondern für den Cookie-Inhaber,
                  unabhängig davon wer dieser Cookie-Inhaber ist. Dies gilt
                  nicht, wenn ein Nutzer Google ausdrücklich erlaubt hat, die
                  Daten ohne diese Pseudonymisierung zu verarbeiten. Die von
                  "DoubleClick" über die Nutzer gesammelten Informationen werden
                  an Google übermittelt und auf Googles Servern in den USA
                  gespeichert. Zu den von uns eingesetzten
                  Google-Marketing-Services gehört u.a. das Online-Werbeprogramm
                  "Google AdWords". Im Fall von Google AdWords, erhält jeder
                  AdWords-Kunde ein anderes "Conversion-Cookie". Cookies können
                  somit nicht über die Websites von AdWords-Kunden nachverfolgt
                  werden. Die mit Hilfe des Cookies eingeholten Informationen
                  dienen dazu, Conversion-Statistiken für AdWords-Kunden zu
                  erstellen, die sich für Conversion-Tracking entschieden haben.
                  Die AdWords-Kunden erfahren die Gesamtanzahl der Nutzer, die
                  auf ihre Anzeige geklickt haben und zu einer mit einem
                  Conversion-Tracking-Tag versehenen Seite weitergeleitet
                  wurden. Sie erhalten jedoch keine Informationen, mit denen
                  sich Nutzer persönlich identifizieren lassen. Wir binden auf
                  Grundlage des Google-Marketing-Services "DoubleClick"
                  Werbeanzeigen Dritter ein. DoubleClick verwendet Cookies, mit
                  dem Google und seinen Partner-Websites, die Schaltung von
                  Anzeigen auf Basis der Besuche von Nutzern auf dieser Website
                  bzw. anderen Websites im Internet ermöglicht wird. Wir binden
                  ferner auf Grundlage des Google-Marketing-Services "AdSense"
                  Werbeanzeigen Dritter ein. AdSense verwendet Cookies, mit dem
                  Google und seinen Partner-Websites, die Schaltung von Anzeigen
                  auf Basis der Besuche von Nutzern auf dieser Website bzw.
                  anderen Websites im Internet ermöglicht wird. Ein weiterer von
                  uns genutzter Google-Marketing-Service ist der "Google Tag
                  Manager", mit dessen Hilfe weitere Google Analyse- und
                  Marketing-Dienste in unsere Website eingebunden werden können
                  (z.B. "AdWords", "DoubleClick" oder "Google Analytics").
                  Weitere Informationen zur Datennutzung zu Marketingzwecken
                  durch Google, erfahren Sie auf der Übersichtsseite:
                  https://www.google.com/policies/technologies/ads, die
                  Datenschutzerklärung von Google ist unter
                  https://www.google.com/policies/privacy abrufbar. Wenn Sie der
                  Erfassung durch Google-Marketing-Services widersprechen
                  möchten, können Sie die von Google gestellten Einstellungs-
                  und Opt-Out-Möglichkeiten nutzen:
                  http://www.google.com/ads/preferences.
                </p>

                <p>8. Facebook Social Plugins</p>
                <p>
                  Unser Onlineangebot verwendet Social Plugins ("Plugins") des
                  sozialen Netzwerkes facebook.com, welches von der Facebook
                  Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
                  Dublin 2, Irland betrieben wird ("Facebook"). Die Plugins sind
                  an einem der Facebook Logos erkennbar (weißes "f" auf blauer
                  Kachel, den Begriffen "Like", "Gefällt mir" oder einem "Daumen
                  hoch"-Zeichen) oder sind mit dem Zusatz "Facebook Social
                  Plugin" gekennzeichnet. Die Liste und das Aussehen der
                  Facebook Social Plugins kann hier eingesehen werden:
                  https://developers.facebook.com/docs/plugins/. Wenn ein Nutzer
                  eine Funktion dieses Onlineangebotes aufruft, die ein solches
                  Plugin enthält, baut sein Gerät eine direkte Verbindung mit
                  den Servern von Facebook auf. Der Inhalt des Plugins wird von
                  Facebook direkt an das Gerät des Nutzers übermittelt und von
                  diesem in das Onlineangebot eingebunden. Dabei können aus den
                  verarbeiteten Daten Nutzungsprofile der Nutzer erstellt
                  werden. Wir haben daher keinen Einfluss auf den Umfang der
                  Daten, die Facebook mit Hilfe dieses Plugins erhebt und
                  informiert die Nutzer daher entsprechend unserem
                  Kenntnisstand. Durch die Einbindung der Plugins erhält
                  Facebook die Information, dass ein Nutzer die entsprechende
                  Seite des Onlineangebotes aufgerufen hat. Ist der Nutzer bei
                  Facebook eingeloggt, kann Facebook den Besuch seinem
                  Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins
                  interagieren, zum Beispiel den Like Button betätigen oder
                  einen Kommentar abgeben, wird die entsprechende Information
                  von Ihrem Gerät direkt an Facebook übermittelt und dort
                  gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist,
                  besteht trotzdem die Möglichkeit, dass Facebook seine
                  IP-Adresse in Erfahrung bringt und speichert. Laut Facebook
                  wird in Deutschland nur eine anonymisierte IP-Adresse
                  gespeichert. Zweck und Umfang der Datenerhebung und die
                  weitere Verarbeitung und Nutzung der Daten durch Facebook
                  sowie die diesbezüglichen Rechte und Einstellungsmöglichkeiten
                  zum Schutz der Privatsphäre der Nutzer, können diese den
                  Datenschutzhinweisen von Facebook entnehmen:
                  https://www.facebook.com/about/privacy/. Wenn ein Nutzer
                  Facebookmitglied ist und nicht möchte, dass Facebook über
                  dieses Onlineangebot Daten über ihn sammelt und mit seinen bei
                  Facebook gespeicherten Mitgliedsdaten verknüpft, muss er sich
                  vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen
                  und seine Cookies löschen. Weitere Einstellungen und
                  Widersprüche zur Nutzung von Daten für Werbezwecke, sind
                  innerhalb der Facebook-Profileinstellungen möglich:
                  https://www.facebook.com/settings?tab=ads oder über die
                  US-amerikanische Seite http://www.aboutads.info/choices/ oder
                  die EU-Seite http://www.youronlinechoices.com/. Die
                  Einstellungen erfolgen plattformunabhängig, d.h. sie werden
                  für alle Geräte, wie Desktopcomputer oder mobile Geräte
                  übernommen.
                </p>

                <p>9. Facebook Remarketing</p>
                <p>
                  Innerhalb unseres Onlineangebotes werden sog. "Facebook-Pixel"
                  des sozialen Netzwerkes Facebook, welches von der Facebook
                  Inc., 1 Hacker Way, Menlo Park, CA 94025, USA, bzw. falls Sie
                  in der EU ansässig sind, Facebook Ireland Ltd., 4 Grand Canal
                  Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird
                  ("Facebook"), eingesetzt. Mit Hilfe des Facebook-Pixels ist es
                  Facebook möglich, die Besucher unseres Angebotes als
                  Zielgruppe für die Darstellung von Anzeigen, sog.
                  "Facebook-Ads" zu bestimmen. Dementsprechend setzen wir das
                  Facebook -Pixel ein, um die durch uns geschalteten
                  Facebook-Ads nur solchen Facebook-Nutzern anzuzeigen, die auch
                  ein Interesse an unserem Internetangebot gezeigt haben. Das
                  heißt, mit Hilfe des Facebook -Pixels möchten wir
                  sicherstellen, dass unsere Facebook-Ads dem potentiellen
                  Interesse der Nutzer entsprechen und nicht belästigend wirken.
                  Mit Hilfe des Facebook-Pixels können wir ferner die
                  Wirksamkeit der Facebook-Werbeanzeigen für statistische und
                  Marktforschungszwecke nachvollziehen, in dem wir sehen ob
                  Nutzer nachdem Klick auf eine Facebook-Werbeanzeige auf unsere
                  Website weitergeleitet wurden. Der Facebook-Pixel wird beim
                  Aufruf unserer Webseiten unmittelbar durch Facebook
                  eingebunden und können auf Ihrem Gerät ein sog. Cookie, d.h.
                  eine kleine Datei abspeichern. Wenn Sie sich anschließend bei
                  Facebook einloggen oder im eingeloggten Zustand Facebook
                  besuchen, wird der Besuch unseres Angebotes in Ihrem Profil
                  vermerkt. Die über Sie erhobenen Daten sind für uns anonym,
                  bieten uns also keine Rückschlüsse auf die Identität der
                  Nutzer. Allerdings werden die Daten von Facebook gespeichert
                  und verarbeitet, sodass eine Verbindung zum jeweiligen
                  Nutzerprofil möglich ist. Die Verarbeitung der Daten durch
                  Facebook erfolgt im Rahmen von Facebooks
                  Datenverwendungsrichtlinie. Dementsprechend erhalten Sie
                  weitere Informationen zur Funktionsweise des
                  Remarketing-Pixels und generell zur Darstellung von
                  Facebook-Ads, in der Datenverwendungsrichtlinie von Facebook:
                  https://www.facebook.com/policy.php. Sie können der Erfassung
                  durch den Facebook-Pixel und Verwendung Ihrer Daten zur
                  Darstellung von Facebook-Ads widersprechen. Hierzu können Sie
                  die von Facebook eingerichtete Seite aufrufen und dort die
                  Hinweise zu den Einstellungen nutzungsbasierter Werbung
                  befolgen: https://www.facebook.com/settings?tab=ads oder den
                  Widerspruch über die US-amerikanische Seite
                  http://www.aboutads.info/choices/ oder die EU-Seite
                  http://www.youronlinechoices.com/ erklären. Die Einstellungen
                  erfolgen plattformunabhängig, d.h. sie werden für alle Geräte,
                  wie Desktopcomputer oder mobile Geräte übernommen.
                </p>

                <p>10. Newsletter</p>
                <p>
                  Mit den nachfolgenden Hinweisen klären wir Sie über die
                  Inhalte unseres Newsletters sowie das Anmelde-, Versand- und
                  das statistische Auswertungsverfahren sowie Ihre
                  Widerspruchsrechte auf. Indem Sie unseren Newsletter
                  abonnieren, erklären Sie sich mit dem Empfang und den
                  beschriebenen Verfahren einverstanden. Inhalt des Newsletters:
                  Wir versenden Newsletter, E-Mails und weitere elektronische
                  Benachrichtigungen mit werblichen Informationen (nachfolgend
                  "Newsletter") nur mit der Einwilligung der Empfänger oder
                  einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung
                  zum Newsletter dessen Inhalte konkret umschrieben werden, sind
                  sie für die Einwilligung der Nutzer maßgeblich. Im Übrigen
                  enthalten unsere Newsletter folgende Informationen: unsere
                  Produkte, Angebote, Aktionen und unser Unternehmen.
                  Double-Opt-In und Protokollierung: Die Anmeldung zu unserem
                  Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h.
                  Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die
                  Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung
                  ist notwendig, damit sich niemand mit fremden E-Mailadressen
                  anmelden kann. Die Anmeldungen zum Newsletter werden
                  protokolliert, um den Anmeldeprozess entsprechend den
                  rechtlichen Anforderungen nachweisen zu können. Hierzu gehört
                  die Speicherung des Anmelde- und des Bestätigungszeitpunkts,
                  als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer
                  bei dem Versanddienstleister gespeicherten Daten
                  protokolliert. Versanddienstleister: Der Versand der
                  Newsletter erfolgt mittels von "Convertkit" (nachfolgend
                  bezeichnet als "Versanddienstleister"). Die
                  Datenschutzbestimmungen des Versanddienstleisters können Sie
                  hier einsehen: https://convertkit.com/privacy/. Die
                  E-Mail-Adressen unserer Newsletterempfänger, als auch deren
                  weitere, im Rahmen dieser Hinweise beschriebenen Daten, werden
                  auf den Servern des Versanddienstleisters gespeichert. Der
                  Versanddienstleister verwendet diese Informationen zum Versand
                  und zur Auswertung der Newsletter in unserem Auftrag. Des
                  Weiteren kann der Versanddienstleister nach eigenen
                  Informationen diese Daten zur Optimierung oder Verbesserung
                  der eigenen Services nutzen, z.B. zur technischen Optimierung
                  des Versandes und der Darstellung der Newsletter oder für
                  wirtschaftliche Zwecke, um zu bestimmen aus welchen Ländern
                  die Empfänger kommen. Der Versanddienstleister nutzt die Daten
                  unserer Newsletterempfänger jedoch nicht, um diese selbst
                  anzuschreiben oder an Dritte weiterzugeben. Anmeldedaten: Um
                  sich für den Newsletter anzumelden, reicht es aus, wenn Sie
                  Ihre E-Mailadresse angeben. Statistische Erhebung und Analysen
                  - Die Newsletter enthalten einen sog. "web-beacon", d.h. eine
                  pixelgroße Datei, die beim Öffnen des Newsletters von dem
                  Server des Versanddienstleisters abgerufen wird. Im Rahmen
                  dieses Abrufs werden zunächst technische Informationen, wie
                  Informationen zum Browser und Ihrem System, als auch Ihre
                  IP-Adresse und Zeitpunkt des Abrufs erhoben. Diese
                  Informationen werden zur technischen Verbesserung der Services
                  anhand der technischen Daten oder der Zielgruppen und ihres
                  Leseverhaltens anhand derer Abruforte (die mit Hilfe der
                  IP-Adresse bestimmbar sind) oder der Zugriffszeiten genutzt.
                  Zu den statistischen Erhebungen gehört ebenfalls die
                  Feststellung, ob die Newsletter geöffnet werden, wann sie
                  geöffnet werden und welche Links geklickt werden. Diese
                  Informationen können aus technischen Gründen zwar den
                  einzelnen Newsletterempfängern zugeordnet werden. Es ist
                  jedoch weder unser Bestreben, noch das des
                  Versanddienstleisters, einzelne Nutzer zu beobachten. Die
                  Auswertungen dienen uns viel mehr dazu, die Lesegewohnheiten
                  unserer Nutzer zu erkennen und unsere Inhalte auf sie
                  anzupassen oder unterschiedliche Inhalte entsprechend den
                  Interessen unserer Nutzer zu versenden. Kündigung/Widerruf -
                  Sie können den Empfang unseres Newsletters jederzeit kündigen,
                  d.h. Ihre Einwilligungen widerrufen. Damit erlöschen
                  gleichzeitig Ihre Einwilligungen in dessen Versand durch den
                  Versanddienstleister und die statistischen Analysen. Ein
                  getrennter Widerruf des Versandes durch den
                  Versanddienstleister oder die statistische Auswertung ist
                  leider nicht möglich. Einen Link zur Kündigung des Newsletters
                  finden Sie am Ende eines jeden Newsletters.
                </p>

                <p>11. Einbindung von Diensten und Inhalten Dritter</p>
                <p>
                  Es kann vorkommen, dass innerhalb unseres Onlineangebotes
                  Inhalte oder Dienste von Dritt-Anbietern, wie zum Beispiel
                  Stadtpläne oder Schriftarten von anderen Webseiten eingebunden
                  werden. Die Einbindung von Inhalten der Dritt-Anbieter setzt
                  immer voraus, dass die Dritt-Anbieter die IP-Adresse der
                  Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte
                  nicht an den Browser der Nutzer senden könnten. Die IP-Adresse
                  ist damit für die Darstellung dieser Inhalte erforderlich. Des
                  Weiteren können die Anbieter der Dritt-Inhalte eigene Cookies
                  setzen und die Daten der Nutzer für eigene Zwecke verarbeiten.
                  Dabei können aus den verarbeiteten Daten Nutzungsprofile der
                  Nutzer erstellt werden. Wir werden diese Inhalte möglichst
                  datensparsam und datenvermeidend einsetzen sowie im Hinblick
                  auf die Datensicherheit zuverlässige Dritt-Anbieter wählen.
                  Die nachfolgende Darstellung bietet eine Übersicht von
                  Dritt-Anbietern sowie ihrer Inhalte, nebst Links zu deren
                  Datenschutzerklärungen, welche weitere Hinweise zur
                  Verarbeitung von Daten und, z.T. bereits hier genannt,
                  Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten: - Externe
                  Schriftarten von Google, Inc., https://www.google.com/fonts
                  ("Google Fonts"). Die Einbindung der Google Fonts erfolgt
                  durch einen Serveraufruf bei Google (in der Regel in den USA).
                  Datenschutzerklärung:
                  https://www.google.com/policies/privacy/, Opt-Out:
                  https://www.google.com/settings/ads/. - Landkarten des
                  Dienstes "Google Maps" des Dritt-Anbieters Google Inc., 1600
                  Amphitheatre Parkway, Mountain View, CA 94043, USA, gestellt.
                  Datenschutzerklärung:
                  https://www.google.com/policies/privacy/, Opt-Out:
                  https://www.google.com/settings/ads/. - Videos der Plattform
                  "YouTube" des Dritt-Anbieters Google Inc., 1600 Amphitheatre
                  Parkway, Mountain View, CA 94043, USA. Datenschutzerklärung:
                  https://www.google.com/policies/privacy/, Opt-Out:
                  https://www.google.com/settings/ads/.
                </p>

                <p>12. Rechte der Nutzer und Löschung von Daten</p>
                <p>
                  Nutzer haben das Recht, auf Antrag unentgeltlich Auskunft zu
                  erhalten über die personenbezogenen Daten, die von uns über
                  sie gespeichert wurden. Zusätzlich haben die Nutzer das Recht
                  auf Berichtigung unrichtiger Daten, Widerruf von
                  Einwilligungen, Sperrung und Löschung ihrer personenbezogenen
                  Daten sowie das Recht, im Fall der Annahme einer
                  unrechtmäßigen Datenverarbeitung eine Beschwerde bei der
                  zuständigen Aufsichtsbehörde einzureichen. Die bei uns
                  gespeicherten Daten werden gelöscht, sobald sie für ihre
                  Zweckbestimmung nicht mehr erforderlich sind und der Löschung
                  keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
                </p>

                <p>13. Änderungen der Datenschutzerklärung</p>
                <p>
                  Wir behalten uns vor, die Datenschutzerklärung zu ändern, um
                  sie an geänderte Rechtslagen, oder bei Änderungen des Dienstes
                  sowie der Datenverarbeitung anzupassen. Dies gilt jedoch nur
                  im Hinblick auf Erklärungen zur Datenverarbeitung. Sofern
                  Einwilligungen der Nutzer erforderlich sind oder Bestandteile
                  der Datenschutzerklärung Regelungen des Vertragsverhältnisses
                  mit den Nutzern enthalten, erfolgen die Änderungen nur mit
                  Zustimmung der Nutzer. Die Nutzer werden gebeten sich
                  regelmäßig über den Inhalt der Datenschutzerklärung zu
                  informieren. Stand: 03.07.2019
                </p>
              </Box>
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }
}

export default withContext(Datenschutz);
