import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import { Grid, Row, Cell } from "../../foundation/foundation";
import DefaultLayout from "../../layout/DefaultLayout";
import AnneTransparent from "../../../images/anne-transparent.png";
//import ImageEbook from "../../../images/innerewelt/ebook-3d.png";
//import ImageEbook from "../../../images/home/ebook-2021.png";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PTPopup from "../../ptPopup/PTPopup";

import Trophy from "../../../images/home/trophy.png";

class Home extends Component {
  state = {
    promoCourse: null,
    quizBoxesHeight: 0,
    selectedAward: null,
    ebookUrl: null,
    ebookThumbnail: null,
  };

  componentDidMount() {
    this.init();
    this.updateView();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateView();
  }

  render() {
    const _c = this.props.context;
    if (!_c.user) {
      return null;
    }

    return (
      <DefaultLayout
        breadcrumbs={["home"]}
        page="account page-home"
        background="home"
      >
        {this.view__showSectionGreeting()}

        {this.view__showBoxes()}
      </DefaultLayout>
    );
  }

  view__showSectionGreeting() {
    return (
      <section className="home-greeting">
        <Grid>
          <Row>
            <Cell sm={24}>
              <Grid type="full">
                <Row>
                  <Cell sm={24} md={12}>
                    <div className="wrapper">
                      <div className="center-vertically">
                        {this.view__showTitle()}
                      </div>
                    </div>
                  </Cell>
                  <Cell sm={24} md={12}>
                    <img
                      src={AnneTransparent}
                      alt="Anne Vonjahr"
                      className="home-greeting-anne"
                    />
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </section>
    );
  }

  view__showTitle() {
    const _c = this.props.context;
    let v__title = (
      <span>
        Herzlich willkommen <br className="hide-for-small-only" />
        in der Welt von Beyond!
      </span>
    );

    if (_c.isUserLoggedIn()) {
      const user = _c.getUser();
      v__title = (
        <span>
          Herzlich willkommen <br className="hide-for-small-only" />
          in der Welt von Beyond, {user.firstname}!
        </span>
      );
    }

    return <h1>{v__title}</h1>;
  }

  view__showBoxes() {
    return (
      <section className="home-quizzes">
        <Grid>
          <Row>
            <Cell sm={24}>
              {/*this.view__showUserAwards()*/}
              <Grid type="full">
                <Row margin="xy">
                  <Cell sm={24} md={8}>
                    {this.view__showQuiz1()}
                  </Cell>
                  <Cell sm={24} md={8}>
                    {this.view__showQuiz2()}
                  </Cell>
                  <Cell sm={24} md={8}>
                    {this.view__showEbook()}
                  </Cell>
                </Row>
                <Row margin="xy">
                  {/*<Cell sm={24} md={8}>
                    {this.view__showPhoenix()}
    </Cell>*/}
                  {/*<Cell sm={24} md={16}>
                    {this.view__showPromoCourse()}
                    </Cell>*/}
                  <Cell sm={24} md={8}>
                    {this.view__showCourses()}
                  </Cell>
                  {/*<Cell sm={24} md={8}>
                    {this.view__showAchievementRoom()}
                  </Cell>*/}
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </section>
    );
  }

  view__showUserAwards() {
    const { selectedAward } = this.state;

    const _c = this.props.context;
    const { user } = _c;

    let output;

    if (user && user.awards && user.awards.length > 0) {
      output = (
        <div className="home-user-awards">
          <h2>Deine Abzeichen</h2>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          {user.awards.map((award, index) => {
            if (!award.info) {
              return null;
            }

            return (
              <button
                onClick={() => {
                  this.setState({ selectedAward: award });
                }}
                className="home-user-award"
                key={index}
                data-tip={award.info.name}
              >
                <img src={award.info.image} alt="" />
              </button>
            );
          })}
        </div>
      );
    }

    let popup;
    if (selectedAward) {
      popup = (
        <div className="home-user-award-popup">
          <PTPopup
            show={selectedAward !== null}
            size="small"
            handleClose={() => {
              this.setState({ selectedAward: null });
            }}
          >
            <img
              src={selectedAward.info.image}
              alt=""
              className="home-user-award-popup-image"
            />
            <h3>{selectedAward.info.name}</h3>
            <div className="home-user-award-popup-info">
              Du hast dieses Abzeichen am {selectedAward.created_at_f} erhalten.
            </div>
          </PTPopup>
        </div>
      );
    }

    return (
      <React.Fragment>
        {output}
        {popup}
      </React.Fragment>
    );
  }

  view__showQuiz1() {
    const _c = this.props.context;
    const { user } = _c;

    let v__quiz = (
      <Link
        to="/quiz/mystische-kreatur"
        className="home-quiz-box home-quiz-box-creature"
      >
        <div className="wrapper">
          <div className="center-vertically">
            <div className="home-quiz-content">
              <h3>Deine mystische Kreatur</h3>
              <div className="home-quiz-text">
                Dieses Quiz hast Du noch nicht gemacht.
              </div>
              <button className="primary hollow button">
                Finde jetzt heraus, welche{" "}
                <br className="hide-for-small-onley" />
                mystische Figur Dich beschützt
              </button>
            </div>
          </div>
        </div>
      </Link>
    );

    if (user && user.creature && user.creature.id) {
      let v__image = <img src={user.creature.image} alt={user.creature.name} />;

      v__quiz = (
        <Link to="/quiz/mystische-kreatur/result" className="home-quiz-box">
          <div className="wrapper">
            <div className="center-vertically">
              <div className="home-quiz-content">
                <h3>Deine mystische Kreatur</h3>
                <div className="home-quiz-image">{v__image}</div>
                <div className="home-quiz-text">
                  <h4>{user.creature.name}</h4>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }

    return <React.Fragment>{v__quiz}</React.Fragment>;
  }

  view__showQuiz2() {
    const _c = this.props.context;
    const { user } = _c;

    let v__quiz = (
      <Link
        to="/quiz/magisches-objekt"
        className="home-quiz-box home-quiz-box-object"
      >
        <div className="wrapper">
          <div className="center-vertically">
            <div className="home-quiz-content">
              <h3>Dein magisches Objekt</h3>
              <div className="home-quiz-text">
                Dieses Quiz hast Du noch nicht gemacht.
              </div>
              <button className="primary hollow button">
                Finde jetzt heraus, welches{" "}
                <br className="hide-for-small-onley" />
                magische Objekt zu Dir gehört
              </button>
            </div>
          </div>
        </div>
      </Link>
    );

    if (user && user.object && user.object.id) {
      let v__image = <img src={user.object.image} alt={user.object.name} />;

      v__quiz = (
        <Link to="/quiz/magisches-objekt/result" className="home-quiz-box">
          <div className="wrapper">
            <div className="center-vertically">
              <div className="home-quiz-content">
                <h3>Dein magisches Objekt</h3>
                <div className="home-quiz-image">{v__image}</div>
                <div className="home-quiz-text">
                  <h4>{user.object.name}</h4>
                </div>
              </div>
            </div>
          </div>
        </Link>
      );
    }

    return <React.Fragment>{v__quiz}</React.Fragment>;
  }

  view__showEbook() {
    const { ebookUrl, ebookThumbnail } = this.state;

    let v__image = <img src={ebookThumbnail} alt="E-Book" />;

    let v__ebook = (
      <a
        href={ebookUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="home-quiz-box"
      >
        <div className="wrapper">
          <div className="center-vertically">
            <div className="home-quiz-content">
              <h3>Dein E-Book</h3>
              <div className="home-ebook-image">{v__image}</div>
              <div className="home-ebook-text">
                <p style={{ marginBottom: "10px" }}>
                  Tauche ein in die Botschaft aller mystischen Kreaturen und
                  magischer Objekte.
                </p>
                <p style={{ marginBottom: "0" }}>
                  Erfahre mehr über Deine innere Kraft und Herausforderungen in
                  Deinem Leben.
                </p>
              </div>
            </div>
          </div>
        </div>
      </a>
    );

    return <React.Fragment>{v__ebook}</React.Fragment>;
  }

  view__showPhoenix() {
    let v__phoenix = (
      <a href="/videoserie" className="home-phoenix">
        <div className="wrapper">
          <div className="center-vertically">
            <div className="home-phoenix-content">
              <h3>
                Der Phönix und
                <br />
                die Rückkehr des Magiers
              </h3>
              <div className="home-phoenix-text">
                <p style={{ marginBottom: "0" }}>
                  Erkenne die Kraft des Phönix in Deiner inneren Welt und
                  erfahre, welche Botschaft der Archetyp des Magiers für Dich
                  hat. Möge ihre Weisheit Dich in Zeiten der Veränderung
                  stärken.
                </p>
              </div>
              <div className="home-phoenix-button">
                <button className="primary hollow button">
                  Zur kostenfreien Videoserie
                </button>
              </div>
            </div>
          </div>
        </div>
      </a>
    );

    return <React.Fragment>{v__phoenix}</React.Fragment>;
  }

  view__showAchievementRoom() {
    return (
      <Link to="/achievements" className="home-achievements-box">
        <div className="wrapper">
          <div className="center-vertically">
            <img src={Trophy} alt="Achievements" />
          </div>
        </div>
      </Link>
    );
  }

  view__showCourses() {
    return (
      <Link to="/courses" className="home-courses-box">
        <div className="wrapper">
          <div className="center-vertically">
            <h3>KURSE</h3>
          </div>
        </div>
      </Link>
    );
  }

  view__showPromoCourse() {
    const { promoCourse } = this.state;

    if (!promoCourse) {
      return null;
    }

    let v__image;
    if (promoCourse.image) {
      v__image = <img src={promoCourse.image} alt={promoCourse.name} />;
    }

    let courseUrl = promoCourse.purchase_url;
    let buttonText = "Mehr erfahren";
    if (promoCourse.user_has_access === true) {
      courseUrl = `/course/${promoCourse.slug}`;
      buttonText = "Zum Kurs";
    }

    let v__promoCourse = (
      <div className="home-promo-course-content">
        <div className="wrapper">
          <div className="center-vertically">
            <Grid type="full">
              <Row margin="x">
                <Cell sm={24} md={10}>
                  <div className="home-promo-course-image">{v__image}</div>
                </Cell>
                <Cell sm={24} md={14}>
                  <div className="home-promo-course-text">
                    <h2>Stehst Du vor einer Wegkreuzung in Deinem Leben?</h2>
                    <div>
                      Dann bist Du an einen magischen Ort und es ist die Zeit
                      gekommen, Dein nächstes Abenteuer in Deiner inneren Welt
                      anzutreten.
                    </div>
                  </div>
                  <div className="home-promo-course-button">
                    <button className="primary hollow button">
                      {buttonText}
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>
      </div>
    );

    if (promoCourse.user_has_access === true) {
      v__promoCourse = (
        <a href={courseUrl} className="home-promo-course">
          {v__promoCourse}
        </a>
      );
    } else {
      v__promoCourse = (
        <a
          href={courseUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="home-promo-course"
        >
          {v__promoCourse}
        </a>
      );
    }

    return <React.Fragment>{v__promoCourse}</React.Fragment>;
  }

  updateView = () => {
    return;
    /*const { quizBoxesHeight } = this.state;

    let quizBoxesMaxHeight = 0;

    const elements = document.getElementsByClassName("home-quiz-box");

    for (let i = 0; i < elements.length; i++) {
      console.log(elements[i].offsetHeight);
      if (parseInt(elements[i].offsetHeight) > parseInt(quizBoxesMaxHeight)) {
        quizBoxesMaxHeight = parseInt(elements[i].offsetHeight);
      }
    }

    console.log(quizBoxesMaxHeight, quizBoxesHeight);

    if (quizBoxesMaxHeight !== quizBoxesHeight) {
      this.setState({ quizBoxesHeight: quizBoxesMaxHeight });
      console.log(quizBoxesMaxHeight);
      for (let i = 0; i < elements.length; i++) {
        //elements[i].style.height = `${quizBoxesMaxHeight}px`;
        console.log(elements[i]);
      }
    }*/
  };

  init() {
    const _c = this.props.context;
    _c.setPageTitle("Meine Welt von Beyond");
    _c.login(() => {
      if (!_c.isUserLoggedIn()) {
        this.props.history.push("/login");
      }
    });

    axios
      .get(_c.apiEndpoints.home, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          _c.handleApiResponse(response.data, true);

          const { promoCourse } = response.data.data;
          this.setState({ promoCourse });

          if (_c.isDebug()) {
            console.log(response.data);
          }

          const ebookUrl = _c.setting("ebook_url");
          const ebookThumbnail = _c.setting("ebook_thumbnail");

          this.setState({ ebookUrl, ebookThumbnail });

          _c.initFinished();
        } catch {
          alert(
            "Home - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(Home);
