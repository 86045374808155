import React, { Component } from "react";
import AudioPlayer from "react-h5-audio-player";

class Audio extends Component {
  state = {
    lesson: this.props.lesson,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lesson !== prevProps.lesson) {
      this.setState({ lesson: this.props.lesson });
    }
  }

  render() {
    const { lesson } = this.state;

    if (!lesson || !lesson.content || !lesson.id) {
      return null;
    }

    return (
      <React.Fragment>
        <AudioPlayer
          src={lesson.content}
          customAdditionalControls={[]}
          onListen={(e) => {
            if (e && e.target && e.target.currentTime) {
              const currentTime = e.target.currentTime;
              //console.log(e);
              //console.log("currentTime", currentTime);

              if (this.props.onListen) {
                this.props.onListen(parseInt(currentTime));
              }
            }
          }}
        />
      </React.Fragment>
    );
  }
}

export default Audio;
