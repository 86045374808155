import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import AdminLayout from "../AdminLayout";
import Box from "../../layout/Box";
import { Cell, Grid, Row } from "../../foundation/_grid";
import { Icon } from "../../foundation/_buttons";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { cloneDeep } from "lodash";
import AdminDataField from "../AdminDataField";
import Collapse from "@kunukn/react-collapse";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

class AdminFaq extends Component {
  state = {
    faq: null,
    isOpen: [],
    changesWereMade: false,
    selectedCategory: null,
    selectedEntry: null,
    newEntry: {
      id: "new",
      question: "",
      visible: true,
    },
    newCategory: {
      id: "new",
      name: "",
    },
    editorstate: null,
  };

  componentDidMount() {
    this.setState({ editorstate: EditorState.createEmpty() });
    this.init();
    this.props.context.setPageTitle("Admin FAQ");
  }

  render() {
    return (
      <AdminLayout nav="faq" breadcrumbs={["admin_dashboard", "admin_faq"]}>
        <div className="admin-faq">
          <div className="float-right">
            <button
              className="primary hollow button"
              onClick={() => {
                this.setState({
                  selectedCategory: cloneDeep(this.state.newCategory),
                });
              }}
            >
              <Icon icon="plus-circle" left /> Neue Kategorie
            </button>
          </div>
          <h1>FAQ</h1>
          {this.view__showFaq()}
          <ul id="adminSort1" className="admin-list" />
          <ul id="adminSort2" className="admin-list" />
          {this.view__showEntryPopup()}
          {this.view__showCategoryPopup()}
        </div>
      </AdminLayout>
    );
  }

  view__showEntryPopup() {
    const { selectedEntry, faq, editorState } = this.state;

    if (selectedEntry) {
      let v__title = "FAQ-Eintrag bearbeiten";
      let v__deleteButton = (
        <button
          className="primary hollow button"
          onClick={this.handle__deleteEntry}
        >
          <Icon icon="trash" left /> Eintrag löschen
        </button>
      );
      if (selectedEntry.id === "new") {
        v__title = "Neuer FAQ-Eintrag";
        v__deleteButton = null;
      }

      let categories = {};
      faq.map((faqCategory) => {
        categories[faqCategory.id] = faqCategory.name;
        return null;
      });

      return (
        <MMPopup
          size="medium"
          show={true}
          handleClose={this.handle__cancelEntry}
        >
          <h3>{v__title}</h3>

          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedEntry.question}
                  editValue={selectedEntry.question}
                  editType="text"
                  label="Frage / Überschrift"
                  selectValues={categories}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__editEntry("question", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <div className="admin-faq-editor">
                  <Editor
                    editorState={editorState}
                    wrapperClassName="editor-wrapper"
                    editorClassName="editor-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    localization={{
                      locale: "de",
                    }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "textAlign",
                        "colorPicker",
                        "list",
                        "image",
                        "link",
                        "emoji",
                        "history",
                      ],
                      inline: {
                        inDropdown: false,
                        options: [
                          "bold",
                          "italic",
                          "underline",
                          "strikethrough",
                        ],
                      },
                      blockType: {
                        inDropdown: false,
                        options: [
                          "Normal",
                          "H1",
                          "H2",
                          "H3",
                          "H4",
                          "H5",
                          "H6",
                          "Blockquote",
                        ],
                      },
                    }}
                  />
                </div>
              </Cell>
            </Row>
          </Grid>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedEntry.category}
                  editValue={selectedEntry.category}
                  editType="select"
                  label="Kategorie"
                  selectValues={categories}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__editEntry("category", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={24} className="text-center">
                <AdminDataField
                  value={selectedEntry.visible}
                  editValue={selectedEntry.visible}
                  editType="singleCheckbox"
                  label="Eintrag veröffentlicht"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__editEntry("visible", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={12}>
                      {v__deleteButton}
                    </Cell>
                    <Cell sm={24} md={12} className="text-right">
                      <button
                        className="primary hollow button"
                        onClick={this.handle__cancelEntry}
                      >
                        <Icon icon="times" left /> Abbrechen
                      </button>
                      <button
                        className="primary button"
                        onClick={this.handle__saveEntry}
                      >
                        <Icon icon="check" left /> Speichern
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      );
    }
  }

  view__showCategoryPopup() {
    const { selectedCategory } = this.state;

    if (selectedCategory) {
      let v__title = "FAQ-Kategorie bearbeiten";
      let v__deleteButton = (
        <button
          className="primary hollow button"
          onClick={this.handle__deleteCategory}
        >
          <Icon icon="trash" left /> Kategorie löschen
        </button>
      );
      if (selectedCategory.id === "new") {
        v__title = "Neue FAQ-Kategorie";
        v__deleteButton = null;
      }

      return (
        <MMPopup
          size="medium"
          show={true}
          handleClose={this.handle__cancelCategory}
        >
          <h3>{v__title}</h3>

          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedCategory.name}
                  editValue={selectedCategory.name}
                  editType="text"
                  label="Name der Kategorie"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__editCategory("name", newValue);
                  }}
                />
              </Cell>
            </Row>

            <Row margin="xy">
              <Cell sm={24} md={24}>
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={12}>
                      {v__deleteButton}
                    </Cell>
                    <Cell sm={24} md={12} className="text-right">
                      <button
                        className="primary hollow button"
                        onClick={this.handle__cancelCategory}
                      >
                        <Icon icon="times" left /> Abbrechen
                      </button>
                      <button
                        className="primary button"
                        onClick={this.handle__saveCategory}
                      >
                        <Icon icon="check" left /> Speichern
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      );
    }
  }

  view__showFaq = () => {
    const { faq } = this.state;

    if (!faq) {
      return null;
    }

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-faq-categories">{children}</ul>;
    });

    const SortableItem = sortableElement(({ faqCategory }) => {
      let categoryLink = `${window.location.protocol}//${window.location.host}/faq/${faqCategory.id}`;
      return (
        <li>
          <div className="faq-category">
            <Box>
              <Grid type="full">
                <Row>
                  <Cell sm={2} md={1}>
                    <div className="area-drag-handle-wrapper">
                      <DragHandle />
                    </div>
                  </Cell>
                  <Cell sm={22} md={23}>
                    <Grid type="full">
                      <Row>
                        <Cell sm={12} md={12}>
                          <h3>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.setState({
                                  selectedCategory: cloneDeep(faqCategory),
                                });
                              }}
                            >
                              {faqCategory.name}
                            </span>
                          </h3>
                        </Cell>
                        <Cell sm={12} md={12} className="text-right">
                          <div className="admin-faq-direktlink">
                            Direktlink:{" "}
                            <a
                              href={categoryLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {categoryLink}
                            </a>
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                    {this.view__showEntries(faqCategory)}
                  </Cell>
                </Row>
              </Grid>
            </Box>
          </div>
        </li>
      );
    });

    return (
      <div>
        <SortableContainer
          onSortEnd={this.onCategoriesSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminSort1")}
        >
          {faq.map((faqCategory, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                faqCategory={faqCategory}
              />
            );
          })}
        </SortableContainer>
        <div
          className="admin-faq-add-button"
          onClick={() => {
            this.setState({
              selectedCategory: cloneDeep(this.state.newCategory),
            });
          }}
        >
          <button>
            <Icon icon="plus-circle" left /> Neue Kategorie hinzufügen
          </button>
        </div>
      </div>
    );
  };

  view__showEntries = (faqCategory) => {
    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-faq-entries">{children}</ul>;
    });

    const SortableItem = sortableElement(({ counter, faqEntry }) => {
      let v__caret = (
        <button
          className="admin-faq-toggle-button"
          onClick={() => {
            this.toggleOpen(faqEntry.id);
          }}
        >
          <Icon icon="caret-right" />
        </button>
      );

      if (faqEntry.isOpen === true) {
        v__caret = (
          <button
            className="admin-faq-toggle-button"
            onClick={() => {
              this.toggleOpen(faqEntry.id);
            }}
          >
            <Icon icon="caret-down" />
          </button>
        );
      }

      let classes = "faq-entry";
      if (!faqEntry.visible) {
        classes = `notvisible ${classes}`;
      }

      let entryLink = `${window.location.protocol}//${window.location.host}/faq/${faqCategory.id}/${faqEntry.id}`;

      return (
        <li>
          <div className={classes}>
            <Box>
              <Grid type="full">
                <Row>
                  <Cell sm={2} md={1}>
                    <div className="area-drag-handle-wrapper">
                      <DragHandle />
                    </div>
                  </Cell>
                  <Cell sm={22} md={23}>
                    <Grid type="full">
                      <Row>
                        <Cell sm={24} md={12}>
                          <h3>
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                this.handle__selectEntry(cloneDeep(faqEntry));
                              }}
                            >
                              {counter}. {faqEntry.question}
                            </span>
                          </h3>
                        </Cell>
                        <Cell sm={22} md={11} className="text-right">
                          <div className="admin-faq-direktlink">
                            Direktlink:{" "}
                            <a
                              href={entryLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {entryLink}
                            </a>
                          </div>
                        </Cell>
                        <Cell sm={2} md={1} className="text-right">
                          {v__caret}
                        </Cell>
                      </Row>
                    </Grid>
                    <Collapse isOpen={faqEntry.isOpen}>
                      <div className="entry-preview">
                        {faqEntry.answer_html_preview}
                      </div>
                    </Collapse>
                  </Cell>
                </Row>
              </Grid>
            </Box>
          </div>
        </li>
      );
    });

    return (
      <div className="admin-faq-entries">
        <SortableContainer
          onSortEnd={({ oldIndex, newIndex }) => {
            this.onEntriesSortEnd({ oldIndex, newIndex }, faqCategory);
          }}
          useDragHandle
          helperContainer={document.getElementById("adminSort2")}
        >
          {faqCategory.entries.map((faqEntry, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                counter={index + 1}
                faqEntry={faqEntry}
              />
            );
          })}
          <li
            className="admin-faq-add-button"
            onClick={() => {
              const selectedEntry = cloneDeep(this.state.newEntry);
              selectedEntry.category = faqCategory.id;
              this.handle__selectEntry(selectedEntry);
            }}
          >
            <button>
              <Icon icon="plus-circle" left /> Neuen Eintrag hinzufügen
            </button>
          </li>
        </SortableContainer>
      </div>
    );
  };

  onCategoriesSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    let { faq } = this.state;
    faq = arrayMove(faq, oldIndex, newIndex);

    const newOrder = [];
    faq.map((area) => {
      return newOrder.push(area.id);
    });

    this.setState({ faq }, () => {
      const apiUrl = _c.apiUrl("adminFaqSortCategories");

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { faq } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    faq,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      "Reihenfolge gespeichert",
                      "Die Reihenfolge der Kategorien wurde gespeichert.",
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:faq:categories:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:faq:categories:sort");
          });
      }, 200);
    });
  };

  onEntriesSortEnd = ({ oldIndex, newIndex }, faqCategory) => {
    const _c = this.props.context;

    const { faq } = this.setState;
    const entries = arrayMove(faqCategory.entries, oldIndex, newIndex);

    const newOrder = [];
    entries.map((entry) => {
      return newOrder.push(entry.id);
    });

    this.setState({ faq }, () => {
      const apiUrl = _c.apiUrl("adminFaqSortEntries");

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { faq } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    faq,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      "Reihenfolge gespeichert",
                      "Die Reihenfolge der Einträge wurde gespeichert.",
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:faq:categories:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:faq:categories:sort");
          });
      }, 200);
    });
  };

  toggleOpen(faqEntryId) {
    const { faq } = this.state;

    faq.map((category) => {
      category.entries.map((entry) => {
        if (parseInt(entry.id) === parseInt(faqEntryId)) {
          if (entry.isOpen === true) {
            entry.isOpen = false;
          } else {
            entry.isOpen = true;
          }
        }
        return null;
      });
      return null;
    });

    this.setState({ faq });
  }

  handle__selectEntry(entry) {
    let editorState = EditorState.createEmpty();

    if (entry.answer) {
      editorState = EditorState.createWithContent(convertFromRaw(entry.answer));
    }

    this.setState({ selectedEntry: entry, editorState });
  }

  handle__cancelEntry = () => {
    const { changesWereMade } = this.state;

    let reallyCancel = false;
    if (changesWereMade === true) {
      if (
        window.confirm(
          `Möchtest Du die Bearbeitung wirklich abbrechen? Alle Änderungen gehen unwiderruflich verloren.`
        )
      ) {
        reallyCancel = true;
      }
    } else {
      reallyCancel = true;
    }

    if (reallyCancel === true) {
      this.setState({ selectedEntry: null, changesWereMade: false });
    }
  };

  handle__editEntry = (property, value) => {
    const { selectedEntry } = this.state;
    selectedEntry[property] = value;
    this.setState({ selectedEntry, changesWereMade: true });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      const { editorState, selectedEntry } = this.state;

      if (editorState) {
        selectedEntry.answer = convertToRaw(editorState.getCurrentContent());
        selectedEntry.answer_html = draftToHtml(selectedEntry.answer);
        this.setState({ selectedEntry, changesWereMade: true });
      }
    });
  };

  handle__deleteEntry = () => {
    const { selectedEntry } = this.state;
    const _c = this.props.context;

    if (
      window.confirm(
        "Möchtest Du diesen Eintrag wirklich unwiderruflich löschen? Die Aktion kann nicht rückgängig gemacht werden."
      )
    ) {
      axios
        .delete(_c.apiEndpoints.adminFaq + "/entry/" + selectedEntry.id, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log(response.data);
            }

            _c.handleApiResponse(response.data, true);
            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { faq } = apiResponseData;
              _c.setIsSaving(false);
              this.setState(
                {
                  faq,
                  selectedEntry: null,
                  changesWereMade: null,
                },
                () => {
                  _c.createNotifictation(
                    "Eintrag gelöscht",
                    `Der FAQ-Eintrag wurde erfolgreich gelöscht.`,
                    "success"
                  );
                }
              );
            }
          } catch {
            alert(
              "Admin Delete Entry - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
            );
          }
        })
        .catch((error) => {
          if (_c.isDebug()) {
            console.log("Error", error);
          }
        });
    }
  };

  handle__saveEntry = () => {
    const _c = this.props.context;
    const { selectedEntry } = this.state;

    if (!selectedEntry.question) {
      window.alert("Bitte gib mindestens eine Frage / Überschrift an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let url = _c.apiEndpoints.adminFaq + "/entry/" + selectedEntry.id;

      axios
        .post(
          url,
          {
            entry: selectedEntry,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);
          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { faq } = apiResponseData;
            _c.setIsSaving(false);
            this.setState(
              {
                faq,
                selectedEntry: null,
                changesWereMade: null,
              },
              () => {
                _c.cancelEditLock();
                _c.createNotifictation(
                  "Eintrag gespeichert",
                  "Der FAQ-Eintrag wurde erfolgreich gespeichert.",
                  "success"
                );
              }
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  handle__cancelCategory = () => {
    const { changesWereMade } = this.state;

    let reallyCancel = false;
    if (changesWereMade === true) {
      if (
        window.confirm(
          `Möchtest Du die Bearbeitung wirklich abbrechen? Alle Änderungen gehen unwiderruflich verloren.`
        )
      ) {
        reallyCancel = true;
      }
    } else {
      reallyCancel = true;
    }

    if (reallyCancel === true) {
      this.setState({ selectedCategory: null, changesWereMade: false });
    }
  };

  handle__editCategory = (property, value) => {
    const { selectedCategory } = this.state;
    selectedCategory[property] = value;
    this.setState({ selectedCategory, changesWereMade: true });
  };

  handle__deleteCategory = () => {
    const { selectedCategory } = this.state;
    const _c = this.props.context;

    if (
      window.confirm(
        "Möchtest Du diese Kategorie wirklich unwiderruflich löschen? Die Aktion kann nicht rückgängig gemacht werden.\r\n\r\nALLE EINTRÄGE INNERHALB DIESER KATEGORIE WERDEN EBENFALLS UNWIDERRUFLICH GELÖSCHT!"
      )
    ) {
      axios
        .delete(_c.apiEndpoints.adminFaq + "/category/" + selectedCategory.id, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log(response.data);
            }

            _c.handleApiResponse(response.data, true);
            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { faq } = apiResponseData;
              _c.setIsSaving(false);
              this.setState(
                {
                  faq,
                  selectedCategory: null,
                  changesWereMade: null,
                },
                () => {
                  _c.createNotifictation(
                    "Kategorie gelöscht",
                    `Die FAQ-Kategorie wurde erfolgreich gelöscht.`,
                    "success"
                  );
                }
              );
            }
          } catch {
            alert(
              "Admin Delete Category - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
            );
          }
        })
        .catch((error) => {
          if (_c.isDebug()) {
            console.log("Error", error);
          }
        });
    }
  };

  handle__saveCategory = () => {
    const _c = this.props.context;
    const { selectedCategory } = this.state;

    if (!selectedCategory.name) {
      window.alert("Bitte den Namen der Kategorie an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let url = _c.apiEndpoints.adminFaq + "/category/" + selectedCategory.id;

      axios
        .post(
          url,
          {
            category: selectedCategory,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);
          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { faq } = apiResponseData;
            _c.setIsSaving(false);
            this.setState(
              {
                faq,
                selectedCategory: null,
                changesWereMade: null,
              },
              () => {
                _c.cancelEditLock();
                _c.createNotifictation(
                  "Kategorie gespeichert",
                  "Die FAQ-Kategorie wurde erfolgreich gespeichert.",
                  "success"
                );
              }
            );
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminFaq, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);
          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { faq } = apiResponseData;
            this.setState({
              faq,
              status: "LOADED",
            });
          }
        } catch {
          alert(
            "Admin FAQ - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminFaq);
