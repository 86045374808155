import React, { Component } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../foundation/_grid";
import Box from "../layout/Box";
import AdminDataField from "../admin/AdminDataField";
import { Icon } from "../foundation/_buttons";
import { isUndefined } from "util";
import PTDialog from "../ptdialog/PTDialog";

import PTPopup from "../ptPopup/PTPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class MyAccount extends Component {
  state = {
    account: undefined,
    original: undefined,
    editMode: true,
    loadingError: false,
    changesWereMade: false,
    popupImageUpload: false,
    popupImageCrop: false,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 1 / 1,
    },
    showDialogDeleteAvatar: false,
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return (
      <DefaultLayout breadcrumbs={["account"]} page="account page-default">
        <Grid>
          <Row>
            <Cell sm={24}>
              <h1>Mein Account</h1>
              {this.view__showPageButtons()}
            </Cell>
          </Row>
          {this.view__showContent()}
          {this.view__showPageButtons()}
        </Grid>

        {this.view__showPopups()}
      </DefaultLayout>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;

    if (editMode === true) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <div className="page-buttons">
                <button
                  className="small primary hollow button"
                  data-tip="Abbrechen"
                  onClick={this.handle__cancel}
                >
                  <Icon icon="times" left /> Abbrechen
                </button>
                <button
                  className="small primary button"
                  data-tip="Änderungen speichern"
                  onClick={this.handle__save}
                >
                  <Icon icon="check" left /> Speichern
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  view__showPopups() {
    const {
      popupImageUpload,
      popupImageCrop,
      cropUrl,
      crop,
      showDialogDeleteAvatar,
    } = this.state;

    return (
      <React.Fragment>
        <PTPopup
          show={popupImageUpload}
          size="medium"
          nopadding
          handleClose={() => {
            this.hidePopupImageUpload();
          }}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h2>Benutzerbild hochladen</h2>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <p>
                        Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab
                        oder
                        <br />
                        klicke hier, um ein Benutzerbild hochzuladen.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </PTPopup>

        <PTPopup
          show={popupImageCrop}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h2>Bildausschnitt wählen</h2>
          </div>

          <ReactCrop
            src={cropUrl}
            crop={crop}
            onChange={this.onCrop}
            circularCrop={true}
          />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> Abbrechen
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadAvatar}
                  >
                    <Icon icon="check" left /> Bildausschnitt wählen
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
        <PTDialog
          show={showDialogDeleteAvatar}
          message={
            <p>
              Möchtest Du Dein aktuelles Benutzerbild wirklich unwiderruflich
              löschen?
              <br />
              Diese Aktion kann <u>nicht</u> rückgängig gemacht werden. Du
              kannst aber jederzeit ein neues Bild hochladen.
            </p>
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDeleteAvatar: false });
          }}
          onButton_2={this.handle__deleteAvatar}
        />
      </React.Fragment>
    );
  }

  view__showContent() {
    const { account, loadingError } = this.state;
    const _c = this.props.context;

    if (isUndefined(account)) {
      return _c.loading(loadingError);
    } else if (account === null) {
      return _c.notfound("Account nicht gefunden");
    }

    return (
      <Row margin="xy">
        <Cell sm={24} md={12}>
          {this.view__showBoxNameInfo()}
          {this.view__showBoxContactInfo()}
          {this.view__showBoxAddressInfo()}
        </Cell>
        <Cell sm={24} md={12}>
          {this.view__showBoxAvatar()}
          {this.view__showBoxPersonalInfo()}
        </Cell>
      </Row>
    );
  }

  view__showBoxNameInfo() {
    const { account, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Name</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={account.firstname}
                editValue={account.firstname}
                editType="text"
                label="Vorname"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("firstname", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={account.lastname}
                editValue={account.lastname}
                editType="text"
                label="Nachname"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("lastname", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxPersonalInfo() {
    const { account, editMode } = this.state;
    const _c = this.props.context;

    let selectValuesGenders = [];
    selectValuesGenders = _c.getGenders();

    let accountGender = account.gender;
    if (!accountGender) {
      accountGender = "";
    }

    let accountGender_f = _c.getGender(account.gender);
    if (!accountGender_f) {
      accountGender_f = "";
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Persönliche Informationen</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={account.birthday_date || ""}
                editValue={account.birthday || ""}
                editType="date"
                label="Geburtstag"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("birthday", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={accountGender_f}
                editValue={accountGender}
                editType="select"
                label="Geschlecht"
                selectValues={selectValuesGenders}
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("gender", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxContactInfo() {
    const { account, original, editMode } = this.state;

    let emailNote = null;
    if (editMode === true && account.email !== original.email) {
      emailNote = (
        <div className="small warning callout">
          <small>
            <strong>
              <Icon icon="exclamation-circle" /> Achtung:
            </strong>{" "}
            Mit dieser E-Mail-Adresse loggst Du Dich hier ein. Bitte beachte,
            dass Du Dich nur noch mit der geänderten E-Mail-Adresse einloggen
            kannst, falls Du Deine E-Mail-Adresse hier änderst.
          </small>
        </div>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Kontakt</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              {emailNote}
              <AdminDataField
                value={account.email}
                editValue={account.email}
                editType="text"
                label="E-Mail-Adresse"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("email", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={account.phone}
                editValue={account.phone}
                editType="text"
                label="Telefon"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("phone", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAddressInfo() {
    const { account, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Adresse</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={account.street}
                editValue={account.street}
                editType="text"
                label="Straße"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("street", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={6}>
              <AdminDataField
                value={account.zip}
                editValue={account.zip}
                editType="text"
                label="PLZ"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("zip", newValue);
                }}
              />
            </Cell>
            <Cell sm={24} md={18}>
              <AdminDataField
                value={account.city}
                editValue={account.city}
                editType="text"
                label="Stadt"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("city", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={account.country}
                editValue={account.country}
                editType="text"
                label="Land"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("country", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAvatar() {
    const { account } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Benutzerbild</h3>
            </Cell>
          </Row>
          <Row margein="x">
            <Cell sm={24} md={17}>
              <div
                style={{
                  display: account.avatar ? "block" : "none",
                }}
              >
                <button
                  className="account-delete-avatar"
                  onClick={() => {
                    this.setState({ showDialogDeleteAvatar: true });
                  }}
                >
                  Benutzerbild entfernen
                </button>
              </div>

              <div>
                <button
                  className="primary hollow button"
                  style={{ marginBottom: "0" }}
                  onClick={() => {
                    this.showPopupImageUpload();
                  }}
                >
                  Neues Bild hochladen
                </button>
              </div>
            </Cell>
            <Cell sm={24} md={7}>
              {this.view__showAvatar()}
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  view__showAvatar() {
    const { account } = this.state;
    if (account.avatar) {
      return (
        <img src={account.avatar} alt="" className="account-avatar-preview" />
      );
    } else {
      return null;
    }
  }

  handle__deleteAvatar = () => {
    const _c = this.props.context;

    axios
      .patch(_c.apiEndpoints.deleteAvatar, {}, { headers: _c.headers })
      .then((response) => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  showPopupImageUpload() {
    this.setState({ popupImageUpload: true });
  }

  hidePopupImageUpload() {
    this.setState({ popupImageUpload: false });
  }

  showPopupImageCrop() {
    this.setState({ popupImageCrop: true });
  }

  hidePopupImageCrop() {
    this.setState({ popupImageCrop: false });
  }

  uploadAvatar = () => {
    const { cropImage, crop, account } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    axios
      .post(_c.apiEndpoints.uploadAvatar, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          account.avatar = path;
          this.setState({ account });
          this.hidePopupImageCrop();
          _c.user.avatar = account.avatar;
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  handle__edit(property, value) {
    const { account } = this.state;
    account[property] = value;
    this.setState({ account, changesWereMade: true });
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Du hast bereits Änderungen an Deinem Account vorgenommen..\r\n\r\nMöchtest Du wirklich abbrechen?\r\nAlle Änderungen gehen dann verloren."
        )
      ) {
        this.props.history.push(`/`);
      } else {
        return false;
      }
    } else {
      this.props.history.push(`/`);
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { account } = this.state;

    if (!account.firstname || !account.email) {
      window.alert(
        "Bitte gib mindestens Deinen Namen und Deine E-Mail-Adresse an."
      );
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let url = _c.apiEndpoints.account;

      // Prepare Birthday
      if (account.birthday) {
        account.birthday = _c.formatDateForSaving(account.birthday);
      }

      axios
        .patch(
          url,
          {
            account: account,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { account } = response.data;

          _c.user.avatar = account.avatar;
          _c.user.firstname = account.firstname;
          _c.user.lastname = account.lastname;

          const original = JSON.parse(JSON.stringify(account));
          _c.setIsSaving(false);
          this.setState(
            {
              changesWereMade: false,
              editMode: true,
              account,
              original,
            },
            () => {
              _c.createNotifictation(
                "Änderungen gespeichert",
                "Die Änderungen an Deinem Account wurden erfolgreich gespeichert.",
                "success"
              );
            }
          );
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.account, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { user, account } = response.data;
          const original = JSON.parse(JSON.stringify(account));

          this.setState({ account, original });

          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.updateUser(user, () => {
            _c.initFinished();
          });
        } catch {
          alert(
            "My Account - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(MyAccount);
