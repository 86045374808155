import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import axios from "axios";

class CourseDirectAccess extends Component {
  state = {
    courseSlug: this.props.match.params.courseSlug
  };

  componentDidMount() {
    this.init();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }

  init() {
    const { courseSlug } = this.state;
    const _c = this.props.context;
    let url = _c.apiUrl("course", {
      courseSlug
    });

    axios
      .get(url, {
        headers: _c.getHeaders()
      })
      .then(response => {
        try {
          const { status, lessonUrl } = response.data;

          if (_c.isDebug()) {
            console.log(response.data);
          }

          if (status && status === "OK" && lessonUrl) {
            console.log("LESSON FOUND: " + lessonUrl);
            window.location.href = lessonUrl;
          } else {
            console.log("REDIRECT TO COURSES OVERVIEW :(");
            //window.location.href = "/courses";
          }
        } catch {
          alert(
            "Course Direct Access - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch(error => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.checkError(error);
      });
  }
}

export default withContext(CourseDirectAccess);
