import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../foundation/_grid";
import DefaultLayout from "../layout/DefaultLayout";
import axios from "axios";
import Box from "../layout/Box";
import { Icon } from "../foundation/_buttons";
import { isArray } from "util";
import SignupForm from "../auth/SignupForm";
import ImageObjects from "../../images/index/tisch-objekte.jpg";
import AnneGoldKreis from "../../images/innerewelt/anne-gold-kreis.gif";
import { Link } from "react-router-dom";

class QuizResult extends Component {
  state = {
    baseUrl: "/quiz",
    slug: this.props.match.params.slug,
    quiz1Id: 1, // Mystische Kreaturen Quiz Id
    quiz2Id: 2, // Magische Objekte
    quiz: null,
    result: null,
    status: null,
    signupStatus: null,
    awardStatus: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.login(() => {
      this.init();
    });
  }

  render() {
    const { quiz } = this.state;

    if (!quiz || !quiz.id) {
      return null;
    }

    return (
      <div className={`page page-quiz quiz-${quiz.id}`}>
        <DefaultLayout>{this.view__showContent()}</DefaultLayout>
      </div>
    );
  }

  view__showContent() {
    const { quiz, status } = this.state;
    const _c = this.props.context;

    if (!quiz) {
      return _c.loading();
    }

    let v__content;
    if (status === "loading") {
      v__content = this.view__showLoading();
    } else if (status === "loaded" || status === "code") {
      v__content = this.view__showResult();
    } else {
      v__content = null;
    }

    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={24}>
            {this.view__showTitle()}

            {v__content}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTitle() {
    const { quiz, quiz1Id, quiz2Id } = this.state;

    let v__title;
    if (quiz.id === quiz1Id) {
    } else if (quiz.id === quiz2Id) {
    } else {
      v__title = (
        <React.Fragment>
          <h1>{quiz.title}</h1>
          <h3>Dein Ergebnis</h3>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Grid>
          <Row>
            <Cell sm={24} md={14} mdo={5}>
              {v__title}
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showLoading() {
    return (
      <div className="quiz-result-loading">
        <Box>
          <div>
            <Icon icon="spin fa-spinner" />
          </div>
          <div>Dein Ergebnis wird ermittelt...</div>
        </Box>
      </div>
    );
  }

  view__showResult() {
    const { result, quiz, quiz1Id, quiz2Id } = this.state;
    const resultInfo = result.result_info;

    if (result.quiz === quiz1Id) {
      return this.view__showResultQuiz1();
    } else if (result.quiz === quiz2Id) {
      return this.view__showResultQuiz2();
    } else if (quiz.type === "points") {
      return this.view__showResultPointsQuiz();
    }

    let v__image;
    let v__title;
    let v__description;

    if (resultInfo.image) {
      v__image = (
        <div className="quiz-result-image">
          <img src={resultInfo.image} alt={resultInfo.title} />
        </div>
      );
    }

    if (resultInfo.title) {
      v__title = <h4 className="quiz-result-title">{resultInfo.title}</h4>;
    }

    if (resultInfo.description) {
      v__description = (
        <div className="quiz-result-title">{resultInfo.title}</div>
      );
    }

    return (
      <div className="quiz-result-result">
        <Box>
          {v__image}
          {v__title}
          {v__description}
        </Box>
      </div>
    );
  }

  view__showResultPointsQuiz() {
    const { result, quiz, awardStatus } = this.state;
    const resultInfo = result.result_info;

    let v__image;
    let v__title;
    let v__description;

    if (resultInfo.image) {
      v__image = (
        <div className="quiz-result-image">
          <img src={resultInfo.image} alt={resultInfo.title} />
        </div>
      );
    } else if (resultInfo.award) {
      v__image = (
        <div className="quiz-result-image">
          <img src={resultInfo.award.image} alt={resultInfo.award.name} />
        </div>
      );
    }

    if (resultInfo.title) {
      v__title = <h4 className="quiz-result-title">{resultInfo.title}</h4>;
    }

    if (resultInfo.description) {
      v__description = (
        <div className="quiz-result-title">{resultInfo.title}</div>
      );
    }

    let v__button, v__form;
    if (quiz.course) {
      // Secret Wisdom
      v__button = (
        <Link
          to={`/course/${quiz.course.slug}`}
          className="primary hollow button"
        >
          Zurück zum Kurs <Icon icon="arrow-right" />
        </Link>
      );

      if (!resultInfo.award) {
        v__button = (
          <React.Fragment>
            <Link to={`/quiz/${quiz.slug}`} className="primary hollow button">
              <Icon icon="refresh" left /> Nochmal versuchen
            </Link>
            &nbsp;
            {v__button}
          </React.Fragment>
        );
      }

      // Immense Soul
      if (
        quiz &&
        quiz.course &&
        quiz.course.id === 2 &&
        awardStatus &&
        awardStatus.schwanenfedern === true
      ) {
        v__button = null;
        v__form = (
          <Box>
            <h2>Du hast alle sechs Schwanenfedern gesammelt!</h2>
            <div className="responsive-embed">
              <iframe
                src="https://player.vimeo.com/video/375255828?color=D0A98F&title=0&byline=0&portrait=0"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Vimeo"
              ></iframe>
            </div>
            <div className="text-center">
              <a
                href="https://forms.gle/W3oKS9mphe886Uvb8"
                target="_blank"
                rel="noopener noreferrer"
                className="primary button"
              >
                Zum Formular <Icon icon="arrow-right" right />
              </a>
            </div>
          </Box>
        );
      }

      // Secret Wisdom
      if (
        quiz &&
        quiz.course &&
        quiz.course.id === 3 &&
        awardStatus &&
        awardStatus.schwanenfedern === true
      ) {
        v__button = null;
        v__form = (
          <Box>
            <h2>Du hast alle vier Quizze beantwortet!</h2>
            <div className="responsive-embed">
              <iframe
                src="https://player.vimeo.com/video/374883191?color=D0A98F&title=0&byline=0&portrait=0"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Vimeo2"
              ></iframe>
            </div>
            <div className="text-center">
              <a
                href="https://forms.gle/PgM4FHMBBMcAAtgC8"
                target="_blank"
                rel="noopener noreferrer"
                className="primary button"
              >
                Zum Formular <Icon icon="arrow-right" right />
              </a>
            </div>
          </Box>
        );
      }
    }

    if (v__button) {
      v__button = <div className="quiz-result-button">{v__button}</div>;
    }

    return (
      <div className="quiz-points-quiz quiz-result-result">
        <Grid>
          <Row>
            <Cell sm={24} md={12} mdo={6}>
              <Box>
                {v__title}
                {v__image}
                {v__description}
                {v__button}
              </Box>
              {v__form}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showResultQuiz1() {
    const { result, quiz1Id, quiz, status } = this.state;
    const resultInfo = result.result_info;
    const { creature } = resultInfo;

    const _c = this.props.context;

    let v__image;
    let v__title;
    let v__message;
    let v__friends;

    if (creature.image) {
      v__image = (
        <div className="quiz-result-creature-image">
          <img src={creature.image} alt={creature.name} />
        </div>
      );
    }

    if (creature.welcome_text) {
      v__title = (
        <h2
          className="quiz-result-creature-title"
          dangerouslySetInnerHTML={{ __html: creature.welcome_text }}
        />
      );
    }

    if (creature.message_title || creature.message_text) {
      let v__messageTitle;
      let v__messageText;

      if (creature.message_title) {
        v__messageTitle = <h2>{creature.message_title}</h2>;
      }
      if (creature.message_text) {
        v__messageText = (
          <div dangerouslySetInnerHTML={{ __html: creature.message_text }} />
        );
      }

      v__message = (
        <Box>
          {v__messageTitle}
          {v__messageText}
        </Box>
      );
    }

    if (creature.friends && isArray(creature.friends)) {
      v__friends = (
        <div className="quiz-result-creature-friends">
          <h2>{creature.friends_title}</h2>
          <Grid type="full">
            <Row margin="xy">
              {creature.friends.map((friend, index) => {
                return (
                  <Cell
                    sm={24}
                    md={8}
                    classes="quiz-result-creature-friend"
                    key={index}
                  >
                    <img
                      src={friend.image}
                      alt=""
                      classes="quiz-result-creature-friend-image"
                    />
                    <div className="quiz-result-creature-friend-title">
                      {friend.name}
                    </div>
                  </Cell>
                );
              })}
            </Row>
          </Grid>
        </div>
      );
    }

    let v__signup;
    if (this.state.signupStatus === "finished") {
      v__signup = (
        <Box>
          <div className="quiz-result-creature-signup-form">
            <SignupForm type="quiz1" view="finished" />
          </div>
        </Box>
      );
    } else if (_c.isUserLoggedIn() !== true) {
      if (quiz.id === quiz1Id) {
        v__signup = (
          <div className="quiz-result-creature-signup">
            <h2 className="text-center">
              Hole Dir jetzt kostenlos das E-Book
              <br />
              &laquo;Welt von Beyond&raquo;
            </h2>
            <p className="text-center">
              Dort findest Du die Beschreibung einer jeden der mystischen
              Kreaturen und wie Du ihre Kraft in Deinem Leben erkennen kannst.
              Erfahre alles zu Deinem magischen Objekt und bei welchen Prüfungen
              in Deinem Leben es Dir hilft.
            </p>
            <Box>
              <div className="text-center">
                <div>
                  <small>
                    <strong>
                      Dein kostenfreier Start in die Welt von Beyond
                    </strong>
                  </small>
                </div>
                <h3 style={{ fontSize: "1.2em" }}>
                  „Der Phönix und die Rückkehr des Magiers“
                </h3>
                <div>
                  Erkenne die Kraft des Phönix in Deiner inneren Welt und
                  erfahre welche Botschaft der Archetyp des Magiers für Dich
                  hat. Möge ihre Weisheit Dich in Zeiten der Veränderung stärken
                </div>
              </div>

              <div className="quiz-result-creature-signup-form">
                <SignupForm
                  type="quiz1"
                  onUpdate={(status) => {
                    this.setState({ signupStatus: status });
                  }}
                />
              </div>
            </Box>
          </div>
        );
      }
    }

    let v__nextQuiz;
    if (quiz.id === quiz1Id) {
      if (_c.isUserLoggedIn() === true) {
        v__nextQuiz = (
          <div className="quiz-result-creature-nextquiz">
            <Grid>
              <Row margin="x">
                <Cell sm={24} md={10} mdo={4}>
                  <div className="wrapper">
                    <div className="center-vertically">
                      <a
                        href="/quiz/magisches-objekt"
                        className="large primary hollow button"
                        style={{ marginBottom: "0" }}
                      >
                        Zum zweiten Quiz
                        <br />
                        <strong>
                          Finde Dein magisches Objekt{" "}
                          <Icon icon="angle-right" right />
                        </strong>
                      </a>
                    </div>
                  </div>
                </Cell>
                <Cell sm={24} md={6}>
                  <img src={ImageObjects} alt="" />
                </Cell>
              </Row>
            </Grid>
          </div>
        );
      } else {
        v__nextQuiz = (
          <div className="quiz-result-creature-nextquiz">
            <Grid>
              <Row margin="x">
                <Cell sm={24} md={16}>
                  <div className="wrapper">
                    <div className="center-vertically">
                      <p>
                        Wenn Du Dich über das oben stehende Formular
                        registrierst, werden Deine Quiz-Ergebnisse automatisch
                        gespeichert. Du kannst aber natürlich auch ohne
                        Speichern direkt zum zweiten Quiz weitergehen, um Dein
                        magisches Objekt zu finden.
                      </p>
                      <a
                        href="/quiz/magisches-objekt"
                        className="large primary hollow button"
                        style={{ marginBottom: "0" }}
                      >
                        Oder direkt zum zweiten Quiz
                        <br />
                        <strong>
                          Finde Dein magisches Objekt{" "}
                          <Icon icon="angle-right" right />
                        </strong>
                      </a>
                    </div>
                  </div>
                </Cell>
                <Cell sm={24} md={8}>
                  <img src={ImageObjects} alt="" />
                </Cell>
              </Row>
            </Grid>
          </div>
        );
      }
    }

    if (status === "code") {
      v__signup = null;
      v__nextQuiz = null;
    }
    return (
      <div className="quiz-result-result">
        <Grid>
          <Row>
            <Cell sm={24} md={14} mdo={5}>
              {v__image}
              {v__title}
              {v__message}
              {v__friends}
            </Cell>
          </Row>
        </Grid>
        <section className="quiz-signup">
          <Grid>
            <Row>
              <Cell sm={24} md={14} mdo={5}>
                {v__signup}
              </Cell>
            </Row>
          </Grid>
        </section>
        <Grid>
          <Row>
            <Cell sm={24} md={20} mdo={2}>
              {v__nextQuiz}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showResultQuiz2() {
    const { result, quiz2Id, quiz, status } = this.state;
    const resultInfo = result.result_info;
    const { object } = resultInfo;

    const _c = this.props.context;

    let v__image;
    let v__title;
    let v__message;

    if (object.image) {
      v__image = (
        <div className="quiz-result-object-image">
          <img src={object.image} alt={object.name} />
        </div>
      );
    }

    v__title = (
      <div className="quiz-result-object-title">
        <h2>Herzlichen Glückwunsch!</h2>
        {/*<p>
          Du hast Deine Aufgabe mit Bravour gemeistert und Dich als würdig
          erwiesen, eines der magischen Objekte aus der Sammlung von William
          Morgan zu erhalten. Deine Ziele und Wünsche haben Dich zu Deinem
          Artefakt geleitet.
        </p>*/}
        <p>
          Du hast Dein magisches Objekt aus der Sammlung von William Morgan
          erhalten.
        </p>
      </div>
    );

    if (object.message_title || object.message_text) {
      let v__messageTitle;
      let v__messageText;

      if (object.message_title) {
        v__messageTitle = <h2>{object.message_title}</h2>;
      }
      if (object.message_text) {
        v__messageText = (
          <div dangerouslySetInnerHTML={{ __html: object.message_text }} />
        );
      }

      v__message = (
        <Box>
          {v__messageTitle}
          {v__messageText}
        </Box>
      );
    }

    let v__signup;
    if (this.state.signupStatus === "finished") {
      v__signup = (
        <Box>
          <div className="quiz-result-creature-signup-form">
            <SignupForm type="quiz2" view="finished" />
          </div>
        </Box>
      );
    } else if (_c.isUserLoggedIn() !== true) {
      if (quiz.id === quiz2Id) {
        v__signup = (
          <div className="quiz-result-object-signup">
            <h2 className="text-center">
              Hole Dir jetzt kostenlos das E-Book
              <br />
              &laquo;Welt von Beyond&raquo;
            </h2>
            <p className="text-center">
              Dort findest Du die Beschreibung einer jeden der mystischen
              Kreaturen und wie Du ihre Kraft in Deinem Leben erkennen kannst.
              Erfahre alles zu Deinem magischen Objekt und bei welchen Prüfungen
              in Deinem Leben es Dir hilft.
            </p>
            <Box>
              <div className="text-center">
                <div>
                  <small>
                    <strong>
                      Dein kostenfreier Start in die Welt von Beyond
                    </strong>
                  </small>
                </div>
                <h3 style={{ fontSize: "1.2em" }}>
                  „Der Phönix und die Rückkehr des Magiers“
                </h3>
                <div>
                  Erkenne die Kraft des Phönix in Deiner inneren Welt und
                  erfahre welche Botschaft der Archetyp des Magiers für Dich
                  hat. Möge ihre Weisheit Dich in Zeiten der Veränderung stärken
                </div>
              </div>

              <div className="text-center">
                Mit der kostenlosen Registrierung werden Deine Quizergebnisse
                automatisch gespeichert und Du kannst sie später jederzeit
                wieder aufrufen.
              </div>

              <div className="quiz-result-object-signup-form">
                <SignupForm
                  type="quiz2"
                  onUpdate={(status) => {
                    this.setState({ signupStatus: status });
                  }}
                />
              </div>
            </Box>
          </div>
        );
      }
    }

    if (status === "code") {
      v__signup = null;
    }

    let v__nextQuiz;
    if (_c.isUserLoggedIn() === true) {
      v__nextQuiz = (
        <div className="quiz-result-creature-nextquiz">
          <Grid>
            <Row margin="x">
              <Cell sm={24} md={10} mdo={4}>
                <div className="wrapper">
                  <div className="center-vertically">
                    <a
                      href="/videoserie"
                      className="large primary hollow button"
                      style={{ marginBottom: "0" }}
                    >
                      Zur kostenfreien Videoserie
                      <br />
                      <strong>
                        Der Phönix und die Rückkehr des Magiers{" "}
                        <Icon icon="angle-right" right />
                      </strong>
                    </a>
                  </div>
                </div>
              </Cell>
              <Cell sm={24} md={6}>
                <img src={AnneGoldKreis} alt="" />
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <div className="quiz-result-result">
        <Grid>
          <Row>
            <Cell sm={24} md={14} mdo={5}>
              {v__image}
              {v__title}
              {v__message}
            </Cell>
          </Row>
        </Grid>
        <section className="quiz-signup">
          <Grid>
            <Row>
              <Cell sm={24} md={14} mdo={5}>
                {v__signup}
              </Cell>
            </Row>
          </Grid>
          <Grid>
            <Row>
              <Cell sm={24} md={20} mdo={2}>
                {v__nextQuiz}
              </Cell>
            </Row>
          </Grid>
        </section>
      </div>
    );
  }

  stopLoading = () => {
    this.setState({ status: "loaded" });
  };

  init() {
    const { slug, baseUrl } = this.state;
    const _c = this.props.context;

    let queryParams = "";
    let url = _c.apiEndpoints.publicQuiz;

    if (_c.isUserLoggedIn() === true) {
      url = _c.apiEndpoints.quiz;
    } else {
      let tmpUserId = _c.getTmpUserId();
      if (tmpUserId) {
        queryParams = "?tmp_user=" + tmpUserId;
      }
    }

    axios
      .get(url + "/" + slug + "/result" + queryParams, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { quiz, result, awardStatus } = response.data;

          if (_c.isDebug()) {
            console.log(response.data);
          }

          if (result === "NOTFINISHED") {
            this.props.history.push(`${baseUrl}/${slug}`);
          }

          let pageTitle = `${quiz.title} - Dein Ergebnis`;
          _c.setPageTitle(pageTitle);

          this.setState({ quiz, result, awardStatus, status: "loaded" });

          _c.initFinished();

          //window.setTimeout(this.stopLoading, 1);
        } catch {
          alert(
            "Quiz Result - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(QuizResult);
