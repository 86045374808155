import React, { Component } from "react";
import { Grid, Row, Cell } from "../../../../foundation/foundation";

class Layout2 extends Component {
  state = {};

  render() {
    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={24}>
            {this.props.children}
          </Cell>
        </Row>
      </Grid>
    );
  }
}

export default Layout2;
