import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../foundation/_buttons";
import ReactTooltip from "react-tooltip";
import AdminLog from "../AdminLog";

import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AdminTags from "../AdminTags";
import AdminCourseLessons from "./AdminCourseLessons";
import AdminDigistoreIds from "./AdminDigistoreIds";
import PTDialog from "../../ptdialog/PTDialog";
import PTTabs from "../../pttabs/PTTabs";
import PTPopup from "../../ptPopup/PTPopup";
import AdminCourseDownloads from "./AdminCourseDownloads";

class AdminCourse extends Component {
  state = {
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_courses"],
    tabs: {},
    selectedTab: "course",
    course: undefined,
    original: undefined,
    newLesson: null,
    newCategory: null,
    newDownload: null,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
    uploadImageStatus: null,
    cropUrl: "",
    cropImage: "",
    cropBanner: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 8 / 3,
    },
    cropBannerMobile: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 16 / 11,
    },
    selectedBanner: null,
  };

  componentDidMount() {
    this.init();

    this.setState({
      tabs: {
        course: {
          name: "Kurs",
          icon: "graduation-cap",
        },
        lessons: {
          name: "Lektionen",
          icon: "youtube-play",
        },
        downloads: {
          name: "Downloads",
          icon: "download",
        },
        /*logs: {
          name: "Log",
          icon: "clock-o",
        },*/
      },
    });

    this.props.context.setPageTitle("Course");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ requestedId: this.props.match.params.id });
    }
  }

  render() {
    const { breadcrumbs } = this.state;

    return (
      <AdminLayout nav="courses" breadcrumbs={breadcrumbs}>
        <AdminBackButton url="/admin/courses" />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
        {this.view__showPopups()}
      </AdminLayout>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, course } = this.state;

    if (!course) {
      return null;
    }

    return (
      <React.Fragment>
        <PTDialog
          show={showDialogDelete}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<p>Möchtest Du diesen Kurs wirklich unwiderruflich löschen?</p><p>Diese Aktion kann nicht rückgängig gemacht werden.</p>",
              }}
            />
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { course, original, requestedId } = this.state;
    const _c = this.props.context;

    if (!course) {
      return _c.loading();
    }

    if (course && requestedId === "new") {
      return <h1>Kurs hinzufügen</h1>;
    } else if (course && course.id && course.name) {
      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">Kurs</div>
          <h1>{original.name}</h1>
        </React.Fragment>
      );
    }
  }

  view__showPageActions() {
    const { editMode } = this.state;

    if (editMode === true) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <button className="button" onClick={this.handle__toggleEditMode}>
            <Icon icon="cog" left /> Kurs bearbeiten
          </button>
          <button
            className="button"
            onClick={() => {
              this.setState({ showDialogDelete: true });
            }}
          >
            <Icon icon="trash" left /> Kurs löschen
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            data-tip="Abbrechen und zurück zur Übersicht"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="small primary button"
            data-tip="Änderungen speichern"
            onClick={this.handle__save}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;

    if (editLock === true) {
      const message = `Dieser Eintrag kann von Dir nicht bearbeitet werden, weil er gerade von ${editLockUser} bearbeitet wird.`;
      const _c = this.props.context;
      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <PTTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showContent() {
    const { course, selectedTab } = this.state;
    const _c = this.props.context;

    if (isUndefined(course)) {
      return _c.loading();
    } else if (course === null) {
      return _c.notfound("Kurs nicht gefunden");
    }

    if (selectedTab === "course") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxCourseInfo()}
              </Cell>
              <Cell sm={24} md={12}>
                {this.view__showBoxCourseBanner()}
                {this.view__showBoxCourseBannerMobile()}
                {this.view__showBoxDigistoreIds()}
                {this.view__showBoxTags()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "lessons") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                {this.view__showBoxLessons()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "downloads") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxDownloads()}
              </Cell>
              <Cell sm={24} md={12}>
                &nbsp;
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showBoxCourseInfo() {
    const { course, editMode } = this.state;

    return (
      <React.Fragment>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>Kurs Informationen</h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDataField
                  value={course.name}
                  editValue={course.name}
                  editType="text"
                  label="Name des Kurses"
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("name", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDataField
                  value={course.description}
                  editValue={course.description}
                  editType="textarea"
                  label="Kurzbeschreibung des Kurses"
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("description", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>Veröffentlichung &amp; Sichtbarkeit</h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24} className="text-center">
                <AdminDataField
                  value={course.published}
                  editValue={course.published}
                  editType="singleCheckbox"
                  label="Kurs ist veröffentlicht"
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("published", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24} className="text-center">
                <AdminDataField
                  value={course.visible}
                  editValue={course.visible}
                  editType="singleCheckbox"
                  label="Kurs ist sichtbar für Nichtkäufer"
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("visible", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>URL zur Kaufseite</h3>
                <p>
                  Gib hier an, auf welche Seite Mitglieder weitergeleitet werden
                  sollen, wenn sie diesen Kurs aufrufen, ohne ihn gekauft zu
                  haben.
                </p>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <AdminDataField
                  value={course.purchase_url}
                  editValue={course.purchase_url}
                  editType="text"
                  label="URL zur Kaufseite"
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("purchase_url", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

  view__showBoxCourseBanner() {
    const { course, editMode } = this.state;

    let courseImage = (
      <div className="admin-course-noimage wrapper">
        <div className="center-vertically">
          Kein Banner (Desktop) hochgeladen
        </div>
      </div>
    );
    if (course.banner) {
      courseImage = <img src={course.banner} alt="Preview" />;
    }

    let uploadButton = (
      <button
        className="button"
        onClick={() => {
          this.setState({ selectedBanner: "desktop" });
          this.showPopupImageUpload();
        }}
      >
        <Icon icon="upload" left /> Neues Bild hochladen
      </button>
    );

    let deleteButton = (
      <button
        className="button"
        onClick={() => {
          this.setState({ selectedBanner: "desktop" });
          this.showPopupImageDelete();
        }}
      >
        <Icon icon="trash" left /> Bild löschen
      </button>
    );

    if (!editMode) {
      uploadButton = null;
    }

    if (!editMode || !course.banner) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Banner (Desktop)</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-course-image">{courseImage}</div>
              <div className="admin-course-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxCourseBannerMobile() {
    const { course, editMode } = this.state;

    let courseImage = (
      <div className="admin-course-noimage wrapper">
        <div className="center-vertically">Kein Banner (mobil) hochgeladen</div>
      </div>
    );
    if (course.banner_mobile) {
      courseImage = <img src={course.banner_mobile} alt="Preview" />;
    }

    let uploadButton = (
      <button
        className="button"
        onClick={() => {
          this.setState({ selectedBanner: "mobile" });
          this.showPopupImageUpload();
        }}
      >
        <Icon icon="upload" left /> Neues Bild hochladen
      </button>
    );

    let deleteButton = (
      <button
        className="button"
        onClick={() => {
          this.setState({ selectedBanner: "mobile" });
          this.showPopupImageDelete();
        }}
      >
        <Icon icon="trash" left /> Bild löschen
      </button>
    );

    if (!editMode) {
      uploadButton = null;
    }

    if (!editMode || !course.banner_mobile) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Banner (mobil)</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-course-image">{courseImage}</div>
              <div className="admin-course-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showPopups() {
    const {
      uploadImageStatus,
      cropUrl,
      cropBanner,
      cropBannerMobile,
      selectedBanner,
    } = this.state;

    let crop = null;
    if (selectedBanner === "desktop") {
      crop = cropBanner;
    } else if (selectedBanner === "mobile") {
      crop = cropBannerMobile;
    }

    if (uploadImageStatus === "crop") {
      console.log("crop", crop);
    }

    return (
      <React.Fragment>
        <PTPopup
          show={uploadImageStatus === "upload"}
          size="medium"
          nopadding
          handleClose={this.hidePopupImageUpload}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h3>Bild hochladen</h3>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            "Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab oder<br />klicke hier, um ein Bild hochzuladen.",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </PTPopup>

        <PTPopup
          show={uploadImageStatus === "crop"}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h3>Bildausschnitt wählen</h3>
          </div>

          <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> Abbrechen
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left /> Bildausschnitt wählen
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
        <PTDialog
          show={uploadImageStatus === "delete"}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<p>Möchtest Du das Bild wirklich unwiderruflich löschen?<br />Diese Aktion kann <u>nicht</u> rückgängig gemacht werden. Du kannst aber jederzeit ein neues Bild hochladen.</p>",
              }}
            />
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ uploadImageStatus: null });
          }}
          onButton_2={this.handle__deleteImage}
        />
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    const { cropBanner, cropBannerMobile, selectedBanner } = this.state;

    let crop = null;
    if (selectedBanner === "desktop") {
      crop = cropBanner;
    } else if (selectedBanner === "mobile") {
      crop = cropBannerMobile;
    }

    if (!crop || (crop.height === 0) | (crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    const { selectedBanner } = this.state;
    if (selectedBanner === "desktop") {
      this.setState({ cropBanner: percentCrop });
    } else if (selectedBanner === "mobile") {
      this.setState({ cropBannerMobile: percentCrop });
    }
  };

  handle__deleteImage = () => {
    const { course, selectedBanner } = this.state;

    if (selectedBanner === "desktop") {
      course.banner = null;
    } else if (selectedBanner === "mobile") {
      course.banner_mobile = null;
    }
    this.setState({ course, uploadImageStatus: null });

    /*const _c = this.props.context;

    axios
      .patch(_c.apiEndpoints.deleteAvatar, {}, { headers: _c.headers })
      .then(response => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });*/
  };

  showPopupImageUpload = () => {
    this.setState({ uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ uploadImageStatus: "delete" });
  };

  hidePopupImageDelete = () => {
    this.setState({ uploadImageStatus: null, selectedBanner: null });
  };

  uploadImage = () => {
    const {
      cropImage,
      cropBanner,
      cropBannerMobile,
      selectedBanner,
      course,
      requestedId,
    } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    let crop = null;
    if (selectedBanner === "desktop") {
      crop = cropBanner;
    } else if (selectedBanner === "mobile") {
      crop = cropBannerMobile;
    }

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    const apiUrl = _c.apiUrl("adminCourseImageUpload", { id: requestedId });

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          if (selectedBanner === "desktop") {
            course.banner = path;
          } else if (selectedBanner === "mobile") {
            course.banner_mobile = path;
          }
          this.setState({ course, changesWereMade: true });
          this.hidePopupImageCrop();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  view__showBoxDigistoreIds() {
    const { course, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Digistore Produkt IDs</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDigistoreIds
                tags={course.digistore_product_ids || []}
                editMode={editMode}
                onUpdate={(tags) => {
                  course.digistore_product_ids = tags;
                  this.setState({ course });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxTags() {
    const { course, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Tags / Stichworte</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminTags
                tags={course.tags}
                editMode={editMode}
                onUpdate={(tags) => {
                  course.tags = tags;
                  this.setState({ course });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxLessons() {
    const { course, newLesson, newCategory } = this.state;

    if (!course || !course.categories) {
      return;
    }

    return (
      <AdminCourseLessons
        course={course}
        newLesson={newLesson}
        newCategory={newCategory}
      />
    );
  }

  view__showBoxDownloads() {
    const { course, newDownload } = this.state;

    if (!course || !course.downloads) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminCourseDownloads
          course={course}
          downloads={course.downloads}
          newDownload={newDownload}
        />
      </React.Fragment>
    );
  }

  view__showLogs() {
    const { course } = this.state;

    if (!course || !course.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={course.logs} />
      </React.Fragment>
    );
  }

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { course } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("course", course.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { course } = this.state;
    course[property] = value;
    this.setState({ course, changesWereMade: true });
  }

  handle__delete = () => {
    const { course } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("adminCourse", { id: course.id });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (response.data === "OK") {
            _c.createNotifictation(
              "Kurs gelöscht",
              `Der Kurs ${course.name} wurde erfolgreich gelöscht.`,
              "success"
            );
            this.props.history.push("/admin/courses");
          }
        } catch {
          alert(
            "Admin Delete Kurs - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  handle__cancel = () => {
    const { requestedId, changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Es wurden bereits Änderungen an diesem Eintrag vorgenommen.\r\n\r\nMöchtest Du wirklich abbrechen?\r\nAlle Änderungen gehen dann verloren."
        )
      ) {
        if (requestedId === "new") {
          window.location = "/admin/courses";
        } else {
          let { livecall, original } = this.state;
          livecall = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, livecall });
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = "/admin/courses";
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        const _c = this.props.context;
        _c.cancelEditLock();
      }
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, requestedId } = this.state;

    if (!course.name) {
      window.alert("Bitte gib mindestens den Namen des Kurses an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminCourse", { id: requestedId });

      axios
        .post(
          apiUrl,
          {
            course,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              if (requestedId === "new" && course && course.id) {
                window.location.href = "/admin/courses/:id".replace(
                  ":id",
                  course.id
                );
                return;
              }

              const original = JSON.parse(JSON.stringify(course));
              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  editMode: false,
                  course,
                  original,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    "Kurs gespeichert",
                    "Der Kurs wurde erfolgreich gespeichert.",
                    "success"
                  );
                }
              );
            }
          } catch {}
        })
        .catch((error) => {});
    }, 200);
  };

  init() {
    const { requestedId } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("adminCourse", { id: requestedId });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, user } = response.data;
          _c.updateUser(user);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const {
              course,
              newLesson,
              newCategory,
              newDownload,
            } = apiResponseData;

            newLesson.id = "new";
            newCategory.id = "new";
            newDownload.id = "new";

            const original = JSON.parse(JSON.stringify(course));

            course.start = new Date(course.start);

            let pageTitle;
            if (requestedId === "new") {
              pageTitle = "Neuer Kurs";
            } else {
              pageTitle = `${course.name}`;
            }

            _c.setPageTitle(pageTitle);

            const bc1 = {
              id: "admin_course",
              to: `/admin/courses/${course.id}`,
              name: pageTitle,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);

            this.setState({
              course,
              original,
              newLesson,
              newCategory,
              newDownload,
              breadcrumbs,
            });

            if (requestedId === "new") {
              this.setState({ editMode: true });
            }

            this.setState(
              {
                course,
                original,
                status: "LOADED",
              },
              () => {
                _c.initFinished();
              }
            );
          }
        } catch {}
      })
      .catch((error) => {});
  }
}

export default withContext(AdminCourse);
