import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../foundation/_buttons";
import ReactTooltip from "react-tooltip";
import PTTabs from "../../pttabs/PTTabs";
import AdminLog from "../AdminLog";
import PTDialog from "../../ptdialog/PTDialog";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { cloneDeep } from "lodash";

class AdminLetter extends Component {
  state = {
    baseUrl: "/admin/letters",
    loadingError: false,
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_letters"],
    tabs: {
      info: {
        name: "Informationen",
        icon: "envelope",
      },
      logs: {
        name: "Log",
        icon: "clock-o",
      },
    },
    selectedTab: "info",
    letter: undefined,
    original: undefined,
    courses: [],
    editorState: null,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
    singleSearch: "",
    singleSearching: false,
    singleResults: null,
  };

  componentDidMount() {
    this.props.context.setPageTitle("Brief");
    this.init(() => {
      const { letter } = this.state;
      let editorState = EditorState.createEmpty();

      if (letter.text) {
        editorState = EditorState.createWithContent(
          convertFromRaw(letter.text)
        );
      }

      this.setState({ editorState });
    });
  }

  render() {
    const { breadcrumbs, baseUrl } = this.state;

    return (
      <AdminLayout nav="letters" breadcrumbs={breadcrumbs}>
        <AdminBackButton url={baseUrl} />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
      </AdminLayout>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, letter } = this.state;

    if (!letter) {
      return null;
    }

    return (
      <React.Fragment>
        <PTDialog
          show={showDialogDelete}
          message={
            <p>
              Möchtest Du den Brief <em>{`${letter.subject} `}</em> wirklich
              unwiderruflich löschen?
              <br />
              Diese Aktion kann <u>nicht</u> rückgängig gemacht werden.
            </p>
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { letter, requestedId } = this.state;
    const _c = this.props.context;

    if (isUndefined(letter)) {
      return <h1>Wird geladen...</h1>;
    } else if (letter === null) {
      return <h1>Nicht gefunden</h1>;
    } else if (letter && requestedId === "new") {
      return <h1>Neuen Brief schreiben</h1>;
    } else if (letter && letter.id) {
      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">Brief</div>
          <h1>{letter.subject}</h1>
        </React.Fragment>
      );
    } else {
      return <h1>Unbekannter Fehler</h1>;
    }
  }

  view__showPageActions() {
    const { editMode } = this.state;

    if (editMode === true) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <button className="button" onClick={this.handle__toggleEditMode}>
            <Icon icon="cog" left /> Brief bearbeiten
          </button>
          <button
            className="button"
            onClick={() => {
              this.setState({ showDialogDelete: true });
            }}
          >
            <Icon icon="trash" left /> Brief löschen
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <PTTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            data-tip="Abbrechen und zurück zur Übersicht"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="small primary button"
            data-tip="Änderungen speichern"
            onClick={this.handle__save}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;

    if (editLock === true) {
      const message = `Dieser Eintrag kann von Dir nicht bearbeitet werden, weil er gerade von ${editLockUser} bearbeitet wird.`;
      const _c = this.props.context;
      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showContent() {
    const { letter, loadingError, selectedTab } = this.state;
    const _c = this.props.context;

    if (isUndefined(letter)) {
      return _c.loading(loadingError);
    } else if (letter === null) {
      return _c.notfound("Brief nicht gefunden");
    }

    if (selectedTab === "info") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={14}>
                {this.view__showBoxSubjectInfo()}
                {this.view__showBoxTextInfo()}
              </Cell>
              <Cell sm={24} md={10}>
                {this.view__showBoxPublishInfo()}

                {this.view__showBoxCoursesInfo()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showLogs() {
    const { letter } = this.state;

    if (!letter || !letter.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={letter.logs} />
      </React.Fragment>
    );
  }

  view__showBoxSubjectInfo() {
    const { letter, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Betreff</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={letter.subject}
                editValue={letter.subject}
                editType="text"
                label="Betreff"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("subject", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxTextInfo() {
    const { letter, editMode, editorState } = this.state;

    let v__text = (
      <div style={{ fontSize: ".9em" }}>
        <div dangerouslySetInnerHTML={{ __html: letter.text_html }} />
      </div>
    );
    if (editMode === true) {
      v__text = (
        <div className="admin-letter-editor">
          <Editor
            editorState={editorState}
            wrapperClassName="editor-wrapper"
            editorClassName="editor-editor"
            onEditorStateChange={this.onEditorStateChange}
            localization={{
              locale: "de",
            }}
            toolbar={{
              options: [
                "inline",
                "blockType",
                "fontSize",
                "textAlign",
                "colorPicker",
                "list",
                "image",
                "link",
                "emoji",
                "history",
              ],
              inline: {
                inDropdown: false,
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              blockType: {
                inDropdown: false,
                options: [
                  "Normal",
                  "H1",
                  "H2",
                  "H3",
                  "H4",
                  "H5",
                  "H6",
                  "Blockquote",
                ],
              },
            }}
          />
        </div>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Text</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>{v__text}</Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxPublishInfo() {
    const { letter, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Automatische Veröffentlichung</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={letter.publish_date}
                editValue={letter.publish || new Date()}
                editType="datetime"
                label="Veröffentlichen an/um"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("publish", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxCoursesInfo() {
    const {
      letter,
      editMode,
      courses,
      singleResults,
      singleSearching,
    } = this.state;
    let { singleSearch } = this.state;
    const _c = this.props.context;

    if (!courses) {
      return null;
    }
    let v__type, v__sendTo;

    if (editMode === true) {
      v__type = (
        <div>
          <label>
            <input
              type="radio"
              name="letterType"
              value="all"
              checked={letter.type === "all"}
              onChange={(event) => {
                letter.type = "all";
                this.setState({ letter });
              }}
            />{" "}
            Brief an alle Mitglieder versenden
          </label>
          <label>
            <input
              type="radio"
              name="letterType"
              value="course"
              checked={letter.type === "course"}
              onChange={(event) => {
                letter.type = "course";
                this.setState({ letter });
              }}
            />{" "}
            Brief an Teilnehmer bestimmter Kurse senden
          </label>
          <label>
            <input
              type="radio"
              name="letterType"
              value="selected"
              checked={letter.type === "selected"}
              onChange={(event) => {
                letter.type = "selected";
                this.setState({ letter });
              }}
            />{" "}
            Brief an einzelnen Teilnehmer senden
          </label>
        </div>
      );

      if (letter.type === "course") {
        v__sendTo = (
          <div className="admin-letter-courses">
            <h3>Kurse auswählen</h3>
            <Grid type="full">
              <Row>
                <Cell sm={21} smo={3}>
                  <ul>
                    {courses.map((course, index) => {
                      let courseChecked = false;

                      if (letter.courses) {
                        letter.courses.map((courseId) => {
                          if (parseInt(courseId) === parseInt(course.id)) {
                            courseChecked = true;
                          }
                          return null;
                        });
                      }

                      return (
                        <li key={index}>
                          <label>
                            <input
                              type="checkbox"
                              checked={courseChecked}
                              onChange={() => {
                                this.handle__selectCourse(course.id);
                              }}
                            />{" "}
                            {course.name}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </Cell>
              </Row>
            </Grid>
          </div>
        );
      } else if (letter.type === "selected") {
        let v__singleSearchResults;

        if (singleSearching === true) {
          v__singleSearchResults = (
            <div className="single-search-searching">
              <Icon icon="spin fa-circle-o-notch" />
            </div>
          );
        } else if (singleResults) {
          v__singleSearchResults = (
            <div className="single-search-results">
              <div>
                <strong>Ergebnisse</strong>
              </div>
              <div>
                <ul>
                  {singleResults.map((result, index) => {
                    let buttonClasses;
                    if (letter.members && letter.members[result.id]) {
                      buttonClasses = "selected";
                    }

                    return (
                      <li key={index}>
                        <button
                          className={buttonClasses}
                          onClick={() => {
                            this.handle__selectMember(result);
                          }}
                        >
                          <div className="check-icon float-left">
                            <Icon icon="check" />
                          </div>
                          <div>
                            <div className="single-result-name">
                              {result.name}
                            </div>
                            <div className="single-result-email">
                              {result.email}
                            </div>
                          </div>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        }

        v__sendTo = (
          <div className="admin-letter-members">
            <h3>Mitglieder auswählen</h3>
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <input
                    type="text"
                    value={singleSearch}
                    onChange={(e) => {
                      singleSearch = e.target.value;
                      this.setState({ singleSearch, singleSearching: true });

                      axios
                        .get(
                          _c.apiEndpoints.adminLetterSingleSearch +
                            "?search=" +
                            singleSearch,
                          {
                            headers: _c.getHeaders(),
                          }
                        )
                        .then((response) => {
                          try {
                            const { results } = response.data;
                            const singleResults = this.handle__results(results);

                            this.setState({
                              singleResults,
                              singleSearching: false,
                            });
                          } catch {
                            //
                          }
                        })
                        .catch((error) => {
                          if (_c.isDebug()) {
                            console.log("Error", error);
                          }
                        });
                    }}
                    placeholder="Suche nach Namen oder E-Mail"
                  />
                </Cell>
              </Row>
              <Row>
                <Cell sm={24}>{v__singleSearchResults}</Cell>
              </Row>
            </Grid>
          </div>
        );
      } else {
        //
      }
    } else {
      if (letter.type === "course") {
        v__type = (
          <div>
            <Icon icon="check" left /> Teilnehmer bestimmter Kurse:
          </div>
        );

        v__sendTo = (
          <div className="admin-letter-courses">
            <Grid type="full">
              <Row>
                <Cell sm={21} smo={3}>
                  <ul>
                    {courses.map((course, index) => {
                      if (
                        letter.courses &&
                        letter.courses.includes(course.id)
                      ) {
                        return (
                          <li key={index} style={{ padding: "5px 0" }}>
                            <label>
                              <Icon icon="check" left /> {course.name}
                            </label>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </Cell>
              </Row>
            </Grid>
          </div>
        );
      } else if (letter.type === "selected") {
        v__type = (
          <div>
            <Icon icon="check" left /> Ausgewählter Teilnehmer:
          </div>
        );

        let v__selectedMembers;
        if (letter.members) {
          v__selectedMembers = (
            <ul>
              {Object.keys(letter.members).map((memberId, index) => {
                return (
                  <li key={index} style={{ padding: "5px 0" }}>
                    <label>
                      <Icon icon="check" left /> {letter.members[memberId].name}{" "}
                      [{letter.members[memberId].email}]
                    </label>
                  </li>
                );
              })}
            </ul>
          );
        }

        v__sendTo = (
          <div className="admin-letter-members">
            <Grid type="full">
              <Row>
                <Cell sm={21} smo={3}>
                  {v__selectedMembers}
                </Cell>
              </Row>
            </Grid>
          </div>
        );
      } else {
        v__type = (
          <React.Fragment>
            {v__type}
            <div>
              <Icon icon="check" left /> alle Mitglieder
            </div>
          </React.Fragment>
        );
      }
    }

    let v__boxSendTo;
    if (v__sendTo) {
      v__boxSendTo = <Box>{v__sendTo}</Box>;
    }

    return (
      <React.Fragment>
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>Versenden an</h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>{v__type}</Cell>
            </Row>
          </Grid>
        </Box>
        {v__boxSendTo}
      </React.Fragment>
    );
  }

  handle__selectCourse(courseId) {
    const { letter } = this.state;

    if (!letter.courses) {
      letter.courses = [courseId];
    } else {
      let courseFound = false;
      letter.courses.map((letterCourseId) => {
        if (parseInt(letterCourseId) === parseInt(courseId)) {
          courseFound = true;
        }
        return null;
      });

      if (courseFound === true) {
        let newCourses = [];
        letter.courses.map((letterCourseId) => {
          if (parseInt(letterCourseId) !== parseInt(courseId)) {
            newCourses.push(letterCourseId);
          }
          return null;
        });

        letter.courses = newCourses;
      } else {
        letter.courses.push(courseId);
      }
    }

    this.setState({ letter });
  }

  handle__selectMember(member) {
    const { letter } = this.state;

    let memberId = member.id;

    /*if (!letter.members) {
      letter.members = [memberId];
    } else {
      if (letter.members.includes(memberId)) {
        letter.members = [];
      } else {
        letter.members = [memberId];
      }
    }*/

    if (!letter.members) {
      letter.members = {
        [memberId]: member,
      };
    } else {
      let memberFound = false;
      Object.keys(letter.members).map((letterMemberId) => {
        if (parseInt(letterMemberId) === parseInt(memberId)) {
          memberFound = true;
        }
        return null;
      });

      if (memberFound === true) {
        let newMembers = {};
        Object.keys(letter.members).map((letterMemberId) => {
          if (parseInt(letterMemberId) !== parseInt(memberId)) {
            newMembers[letterMemberId] = letter.members[letterMemberId];
          }
          return null;
        });

        letter.members = newMembers;
      } else {
        letter.members[memberId] = member;
      }
    }

    /* NUR ID
if (!letter.members) {
      letter.members = [memberId];
    } else {
      let memberFound = false;
      letter.members.map((lettermemberId) => {
        if (parseInt(lettermemberId) === parseInt(memberId)) {
          memberFound = true;
        }
        return null;
      });

      if (memberFound === true) {
        let newMembers = [];
        letter.members.map((letterMemberId) => {
          if (parseInt(letterMemberId) !== parseInt(memberId)) {
            newMembers.push(letterMemberId);
          }
          return null;
        });

        letter.members = newMembers;
      } else {
        letter.members.push(memberId);
      }
    }
    */

    this.setState({ letter });
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      const { editorState, letter } = this.state;

      if (editorState) {
        letter.text = convertToRaw(editorState.getCurrentContent());
        letter.text_html = draftToHtml(letter.text);
        this.setState({ letter });
      }
    });
  };

  handle__results(results) {
    let { letter } = this.state;
    const { members } = letter;

    let foundResultIds = [];
    results.map((result) => {
      foundResultIds.push(parseInt(result.id));
      return null;
    });

    let singleResults = cloneDeep(results);
    if (members) {
      Object.keys(members).map((memberId) => {
        if (!foundResultIds.includes(parseInt(memberId))) {
          singleResults.push(members[memberId]);
        }

        return null;
      });
    }

    if (singleResults.length === 0) {
      singleResults = null;
    }

    return singleResults;
  }

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { letter } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("letter", letter.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { letter } = this.state;
    letter[property] = value;
    this.setState({ letter, changesWereMade: true });
  }

  handle__delete = () => {
    const { letter, baseUrl } = this.state;
    const _c = this.props.context;

    axios
      .delete(_c.apiEndpoints.adminLetters + "/" + letter.id, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (response.data === "OK") {
            _c.createNotifictation(
              "Brief gelöscht",
              `Der Brief ${letter.subject} wurde erfolgreich gelöscht.`,
              "success"
            );
            this.props.history.push(`${baseUrl}`);
          }
        } catch {
          alert(
            "Admin Delete Letter - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  handle__cancel = () => {
    const { requestedId, baseUrl, changesWereMade } = this.state;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Es wurden bereits Änderungen an diesem Eintrag vorgenommen.\r\n\r\nMöchtest Du wirklich abbrechen?\r\nAlle Änderungen gehen dann verloren."
        )
      ) {
        if (requestedId === "new") {
          window.location = baseUrl;
        } else {
          let { letter, original } = this.state;
          letter = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, letter });
          const _c = this.props.context;
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = baseUrl;
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        const _c = this.props.context;
        _c.cancelEditLock();
      }
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { letter, requestedId, baseUrl, showCourses } = this.state;

    if (!letter.subject) {
      window.alert("Bitte gib mindestens einen Betreff an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let url = _c.apiEndpoints.adminLetters + "/" + requestedId;

      // Prepare publish date
      if (letter.publish) {
        letter.publish = _c.formatDatetimeForSaving(letter.publish);
      }

      if (
        showCourses === false ||
        (showCourses === true && letter.courses.length === 0)
      ) {
        letter.courses = null;
        this.setState({ showCourses: false });
      }

      axios
        .post(
          url,
          {
            letter: letter,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { letter } = response.data;

          if (requestedId === "new" && letter && letter.id) {
            window.location = baseUrl + "/" + letter.id;
            return;
          }

          this.initLetter(letter);

          const original = JSON.parse(JSON.stringify(letter));
          _c.setIsSaving(false);
          this.setState(
            {
              changesWereMade: false,
              editMode: false,
              letter,
              original,
            },
            () => {
              _c.cancelEditLock();
              _c.createNotifictation(
                "Eintrag gespeichert",
                "Die Änderungen am Brief wurden erfolgreich gespeichert.",
                "success"
              );
            }
          );
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const { requestedId, baseUrl } = this.state;
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminLetters + "/" + requestedId, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { user, letter, courses } = response.data;
          const original = JSON.parse(JSON.stringify(letter));

          let pageTitle;
          if (requestedId === "new") {
            letter.type = "all";
            pageTitle = "Neuer Brief";
          } else {
            pageTitle = `${letter.subject}`;
          }

          _c.setPageTitle(pageTitle);

          const bc1 = {
            id: "admin_letter",
            to: `${baseUrl}/${letter.id}`,
            name: pageTitle,
          };

          const { breadcrumbs } = this.state;
          breadcrumbs.push(bc1);

          this.initLetter(letter);

          this.setState({ letter, original, breadcrumbs, courses }, () => {
            if (letter.members) {
              const singleResults = this.handle__results([]);
              this.setState({ singleResults });
            }
          });

          if (requestedId === "new") {
            this.setState({ editMode: true });
          }

          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.updateUser(user, () => {
            _c.initFinished();
          });
        } catch {
          alert(
            "Admin Letter - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }

  initLetter = (letter) => {
    if (!letter.type) {
      letter.type = "all";
    }

    /*if (letter.courses) {
      this.setState({ showCourses: true });
    }*/
  };
}

export default withContext(AdminLetter);
