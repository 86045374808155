import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import { Icon } from "../../foundation/_buttons";

class AdminLiveCalls extends Component {
  state = {
    baseUrl: "/admin/livecalls",
    loadingError: false,
    livecalls: undefined
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Live Calls");
  }

  render() {
    return (
      <AdminLayout
        nav="livecalls"
        breadcrumbs={["admin_dashboard", "admin_livecalls"]}
      >
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showList()}
      </AdminLayout>
    );
  }

  view__showHeadline() {
    return <h1>Live Calls</h1>;
  }

  view__showPageActions() {
    const { baseUrl } = this.state;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a
            href={`${baseUrl}/new`}
            className="button"
            data-tip="Neuen Live Call anlegen"
          >
            <Icon icon="plus" left /> Live Call anlegen
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showList() {
    const { livecalls, loadingError } = this.state;
    const _c = this.props.context;

    if (!livecalls) {
      return _c.loading(loadingError);
    }

    if (livecalls && isArray(livecalls) && livecalls.length === 0) {
      return <p className="text-center">Keine Live Calls gefunden.</p>;
    }

    return (
      <React.Fragment>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showListDesktop(livecalls)}
          {this.view__showListMobile(livecalls)}
        </Box>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={3}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={13}>
              <div className="padding">Name</div>
            </Cell>
            <Cell md={8} className="text-center">
              <div className="padding">Typ / Id</div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(livecalls) {
    const { baseUrl } = this.state;

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {livecalls.map((livecall, index) => {
            let livecallImage;
            if (livecall.image) {
              livecallImage = (
                <img
                  src={livecall.image}
                  alt="Vorschau"
                  className="admin-livecall-image"
                />
              );
            }

            return (
              <li className="admin-list-item" key={index}>
                <Link to={`${baseUrl}/${livecall.id}`} className="clickable">
                  <Grid type="full">
                    <Row>
                      <Cell md={3} className="text-center">
                        <div className="padding">{livecallImage}</div>
                      </Cell>
                      <Cell md={13}>
                        <div className="padding">
                          <div>{livecall.start_date}</div>
                          <div>
                            <strong>{livecall.title}</strong>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={8} className="text-center">
                        <div className="padding">
                          <div>{livecall.type}</div>
                          <div>{livecall.external_id}</div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showListMobile() {
    return (
      <div className="show-for-small-only">
        <p>mobile</p>
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminLivecalls, {
        headers: _c.getHeaders()
      })
      .then(response => {
        try {
          const { user, livecalls } = response.data;

          this.setState({ livecalls });

          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.updateUser(user, () => {
            _c.initFinished();
          });
        } catch {
          alert(
            "Admin Live Calls - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch(error => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminLiveCalls);
