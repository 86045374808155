import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import CommunityLayout from "./layouts/CommunityLayout";
import { Grid, Row, Cell } from "../../foundation/_grid";
import { Link } from "react-router-dom";
import { Icon } from "../../foundation/_buttons";
import Avatar from "../../user/Avatar";
import CommunityBackButton from "./CommunityBackButton";

class CommunityBoard extends Component {
  state = {
    boardSlug: this.props.match.params.boardSlug,
    board: null,
    status: "INIT",
    breadcrumbs: ["home", "communityAreas"],
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let layout = _c.setting("community_main_layout");
    if (!layout) {
      layout = "layout1";
    }

    let v__content = (
      <div className={`community-${layout}`}>
        <CommunityLayout
          view="board"
          layout={layout}
          breadcrumbs={breadcrumbs}
          user={_c.getUser()}
          help="communityBoard"
        >
          <Grid type="full">
            <Row>
              <Cell sm={24} md={18} className="text-center medium-text-left">
                {this.view__showTitle()}
              </Cell>
              <Cell sm={24} md={6} className="text-center medium-text-right">
                <div className="community-header-buttons">
                  {this.view__buttonAddButton()}
                </div>
              </Cell>
            </Row>
          </Grid>

          {this.view__showBoard()}
        </CommunityLayout>
      </div>
    );

    return <div className="community-board">{v__content}</div>;
  }

  view__showTitle() {
    const { board, status } = this.state;
    const _c = this.props.context;

    if (status === "LOADED") {
      let v__boardDescription;
      if (board.description) {
        v__boardDescription = (
          <div className="board-description">{board.description}</div>
        );
      }

      let v__backButton = (
        <CommunityBackButton
          url={_c.custom("urls.communityAreas")}
          text={_c.translate("community.board.backToOverview")}
        />
      );

      return (
        <div className="community-header">
          {v__backButton}
          <h1>{board.name}</h1>
          {v__boardDescription}
        </div>
      );
    }
  }

  view__showBoard() {
    const { board, status } = this.state;
    const _c = this.props.context;

    if (!board || !board.threads || status === "INIT" || status === "LOADING") {
      return _c.loading();
    }

    return (
      <React.Fragment>
        {this.view__showThreadCellTitles()}
        <div className="community-board-threads">
          {this.view__showThreads()}
        </div>
      </React.Fragment>
    );
  }

  view__showThreadCellTitles() {
    const _c = this.props.context;

    return (
      <div className="board-cell-titles hide-for-small-only">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={14}>
              &nbsp;
            </Cell>
            <Cell sm={12} md={2}>
              {_c.translate("community.areas.cellTitles.replyCount")}
            </Cell>
            <Cell sm={12} md={8}>
              {_c.translate("community.areas.cellTitles.lastReply")}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showThreads() {
    const { board } = this.state;
    const _c = this.props.context;

    let v__content;
    if (!board.threads || board.threads.length === 0) {
      v__content = (
        <div className="message">
          {_c.translate("community.board.messages.nothreads")}
          <div style={{ marginTop: "10px" }} className="hide-for-small-only">
            {this.view__buttonAddButton(
              _c.translate("community.board.messageNewThread")
            )}
          </div>
        </div>
      );
    } else {
      v__content = (
        <React.Fragment>
          {board.threads.map((thread, index) => {
            let v__iconLocked, v__iconSticky;

            if (thread.locked === true) {
              v__iconLocked = <Icon icon="lock" />;
            }

            if (thread.sticky === true) {
              v__iconSticky = <Icon icon="thumb-tack" />;
            }

            let v__threadName = (
              <div className="thread-name">
                {v__iconSticky}
                {v__iconLocked} {thread.name}
              </div>
            );

            let v__author = (
              <div className="thread-author">
                von {thread.author.displayname} am {thread.date_f} um{" "}
                {thread.time_f} Uhr
              </div>
            );

            let v__avatar = (
              <div className="wrapper text-center">
                <div className="center-vertically- padding">
                  <Avatar user={thread.author} size="forum-thread" />
                </div>
              </div>
            );

            let threadUrl = _c.custom("urls.communityThread");
            threadUrl = threadUrl.replace(":areaSlug", board.area.slug);
            threadUrl = threadUrl.replace(":boardSlug", board.slug);
            threadUrl = threadUrl.replace(":threadSlug", thread.slug);

            let v__link = (
              <Link to={threadUrl} className="clickable">
                <Grid type="full">
                  <Row>
                    <Cell sm={4} md={2}>
                      {v__avatar}
                    </Cell>
                    <Cell sm={20} md={22}>
                      <div className="padding">
                        {v__threadName}
                        {v__author}
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Link>
            );

            //let v__postCount = thread.post_count;
            let v__replyCount = thread.reply_count;

            let v__lastPost = <div>---</div>;
            if (
              thread.last_post &&
              thread.last_post_id !== thread.first_post_id
            ) {
              v__lastPost = (
                <div>
                  <div>
                    Von <span>{thread.last_post.author.displayname}</span>
                  </div>
                  <div>am {thread.last_post.created_at_f}</div>
                </div>
              );
            }

            return (
              <div className={`community-board-thread`} key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={24} md={14}>
                      <div className="wrapper">
                        <div className="center-vertically-">{v__link}</div>
                      </div>
                    </Cell>

                    <Cell sm={12} md={2}>
                      <div className="wrapper">
                        <div className="center-vertically- padding">
                          <div className="thread-post-count">
                            {v__replyCount}
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell sm={12} md={8}>
                      <div className="wrapper">
                        <div className="center-vertically- padding">
                          <div className="thread-last-post">{v__lastPost}</div>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            );
          })}
        </React.Fragment>
      );
    }

    return <div className="community-areas-boards">{v__content}</div>;
  }

  view__buttonAddButton = (text) => {
    const { board } = this.state;
    const _c = this.props.context;

    if (!board || !board.slug) {
      return;
    }

    if (!text) {
      text = _c.translate("community.board.newThread");
    }

    let url = _c.custom("urls.communityNewThread");
    url = `${url
      .replace(":boardSlug", board.slug)
      .replace(":areaSlug", board.area.slug)}`;

    return (
      <Link to={url} className="primary button">
        <Icon icon="plus" left />
        {text}
      </Link>
    );
  };

  init() {
    const { boardSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("communityBoard", {
      boardSlug,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const communityData = response.data.data;
            const { board } = communityData;

            if (!board) {
              _c.permissionDenied();
              return;
            }

            const newBreadcrumb = {
              to: _c
                .custom("urls.communityArea")
                .replace(":areaSlug", board.area.slug),
              id: "communityArea",
              name: board.area.name,
            };

            const newBreadcrumb2 = {
              to: "/",
              id: "communityBoard",
              name: board.name,
            };

            let { breadcrumbs } = this.state;
            breadcrumbs.push(newBreadcrumb);
            breadcrumbs.push(newBreadcrumb2);

            this.setState({
              board,
              status: "LOADED",
              breadcrumbs,
            });

            _c.initFinished();
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:board:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:board:init");
      });
  }
}

export default withContext(CommunityBoard);
