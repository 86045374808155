import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import AdminDataField from "../AdminDataField";
import { cloneDeep } from "lodash";
import { Icon } from "../../foundation/_buttons";
import axios from "axios";
import AdminTags from "../AdminTags";
import PTTabs from "../../pttabs/PTTabs";
import PTPopup from "../../ptPopup/PTPopup";
import Audio from "../../courses/parts/Audio";

class AdminCourseLessons extends Component {
  state = {
    _updatableProps: ["course", "newLesson"],
    course: this.props.course,
    selectedCategory: null,
    selectedEditCategory: null,
    selectedLesson: null,
    changesWereMade: false,
    lessonTabs: {},
    selectedLessonTab: "info",
    newLesson: this.props.newLesson,
    newCategory: this.props.newCategory,
  };

  componentDidMount() {
    this.setState({
      lessonTabs: {
        info: {
          name: "Informationen",
          icon: "youtube-play",
        },
        tags: {
          name: "Stichworte / Tags",
          icon: "tags",
        },
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { course } = this.state;

    if (!course) {
      return;
    }

    return (
      <div className="admin-course-lessons">
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={12}>
              {this.view__showLeftSide()}
            </Cell>
            <Cell sm={24} md={12}>
              {this.view__showRightSide()}
            </Cell>
          </Row>
        </Grid>

        {this.view__showPopups()}
      </div>
    );
  }

  view__showLessonTabs() {
    const { lessonTabs, selectedLessonTab } = this.state;

    return (
      <div className="admin-tabs">
        <PTTabs
          tabs={lessonTabs}
          selected={selectedLessonTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedLessonTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPopups() {
    const { selectedCategory, selectedLesson, selectedEditCategory } =
      this.state;

    if (selectedCategory && selectedLesson) {
      return (
        <PTPopup show={true} size="large" handleClose={this.handle__cancel}>
          <h3>Lektion: {selectedLesson.name}</h3>
          {this.view__showLessonEditButtons()}
          {this.view__showLessonTabs()}
          {this.view__showLessonContent()}
          {this.view__showLessonEditButtons()}
        </PTPopup>
      );
    } else if (selectedEditCategory) {
      let popupTitle = "Kategorie bearbeiten";
      if (selectedEditCategory.id === "new") {
        popupTitle = "Kategorie hinzufügen";
      }

      return (
        <PTPopup show={true} size="small" handleClose={this.handle__cancel}>
          <h3>{popupTitle}</h3>
          {this.view__showCategoryEdit()}
        </PTPopup>
      );
    }
  }

  view__showCategoryEdit() {
    const { selectedEditCategory } = this.state;

    return (
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={24}>
            <input
              type="text"
              value={selectedEditCategory.name}
              onChange={(e) => {
                selectedEditCategory.name = e.target.value;
                this.setState({ selectedEditCategory });
              }}
              placeholder="Name der Kategorie"
            />
          </Cell>
        </Row>
        <Row margin="xy">
          <Cell sm={24} md={24} className="text-right">
            <div className="page-buttons">
              <button
                className="small primary hollow button"
                onClick={this.handle__cancelCategory}
              >
                <Icon icon="times" left /> Abbrechen
              </button>
              <button
                className="small primary button"
                onClick={this.handle__saveCategory}
              >
                <Icon icon="check" left /> Speichern
              </button>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonContent() {
    const { selectedLessonTab } = this.state;

    if (selectedLessonTab === "info") {
      return this.view__showLessonPopupInfo();
    } else if (selectedLessonTab === "tags") {
      return this.view__showLessonPopupTags();
    }
  }

  view__showLessonPopupInfo() {
    const { course, selectedLesson } = this.state;
    const _c = this.props.context;

    let availableCategories = {};
    course.categories.map((category) => {
      availableCategories[category.id] = category.name;
      return null;
    });

    let availableVideoTypes = _c.getVideoTypes();

    let v__lessonContent;

    if (selectedLesson.type === "audio") {
      v__lessonContent = (
        <React.Fragment>
          <p>
            <strong>Neue Datei hochladen (optional)</strong>
          </p>
          <input
            type="file"
            onChange={(e) => {
              this.handle__edit("file", e.target.files[0]);
            }}
          />
        </React.Fragment>
      );
    } else {
      v__lessonContent = (
        <React.Fragment>
          <AdminDataField
            value={selectedLesson.content}
            editValue={selectedLesson.content}
            editType="text"
            label="Video ID"
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              this.handle__edit("content", newValue);
            }}
          />
          <div
            className="small warning callout"
            style={{ marginTop: "10px", marginBottom: "0" }}
          >
            Du benötigst nur die reine ID, nicht die URL und nicht den
            Embed-Code. Den generiert membermate automatisch für Dich.
          </div>
        </React.Fragment>
      );
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            <Box>
              <h3>Kategorie</h3>
              <Grid type="full">
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    <AdminDataField
                      value={selectedLesson.category}
                      editValue={selectedLesson.category}
                      editType="select"
                      selectValues={availableCategories}
                      label="Kategorie"
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("category", newValue);
                      }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Box>
            <Box>
              <h3>Lektion</h3>
              <Grid type="full">
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    <AdminDataField
                      value={selectedLesson.name}
                      editValue={selectedLesson.name}
                      editType="text"
                      label={"Name der Lektion"}
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("name", newValue);
                      }}
                    />
                  </Cell>
                </Row>
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    <AdminDataField
                      value={selectedLesson.description}
                      editValue={selectedLesson.description}
                      editType="textarea"
                      label="Kurze Beschreibung (optional)"
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("description", newValue);
                      }}
                    />
                  </Cell>
                </Row>
                <Row margin="y">
                  <Cell
                    sm={24}
                    md={24}
                    className="text-center"
                    style={{ paddingTop: "10px" }}
                  >
                    <AdminDataField
                      value={selectedLesson.live}
                      editValue={selectedLesson.live}
                      editType="singleCheckbox"
                      label="Dieses Video ist eine LiveCall-Aufzeichnung"
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("live", newValue);
                      }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Box>
          </Cell>
          <Cell sm={24} md={12}>
            {this.view__showVideoPreview()}
            <Box>
              <h3>Video</h3>
              <Grid type="full">
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    <AdminDataField
                      value={selectedLesson.type}
                      editValue={selectedLesson.type}
                      editType="select"
                      selectValues={availableVideoTypes}
                      label="Video-Plattform"
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("type", newValue);
                      }}
                    />
                  </Cell>
                </Row>
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    {v__lessonContent}
                  </Cell>
                </Row>
              </Grid>
            </Box>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonPopupTags() {
    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            {this.view__showTags()}
          </Cell>
          <Cell sm={24} md={12}>
            &nbsp;
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonEditButtons() {
    return (
      <div className="page-buttons">
        <button
          className="small primary hollow button"
          onClick={this.handle__cancel}
        >
          <Icon icon="times" left /> Abbrechen
        </button>
        <button className="small primary button" onClick={this.handle__save}>
          <Icon icon="check" left /> Speichern
        </button>
      </div>
    );
  }

  view__showVideoPreview() {
    const { selectedLesson } = this.state;

    if (selectedLesson.type && selectedLesson.content) {
      if (selectedLesson.type === "vimeo") {
        let embedUrl = `https://player.vimeo.com/video/${selectedLesson.content}`;
        return (
          <div className="responsive-embed widescreen">
            <div id="vimeoPlayer">
              <iframe
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="VimeoPlayer"
              ></iframe>
            </div>
          </div>
        );
      } else if (selectedLesson.type === "youtube") {
        let embedUrl = `https://www.youtube.com/embed/${selectedLesson.content}`;
        return (
          <div className="responsive-embed widescreen">
            <div id="youtubePlayer">
              <iframe
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YoutubePlayer"
              ></iframe>
            </div>
          </div>
        );
      } else if (selectedLesson.type === "audio") {
        return <Audio lesson={selectedLesson} />;
      }
    }

    return null;
  }

  view__showLeftSide() {
    const { course, newCategory } = this.state;

    return (
      <React.Fragment>
        <Box>
          <h3 style={{ marginBottom: "0" }}>Kategorien</h3>
        </Box>
        {course.categories.map((category, index) => {
          return (
            <Box
              key={index}
              size="small"
              style={{ marginBottom: "10px" }}
              onClick={() => {
                if (this.handle__cancel() === true) {
                  this.setState({ selectedCategory: category.id });
                }
              }}
            >
              <h4 style={{ marginBottom: "0" }}>{category.name}</h4>
            </Box>
          );
        })}
        <div className="admin-courses-add-category">
          <Box
            size="small"
            onClick={() => {
              this.setState({ selectedEditCategory: cloneDeep(newCategory) });
            }}
          >
            <Icon icon="plus" left /> Neue Kategorie
          </Box>
        </div>
      </React.Fragment>
    );
  }

  view__showRightSide() {
    const { course, selectedCategory, newLesson } = this.state;

    return (
      <React.Fragment>
        <Box style={{ visibility: "hidden" }}>
          <h3 style={{ marginBottom: "0" }}>Kategorien</h3>
        </Box>
        {course.categories.map((category, index) => {
          let categoryBoxStyle = {
            marginBottom: "10px",
          };

          let lessonBoxStyle = {
            marginBottom: "10px",
          };

          let lessonStyle = {};

          if (parseInt(selectedCategory) !== parseInt(category.id)) {
            categoryBoxStyle.visibility = "hidden";
            lessonStyle.display = "none";
          }

          return (
            <React.Fragment key={index}>
              <Box size="small" style={categoryBoxStyle}>
                <h4 style={{ marginBottom: "0" }}>
                  {category.name}
                  <div className="float-right">
                    <button
                      className="admin-course-edit-category"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        this.handle__delete(category);
                      }}
                    >
                      <Icon icon="trash" />
                    </button>
                    <button
                      className="admin-course-edit-category"
                      onClick={() => {
                        this.setState({
                          selectedEditCategory: cloneDeep(category),
                        });
                      }}
                    >
                      <Icon icon="cog" />
                    </button>
                  </div>
                </h4>
              </Box>
              <ul className="admin-course-lessons-list">
                {category.lessons.map((lesson, lessonIndex) => {
                  return (
                    <li key={lessonIndex} style={lessonStyle}>
                      <Box
                        size="small"
                        style={lessonBoxStyle}
                        onClick={() => {
                          this.setState({ selectedLesson: cloneDeep(lesson) });
                        }}
                      >
                        {lessonIndex + 1}. {lesson.name}
                      </Box>
                    </li>
                  );
                })}
                <li className="admin-courses-add-lesson" style={lessonStyle}>
                  <Box
                    size="small"
                    onClick={() => {
                      newLesson.category = category.id;
                      this.setState({ selectedLesson: cloneDeep(newLesson) });
                    }}
                  >
                    <Icon icon="plus" left /> Lektion hinzufügen
                  </Box>
                </li>
              </ul>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  view__showTags() {
    const { selectedLesson } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Stichworte / Tags</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminTags
                tags={selectedLesson.tags}
                editMode={true}
                onUpdate={(tags) => {
                  this.handle__edit("tags", tags);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  handle__edit(property, value) {
    const { selectedLesson } = this.state;
    selectedLesson[property] = value;
    this.setState({ selectedLesson, changesWereMade: true });
  }

  handle__delete = (category) => {
    const { course } = this.state;
    const _c = this.props.context;

    let lessons = ``;
    if (category && category.lessons && category.lessons.length > 0) {
      lessons = `\r\n\r\nIn dieser Kategorie sind [${category.lessons.length}] Lektionen enthalten. DIESE LEKTIONEN WERDEN EBENFALLS UNWIDERRUFLICH GELÖSCHT.`;
    }
    if (
      window.confirm(
        `Möchtest Du die Kategorie [${category.name}] wirklich unwiderruflich löschen?${lessons}`
      )
    ) {
      setTimeout(() => {
        const apiUrl = _c.apiUrl("adminCourseCategory", {
          courseId: course.id,
          categoryId: category.id,
        });

        axios
          .delete(apiUrl, {
            headers: _c.headers,
          })
          .then((response) => {
            try {
              if (_c.isDebug()) {
                console.log("API RESPONSE", response.data);
              }

              const { status } = response.data;

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { course } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    selectedCategory: null,
                    course,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      "Kategorie gelöscht",
                      "Die Kaegorie wurde erfolgreich gelöscht.",
                      "success"
                    );
                  }
                );
              }
            } catch {}
          })
          .catch((error) => {});
      }, 200);
    }
  };

  handle__cancel = () => {
    const { changesWereMade } = this.state;

    let confirmCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Möchtest Du wirklich abbrechen? Alle Eingaben/Änderungen gehen unwiderruflich verloren."
        )
      ) {
        confirmCancel = true;
      }
    } else {
      confirmCancel = true;
    }

    if (confirmCancel === true) {
      this.setState({
        selectedLesson: null,
        selectedLessonTab: "info",
        changesWereMade: false,
      });
    }

    return confirmCancel;
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, selectedLesson } = this.state;

    if (!selectedLesson.name) {
      window.alert("Bitte gib einen Namen für diese Lektion an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminCourseLesson", {
        courseId: course.id,
        lessonId: selectedLesson.id,
      });

      const formData = new FormData();

      if (selectedLesson.file) {
        formData.append("file", selectedLesson.file);
        formData.append("name", selectedLesson.file.name);
      }
      formData.append("lesson", JSON.stringify(selectedLesson));

      axios
        .post(apiUrl, formData, {
          headers: _c.headers,
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedLesson: null,
                  selectedLessonTab: "info",
                  course,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    "Kurs gespeichert",
                    "Der Kurs wurde erfolgreich gespeichert.",
                    "success"
                  );
                }
              );
            }
          } catch {}
        })
        .catch((error) => {});
    }, 200);
  };

  handle__cancelCategory = () => {
    this.setState({ selectedEditCategory: null });
  };

  handle__saveCategory = () => {
    const _c = this.props.context;
    const { course, selectedEditCategory } = this.state;

    if (!selectedEditCategory.name) {
      window.alert("Bitte gib einen Namen für diese Kategorie an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminCourseCategory", {
        courseId: course.id,
        categoryId: selectedEditCategory.id,
      });

      axios
        .post(
          apiUrl,
          {
            category: selectedEditCategory,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedEditCategory: null,
                  selectedLessonTab: "info",
                  course,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    "Kategorie gespeichert",
                    "Die Kategorie wurde erfolgreich gespeichert.",
                    "success"
                  );
                }
              );
            }
          } catch {}
        })
        .catch((error) => {});
    }, 200);
  };
}

export default withContext(AdminCourseLessons);
