import React, { Component } from "react";
import { Grid, Row, Cell } from "../foundation/_grid";
import DefaultLayout from "../layout/DefaultLayout";
import withContext from "../../context/contextHOC";
import { Link } from "react-router-dom";
import { Icon } from "../foundation/_buttons";
import ExternalVideo from "./parts/ExternalVideo";
import PTPopup from "../ptPopup/PTPopup";
import SignupForm from "../auth/SignupForm";
import Thumbnail1 from "../../images/freebie/thumbnail-video1.jpg";
import Thumbnail2 from "../../images/freebie/thumbnail-video2.jpg";
import Thumbnail3 from "../../images/freebie/thumbnail-video3.jpg";

class PageFreebie extends Component {
  state = {
    showSignupPopup: false,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.login(() => {
      _c.initFinished();
    });

    _c.setPageTitle("Magische Videoserie");
  }

  render() {
    const _c = this.props.context;

    let thumbnail1 = (
      <div className="freebie-thumbnail">
        <img src={Thumbnail1} alt="" />
      </div>
    );
    let thumbnail2 = (
      <div className="freebie-thumbnail">
        <img src={Thumbnail2} alt="" />
      </div>
    );
    let thumbnail3 = (
      <div className="freebie-thumbnail">
        <img src={Thumbnail3} alt="" />
      </div>
    );

    let v__isNotLoggedin1 = (
      <div className="freebie-content-isnotloggedin">
        {thumbnail1}
        <p>Du musst angemeldet sein, um diesen Inhalt ansehen zu können.</p>
        <div>
          <button
            className="primary hollow button"
            onClick={() => {
              this.setState({ showSignupPopup: true });
            }}
          >
            Jetzt kostenlos anmelden
          </button>
        </div>
      </div>
    );

    let v__isNotLoggedin2 = (
      <div className="freebie-content-isnotloggedin">
        {thumbnail2}
        <p>Du musst angemeldet sein, um diesen Inhalt ansehen zu können.</p>
        <div>
          <button
            className="primary hollow button"
            onClick={() => {
              this.setState({ showSignupPopup: true });
            }}
          >
            Jetzt kostenlos anmelden
          </button>
        </div>
      </div>
    );

    let v__isNotLoggedin3 = (
      <div className="freebie-content-isnotloggedin">
        {thumbnail3}
        <p>Du musst angemeldet sein, um diesen Inhalt ansehen zu können.</p>
        <div>
          <button
            className="primary hollow button"
            onClick={() => {
              this.setState({ showSignupPopup: true });
            }}
          >
            Jetzt kostenlos anmelden
          </button>
        </div>
      </div>
    );

    let v__video1 = v__isNotLoggedin1;
    let v__video2 = v__isNotLoggedin2;
    let v__video3 = v__isNotLoggedin3;

    let v__buttonEndOfPage = (
      <div className="freebie-button-endofpage">
        <Grid>
          <Row>
            <Cell sm={24} md={18} mdo={3}>
              <h2>Möchtest Du Dir diese magische Videoserie ansehen?</h2>
              <p>
                <strong>
                  Dann trete jetzt kostenfrei der Welt von Beyond bei.
                </strong>
              </p>
              <div>
                <button
                  className="large primary hollow button"
                  onClick={() => {
                    this.setState({ showSignupPopup: true });
                  }}
                >
                  Kostenloser Zugang zur Videoserie
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );

    if (_c.isUserLoggedIn()) {
      v__video1 = (
        <div className="freebie-content-video">
          <ExternalVideo type="vimeo" videoId="399945939" />
        </div>
      );
      v__video2 = (
        <div className="freebie-content-video">
          <ExternalVideo type="vimeo" videoId="397831303" />
        </div>
      );
      /*v__video3 = (
        <div className="freebie-content-video">
          <ExternalVideo type="vimeo" videoId="397831303" />
        </div>
      );*/
      /*v__video3 = (
        <div className="freebie-content-audio not-published-yet">
          <Icon icon="info-circle" left /> Dieser Inhalt erscheint am
          19.04.2020.
        </div>
      );*/
      v__video3 = (
        <div className="freebie-content-audio not-published-yet">
          <p>
            Meine persönliche Phönix Erfahrung und meine Begegnung mit dem
            Magier findest Du als allererste Folge des neuen Welt von Beyond
            Podcasts.
          </p>
          <div>
            <a
              href="https://podcasts.apple.com/at/podcast/welt-von-beyond-podcast/id1507167120"
              target="_blank"
              rel="noopener noreferrer"
              className="primary hollow button"
            >
              <Icon icon="podcast" left /> Direkt zum neuen{" "}
              <em>Welt von Beyond</em> Podcast{" "}
              <Icon icon="external-link" right />
            </a>
          </div>
        </div>
      );

      v__buttonEndOfPage = "";
    }

    return (
      <DefaultLayout page={"text-page page-freebie"}>
        {/*<section className="full-height">
          <div className="center-vertically">*/}
        <section style={{ padding: "160px 0" }}>
          <div>
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <div className="text-center">
                    <div className="pretitle">
                      Dein kostenloser Start in die Welt von Beyond
                    </div>
                    <h1 style={{ marginBottom: "20px" }}>
                      Der Phönix und die Rückkehr des Magiers
                    </h1>

                    <div className="lead">
                      Erkenne die Kraft des Phönix in Deiner inneren Welt und
                      erfahre, welche Botschaft der Archetyp des Magiers für
                      Dich hat. Möge ihre Weisheit Dich in Zeiten der
                      Veränderung stärken.
                    </div>

                    <div className="freebie-content-trailer">
                      <ExternalVideo type="vimeo" videoId="403683271" />
                    </div>
                    {/*<div className="full-height-scroll-arrow">
                      <button
                        onClick={() => {
                          let y = 0;
                          if (window.document.getElementById("secondPage")) {
                            y = window.document.getElementById("secondPage")
                              .offsetTop;
                            window.scrollTo(0, y - 120);
                          }
                        }}
                      >
                        <Icon icon="arrow-circle-down" />
                      </button>
                      </div>*/}
                  </div>
                </Cell>
              </Row>
            </Grid>
          </div>
        </section>

        <div id="secondPage" />

        <section className="freebie-contents">
          <div className="freebie-content freebie-video">
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <div className="pretitle">1. Video</div>
                  <h2>Der Phönix und Deine innere Welt</h2>

                  <p>
                    In den Mythen vieler Kulturen finden sich Geschichten über
                    den sagenumwobenen und legendären Feuervogel - dem Phönix
                    aus der Asche. Wann stehen wir selbst unter seinen
                    energetischen Einfluss und was ist seine Weisheit, die uns
                    hilft im Einklang zu sein mit den Zyklen unseres Lebens?
                  </p>

                  {v__video1}
                </Cell>
              </Row>
            </Grid>
          </div>
          <div className="website-separator" />
          <div className="freebie-content freebie-video">
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <div className="pretitle">2. Video</div>
                  <h2>Die Rückkehr des Magier Archetyps</h2>

                  <p>
                    Die große Rückkehr des Magier Archetypen hat begonnen und er
                    ist so aktiv, wie schon lange nicht mehr. Wir begegnen ihm
                    alle, ob es uns bewusst oder nicht. Wir sind ihm begegnet in
                    den großen Filmen, wie Herr der Ringe, in den Avengers, in
                    Star Wars und in X Man. Wir lieben die Filme und Mythen in
                    denen er erscheint so sehr, weil sie für unseren Sehnsucht
                    stehen, seine Macht wieder in uns anzunehmen.
                  </p>
                  <p>
                    Auch wir möchten so gerne die Kontrolle über unsere inneren
                    Energien finden. Lass uns gemeinsam diesen Archetypen
                    besuchen, damit wir wirklich verstehen warum er uns begegnet
                    und wozu er uns aufruft.
                  </p>

                  {v__video2}
                </Cell>
              </Row>
            </Grid>
          </div>
          <div className="website-separator" />
          <div className="freebie-content freebie-video">
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <div className="pretitle">3. Audio</div>
                  <h2>
                    Meine persönliche Phönix Erfahrung und meine Begegnung mit
                    dem Magier
                  </h2>

                  <p>
                    Komm mit auf eine kleine Zeitreise und erfahre, wann mir der
                    Phönix das erste Mal bewusst begegnet ist und mich zu dem
                    Magier führte. Erlebe mit mir, wie die beiden in unser Leben
                    treten können auf Wegen, die wir uns so niemals hätten
                    vorstellen können.
                  </p>
                  <p>
                    Lerne wie Du den Archetypen des Phönix und des Magiers
                    erkennst, wenn sie Dir begegnen. Sie beiden laden Dich ein
                    ganz bestimmte Kräfte in Dir wieder zu erwecken. Lass uns
                    gemeinsam entdecken, welche dies sind.
                  </p>

                  {v__video3}
                </Cell>
              </Row>
            </Grid>
          </div>
          <div className="website-separator" />
          <div className="freebie-content freebie-video">
            <Grid>
              <Row>
                <Cell sm={24} md={18} mdo={3}>
                  <h2>Dein nächster Schritt</h2>

                  <p>
                    <em>
                      „Hab keine Angst.“, flüsterte Deine Seele, als Du an die
                      Wegkreuzung kamst.
                    </em>
                    <br />
                    <em>
                      „Trete über die Schwelle, tauche ein in Deine innere Welt
                      und beginne ein neues Leben.“
                    </em>
                  </p>
                  <div>&nbsp;</div>

                  <h3>Stehst Du vor einer Wegkreuzung in Deinem Leben?</h3>
                  <p>
                    Dann bist Du an einen magischen Ort und es ist die Zeit
                    gekommen, Dein nächstes Abenteuer in Deiner inneren Welt
                    anzutreten. An einer Wegkreuzung zu stehen kann sich so
                    überwältigend anfühlen. Wir wissen, dass das Alte zu Ende
                    geht und ein neuer Anfang auf uns wartet. Manchmal spüren
                    wir bereits vorher, dass wir den alten Weg so nicht
                    weitergehen können.
                  </p>

                  <div>
                    <a
                      href="https://annevonjahr.com/wegkreuzungen"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary hollow button"
                    >
                      Auf zu Deinem nächsten Schritt{" "}
                      <Icon icon="angle-right" right />
                    </a>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </div>
        </section>

        {v__buttonEndOfPage}
        {this.view__showSignupPopup()}
      </DefaultLayout>
    );
  }

  view__showSignupPopup() {
    const { showSignupPopup } = this.state;

    return (
      <PTPopup
        show={showSignupPopup}
        size="medium"
        handleClose={this.handle__hidePopup}
      >
        <h2>Kostenlos registrieren</h2>
        <p className="lead">
          Wir freuen uns, dass Du die Welt von Beyond betreten möchtest.
        </p>
        <p>
          Falls Du bereits ein Welt von Beyond Benutzerkonto hast, kannst Du
          Dich{" "}
          <Link to="/login" style={{ textDecoration: "underline" }}>
            hier ganz einfach mit Deiner E-Mail-Adresse und Deinem Passwort
            anmelden
          </Link>
          .
        </p>
        <SignupForm type="freebie" />
        <div>&nbsp;</div>
      </PTPopup>
    );
  }

  handle__hidePopup = () => {
    this.setState({ showSignupPopup: false });
  };
}

export default withContext(PageFreebie);
