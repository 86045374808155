import React, { Component } from "react";

class PTDialog extends Component {
  state = {
    show: this.props.show,
    message: this.props.message,
    button_1: this.props.button_1,
    button_2: this.props.button_2,
    button_3: this.props.button_3
  };

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({ show: this.props.show });
    }
    if (prevProps.message !== this.props.message) {
      this.setState({ message: this.props.message });
    }
    if (prevProps.button_1 !== this.props.button_1) {
      this.setState({ button_1: this.props.button_1 });
    }
    if (prevProps.button_2 !== this.props.button_2) {
      this.setState({ button_2: this.props.button_2 });
    }
    if (prevProps.button_3 !== this.props.button_3) {
      this.setState({ button_3: this.props.button_3 });
    }
  }

  render() {
    const { show } = this.state;

    if (show !== true) {
      return null;
    }

    return (
      <div className="ptdialog-modal">
        <div className="ptdialog-popup">
          <div className="ptdialog-popup-content">
            {this.view__showMessage()}
            {this.view__showButtons()}
          </div>
        </div>
      </div>
    );
  }

  view__showMessage() {
    const { message } = this.state;
    return <div className="ptdialog-popup-text">{message}</div>;
  }

  view__showButtons() {
    const { button_1, button_2, button_3 } = this.state;

    let v__button_1, v__button_2, v__button_3;

    if (button_1) {
      let button_1_icon;
      if (button_1.icon) {
        button_1_icon = button_1.icon;
      }
      v__button_1 = (
        <button
          className={`${button_1.type} button`}
          onClick={this.props.onButton_1}
        >
          {button_1_icon} {button_1.title}
        </button>
      );
    }

    if (button_2) {
      let button_2_icon;
      if (button_2.icon) {
        button_2_icon = button_2.icon;
      }
      v__button_2 = (
        <button
          className={`${button_2.type} button`}
          onClick={this.props.onButton_2}
        >
          {button_2_icon} {button_2.title}
        </button>
      );
    }

    if (button_3) {
      let button_3_icon;
      if (button_3.icon) {
        button_3_icon = button_3.icon;
      }
      v__button_3 = (
        <button
          className={`${button_3.type} button`}
          onClick={this.props.onButton_3}
        >
          {button_3_icon} {button_3.title}
        </button>
      );
    }

    return (
      <div className="page-buttons text-right">
        <div>&nbsp;</div>
        <div className="button-group no-gaps">
          {v__button_1}
          {v__button_2}
          {v__button_3}
        </div>
      </div>
    );
  }
}

export default PTDialog;
