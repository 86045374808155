import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../foundation/_grid";
import ReactTooltip from "react-tooltip";
import { Icon } from "../foundation/_buttons";
import axios from "axios";

class CourseDownloads extends Component {
  state = {
    course: this.props.course,
    downloads: this.props.downloads,
    types: {
      pdf: {
        title: "PDFs",
        icon: "file-pdf-o",
      },
      audio: {
        title: "Audiodateien",
        icon: "file-audio-o",
      },
      image: {
        title: "Grafiken",
        icon: "file-image-o",
      },
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.course !== prevProps.course) {
      this.setState({ course: this.props.course });
    }

    if (this.props.downloads !== prevProps.downloads) {
      this.setState({ downloads: this.props.downloads });
    }
  }

  render() {
    return <div className="course-downloads">{this.view__showContent()}</div>;
  }

  view__showContent() {
    const { types, course } = this.state;

    let v__output;

    if (course.downloads_sorting === "type") {
      v__output = (
        <React.Fragment>
          {Object.keys(types).map((typeKey, index) => {
            const selectedDownloads = this.getDownloadsByType(typeKey);
            if (selectedDownloads.length > 0) {
              return (
                <div className="course-downloads-type" key={index}>
                  {this.view__showDownloadsGrid(
                    types[typeKey],
                    selectedDownloads
                  )}
                </div>
              );
            }
            return null;
          })}
        </React.Fragment>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }

  view__showDownloadsGrid(typeInfo, downloads) {
    const { course } = this.state;
    const _c = this.props.context;

    let v__output;

    if (course.downloads_layout === "grid-3") {
      v__output = (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <Grid type="full">
            <Row margin="xy">
              {downloads.map((download, index) => {
                return (
                  <Cell sm={24} md={8} key={index}>
                    <a
                      className="course-download-grid-download"
                      href={download.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      data-tip={download.name}
                      onClick={() => {
                        this.handle__downloadClick(download.id);
                      }}
                    >
                      {this.view__showDownloadImage(download)}
                    </a>
                  </Cell>
                );
              })}
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{typeInfo.title}</h3>
        {v__output}
      </React.Fragment>
    );
  }

  view__showDownloadImage(download) {
    let v__image;

    if (download.image) {
      v__image = <img src={download.image} alt={download.name} />;
    } else {
      if (download.type === "audio") {
        v__image = (
          <div className="download-image-icon">
            <Icon icon="file-audio-o" />
            <h4>{download.name}</h4>
          </div>
        );
      }
    }

    return v__image;
  }

  getDownloadsByType = (typeKey) => {
    const { downloads } = this.state;

    let selectedDownloads = [];

    downloads.map((download) => {
      if (download.type === typeKey) {
        selectedDownloads.push(download);
      }
      return null;
    });

    return selectedDownloads;
  };

  handle__downloadClick(downloadId) {
    const _c = this.props.context;

    let url = _c.apiUrl("courseDownloadClick", {
      downloadId,
    });

    axios
      .patch(
        url,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          //
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        //_c.checkError(error);
      });
  }
}

export default withContext(CourseDownloads);
