import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import "./stylesheets/app.scss";
import ScrollToTop from "./components/ScrollToTop";
import Index from "./components/index";
import Login from "./components/auth/login";
import PasswordReset from "./components/auth/PasswordReset";
import ChangePassword from "./components/auth/ChangePassword";
import AdminDashboard from "./components/admin/dashboard/AdminDashboard";
import AdminMembers from "./components/admin/members/AdminMembers";
import AdminMember from "./components/admin/members/AdminMember";
import MyAccount from "./components/user/MyAccount";
import AdminLiveCalls from "./components/admin/livecalls/AdminLiveCalls";
import AdminLiveCall from "./components/admin/livecalls/AdminLiveCall";
import Quiz from "./components/quiz/Quiz";
import QuizResult from "./components/quiz/QuizResult";
import Signup from "./components/auth/Signup";
import Impressum from "./components/pages/Impressum";
import Datenschutz from "./components/pages/Datenschutz";
import PageInnereWelt from "./components/pages/PageInnereWelt";
import PageFreebie from "./components/pages/PageFreebie";
import CourseLesson from "./components/courses/CourseLesson";
import CourseOverview from "./components/courses/CourseOverview";
import CourseDirectAccess from "./components/courses/CourseDirectAccess";
import Home from "./components/pages/home/Home";
import AdminLetters from "./components/admin/letters/AdminLetters";
import AdminLetter from "./components/admin/letters/AdminLetter";
import AdminCourses from "./components/admin/courses/AdminCourses";
import AdminCourse from "./components/admin/courses/AdminCourse";

import CommunityAreas from "./components/pages/community/CommunityAreas";
import CommunityBoard from "./components/pages/community/CommunityBoard";
import CommunityThread from "./components/pages/community/CommunityThread";
import NewThread from "./components/pages/community/NewThread";
import Profile from "./components/pages/community/Profile";

import AdminCommunity from "./components/admin/community/AdminCommunity";
import AdminBackgrounds from "./components/admin/AdminBackgrounds";
import AdminFaq from "./components/admin/faq/AdminFaq";
import Faq from "./components/faq/Faq";
import AdminSettings from "./components/admin/settings/AdminSettings";
import AchievementRoom from "./components/pages/home/AchievementRoom";
import CommunityArea from "./components/pages/community/CommunityArea";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/impressum"
            render={(props) => <Impressum {...props} />}
          />
          <Route
            path="/datenschutz"
            render={(props) => <Datenschutz {...props} />}
          />

          <Route
            path="/innerewelt"
            render={(props) => <PageInnereWelt {...props} />}
          />
          {/*<Route
            path="/videoserie"
            render={(props) => <PageFreebie {...props} />}
          />*/}

          <Route path="/home" render={(props) => <Home {...props} />} />

          <Route
            path="/achievements"
            render={(props) => <AchievementRoom {...props} />}
          />
          <Route
            path="/achievementroom"
            render={(props) => <AchievementRoom {...props} />}
          />

          <Route
            path="/courses"
            render={(props) => <CourseOverview {...props} />}
          />
          <Route
            path="/kurse"
            render={(props) => <CourseOverview {...props} />}
          />

          <Route
            path="/course/:courseSlug/:lessonSlug"
            render={(props) => <CourseLesson {...props} />}
          />
          <Route
            path="/course/:courseSlug"
            render={(props) => <CourseDirectAccess {...props} />}
          />

          <Route
            path="/password/reset"
            render={(props) => <PasswordReset {...props} />}
          />
          <Route path="/login" render={(props) => <Login {...props} />} />
          <Route
            path="/signup/:view/:code"
            render={(props) => <Signup {...props} />}
          />
          <Route path="/signup" render={(props) => <Signup {...props} />} />
          <Route
            path="/password"
            render={(props) => <ChangePassword {...props} />}
          />

          {/* 
            Community Profile
          */}
          <Route
            path={"/community/@:username"}
            render={(props) => <Profile {...props} />}
          />

          {/* 
            Community New Thread
          */}
          <Route
            path={"/community/:areaSlug/:boardSlug/new"}
            render={(props) => <NewThread {...props} />}
          />

          {/* 
            Community Thread
          */}
          <Route
            path={"/community/:areaSlug/:boardSlug/:threadSlug"}
            render={(props) => <CommunityThread {...props} />}
          />

          {/* 
            Community Board (Thread Overview)
            
          */}
          <Route
            path={"/community/:areaSlug/:boardSlug"}
            render={(props) => <CommunityBoard {...props} />}
          />

          {/* 
            Community Area
            
          */}
          <Route
            path={"/community/:areaSlug"}
            render={(props) => <CommunityArea {...props} />}
          />

          {/* 
            Community Areas (Main Page)
            
          */}
          <Route
            path={"/community"}
            render={(props) => <CommunityAreas {...props} />}
          />

          <Route
            path={"/faq/:category/:question"}
            render={(props) => <Faq {...props} />}
          />
          <Route
            path={"/faq/:category"}
            render={(props) => <Faq {...props} />}
          />
          <Route path={"/faq"} render={(props) => <Faq {...props} />} />

          <Route
            path="/quiz/:slug/result"
            render={(props) => <QuizResult {...props} />}
          />
          <Route path="/quiz/:slug" render={(props) => <Quiz {...props} />} />

          <Route
            path="/admin/members/:id"
            render={(props) => <AdminMember {...props} />}
          />
          <Route
            path="/admin/members"
            render={(props) => <AdminMembers {...props} />}
          />

          <Route
            path="/admin/faq"
            render={(props) => <AdminFaq {...props} />}
          />

          <Route
            path="/admin/livecalls/:id"
            render={(props) => <AdminLiveCall {...props} />}
          />
          <Route
            path="/admin/livecalls"
            render={(props) => <AdminLiveCalls {...props} />}
          />

          {/* 
            Admin Community
            /admin/community
          */}
          <Route
            path="/admin/community"
            render={(props) => <AdminCommunity {...props} />}
          />

          <Route
            path="/admin/courses/:id"
            render={(props) => <AdminCourse {...props} />}
          />
          <Route
            path="/admin/courses"
            render={(props) => <AdminCourses {...props} />}
          />

          <Route
            path="/admin/letters/:id"
            render={(props) => <AdminLetter {...props} />}
          />

          <Route
            path="/admin/letters"
            render={(props) => <AdminLetters {...props} />}
          />

          <Route
            path="/admin/backgrounds"
            render={(props) => <AdminBackgrounds {...props} />}
          />

          <Route
            path="/admin/settings"
            render={(props) => <AdminSettings {...props} />}
          />

          <Route
            path="/admin"
            render={(props) => <AdminDashboard {...props} />}
          />

          <Route path="/account" render={(props) => <MyAccount {...props} />} />

          <Route path="/" render={(props) => <Index {...props} />} />
        </Switch>
        <ScrollToTop />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
