import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import LogoData from "../../images/logos/gold-with-text.png";
import Box from "../layout/Box";
import qs from "query-string";
import { isUndefined } from "util";

class Login extends Component {
  state = {
    isLoading: false,
    msgSuccess: false,
    loginEmail: "",
    loginPassword: "",
    hasErrorEmailNotFound: false,
    hasErrorEmpty: false,
    hasErrorWrong: false,
    view: "login",
    showErrorInactive: false,
    showErrorSignupExists: false
  };

  componentDidMount() {
    const _c = this.props.context;

    // If Access Token is saved, try to catch user info
    // -> if User is logged in, redirect to Page after Login
    // (So the Login Page won't be shown for people already loggedin)
    if (_c.hasAccessToken()) {
      axios
        .get(this.props.context.apiEndpoints.user, {
          headers: _c.getHeaders()
        })
        .then(response => {
          try {
            const { user } = response.data;

            if (_c.isDebug()) {
              console.log(response.data);
            }

            if (user && user.active && user.active === true) {
              _c.redirectAfterLogin();
            }
          } catch {}
        })
        .catch(error => {});
    }

    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.error) && queryParams.error === "inactive") {
      this.setState({ showErrorInactive: true });
    }

    if (!isUndefined(queryParams.email)) {
      this.setState({
        loginEmail: queryParams.email,
        showErrorSignupExists: true
      });
    }

    _c.initFinished();
  }

  render() {
    let v__title;
    let v__text;
    const { view } = this.state;
    if (view === "reset") {
      v__title = "Passwort vergessen";
    } else {
      v__title = "Anmelden";
      v__text = (
        <React.Fragment>
          <p>
            Wenn Du bereits ein Welt von Beyond Benutzerkonto hast, kannst Du
            Dich nun mit Deiner E-Mail-Adresse und Deinem Passwort anmelden.
          </p>
          <p>
            Solltest Du <strong>noch kein Benutzerkonto haben</strong>,{" "}
            <Link to="/signup" style={{ textDecoration: "underline" }}>
              kannst Du Dir hier ganz einfach einen kostenfrei anlegen
            </Link>
            .
          </p>
        </React.Fragment>
      );
    }

    return (
      <div className="page page-login">
        <main className="content">
          <Grid>
            <Row>
              <Cell sm={24} md={12} mdo={6}>
                <div className="login-logo">
                  <Link to="/">
                    <img src={LogoData} alt="Logo" />
                  </Link>
                </div>
                <Box>
                  <h1 className="text-center">{v__title}</h1>
                  {v__text}

                  {this.view__showErrorInactive()}

                  {this.view__showLoginForm()}
                  {this.view__showPasswordForgottenForm()}
                </Box>
              </Cell>
            </Row>
          </Grid>
        </main>
      </div>
    );
  }

  view__showLoginForm() {
    const { view } = this.state;
    if (view !== "login") {
      return;
    }

    // Show Spinner If Login Form is checking Login Credentials
    if (this.state.isLoading) {
      return (
        <div className="login-form-loading">
          <p>
            <Icon icon="spin fa-circle-o-notch" />
          </p>
          <p>Bitte warten ...</p>
        </div>
      );
    }

    return (
      <React.Fragment>
        {this.view__showErrorMessages()}
        <label>
          E-Mail-Adresse:
          <input
            type="email"
            placeholder="Deine E-Mail-Adresse"
            value={this.state.loginEmail}
            onChange={this.handle__change("loginEmail")}
            onKeyUp={e => {
              if (e.keyCode === 13) {
                this.handle__login();
              }
            }}
          />
        </label>
        <label>
          Passwort
          <input
            type="password"
            placeholder="Dein Passwort"
            value={this.state.loginPassword}
            onChange={this.handle__change("loginPassword")}
            onKeyUp={e => {
              if (e.keyCode === 13) {
                this.handle__login();
              }
            }}
          />
        </label>
        <p className="text-center">
          <button
            className="login-password-forgotten"
            onClick={() => {
              this.setState({ view: "reset" });
            }}
          >
            <small>Passwort vergessen?</small>
          </button>
        </p>
        <div className="login-buttons">
          <button
            className="primary hollow button"
            onClick={() => {
              this.handle__login();
            }}
          >
            Anmelden <Icon icon="angle-right" right />
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showErrorInactive() {
    const { showErrorInactive } = this.state;

    if (showErrorInactive === true) {
      return (
        <div className="alert callout">
          <p>
            <strong>
              Dein Benutzerkonto scheint nicht freigeschaltet zu sein.
            </strong>
            <br />
            Falls dies eigentlich nicht so sein sollte, kontaktiere bitte den
            Support.
          </p>
        </div>
      );
    }

    return null;
  }

  view__showErrorMessages() {
    const {
      hasErrorEmailNotFound,
      hasErrorEmpty,
      hasErrorWrong,
      showErrorSignupExists,
      loginEmail
    } = this.state;

    let errorMessageEmailNotFound,
      errorMessageEmpty,
      errorMessageWrong,
      errorSignupExists;

    if (showErrorSignupExists === true && loginEmail) {
      errorSignupExists = (
        <div className="warning callout">
          <p>
            Es existiert bereits ein Benutzerkonto mit Deiner E-Mail-Adresse.
            Gib unten einfach Dein Passwort ein oder nutze die
            Passwort-vergessen-Funktion, falls Du Dein Passwort vergessen haben
            solltest.
          </p>
        </div>
      );
    }

    if (hasErrorEmailNotFound === true) {
      errorMessageEmailNotFound = (
        <div className="alert callout">
          <p>
            Es konnte kein Benutzerkonto mit dieser E-Mail-Adresse gefunden
            werden.
          </p>
        </div>
      );
    }

    if (hasErrorEmpty === true) {
      errorMessageEmpty = (
        <div className="alert callout">
          <p>Bitte gib Deine E-Mail-Adresse und Dein Passwort ein.</p>
        </div>
      );
    }

    if (hasErrorWrong === true) {
      errorMessageWrong = (
        <div className="alert callout">
          <p>
            Die von Dir eingegebene E-Mail-/Passwort-Kombination ist leider
            nicht korrekt.
          </p>
          <p>
            Bitte probiere es erneut oder nutze die Passwort vergessen Funktion.
          </p>
        </div>
      );
    }

    return (
      <React.Fragment>
        {errorSignupExists}
        {errorMessageEmailNotFound}
        {errorMessageEmpty}
        {errorMessageWrong}
      </React.Fragment>
    );
  }

  view__showPasswordForgottenForm() {
    const { isLoading, msgSuccess, view } = this.state;

    if (view !== "reset") {
      return;
    }

    if (isLoading) {
      return (
        <div id="loginFormLoading">
          <Icon icon="spin fa-spinner" />
          <br />
          Bitte warten ...
        </div>
      );
    } else if (msgSuccess === true) {
      return (
        <React.Fragment>
          <div className="success callout">
            <p>
              Vielen Dank! Wir haben Dir eine E-Mail mit einem Link zum
              Zurücksetzen Deines Passworts zugesendet.
            </p>
            <p>
              Dieses Fenster / diesen Tab kannst Du nun gefahrlos schließen.
              Weiter geht es über besagten Link in der E-Mail.
            </p>
          </div>
          <div>&nbsp;</div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p>Hast Du Dein Passwort vergessen? Kein Problem.</p>
          <p>
            Trage unten einfach Deine E-Mail-Adresse ein und wir senden Dir eine
            E-Mail, mit der Du Dein Passwort zurücksetzen kannst.
          </p>
          {this.view__showErrorMessages()}
          <label>
            E-Mail-Adresse:
            <input
              type="email"
              placeholder="Deine E-Mail-Adresse"
              value={this.state.inputEmail}
              onChange={this.handle__change("loginEmail")}
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  this.handle__reset();
                }
              }}
            />
          </label>
          <div className="login-buttons">
            <button
              className="primary hollow button"
              onClick={() => {
                this.setState({ view: "login" });
              }}
            >
              <Icon icon="angle-left" left />
              Zurück
            </button>
            <button
              className="primary button"
              onClick={() => {
                this.handle__reset();
              }}
            >
              Passwort zurücksetzen <Icon icon="angle-right" right />
            </button>
          </div>
        </React.Fragment>
      );
    }
  }

  handle__resetPasswordForgotten() {
    this.setState({ view: "login" });
  }

  handle__change = prop => event => {
    this.setState({ [prop]: event.target.value });
  };

  handle__login() {
    // Reset Error Messages and show Login Spinner
    this.setState({
      isLoading: true,
      hasErrorEmailNotFound: false,
      hasErrorEmpty: false,
      hasErrorWrong: false
    });

    const { loginEmail, loginPassword } = this.state;

    if (!loginEmail || !loginPassword) {
      this.setState({ hasErrorEmpty: true, isLoading: false });
      return;
    }

    // Get Auth Information for API
    const _c = this.props.context;
    const { auth } = _c;

    // Try Login
    axios
      .post(_c.apiEndpoints.login, {
        username: loginEmail,
        password: loginPassword,
        grant_type: auth.grant_type,
        client_id: auth.client_id,
        client_secret: auth.client_secret,
        scope: auth.scope
      })
      .then(response => {
        const statusCode = response.status;
        if (statusCode === 200) {
          if (response.data) {
            const accessToken = response.data.access_token;
            localStorage.setItem("access_token", accessToken);

            //if (!_c.isDebug()) {
            _c.redirectAfterLogin();
            //}
          }
        }
      })
      .catch(error => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  }

  handle__reset() {
    this.setState({
      isLoading: true,
      hasErrorEmailNotFound: false,
      hasErrorEmpty: false
    });
    const { loginEmail } = this.state;

    if (!loginEmail) {
      this.setState({ hasErrorEmpty: true, isLoading: false });
      return false;
    }

    axios
      .post(this.props.context.apiEndpoints.passwordForgotten, {
        email: loginEmail
      })
      .then(response => {
        if (response.data === "OK") {
          this.setState({ msgSuccess: true, isLoading: false });
        }
      })
      .catch(error => {
        if (error.response) {
          let statusCode = error.response.status;
          if (statusCode === 404 && error.response.data === "notfound") {
            this.setState({ hasErrorEmailNotFound: true, isLoading: false });
          }
        }
      });
  }
}

export default withContext(Login);
