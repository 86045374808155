import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import validator from "email-validator";

class SignupForm extends Component {
  state = {
    type: this.props.type || "signup",
    view: this.props.view, // signup, code, password, finished
    code: this.props.code,
    userId: null,
    user: null,
    isLoading: false,
    msgSuccess: false,
    signupEmail: "",
    signupFirstname: "",
    signupLastname: "",
    signupCode: "",
    signupPassword1: "",
    signupPassword2: "",
    showErrorEmpty: false,
    showErrorNotEmail: false,
    showErrorCodeNotFound: false,
    showErrorCodeExpired: false,
    showErrorPasswordsNotEqual: false,
    showErrorPasswordAlreadySet: false,
  };

  componentDidMount() {
    const { view, code } = this.state;
    if (!view) {
      this.setState({ view: "signup" });
    }

    if (view === "code" && code) {
      this.setState({ signupCode: code }, () => {
        this.handle__checkCode();
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.view__showErrorMessages()}
        {this.view__showContent()}
      </React.Fragment>
    );
  }

  view__showContent() {
    const { view, isLoading } = this.state;

    // Show Spinner If Login Form is checking Login Credentials
    if (isLoading) {
      return this.view__showLoading();
    }

    if (view === "code") {
      return this.view__showCodeForm();
    } else if (view === "password") {
      return this.view__showPassword();
    } else if (view === "finished") {
      return this.view__showFinished();
    } else {
      return this.view__showSignupForm();
    }
  }

  view__showErrorMessages() {
    const {
      showErrorNotEmail,
      showErrorEmpty,
      showErrorCodeNotFound,
      showErrorCodeExpired,
      showErrorPasswordsNotEqual,
      showErrorPasswordAlreadySet,
    } = this.state;
    let errorNotEmail,
      errorEmpty,
      errorCodeNotFound,
      errorCodeExpired,
      errorPasswordNotEqual,
      errorPasswordAlreadySet;

    if (showErrorNotEmail === true) {
      errorNotEmail = (
        <div className="alert callout">
          Bitte gib eine gültige E-Mail-Adresse ein.
        </div>
      );
    }

    if (showErrorEmpty === true) {
      errorNotEmail = (
        <div className="alert callout">
          Bitte gib Deinen Namen und Deine E-Mail-Adresse an, damit wir Dein
          Benutzerkonto erstellen können.
        </div>
      );
    }

    if (showErrorCodeNotFound === true) {
      errorCodeNotFound = (
        <div className="alert callout">
          Leider konnte der von Dir eingegebene Code nicht gefunden werden.
          Bitte versuche es erneut oder registriere Dich neu.
        </div>
      );
    }

    if (showErrorCodeExpired === true) {
      errorCodeExpired = (
        <div className="alert callout">
          Der von Dir eingegebene Code ist aus Sicherheitsgründen leider
          abgelaufen. Bitte registriere Dich erneut.
        </div>
      );
    }

    if (showErrorPasswordsNotEqual === true) {
      errorPasswordNotEqual = (
        <div className="alert callout">
          Die beiden eingegebenen Passwörter stimmen leider nicht überein. Bitte
          versuche es erneut.
        </div>
      );
    }

    if (showErrorPasswordAlreadySet === true) {
      errorPasswordAlreadySet = (
        <div className="alert callout">
          Der Benutzer existiert nicht oder das Passwort wurde bereits vergeben.
          Falls Du Dein Passwort vergessen haben solltest, nutze die{" "}
          <a href="/login" style={{ textDecoration: "underline" }}>
            Passwort-vergessen-Funktion
          </a>
          .
        </div>
      );
    }

    return (
      <React.Fragment>
        {errorEmpty}
        {errorNotEmail}
        {errorCodeNotFound}
        {errorCodeExpired}
        {errorPasswordNotEqual}
        {errorPasswordAlreadySet}
      </React.Fragment>
    );
  }

  view__showLoading() {
    return (
      <div className="login-form-loading">
        <p>
          <Icon icon="spin fa-circle-o-notch" />
        </p>
        <p>Bitte warten ...</p>
      </div>
    );
  }

  view__showSignupForm() {
    const { type } = this.state;

    if (type === "quiz") {
      return this.view__showSignupFormDefault();
    } else {
      return this.view__showSignupFormDefault();
    }
  }

  view__showSignupFormDefault() {
    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={24}>
              <label>
                Vorname
                <input
                  type="text"
                  placeholder="Dein Vorname"
                  value={this.state.signupFirstname}
                  onChange={this.handle__change("signupFirstname")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.handle__signup();
                    }
                  }}
                />
              </label>
            </Cell>
            {/*<Cell sm={24} md={12}>
              <label>
                Nachname
                <input
                  type="text"
                  placeholder="Dein Nachname"
                  value={this.state.signupLastname}
                  onChange={this.handle__change("signupLastname")}
                  onKeyUp={e => {
                    if (e.keyCode === 13) {
                      this.handle__signup();
                    }
                  }}
                />
              </label>
                </Cell>*/}
          </Row>
          <Row margin="x">
            <Cell sm={24} md={24}>
              <label>
                E-Mail-Adresse:
                <input
                  type="email"
                  placeholder="Deine E-Mail-Adresse"
                  value={this.state.signupEmail}
                  onChange={this.handle__change("signupEmail")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.handle__signup();
                    }
                  }}
                />
              </label>
            </Cell>
          </Row>
        </Grid>

        <div className="login-buttons">
          <button
            className="primary hollow button"
            onClick={() => {
              this.handle__signup();
            }}
          >
            Kostenfrei registrieren <Icon icon="angle-right" right />
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showCodeForm() {
    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>
              <div className="signup-form-step">
                <p className="text-center">
                  <strong>Schritt 2 / 3</strong>
                </p>
                <p>
                  Vielen Dank für Deine Anmeldung! Innerhalb der nächsten
                  Minuten erhältst Du eine E-Mail mit einem Bestätigungscode.
                  Gib diesen Bestätigungscode einfach in das unten stehende
                  Formular ein, um ein Passwort festzulegen.
                </p>
              </div>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={12}>
              <label>
                <strong>Bestätigungscode:</strong>
              </label>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={18}>
              <input
                type="text"
                placeholder="Dein Bestätigungscode"
                value={this.state.signupCode}
                onChange={this.handle__change("signupCode")}
                onKeyUp={(e) => {
                  if (e.keyCode === 13) {
                    this.handle__checkCode();
                  }
                }}
              />
            </Cell>
            <Cell sm={24} md={6}>
              <button
                className="primary hollow button"
                onClick={() => {
                  this.handle__checkCode();
                }}
                disabled={!this.state.signupCode}
              >
                Weiter <Icon icon="angle-right" right />
              </button>
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showPassword() {
    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>
              <div className="signup-form-step">
                <p className="text-center">
                  <strong>Schritt 3 / 3</strong>
                </p>
                <p>
                  Vergib nun ein persönliches Passwort. Mit Deiner
                  E-Mail-Adresse und diesem Passwort kannst Du Dich dann
                  jederzeit in die Welt von Beyond einloggen.
                </p>
              </div>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={12}>
              <label>
                <strong>Passwort eingeben:</strong>
                <input
                  type="password"
                  placeholder="Passwort eingeben"
                  value={this.state.signupPassword1}
                  onChange={this.handle__change("signupPassword1")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.handle__password();
                    }
                  }}
                />
              </label>
            </Cell>
            <Cell sm={24} md={12}>
              <label>
                <strong>Passwort wiederholen:</strong>
                <input
                  type="password"
                  placeholder="Passwort wiederholen"
                  value={this.state.signupPassword2}
                  onChange={this.handle__change("signupPassword2")}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.handle__password();
                    }
                  }}
                />
              </label>
            </Cell>
          </Row>
        </Grid>

        <div className="login-buttons">
          <button
            className="primary hollow button"
            onClick={() => {
              this.handle__password();
            }}
            disabled={
              !this.state.signupPassword1 || !this.state.signupPassword2
            }
          >
            <Icon icon="check" left /> Passwort festlegen
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showFinished() {
    const { type } = this.state;

    if (type === "quiz1") {
      return (
        <div className="quiz-signup-finished">
          <div className="success callout">
            <div>
              <strong>Herzlich willkommen in der Welt von Beyond!</strong>
            </div>
            <p>
              Ab sofort kannst Du Dich mit Deiner E-Mail-Adresse und Deinem
              Passwort jederzeit einloggen.
            </p>
            <div className="text-center">
              <a
                href="/quiz/magisches-objekt"
                className="primary hollow button"
              >
                Zum nächsten Quiz: Finde Dein magisches Objekt
              </a>
            </div>
          </div>
        </div>
      );
    } else if (type === "quiz2") {
      return (
        <div className="quiz-signup-finished">
          <div className="success callout">
            <div>
              <strong>Herzlich willkommen in der Welt von Beyond!</strong>
            </div>
            <p>
              Ab sofort kannst Du Dich mit Deiner E-Mail-Adresse und Deinem
              Passwort jederzeit einloggen.
            </p>
            <div className="text-center">
              <a href="/videoserie" className="primary hollow button">
                Zur kostenfreien Mini-Videoserie
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="quiz-signup-finished">
          <div className="success callout">
            <div>
              <strong>Herzlich willkommen in der Welt von Beyond!</strong>
            </div>
            <p>
              Ab sofort kannst Du Dich mit Deiner E-Mail-Adresse und Deinem
              Passwort jederzeit einloggen.
            </p>
            <div className="text-center">
              <a href="/login" className="primary hollow button">
                Zur Anmeldung
              </a>
            </div>
          </div>
        </div>
      );
    }
  }

  resetErrorMessages() {
    this.setState({
      showErrorNotEmail: false,
      showErrorEmpty: false,
      showErrorCodeExpired: false,
      showErrorCodeNotFound: false,
      showErrorPasswordsNotEqual: false,
      showErrorPasswordAlreadySet: false,
    });
  }

  handle__change = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handle__signup() {
    const { signupEmail, signupFirstname } = this.state;

    // Reset Error Messages and show Spinner
    this.resetErrorMessages();
    this.setState({ isLoading: true });

    if (!signupEmail || !signupFirstname) {
      this.setState({ showErrorEmpty: true, isLoading: false });
      return;
    }

    if (!validator.validate(signupEmail)) {
      this.setState({ showErrorNotEmail: true, isLoading: false });
      return;
    }

    this.setState({
      isLoading: true,
    });

    // Get Auth Information for API
    const _c = this.props.context;

    let tmpUserId = _c.getTmpUserId();

    //console.log("HISTORY", this.props.history);

    // Signup
    axios
      .post(_c.apiEndpoints.signup, {
        email: signupEmail,
        firstname: signupFirstname,
        tmp_user_id: tmpUserId,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log(response.data);
        }

        const statusCode = response.status;
        if (statusCode === 200) {
          const status = response.data.status;

          if (status.status === "ACCOUNT_EXISTS") {
            //this.props.onUpdateHistory("/login?email=" + signupEmail);
            window.location.href = "/login?email=" + signupEmail;
          } else if (status.status === "SUCCESS") {
            this.setState({ isLoading: false, view: "code" });
          }
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }

  handle__checkCode = () => {
    const { signupCode } = this.state;
    const _c = this.props.context;

    let tmpUserId = _c.getTmpUserId();

    this.setState({ isLoading: true });
    // Reset Error Messages and show Spinner
    this.resetErrorMessages();

    axios
      .post(_c.apiEndpoints.signup + "/code", {
        code: signupCode,
        tmp_user_id: tmpUserId,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log(response.data);
        }

        const statusCode = response.status;
        if (statusCode === 200) {
          const { status } = response.data;
          const { user } = status;

          if (status.status === "EXPIRED") {
            this.setState({
              isLoading: false,
              showErrorCodeExpired: true,
              view: "code",
            });
          } else if (status.status === "NOTFOUND") {
            this.setState({
              isLoading: false,
              showErrorCodeNotFound: true,
              view: "code",
            });
          } else if (status.status === "SUCCESS") {
            this.resetErrorMessages();
            this.setState({
              isLoading: false,
              view: "password",
              userId: user.id,
            });
          }
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  handle__password = () => {
    const { signupPassword1, signupPassword2, userId, type } = this.state;
    const _c = this.props.context;

    // Reset Error Messages and show Spinner
    this.resetErrorMessages();
    this.setState({ isLoading: true });

    if (!signupPassword1 && !signupPassword2) {
      return null;
    }

    if (signupPassword1 !== signupPassword2) {
      this.setState({ showErrorPasswordsNotEqual: true, isLoading: false });
      return null;
    }

    axios
      .post(_c.apiEndpoints.signup + "/password", {
        password: signupPassword1,
        user: userId,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log(response.data);
        }

        const statusCode = response.status;
        if (statusCode === 200) {
          const { status } = response.data;
          const { user } = status;

          if (status.status === "SUCCESS") {
            this.resetErrorMessages();
            this.setState({ isLoading: false, view: "finished", user: user });
            this.login(user.email, signupPassword1);
            _c.updateUser(user);
            if (type === "quiz1" || type === "quiz2") {
              this.props.onUpdate("finished");
            }
          } else if (status.status === "NOTFOUND") {
            this.resetErrorMessages();
            this.setState({
              isLoading: false,
              view: "password",
              showErrorPasswordAlreadySet: true,
              user: user,
            });
          }
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  login(userEmail, userPassword) {
    const _c = this.props.context;
    const { auth } = _c;

    // Try Login
    axios
      .post(_c.apiEndpoints.login, {
        username: userEmail,
        password: userPassword,
        grant_type: auth.grant_type,
        client_id: auth.client_id,
        client_secret: auth.client_secret,
        scope: auth.scope,
      })
      .then((response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          if (response.data) {
            const accessToken = response.data.access_token;
            localStorage.setItem("access_token", accessToken);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  }
}

export default withContext(SignupForm);
