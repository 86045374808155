import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { Icon } from "../../../foundation/_buttons";

class ReplyForm extends Component {
  state = {
    _updatableProps: [
      "type",
      "view",
      "thread",
      "post",
      "replyForm",
      "editorState",
    ],
    type: this.props.type,
    thread: this.props.thread,
    replyForm: this.props.replyForm || null,
    editorState: this.props.editorState,
    post: this.props.post || null,
  };

  componentDidMount() {
    let { post, thread } = this.state;
    const _c = this.props.context;

    if (!post || post === "new") {
      post = _c.createEmptyPost(thread);
    }

    let editorState = EditorState.createEmpty();

    if (post.text) {
      editorState = EditorState.createWithContent(convertFromRaw(post.text));
    }

    this.setState({ post, editorState });
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;
    let { editorState } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });

        if (propKey === "post") {
          let editorState = EditorState.createEmpty();

          if (this.props[propKey] && this.props[propKey].text) {
            editorState = EditorState.createWithContent(
              convertFromRaw(this.props[propKey].text)
            );
            this.setState({ editorState });
          } else if (this.props[propKey] && this.props[propKey].text_html) {
            const blocksFromHtml = convertFromHTML(
              this.props[propKey].text_html
            );
            editorState = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
              )
            );
            this.setState({ editorState });
          }
        } else if (propKey === "replyForm") {
          let editorHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );
          editorHtml = `${editorHtml} ${this.props[propKey]}`;
          const blocksFromHtml = convertFromHTML(editorHtml);
          editorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              blocksFromHtml.contentBlocks,
              blocksFromHtml.entityMap
            )
          );
          this.setState({ editorState });
        }
      }
      return null;
    });
  }

  render() {
    const { editorState, type } = this.state;
    const _c = this.props.context;

    let v__buttons;
    if (type === "newThread") {
      v__buttons = (
        <button className="primary button" onClick={this.handle__saveThread}>
          <Icon icon="check" />{" "}
          {_c.translate("community.newThread.form.buttons.create.label")}
        </button>
      );
    } else if (type === "editPost") {
      v__buttons = (
        <button className="primary button" onClick={this.handle__savePost}>
          <Icon icon="check" />{" "}
          {_c.translate("community.post.editPopup.button.label")}
        </button>
      );
    } else {
      v__buttons = (
        <button className="primary button" onClick={this.handle__savePost}>
          <Icon icon="check" /> {_c.translate("community.reply.replyButton")}
        </button>
      );
    }

    return (
      <div className="community-reply-form">
        <Editor
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={this.onEditorStateChange}
          localization={{
            locale: "de",
          }}
          toolbar={{
            options: _c.custom("community.replyFormOptions"),
            inline: _c.custom("community.replyInlineOptions"),
            blockType: _c.custom("community.replyBlockTypeOptions"),
          }}
        />
        <div className="reply-form-button">{v__buttons}</div>
      </div>
    );
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      const post = this.updatePost();
      if (this.props.onChange) {
        this.props.onChange(post);
      }
    });
  };

  updatePost = () => {
    const { post, editorState } = this.state;

    if (editorState) {
      post.text = convertToRaw(editorState.getCurrentContent());
      post.text_html = draftToHtml(post.text);
    }

    return post;
  };

  handle__saveThread = () => {
    if (this.props.onSaveThread) {
      this.props.onSaveThread();
    }
  };

  handle__savePost = () => {
    let { post } = this.state;
    const _c = this.props.context;

    post = this.updatePost();

    let apiUrl = _c.apiUrl("communityPost", {
      postId: post.id,
    });

    axios
      .post(
        apiUrl,
        {
          post: post,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const { thread, post } = response.data.data;

            if (thread) {
              this.onSavePost(thread, post);
            } else {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "community:post:save:empty"
              );
            }
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:post:save:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:post:save");
      });
  };

  onSavePost = (thread, post) => {
    const _c = this.props.context;
    this.setState({
      thread,
      editorState: EditorState.createEmpty(),
    });

    window.setTimeout(() => {
      //console.log("post:", post);
      if (document.getElementById(`post_${post.slug}`)) {
        const postElement = document.getElementById(`post_${post.slug}`);
        const rect = postElement.getBoundingClientRect();
        //console.log("rect", rect);
        if (rect.top) {
          //console.log(parseInt(rect.top + window.scrollY));
          window.scrollTo(0, parseInt(rect.top + window.scrollY));
        }
      }
    }, 100);

    this.props.onSavePost(thread);
    post = _c.createEmptyPost(thread);
    this.setState({ post });
  };
}

export default withContext(ReplyForm);
