import React, { Component } from "react";
import closeButton from "./close-button.png";
//import closeButton from "../../images/close-narrow-white.png";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { isUndefined } from "util";

/**
 * show = true / false
 * type = small / medium / large / fullscreen [DEFAULT: medium]
 * nopadding
 * modalClose = true / false [DEFAULT: true]
 * showCloseButton = true / false [DEFAULT: true]
 */
class MMPopup extends Component {
  state = {};
  render() {
    return (
      <div style={this.isPopupVisible()}>
        <KeyboardEventHandler
          handleKeys={["all"]}
          onKeyEvent={(key, event) => {
            //console.log(key);
            if (key === "esc") {
              if (
                (this.props.modalClose && this.props.modalClose === true) ||
                isUndefined(this.props.modalClose)
              ) {
                this.closePopup();
              }
            }
          }}
          handleFocusableElements={true}
        />
        <div
          className="mmpopup-modal"
          onClick={() => {
            if (
              (this.props.modalClose && this.props.modalClose === true) ||
              isUndefined(this.props.modalClose)
            ) {
              this.closePopup();
            }
          }}
        />
        <div className={this.getClasses()}>
          {this.showCloseButton()}
          {this.props.children}
        </div>
      </div>
    );
  }

  isPopupVisible() {
    if (this.props.show === true) {
      return {
        display: "block",
      };
    } else {
      return {
        display: "none",
      };
    }
  }

  getClasses() {
    let classes = "mmpopup-popup";

    if (this.props.nopadding) {
      classes += " nopadding";
    }

    if (this.props.size === "small") {
      classes += " mmpopup-small";
    } else if (this.props.size === "large") {
      classes += " mmpopup-large";
    } else if (this.props.size === "fullscreen") {
      classes += " mmpopup-fullscreen";
    } else {
      classes += " mmpopup-medium";
    }

    return classes;
  }

  showCloseButton() {
    if (
      (this.props.showCloseButton && this.props.showCloseButton === true) ||
      isUndefined(this.props.showCloseButton)
    ) {
      return (
        <button
          className="mmpopup-close-button"
          onClick={() => {
            this.closePopup();
          }}
        >
          <img src={closeButton} alt="[Close]" />
        </button>
      );
    }
  }

  closePopup() {
    this.props.handleClose();
  }
}

export default MMPopup;
