import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import DefaultLayout from "../../layout/DefaultLayout";
import { Cell, Grid, Row } from "../../foundation/_grid";

import FrameImmenseSoul from "../../../images/achievements/frame-immense-soul.png";
import FrameImmenseSoulNot from "../../../images/achievements/frame-immense-soul-not.png";
import PlateImmenseSoul from "../../../images/achievements/plate-immense-soul.png";
import PlateImmenseSoulNot from "../../../images/achievements/plate-immense-soul-not.png";

import FrameSecretWisdom from "../../../images/achievements/frame-secret-wisdom.png";
import FrameSecretWisdomNot from "../../../images/achievements/frame-secret-wisdom-not.png";
import PlateSecretWisdom from "../../../images/achievements/plate-secret-wisdom.png";
import PlateSecretWisdomNot from "../../../images/achievements/plate-secret-wisdom-not.png";

import FrameRelationships from "../../../images/achievements/frame-relationships.png";
import FrameRelationshipsNot from "../../../images/achievements/frame-relationships-not.png";
import PlateRelationships from "../../../images/achievements/plate-relationships.png";
import PlateRelationshipsNot from "../../../images/achievements/plate-relationships-not.png";

import FrameCrossroads from "../../../images/achievements/frame-crossroads.png";
import FrameCrossroadsNot from "../../../images/achievements/frame-crossroads-not.png";
import PlateCrossroads from "../../../images/achievements/plate-crossroads.png";
import PlateCrossroadsNot from "../../../images/achievements/plate-crossroads-not.png";

class AchievementRoom extends Component {
  state = {
    achievements: null,
    frames: [
      {
        id: "immense-soul",
        image: FrameImmenseSoul,
        imageNot: FrameImmenseSoulNot,
        plate: PlateImmenseSoul,
        plateNot: PlateImmenseSoulNot,
        title: "Immense Soul",
        achievedKey: "immensesoul",
      },
      {
        id: "secret-wisdom",
        image: FrameSecretWisdom,
        imageNot: FrameSecretWisdomNot,
        plate: PlateSecretWisdom,
        plateNot: PlateSecretWisdomNot,
        title: "Secret Wisdom",
        achievedKey: "secretwisdom",
      },
      {
        id: "relationships",
        image: FrameRelationships,
        imageNot: FrameRelationshipsNot,
        plate: PlateRelationships,
        plateNot: PlateRelationshipsNot,
        title: "Secret Wisdom: Beziehungsanalysen",
        achievedKey: "relationships",
      },
      {
        id: "wegkreuzungen",
        image: FrameCrossroads,
        imageNot: FrameCrossroadsNot,
        plate: PlateCrossroads,
        plateNot: PlateCrossroadsNot,
        title: "Wegkreuzungen",
        achievedKey: "wegkreuzungen",
      },
    ],
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const _c = this.props.context;
    if (!_c.user) {
      return null;
    }

    return (
      <DefaultLayout
        breadcrumbs={["home", "achievements"]}
        page="account page-achievements"
        background="achievements"
      >
        <Grid>
          <Row>
            <Cell sm={24} md={18}>
              <h1>Mein Achievement Room</h1>
              <p className="lead">
                In Deinem persönlichen Achievement Room findest Du all Deine
                Erfolge, die Du bisher in der Welt von Beyond gesammelt hast.
              </p>
              <div>&nbsp;</div>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              {this.view__showFrames()}
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }

  view__showFrames() {
    const { frames } = this.state;
    const { achievements } = this.state;

    if (!achievements) {
      return;
    }

    return (
      <div className="achievements-frames">
        <Grid type="full">
          <Row margin="xy">
            {frames.map((frame, index) => {
              return (
                <Cell sm={24} md={8} key={index}>
                  {this.view__showFrame(frame)}
                </Cell>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }

  view__showFrame(frame) {
    const { achievements } = this.state;
    let classes = "achievement";

    let v__frame, v__plate;

    if (
      achievements[frame.achievedKey] &&
      achievements[frame.achievedKey] === true
    ) {
      v__frame = <img src={frame.image} alt={frame.title} />;
      v__plate = <img src={frame.plate} alt={frame.title} />;
    } else {
      v__frame = <img src={frame.imageNot} alt={frame.title} />;
      v__plate = <img src={frame.plateNot} alt={frame.title} />;
      classes = `not-achieved ${classes}`;
    }

    return (
      <div className={classes}>
        <div className="achievement-frame">{v__frame}</div>
        <div className="achievement-plate">{v__plate}</div>
      </div>
    );
  }

  init() {
    const _c = this.props.context;
    _c.setPageTitle("Mein Achievement Room");
    _c.login(() => {
      if (!_c.isUserLoggedIn()) {
        this.props.history.push("/login");
      }
    });

    axios
      .get(_c.apiEndpoints.achievements, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          _c.handleApiResponse(response.data, true);

          if (_c.isDebug()) {
            console.log(response.data);
          }

          const { achievements } = response.data.data;
          this.setState({ achievements });

          _c.initFinished();
        } catch {
          alert(
            "Achievement Room - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AchievementRoom);
