import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import { isValid } from "date-fns";
import { Icon } from "../foundation/_buttons";

registerLocale("de", de);

class AdminDataField extends Component {
  state = {
    value: this.props.value, // The value to be displayed in View Mode
    editValue: this.props.editValue, // The value to be displayed in Edit Mode
    label: this.props.label, // The Label underneath the Value
    selectValues: this.props.selectValues, // If editType === "select" -> these are the options vor the Select
    editable: this.props.editable, // Is this Field editable?
    edit: this.props.edit, // EditMode: true / false
    editType: this.props.editType || "text", // select, date, text
    placeholder: this.props.placeholder || "",
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.value !== prevProps.value ||
      this.props.editValue !== prevProps.editValue ||
      this.props.edit !== prevProps.edit ||
      this.props.label !== prevProps.label ||
      this.props.editType !== prevProps.editType
    ) {
      this.setState({
        value: this.props.value,
        editValue: this.props.editValue,
        edit: this.props.edit,
        editType: this.props.editType,
        label: this.props.label,
      });
    }
  }

  render() {
    const { value, editType, label, editValue } = this.state;

    if (editType === "singleCheckbox") {
      let v__view;
      if (editValue === true) {
        v__view = (
          <label>
            <Icon icon="check-square-o" left /> {label}
          </label>
        );
      } else {
        v__view = (
          <label>
            <Icon icon="square-o" left /> {label}
          </label>
        );
      }

      return (
        <React.Fragment>
          <div style={this.view("view")}>{v__view}</div>
          <div style={this.view("edit")}>{this.view__showEditForm()}</div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div>
            <div className="admin-data-value" style={this.view("view")}>
              {value || <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />}
            </div>
            <div className="admin-data-edit-value" style={this.view("edit")}>
              {this.view__showEditForm()}
            </div>
            <div className="admin-data-label">{label}</div>
          </div>
        </React.Fragment>
      );
    }
  }

  view__showEditForm() {
    const { editType, selectValues, placeholder, label } = this.state;

    let { editValue } = this.state;

    if (editType === "select") {
      return (
        <select
          value={editValue}
          onChange={(e) => {
            this.handle__edit(e.target.value);
          }}
        >
          {Object.keys(selectValues).map((key, index) => {
            let optionValue = key;
            if (parseInt(key) === parseInt(index)) {
              optionValue = selectValues[key];
            }
            return (
              <option key={index} value={optionValue}>
                {selectValues[key]}
              </option>
            );
          })}
        </select>
      );
    } else if (editType === "date") {
      let editDate;
      if (editValue) {
        editDate = new Date(editValue);
        if (!isValid(editDate)) {
          editDate = null;
        }
      } else {
        editDate = null;
      }

      return (
        <DatePicker
          selected={editDate || null}
          onChange={(value) => {
            this.handle__editDate(value);
          }}
          dateFormat="dd.MM.yyyy"
          locale="de"
          showMonthDropdown
          showYearDropdown
          showWeekNumbers
          dropdownMode="select"
        />
      );
    } else if (editType === "datetime") {
      let editDate;
      if (editValue) {
        editDate = new Date(editValue);
        if (!isValid(editDate)) {
          editDate = null;
        }
      } else {
        editDate = null;
      }

      return (
        <DatePicker
          selected={editDate || null}
          onChange={(value) => {
            this.handle__editDate(value);
          }}
          dateFormat="dd.MM.yyyy - HH:mm"
          locale="de"
          showTimeSelect
          timeIntervals={5}
          timeFormat="HH:mm"
          timeCaption="Uhrzeit"
          showMonthDropdown
          showYearDropdown
          showWeekNumbers
          dropdownMode="select"
        />
      );
    } else if (editType === "textarea") {
      return (
        <textarea
          rows={4}
          value={editValue || ""}
          onChange={(e) => {
            this.handle__edit(e.target.value);
          }}
          placeholder={placeholder}
        />
      );
    } else if (editType === "singleCheckbox") {
      return (
        <label>
          <input
            type="checkbox"
            checked={editValue}
            onChange={(e) => {
              if (editValue === true) {
                editValue = false;
              } else {
                editValue = true;
              }
              this.props.onUpdate(editValue);
            }}
          />{" "}
          {label}
        </label>
      );
    } else {
      return (
        <input
          type="text"
          value={editValue || ""}
          onChange={(e) => {
            this.handle__edit(e.target.value);
          }}
          placeholder={placeholder}
        />
      );
    }
  }

  view(mode) {
    const { edit, editable } = this.state;

    if (
      (editable === true && mode === "edit" && edit === true) ||
      (mode === "view" && edit === false) ||
      (editable === false && mode === "view" && edit === true)
    ) {
      return { display: "block" };
    } else {
      return { display: "none" };
    }
  }

  handle__edit(value) {
    this.setState({ editValue: value, value });
    this.props.onUpdate(value);
  }

  handle__editDate(value) {
    this.setState({ editValue: value });
    this.props.onUpdate(value);
  }
}

export default AdminDataField;
