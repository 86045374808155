import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell } from "../foundation/foundation";
import axios from "axios";
import withContext from "../../context/contextHOC";
import LogoData from "../../images/logos/gold-with-text.png";
import Box from "../layout/Box";
import SignupForm from "./SignupForm";

class Signup extends Component {
  state = {
    view: this.props.match.params.view,
    code: this.props.match.params.code
  };

  componentDidMount() {
    const _c = this.props.context;

    const { view } = this.state;
    if (!view) {
      this.setState({ view: "signup" });
    }

    // If Access Token is saved, try to catch user info
    // -> if User is logged in, redirect to Page after Login
    // (So the Login Page won't be shown for people already loggedin)
    if (_c.hasAccessToken()) {
      axios
        .get(this.props.context.apiEndpoints.user, {
          headers: _c.getHeaders()
        })
        .then(response => {
          try {
            const { user } = response.data;

            if (_c.isDebug()) {
              console.log(response.data);
            }

            if (user && user.active && user.active === true) {
              _c.redirectAfterLogin();
            }
          } catch {}
        })
        .catch(error => {});
    }

    _c.initFinished();
  }

  render() {
    const { view, code } = this.state;

    return (
      <div className="page page-signup">
        <main className="content">
          <Grid>
            <Row>
              <Cell sm={24} md={12} mdo={6}>
                <div className="login-logo">
                  <Link to="/">
                    <img src={LogoData} alt="Logo" />
                  </Link>
                </div>
                <h1 className="text-center">Herzlich Willkommen!</h1>
                <p className="lead text-center">
                  Wir freuen uns sehr, dass Du Teil der Welt von Beyond
                  <br />
                  werden möchtest.
                </p>

                <p>
                  Nachfolgend kannst Du Dir ein neues, kostenfreies
                  Benutzerkonto anlegen. Solltest Du{" "}
                  <strong>bereits ein Benutzerkonto haben</strong>,{" "}
                  <Link to="/login" style={{ textDecoration: "underline" }}>
                    kannst Du Dich hier ganz einfach mit E-Mail-Adresse und
                    Passwort anmelden
                  </Link>
                  .
                </p>
                <Box>
                  <SignupForm type="signup" view={view} code={code} />
                </Box>
              </Cell>
            </Row>
          </Grid>
        </main>
      </div>
    );
  }
}

export default withContext(Signup);
