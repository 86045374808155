import React, { Component } from "react";
import { isUndefined } from "util";
import { Icon } from "../foundation/_buttons";

class PTTabs extends Component {
  state = {
    tabs: this.props.tabs,
    selectedTab: this.props.selected
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tabs !== this.props.tabs) {
      this.setState({ tabs: this.props.tabs });
    }

    if (prevProps.selected !== this.props.selected) {
      this.setState({ selectedTab: this.props.selected });
    }
  }

  render() {
    const { tabs } = this.state;
    return (
      <div className="pttabs">
        <div className="pttabs-inner">
          {Object.keys(tabs).map((keyName, index) => {
            return (
              <button
                className={this.isSelectedClasses(keyName)}
                key={index}
                onClick={() => {
                  this.select(keyName);
                }}
              >
                {this.icon(tabs[keyName])}
                {tabs[keyName].name}
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  isSelectedClasses(tabKey) {
    const { selectedTab } = this.state;

    if (selectedTab === tabKey) {
      return "pttab selected";
    } else {
      return "pttab";
    }
  }

  icon(tab) {
    if (!isUndefined(tab.icon) && tab.icon) {
      return (
        <span>
          <Icon icon={tab.icon} left />
          &nbsp;
        </span>
      );
    } else {
      return null;
    }
  }

  select = selectedKeyName => {
    this.props.onUpdate(selectedKeyName);
  };
}

export default PTTabs;
