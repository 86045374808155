import React, { Component } from "react";
import { Icon } from "../../foundation/_buttons";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";
import { Grid, Row, Cell } from "../../foundation/_grid";
import AdminDataField from "../AdminDataField";
import { cloneDeep } from "lodash";
import axios from "axios";
import Switch from "react-switch";
import PTPopup from "../../ptPopup/PTPopup";

class AdminMemberPurchases extends Component {
  state = {
    _updatableProps: ["member", "purchases", "courses", "newPurchase"],
    member: this.props.member,
    purchases: this.props.purchases,
    courses: this.props.courses,
    newPurchase: this.props.newPurchase,
    selectedPurchase: null,
    changesWereMade: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { member, purchases, newPurchase } = this.state;
    const _c = this.props.context;

    if (!member || !purchases) {
      return _c.loading();
    }

    return (
      <div className="admin-purchases">
        <div className="admin-purchases-header">
          <button
            className="small primary button"
            onClick={() => {
              this.setState({ selectedPurchase: cloneDeep(newPurchase) });
            }}
          >
            <Icon icon="plus" left /> Kauf hinzufügen
          </button>
        </div>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showList()}
          {this.view__showPopups()}
        </Box>
      </div>
    );
  }

  view__showPopups() {
    const { selectedPurchase } = this.state;

    if (selectedPurchase) {
      return (
        <PTPopup show={true} size="medium" handleClose={this.handle__cancel}>
          {this.view__showPopupEdit()}
        </PTPopup>
      );
    }
  }

  view__showPopupEdit() {
    const { selectedPurchase, courses } = this.state;
    const _c = this.props.context;

    let v__title;
    if (selectedPurchase.id === "new") {
      v__title = "Kauf hinzufügen";
    } else {
      v__title = "Kauf bearbeiten";
    }

    let selectValuesCourses = {};
    Object.keys(courses).map((courseId) => {
      selectValuesCourses[courseId] = courses[courseId].name;
      return null;
    });

    let selectValuesTypes = {};
    const purchaseTypes = _c.getPurchaseTypes();
    Object.keys(purchaseTypes).map((purchaseTypeKey) => {
      selectValuesTypes[purchaseTypeKey] = purchaseTypes[purchaseTypeKey].title;
      return null;
    });

    return (
      <React.Fragment>
        <h3>{v__title}</h3>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.course_id}
                editValue={selectedPurchase.course_id}
                editType="select"
                selectValues={selectValuesCourses}
                label="Kurs"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("course_id", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.type}
                editValue={selectedPurchase.type}
                editType="select"
                selectValues={selectValuesTypes}
                label="Typ"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("type", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.purchase_date_f}
                editValue={selectedPurchase.purchase_date || null}
                editType="datetime"
                label="Kaufdatum"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("purchase_date", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.voucher_code}
                editValue={selectedPurchase.voucher_code}
                editType="text"
                label="Voucher Code"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("voucher_code", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <div className="page-buttons">
                <button
                  className="small primary hollow button"
                  data-tip="Abbrechen und zurück zur Übersicht"
                  onClick={this.handle__cancel}
                >
                  <Icon icon="times" left /> Abbrechen
                </button>
                <button
                  className="small primary button"
                  data-tip="Änderungen speichern"
                  onClick={this.handle__save}
                >
                  <Icon icon="check" left /> Speichern
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={2}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={22}>
              <Grid type="full">
                <Row>
                  <Cell md={10}>
                    <div className="padding">Kurs</div>
                  </Cell>
                  <Cell md={4} className="text-center">
                    <div className="padding">Typ</div>
                  </Cell>
                  <Cell md={6} className="text-center">
                    <div className="padding">Kaufdatum</div>
                  </Cell>
                  <Cell md={4} className="text-center">
                    <div className="padding">Voucher Code</div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showList() {
    const { purchases, courses } = this.state;
    const _c = this.props.context;

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {purchases.map((purchase, index) => {
            let courseName = "?";
            if (
              courses &&
              courses[purchase.course_id] &&
              courses[purchase.course_id].name
            ) {
              courseName = courses[purchase.course_id].name;
            }

            let classActive = "purchase-inactive";
            if (purchase.active) {
              classActive = "purchase-active";
            }

            return (
              <li className="admin-list-item" key={index}>
                <Grid type="full">
                  <Row>
                    <Cell md={2} className="text-center">
                      <div className="wrapper">
                        <div
                          className="center-vertically_"
                          style={{ paddingTop: "15px" }}
                        >
                          <Switch
                            height={20}
                            width={40}
                            onChange={() => {
                              this.handle__toggleActive(purchase.id);
                            }}
                            checked={purchase.active}
                          />
                        </div>
                      </div>
                    </Cell>
                    <Cell md={22}>
                      <button
                        className={`clickable ${classActive}`}
                        onClick={() => {
                          this.setState({
                            selectedPurchase: cloneDeep(purchase),
                          });
                        }}
                      >
                        <Grid type="full">
                          <Row>
                            <Cell md={10} className="text-text-left">
                              <div className="wrapper">
                                <div className="center-vertically">
                                  <div className="padding">{courseName}</div>
                                </div>
                              </div>
                            </Cell>
                            <Cell md={4} className="text-center">
                              <div className="wrapper">
                                <div className="center-vertically">
                                  <div className="padding">
                                    {_c.getPurchaseType(purchase.type)}
                                  </div>
                                </div>
                              </div>
                            </Cell>
                            <Cell md={6} className="text-center">
                              <div className="padding">
                                {purchase.purchase_date_f}
                              </div>
                            </Cell>
                            <Cell md={4} className="text-center">
                              <div className="wrapper">
                                <div className="center-vertically">
                                  <div className="padding">
                                    {purchase.voucher_code}
                                  </div>
                                </div>
                              </div>
                            </Cell>
                          </Row>
                        </Grid>
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  handle__toggleActive = (purchaseId) => {
    const _c = this.props.context;
    const { member } = this.state;

    const apiUrl = _c.apiUrl("adminPurchaseToggleActive", {
      memberId: member.id,
      purchaseId: purchaseId,
    });

    axios
      .patch(
        apiUrl,
        {},
        {
          headers: _c.headers,
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { purchases } = apiResponseData;

            this.setState({
              purchases,
            });
          }
        } catch {}
      })
      .catch((error) => {});
  };

  handle__edit(property, value) {
    const { selectedPurchase } = this.state;
    selectedPurchase[property] = value;
    this.setState({ selectedPurchase, changesWereMade: true });
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Es wurden bereits Änderungen an diesem Eintrag vorgenommen.\r\n\r\nMöchtest Du wirklich abbrechen?\r\nAlle Änderungen gehen dann verloren."
        )
      ) {
        this.setState({ changesWereMade: false, selectedPurchase: null });
        _c.cancelEditLock();
      } else {
        return false;
      }
    } else {
      this.setState({ changesWereMade: false, selectedPurchase: null });
      _c.cancelEditLock();
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { selectedPurchase, member } = this.state;

    if (!selectedPurchase.purchase_date) {
      window.alert("Bitte gib das Kaufdatum an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminPurchase", {
        memberId: member.id,
        purchaseId: selectedPurchase.id,
      });

      // Prepare Purchase Date
      if (selectedPurchase.purchase_date) {
        selectedPurchase.purchase_date = _c.formatDatetimeForSaving(
          selectedPurchase.purchase_date
        );
      }

      axios
        .post(
          apiUrl,
          {
            purchase: selectedPurchase,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { member, purchases } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedPurchase: null,
                  member,
                  purchases,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    "Kauf gespeichert",
                    "Der Kauf wurde erfolgreich gespeichert",
                    "success"
                  );
                }
              );
            }
          } catch {}
        })
        .catch((error) => {});
    }, 200);
  };
}

export default withContext(AdminMemberPurchases);
