import React, { Component } from "react";
import MainTopbar from "../topbars/mainTopbar";
import { Grid, Row, Cell } from "../foundation/_grid";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import { Icon } from "../foundation/_buttons";

import logoGoldSlogan from "../../images/logos/gold-with-slogan.png";
import withContext from "../../context/contextHOC";

class GuestIndex extends Component {
  state = {
    videos: this.props.videos,
    allowYoutubeVideos: false,
    allowYoutubeVideosKey: "allow_youtube_videos",
  };

  componentDidMount() {
    const { allowYoutubeVideosKey } = this.state;
    let allowYoutubeVideos = localStorage.getItem(allowYoutubeVideosKey);
    if (
      allowYoutubeVideos === true ||
      allowYoutubeVideos === 1 ||
      allowYoutubeVideos === "1" ||
      allowYoutubeVideos === "yes"
    ) {
      this.setState({ allowYoutubeVideos: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.videos !== prevProps.videos) {
      this.setState({ videos: this.props.videos });
    }
  }

  render() {
    return (
      <div className="page-index">
        <MainTopbar type="index" />
        <main className="content">
          {this.view__showHero()}
          {this.view__showHistory()}
          {this.view__showMainLinks()}
          {/*this.view__showYoutubeVideos()*/}
        </main>
        <Footer />
      </div>
    );
  }

  view__showHero() {
    const _c = this.props.context;
    const { app } = _c;

    if (!app || !app.settings) {
      return null;
    }

    let styles = {};
    const bg = _c.setting("design_bg_index_hero");
    if (bg) {
      styles.backgroundImage = `url("${bg}")`;
    }

    return (
      <section id="indexHero" style={styles}>
        <div className="hero-content">
          <div className="hero-logo">
            <img src={logoGoldSlogan} alt="Welt von Beyond" />
          </div>
          <div className="hero-text">
            <h1>
              <span>Welt von Beyond</span>
            </h1>
            <h2>
              <span>Reise in Deine innere Welt</span>
            </h2>
          </div>
          <div className="index-hero-buttons">
            <Link
              to="/quiz/mystische-kreatur"
              className="large primary hollow button hide-for-small-only"
            >
              Entdecke, welche mystische Kreatur für Deine innere Kraft steht{" "}
              <Icon icon="angle-right" right />
            </Link>
            <Link
              to="/quiz/mystische-kreatur"
              className="primary hollow button show-for-small-only"
            >
              Entdecke, welche mystische Kreatur für Deine innere Kraft steht{" "}
              <Icon icon="angle-right" right />
            </Link>
          </div>
        </div>
      </section>
    );
  }

  view__showHistory() {
    const _c = this.props.context;
    const { app } = _c;

    if (!app || !app.settings) {
      return null;
    }

    let styles = {};
    const bg = _c.setting("design_bg_index_williammorgan");
    if (bg) {
      styles.backgroundImage = `url("${bg}")`;
    }

    return (
      <section id="indexHistory" style={styles}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={4} lg={6}>
              <div className="index-box-wrapper index-box-williammorgan">
                <div className="index-box"></div>
              </div>
            </Cell>
            <Cell sm={24} md={16} lg={12}>
              <div className="index-box-wrapper index-box-history">
                <div className="index-box">
                  {/*<div className="index-pretitle">Entdecke die</div>*/}
                  <h2>Was ist die Welt von Beyond?</h2>
                  <div className="history-text">
                    <p>
                      <strong>
                        Trägst Du in Dir eine innere Welt? Und wenn ja, wo ist
                        ihr Eingang?
                      </strong>
                      <br />
                      Ist die aufregendste Reise die wir antreten können, die
                      Reise in uns selbst? Und wenn ja - ist es möglich, dass
                      wir durch unsere inneren Augen die äußere Welt ganz anders
                      sehen?
                    </p>
                  </div>
                  <div className="index-box-buttons">
                    <a
                      href="https://annevonjahr.com/welt-von-beyond"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="large primary hollow button"
                    >
                      Entdecke die Welt von Beyond{" "}
                      <Icon icon="angle-right" right />
                    </a>
                  </div>
                </div>
              </div>
            </Cell>
            <Cell sm={24} md={4} lg={6}>
              <div className="index-box-wrapper index-box-tischobjekte">
                <div className="index-box"></div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </section>
    );
  }

  view__showMainLinks() {
    const _c = this.props.context;
    const { app } = _c;

    if (!app || !app.settings) {
      return null;
    }

    let styles1 = {};
    const bg1 = _c.setting("design_bg_index_box1");
    if (bg1) {
      styles1.backgroundImage = `url("${bg1}")`;
    }

    let styles2 = {};
    const bg2 = _c.setting("design_bg_index_box2");
    if (bg2) {
      styles2.backgroundImage = `url("${bg2}")`;
    }

    let styles3 = {};
    const bg3 = _c.setting("design_bg_index_box3");
    if (bg3) {
      styles3.backgroundImage = `url("${bg3}")`;
    }

    let styles4 = {};
    const bg4 = _c.setting("design_bg_index_box4");
    if (bg4) {
      styles4.backgroundImage = `url("${bg4}")`;
    }

    return (
      <section id="indexMainLinks">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={8} lg={8}>
              <div
                className="index-box-wrapper index-box-quiz1"
                style={styles1}
              >
                <div className="index-box">
                  <h2>
                    Welche mystische Kreatur
                    <br />
                    beschützt Dich?
                  </h2>
                  <div className="index-box-buttons">
                    <Link
                      to="/quiz/mystische-kreatur"
                      className="primary hollow button"
                    >
                      Zum kostenfreien Quiz <Icon icon="angle-right" right />
                    </Link>
                  </div>
                </div>
              </div>
            </Cell>
            <Cell sm={24} md={8} lg={8}>
              <div
                className="index-box-wrapper index-box-quiz2"
                style={styles2}
              >
                <div className="index-box">
                  <h2>
                    4 kostenfreie
                    <br />
                    Reisen Beyond
                  </h2>
                  <div className="index-box-buttons">
                    <a
                      href="https://annevonjahr.com/kostenfreie-reisen"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary hollow button"
                    >
                      Mehr erfahren <Icon icon="angle-right" right />
                    </a>
                  </div>
                </div>
              </div>
            </Cell>
            {/*<Cell sm={24} md={6} lg={6}>
              <div
                className="index-box-wrapper index-box-freebie"
                style={styles3}
              >
                <div className="index-box">
                  <h2>Der Phönix und die Rückkehr des Magiers</h2>
                  <div className="index-box-buttons">
                    <Link to="/videoserie" className="primary hollow button">
                      Dein kostenloser Start in
                      <br />
                      die Welt von Beyond
                    </Link>
                  </div>
                </div>
              </div>
    </Cell>*/}
            <Cell sm={24} md={8} lg={8}>
              <div
                className="index-box-wrapper index-box-membership"
                style={styles4}
              >
                <div className="index-box">
                  <h2>
                    Welt&nbsp;von&nbsp;Beyond
                    <br />
                    Kurse
                  </h2>
                  <div className="index-box-buttons">
                    <a
                      href="https://annevonjahr.com/kurse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="primary hollow button"
                    >
                      Mehr erfahren
                    </a>
                  </div>
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </section>
    );
  }

  view__showYoutubeVideos() {
    const { videos, allowYoutubeVideos } = this.state;

    if (!videos || videos.length === 0) {
      return null;
    }

    const _c = this.props.context;
    const { app } = _c;

    if (!app || !app.settings) {
      return null;
    }

    let styles = {};
    const bg = _c.setting("design_bg_index_videos");
    if (bg) {
      styles.backgroundImage = `url("${bg}")`;
    }

    return (
      <section id="indexYoutubeVideos" style={styles}>
        <h2>Botschaften aus der Welt von Beyond</h2>
        <Grid>
          <Row margin="xy">
            {videos.map((video, index) => {
              let v__video = (
                <div className="responsive-embed widescreen">
                  <iframe
                    title="Youtube"
                    width="560"
                    height="315"
                    src={video.embed_url}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              );

              if (allowYoutubeVideos === false) {
                let previewTextClasses = `index-youtube-preview-content`;
                if (index !== 1) {
                  previewTextClasses = `hide ${previewTextClasses}`;
                }
                v__video = (
                  <div
                    className="index-youtube-preview-wrapper"
                    style={{ backgroundImage: `url('${video.thumbnail}')` }}
                  >
                    <div className="index-youtube-preview-background">
                      <div className={previewTextClasses}>
                        <div className="index-youtube-preview-text">
                          Mit dem Laden der Videos akzeptierst Du die{" "}
                          <a
                            href="https://policies.google.com/privacy"
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            Datenschutzbestimmungen von Google
                          </a>
                          , da ggfs. Daten (z.B. die IP-Adresse) an Google
                          gesendet werden könnten.
                        </div>
                        <div className="index-youtube-preview-button">
                          <button
                            className="small primary button"
                            onClick={this.handle__allowYoutubeVideos}
                          >
                            <Icon icon="youtube-play" left /> Youtube-Videos
                            zulassen
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <Cell sm={24} md={8} key={index}>
                  <div className="index-youtube-video">{v__video}</div>
                </Cell>
              );
            })}
          </Row>
        </Grid>
      </section>
    );
  }

  handle__allowYoutubeVideos = () => {
    const { allowYoutubeVideosKey } = this.state;

    localStorage.setItem(allowYoutubeVideosKey, "yes");
    this.setState({ allowYoutubeVideos: true });
  };
}

export default withContext(GuestIndex);
