import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import CommunityLayout from "./layouts/CommunityLayout";
import { Grid, Row, Cell } from "../../foundation/_grid";
import { Link } from "react-router-dom";
import { Icon } from "../../foundation/_buttons";

class CommunityArea extends Component {
  state = {
    areaSlug: this.props.match.params.areaSlug,
    areas: null,
    status: "INIT",
    breadcrumbs: ["home", "communityAreas"],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.setPageTitle("Community");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let layout = "layout2";

    let v__content = (
      <div className={`community-${layout}`}>
        <CommunityLayout
          view="area"
          layout={layout}
          breadcrumbs={breadcrumbs}
          user={_c.getUser()}
          help="communityArea"
        >
          <div className="community-header">
            <h1>Welt von Beyond Community</h1>
          </div>
          {this.view__showArea()}
        </CommunityLayout>
      </div>
    );

    return <div className="community-areas">{v__content}</div>;
  }

  view__showArea() {
    const { area, status } = this.state;
    const _c = this.props.context;

    if (!area || status === "INIT" || status === "LOADING") {
      return _c.loading();
    }

    let v__title = <h2>{area.name}</h2>;

    let v__area = (
      <div className="no-banner">
        {v__title}
        {this.view__showBoardCellTitles(area)}
        <div className="community-area-boards">
          {this.view__showBoards(area)}
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="community-areas-area">{v__area}</div>
      </React.Fragment>
    );
  }

  view__showBoardCellTitles(area) {
    return (
      <div className="board-cell-titles hide-for-small-only">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              &nbsp;
            </Cell>
            <Cell sm={12} md={2}>
              Themen
            </Cell>
            <Cell sm={12} md={2}>
              Beiträge
            </Cell>
            <Cell sm={12} md={8}>
              Letzter Beitrag
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showBoards(area) {
    const _c = this.props.context;

    let v__content;
    if (!area.boards || area.boards.length === 0) {
      v__content = (
        <div className="message">Keine Boards in diesem Bereich.</div>
      );
    } else {
      v__content = (
        <React.Fragment>
          {area.boards.map((board, index) => {
            let v__boardName = <div className="board-name">{board.name}</div>;
            if (board.locked === true) {
              v__boardName = (
                <div className="board-name">
                  <Icon icon="lock" /> {board.name}
                </div>
              );
            }

            let v__boardDescription;
            if (board.description) {
              v__boardDescription = (
                <div className="board-description">{board.description}</div>
              );
            }

            let boardUrl = _c.custom("urls.communityBoard");
            boardUrl = boardUrl.replace(":areaSlug", board.area.slug);
            boardUrl = boardUrl.replace(":boardSlug", board.slug);

            let v__link = (
              <div className="padding">
                {v__boardName}
                {v__boardDescription}
              </div>
            );

            let extraClasses;
            if (board.locked === true) {
              extraClasses = `${extraClasses} locked`;
              v__link = <div>{v__link}</div>;
            } else {
              v__link = (
                <Link to={boardUrl} className="clickable">
                  {v__link}
                </Link>
              );
            }

            let v__threadCount = board.thread_count;
            let v__postCount = board.post_count;

            let v__lastPost = <div>---</div>;
            if (board.last_post) {
              v__lastPost = (
                <div>
                  <div>
                    Von <span>{board.last_post.author.displayname}</span>
                  </div>
                  <div>am {board.last_post.created_at_f}</div>
                </div>
              );
            }

            let v__board;
            if (board.banner) {
              if (board.locked === true) {
                v__board = (
                  <div className="community-board-banner">
                    <img src={board.banner} alt={`${board.name}`} />
                  </div>
                );
              } else {
                v__board = (
                  <div className="community-board-banner">
                    <Link to={boardUrl}>
                      <img src={board.banner} alt={`${board.name}`} />
                    </Link>
                  </div>
                );
              }
            } else {
              v__board = (
                <div
                  className={`${extraClasses} community-area-boards-board`}
                  key={index}
                >
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={12}>
                        <div>
                          <div className="wrapper">
                            <div className="center-vertically- padding">
                              {v__link}
                            </div>
                          </div>
                        </div>
                      </Cell>
                      <Cell sm={12} md={2}>
                        <div className="wrapper">
                          <div className="center-vertically padding">
                            <div className="board-thread-count">
                              {v__threadCount}
                            </div>
                          </div>
                        </div>
                      </Cell>
                      <Cell sm={12} md={2}>
                        <div className="wrapper">
                          <div className="center-vertically padding">
                            <div className="board-post-count">
                              {v__postCount}
                            </div>
                          </div>
                        </div>
                      </Cell>
                      <Cell sm={12} md={8}>
                        <div className="wrapper">
                          <div className="center-vertically padding">
                            <div className="board-last-post">{v__lastPost}</div>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              );
            }

            return <React.Fragment key={index}>{v__board}</React.Fragment>;
          })}
        </React.Fragment>
      );
    }

    return <div className="community-areas-boards">{v__content}</div>;
  }

  init() {
    const { areaSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("communityArea", { areaSlug });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, userStatus } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const communityData = response.data.data;
            const { area } = communityData;

            const newBreadcrumb = {
              to: "/",
              id: "communityArea",
              name: area.name,
            };

            let { breadcrumbs } = this.state;
            breadcrumbs.push(newBreadcrumb);

            this.setState(
              {
                area,
                userStatus,
                status: "LOADED",
                breadcrumbs,
              },
              () => {}
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:area:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:area:init");
      });
  }
}

export default withContext(CommunityArea);
