import React, { Component } from "react";
import { Icon } from "../foundation/_buttons";
import { Link } from "react-router-dom";

class AdminBackButton extends Component {
  state = {};

  render() {
    return (
      <div className="admin-back-button">
        <Link to={this.props.url}>
          <small>
            <Icon icon="angle-double-left" left />{" "}
            {this.props.text || "Zurück zur Übersicht"}
          </small>
        </Link>
      </div>
    );
  }
}

export default AdminBackButton;
