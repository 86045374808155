import React, { Component } from "react";
import Breadcrumbs from "../topbars/Breadcrumbs";
import MainTopbar from "../topbars/mainTopbar";
import { Grid, Row, Cell } from "../foundation/_grid";
import Footer from "../layout/Footer";
import withContext from "../../context/contextHOC";

class CourseLayout extends Component {
  state = {
    course: this.props.course,
    lessons: this.props.lessons,
    lesson: this.props.lesson,
    breadcrumbs: this.props.breadcrumbs,
    status: this.props.status,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.init();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState({ lesson: this.props.lesson });
    }
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let mainContentStyles = {};

    let backgroundUrl = _c.setting(`design_bg_course`);
    if (backgroundUrl) {
      mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
    }

    return (
      <div className="page page-course">
        <MainTopbar type="course" />
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <main className="content" style={mainContentStyles}>
          <Grid>
            <Row>
              <Cell sm={24}>{this.view__showContent()}</Cell>
            </Row>
          </Grid>
        </main>
        <Footer />
      </div>
    );
  }

  view__showContent() {
    const { status, course, lesson, lessons } = this.state;
    const _c = this.props.context;

    if (status === "INIT" || !course || !lesson || !lessons) {
      return _c.loading();
    }

    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withContext(CourseLayout);
