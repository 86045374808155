import React, { Component } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import { Grid, Row, Cell } from "../foundation/_grid";
import withContext from "../../context/contextHOC";
import Box from "../layout/Box";

class Impressum extends Component {
  state = {};

  componentDidMount() {
    const _c = this.props.context;
    _c.initFinished();
    _c.setPageTitle("Impressum");
  }

  render() {
    return (
      <DefaultLayout page={"text-page page-impressum"}>
        <Grid>
          <Row>
            <Cell sm={24} md={24}>
              <h1>Impressum</h1>

              <Box>
                <p>
                  Informationspflicht laut §5 E-Commerce Gesetz, §14
                  Unternehmensgesetzbuch, §63 Gewerbeordnung und
                  Offenlegungspflicht laut §25 Mediengesetz
                </p>
                <p>
                  <strong>Welt von Beyond GmbH</strong>
                  <br />
                  Neubaugasse 24/1
                  <br />
                  Graz 8020
                </p>
                {/*<p>
                  <strong>Unternehmensgegenstand:</strong> Humanenergetik,
                  Werbeagentur, Vorträge, Seminare
                  <br />
                  <strong>Firmenbuchnummer:</strong> FN 488156 b<br />
                  <strong>Firmenbuchgericht:</strong> Landes- als Handelsgericht
                  Graz
                  <br />
                  <strong>Firmensitz:</strong> 8010 Graz
                </p>*/}
                <p>Umsatzsteuer-Identifikationsnummer: ATU76437125</p>
                <p>Inhaltlich verantwortlich: Marc Rabl</p>
                <p>
                  Tel.: +436702013727
                  <br />
                  E-Mail: info(at)annevonjahr.com
                </p>
                <p>Mitglied bei: WKO</p>
                <p>
                  <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Magistrat
                  der Stadt Graz
                  <br />
                  <strong>Verleihungsstaat:</strong> Österreich
                </p>
                <p>
                  Wir sind nicht bereit und nicht verpflichtet an einem
                  Streitbeilegungsverfahren vor einer
                  Verbraucherschlichtungsstelle teilzunehmen.
                </p>
              </Box>
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }
}

export default withContext(Impressum);
