import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";
import { cloneDeep } from "lodash";
import { Grid, Row, Cell, Icon } from "../../foundation/foundation";
import AdminDataField from "../AdminDataField";
import axios from "axios";
import PTPopup from "../../ptPopup/PTPopup";
import PTDialog from "../../ptdialog/PTDialog";

class AdminCourseDownloads extends Component {
  state = {
    _updatableProps: ["course", "downloads", "newDownload"],
    course: this.props.course,
    downloads: this.props.downloads,
    newDownload: this.props.newDownload,
    selectedDownload: null,
    changesWereMade: false,
    showDialogDelete: false,
  };

  render() {
    const { downloads } = this.state;

    if (!downloads) {
      return null;
    }

    return (
      <React.Fragment>
        {this.view__showDownloads()}
        {this.view__showPopups()}
        {this.view__showDialogs()}
      </React.Fragment>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  view__showDialogs() {
    const { showDialogDelete, selectedDownload } = this.state;

    if (!selectedDownload) {
      return null;
    }

    return (
      <React.Fragment>
        <PTDialog
          show={showDialogDelete}
          message={
            <p>
              Möchtest Du diesen Download wirklich löschen?
              <br />
              Diese Aktion kann nicht rückgängig gemacht werden.
            </p>
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showPopups() {
    const { selectedDownload, showDialogDelete } = this.state;
    const _c = this.props.context;

    let output;

    if (selectedDownload && !showDialogDelete) {
      const downloadTypes = _c.getDownloadTypes("select");

      output = (
        <PTPopup show={true} size="small" handleClose={this.handle__cancel}>
          <h3>{selectedDownload.name || "Download"}</h3>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.name}
                  editValue={selectedDownload.name}
                  editType="text"
                  label="Name des Downloads"
                  placeholder="z.B. Workbook"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("name", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.type}
                  editValue={selectedDownload.type}
                  editType="select"
                  selectValues={downloadTypes}
                  label="Typ"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("type", newValue);
                  }}
                />
              </Cell>
            </Row>
            {/*<Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.url}
                  editValue={selectedDownload.url}
                  editType="text"
                  label="URL"
                  placeholder="z.B. https:// ..."
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("url", newValue);
                  }}
                />
              </Cell>
                </Row>*/}
            <Row margin="y">
              <Cell sm={24} md={24}>
                <small style={{ wordWrap: "break-word" }}>
                  URL:{" "}
                  <a
                    href={selectedDownload.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedDownload.url}
                  </a>
                </small>
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <p>
                  <strong>Neue Datei hochladen (optional)</strong>
                </p>
                <input
                  type="file"
                  onChange={(e) => {
                    this.handle__edit("file", e.target.files[0]);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <p>
                  <strong>Vorschaugrafik hochladen (optional)</strong>
                </p>
                <input
                  type="file"
                  onChange={(e) => {
                    this.handle__edit("imagefile", e.target.files[0]);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <small style={{ wordWrap: "break-word" }}>
                  Bild-URL:{" "}
                  <a
                    href={selectedDownload.image}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedDownload.image}
                  </a>
                </small>
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24} className="text-center">
                <AdminDataField
                  value={selectedDownload.published}
                  editValue={selectedDownload.published}
                  editType="singleCheckbox"
                  label="Download veröffentlicht"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("published", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24}>
                <div style={{ margin: "1rem 0" }}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={12}>
                        <button
                          className="small primary hollow button"
                          onClick={() => {
                            this.setState({ showDialogDelete: true });
                          }}
                        >
                          <Icon icon="trash" left /> Download löschen
                        </button>
                      </Cell>
                      <Cell sm={24} md={12} className="text-right">
                        <button
                          className="small primary hollow button"
                          onClick={this.handle__cancel}
                        >
                          <Icon icon="times" left /> Abbrechen
                        </button>
                        <button
                          className="small primary button"
                          onClick={this.handle__save}
                        >
                          <Icon icon="check" left /> Speichern
                        </button>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
      );
    }

    return <React.Fragment>{output}</React.Fragment>;
  }

  view__showDownloads() {
    const { downloads, newDownload } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        {downloads.map((download, index) => {
          let v__type = _c.getDownloadType(download.type);

          let v__published;
          if (download.published === true) {
            v__published = (
              <span>
                <Icon icon="check" left /> ist veröffentlicht
              </span>
            );
          } else {
            v__published = (
              <span>
                <Icon icon="minus" left /> ist nicht veröffentlicht
              </span>
            );
          }

          return (
            <div className="admin-course-download" key={index}>
              <Box
                size="small"
                onClick={() => {
                  this.setState({ selectedDownload: cloneDeep(download) });
                }}
              >
                <h3>{download.name}</h3>
                <Grid type="full">
                  <Row margin="xy">
                    <Cell sm={24} md={24} className="text-center">
                      {v__published}
                    </Cell>
                  </Row>
                  <Row margin="xy">
                    <Cell sm={24} md={12}>
                      <div className="admin-course-download-info">
                        {v__type}
                      </div>
                    </Cell>
                    <Cell sm={24} md={12} className="text-right">
                      Klicks: {download.clicks}
                    </Cell>
                  </Row>
                  <Row margin="xy">
                    <Cell sm={24} md={24}>
                      URL: {download.url}
                    </Cell>
                  </Row>
                </Grid>
              </Box>
            </div>
          );
        })}
        <div className="admin-courses-add-download">
          <Box
            size="small"
            onClick={() => {
              this.setState({ selectedDownload: cloneDeep(newDownload) });
            }}
          >
            <Icon icon="plus" left /> Download hinzufügen
          </Box>
        </div>
      </React.Fragment>
    );
  }

  handle__edit(property, value) {
    const { selectedDownload } = this.state;
    selectedDownload[property] = value;
    this.setState({ selectedDownload, changesWereMade: true });
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;

    let confirmCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Möchtest Du wirklich abbrechen? Alle Eingaben/Änderungen gehen unwiderruflich verloren."
        )
      ) {
        confirmCancel = true;
      }
    } else {
      confirmCancel = true;
    }

    if (confirmCancel === true) {
      this.setState({
        selectedDownload: null,
        changesWereMade: false,
      });
    }

    return confirmCancel;
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, selectedDownload } = this.state;

    if (!selectedDownload.name) {
      window.alert("Bitte gib einen Namen für diesen Download an.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminCourseDownload", {
        courseId: course.id,
        downloadId: selectedDownload.id,
      });

      const formData = new FormData();

      if (selectedDownload.file) {
        formData.append("file", selectedDownload.file);
        formData.append("name", selectedDownload.file.name);
      }
      if (selectedDownload.imagefile) {
        formData.append("imagefile", selectedDownload.imagefile);
        formData.append("imagename", selectedDownload.imagefile.name);
      }
      formData.append("download", JSON.stringify(selectedDownload));

      axios
        .post(apiUrl, formData, {
          headers: _c.headers,
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedDownload: null,
                  showDialogDelete: false,
                  course,
                  downloads: course.downloads,
                },
                () => {
                  if (this.props.onUpdate) {
                    this.props.onUpdate(course);
                  }
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    "Download gespeichert",
                    "Der Download wurde erfolgreich gespeichert",
                    "success"
                  );
                }
              );
            }
          } catch {}
        })
        .catch((error) => {});
    }, 200);
  };

  handle__delete = () => {
    const { course, selectedDownload } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("adminCourseDownload", {
      courseId: course.id,
      downloadId: selectedDownload.id,
    });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { course } = apiResponseData;

            _c.setIsSaving(false);

            this.setState(
              {
                changesWereMade: false,
                selectedDownload: null,
                showDialogDelete: false,
                course,
                downloads: course.downloads,
              },
              () => {
                if (this.props.onUpdate) {
                  this.props.onUpdate(course);
                }
                _c.cancelEditLock();
                _c.createNotifictation(
                  "Download gelöscht",
                  "Der Download wurde erfolgreiche gelöscht.",
                  "success"
                );
              }
            );
          }
        } catch {}
      })
      .catch((error) => {});
  };
}

export default withContext(AdminCourseDownloads);
