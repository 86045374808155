import React, { Component } from "react";
import { Icon } from "../../foundation/_buttons";

class ExternalVideo extends Component {
  state = {
    allowYoutubeVideos: false,
    allowVimeoVideos: false,
    type: this.props.type,
    videoId: this.props.videoId,
    allowYoutubeVideosKey: "allow_youtube_videos",
    allowVimeoVideosKey: "allow_vimeo_videos"
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.type !== prevProps.type) {
      this.setState({ type: this.props.type });
    }

    if (this.props.videoId !== prevProps.videoId) {
      this.setState({ videoId: this.props.videoId });
    }
  }

  componentDidMount() {
    const { allowYoutubeVideosKey, allowVimeoVideosKey } = this.state;

    let allowYoutubeVideos = localStorage.getItem(allowYoutubeVideosKey);
    if (
      allowYoutubeVideos === true ||
      allowYoutubeVideos === 1 ||
      allowYoutubeVideos === "1" ||
      allowYoutubeVideos === "yes"
    ) {
      this.setState({ allowYoutubeVideos: true });
    }

    let allowVimeoVideos = localStorage.getItem(allowVimeoVideosKey);
    if (
      allowVimeoVideos === true ||
      allowVimeoVideos === 1 ||
      allowVimeoVideos === "1" ||
      allowVimeoVideos === "yes"
    ) {
      this.setState({ allowVimeoVideos: true });
    }
  }

  render() {
    return <React.Fragment>{this.view__showVideo()}</React.Fragment>;
  }

  view__showVideo() {
    const { type, videoId } = this.state;

    if (!videoId) {
      return;
    }

    if (type === "vimeo") {
      return this.view__showVimeo();
    }
  }

  view__showVimeo() {
    const { allowVimeoVideos, videoId } = this.state;

    let content;

    if (allowVimeoVideos === true) {
      let url = `https://player.vimeo.com/video/${videoId}?dnt=1`;
      content = (
        <div className="responsive-embed widescreen">
          <iframe
            title="Vimeo"
            width="560"
            height="315"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    } else {
      content = (
        <div className="external-video-preview-wrapper">
          <div className="external-video-preview-background">
            <div className="external-video-preview-content">
              <div className="external-video-preview-text">
                Mit dem Laden der Videos akzeptierst Du die{" "}
                <a
                  href="https://vimeo.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Datenschutzbestimmungen von Vimeo
                </a>
                , da ggfs. Daten (z.B. die IP-Adresse) an Google gesendet werden
                könnten.
              </div>
              <div className="external-video-preview-button">
                <button
                  className="small primary button"
                  onClick={this.handle__allowVimeoVideos}
                >
                  <Icon icon="vimeo" left /> Vimeo-Videos zulassen
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return content;
  }

  view__showYoutube() {
    const { allowYoutubeVideos, videoId } = this.state;

    let content;

    if (allowYoutubeVideos === true) {
      let url = `https://www.youtube-nocookie.com/embed/${videoId}`;
      content = (
        <div className="responsive-embed widescreen">
          <iframe
            title="Youtube"
            width="560"
            height="315"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      );
    } else {
      content = (
        <div className="external-video-preview-wrapper">
          <div className="external-video-preview-background">
            <div className="external-video-preview-content">
              <div className="external-video-preview-text">
                Mit dem Laden der Videos akzeptierst Du die{" "}
                <a
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Datenschutzbestimmungen von Google
                </a>
                , da ggfs. Daten (z.B. die IP-Adresse) an Google gesendet werden
                könnten.
              </div>
              <div className="external-video-preview-button">
                <button
                  className="small primary button"
                  onClick={this.handle__allowYoutubeVideos}
                >
                  <Icon icon="youtube-play" left /> Youtube-Videos zulassen
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return content;
  }

  handle__allowYoutubeVideos = () => {
    const { allowYoutubeVideosKey } = this.state;

    localStorage.setItem(allowYoutubeVideosKey, "yes");
    this.setState({ allowYoutubeVideos: true });
  };

  handle__allowVimeoVideos = () => {
    const { allowVimeoVideosKey } = this.state;

    localStorage.setItem(allowVimeoVideosKey, "yes");
    this.setState({ allowVimeoVideos: true });
  };
}

export default ExternalVideo;
