import React, { Component } from "react";
import { Grid, Row, Cell } from "../foundation/_grid";
import MainTopbar from "../topbars/mainTopbar";
import Breadcrumbs from "../topbars/Breadcrumbs";
import withContext from "../../context/contextHOC";
import Footer from "../layout/Footer";

class DefaultLayout extends Component {
  state = {
    nav: this.props.nav, // Selected Navigation Item
    breadcrumbs: this.props.breadcrumbs,
    page: this.props.page,
    background: this.props.background,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.init();
  }

  render() {
    let { breadcrumbs, page, background } = this.state;
    const _c = this.props.context;

    let v__breadcrumbs = null;
    let classesBreadcrumbs = "";
    if (breadcrumbs) {
      v__breadcrumbs = <Breadcrumbs breadcrumbs={breadcrumbs} />;
      classesBreadcrumbs = "page-with-breadcrumbs";
    }

    if (!page) {
      page = "default";
    }

    let mainContentStyles = {};

    if (page) {
      let backgroundUrl;
      if (background) {
        backgroundUrl = _c.setting(`design_bg_${background}`);
      } else {
        backgroundUrl = _c.setting(`design_bg_${page}`);
      }
      if (backgroundUrl) {
        mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
      }
    }

    return (
      <div className={`page page-${page} ${classesBreadcrumbs}`}>
        <MainTopbar type="admin" />
        {v__breadcrumbs}
        <main className="content" style={mainContentStyles}>
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24}>
                {this.props.children}
              </Cell>
            </Row>
          </Grid>
        </main>
        <Footer />
      </div>
    );
  }
}

export default withContext(DefaultLayout);
