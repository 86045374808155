import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";

class AdminDashboard extends Component {
  state = {};

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Admin Dashboard");
  }

  render() {
    return (
      <AdminLayout nav="dashboard" breadcrumbs={["admin_dashboard"]}>
        <div style={{ textAlign: "center", paddingTop: "100px" }}>
          <h2>
            Herzlich willkommen
            <br />
            im Administrationsbereich!
          </h2>
        </div>
      </AdminLayout>
    );
  }

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminDashboard, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);
        } catch {
          alert(
            "Admin Dashboard - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminDashboard);
