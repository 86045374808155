import React, { Component } from "react";
import axios from "axios";
import withContext from "../../context/contextHOC";
import DefaultLayout from "../layout/DefaultLayout";
import { Grid, Row, Cell } from "../foundation/_grid";

import { Icon } from "../foundation/_buttons";
import Collapse from "@kunukn/react-collapse";

class Faq extends Component {
  state = {
    category: this.props.match.params.category,
    question: this.props.match.params.question,
    faq: null,
    status: "INIT",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.setPageTitle("FAQ - Häufig gestellte Fragen");
    this.init();
  }

  render() {
    return (
      <div className={`page page-faq`}>
        <DefaultLayout breadcrumbs={["home", "faq"]} background="faq">
          <Grid>
            <Row>
              <Cell sm={24} md={18} mdo={3}>
                {this.view__showContent()}
              </Cell>
            </Row>
          </Grid>
        </DefaultLayout>
      </div>
    );
  }

  view__showToggleButtons() {
    return (
      <div className="text-right">
        <button onClick={this.closeAll} className="small hollow primary button">
          <Icon icon="compress" left /> Alle einklappen
        </button>
        <button onClick={this.openAll} className="small hollow primary button">
          <Icon icon="expand" left /> Alle aufklappen
        </button>
      </div>
    );
  }

  view__showContent() {
    const { faq } = this.state;
    const _c = this.props.context;

    if (!faq) {
      return _c.loading();
    }

    return (
      <div className="faq">
        <h1>Häufig gestellte Fragen</h1>
        {this.view__showToggleButtons()}
        {this.view__showFaq()}
      </div>
    );
  }

  view__showFaq() {
    const { faq, category } = this.state;

    return (
      <div className="faq-categories">
        {faq.map((faqCategory, index) => {
          if (category && parseInt(faqCategory.id) !== parseInt(category)) {
            return null;
          }

          return (
            <div className="faq-category" key={index}>
              <h2>{faqCategory.name}</h2>
              <ul className="faq-entries">
                {faqCategory.entries.map((faqEntry, entryIndex) => {
                  let v__caret = (
                    <button
                      onClick={() => {
                        this.toggleOpen(faqEntry.id);
                      }}
                    >
                      <Icon icon="caret-right" />
                    </button>
                  );

                  if (faqEntry.isOpen === true) {
                    v__caret = (
                      <button
                        onClick={() => {
                          this.toggleOpen(faqEntry.id);
                        }}
                      >
                        <Icon icon="caret-down" />
                      </button>
                    );
                  }

                  return (
                    <li className="faq-entry" key={entryIndex}>
                      <h3>
                        <div className="float-right">{v__caret}</div>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            this.toggleOpen(faqEntry.id);
                          }}
                        >
                          {entryIndex + 1}. {faqEntry.question}
                        </span>
                      </h3>
                      <Collapse isOpen={faqEntry.isOpen}>
                        <div className="entry-content">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: faqEntry.answer_html,
                            }}
                          />
                        </div>
                      </Collapse>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>
    );
  }

  toggleOpen(faqEntryId) {
    const { faq } = this.state;

    faq.map((category) => {
      category.entries.map((entry) => {
        if (parseInt(entry.id) === parseInt(faqEntryId)) {
          if (entry.isOpen === true) {
            entry.isOpen = false;
          } else {
            entry.isOpen = true;
          }
        }
        return null;
      });
      return null;
    });

    this.setState({ faq });
  }

  openAll = () => {
    const { faq } = this.state;
    faq.map((category) => {
      category.entries.map((entry) => {
        entry.isOpen = true;
        return null;
      });
      return null;
    });

    this.setState({ faq });
  };

  closeAll = () => {
    const { faq } = this.state;
    faq.map((category) => {
      category.entries.map((entry) => {
        entry.isOpen = false;
        return null;
      });
      return null;
    });

    this.setState({ faq });
  };

  init() {
    const { question } = this.state;
    const _c = this.props.context;
    _c.login(() => {
      let url = _c.apiEndpoints.faq;

      axios
        .get(url, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const { faq } = response.data.data;

              if (question) {
                faq.map((faqCategory) => {
                  faqCategory.entries.map((faqEntry) => {
                    if (parseInt(faqEntry.id) === parseInt(question)) {
                      faqEntry.isOpen = true;
                    }
                    return null;
                  });
                  return null;
                });
              }

              this.setState({
                faq,
                status: "LOADED",
              });

              if (_c.isDebug()) {
                console.log(response.data);
              }
            }
          } catch {
            alert(
              "FAQ - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
            );
          }
        })
        .catch((error) => {
          if (_c.isDebug()) {
            console.log("Error", error);
          }
        });
    });
  }
}

export default withContext(Faq);
