import React, { Component } from "react";
import { Icon } from "../../foundation/_buttons";
import closeButton from "../../ptPopup/close-button.png";
import axios from "axios";
import withContext from "../../../context/contextHOC";

class LessonList extends Component {
  state = {
    baseUrl: this.props.baseUrl,
    status: this.props.status,
    course: this.props.course,
    lesson: this.props.lesson,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    selectedCategory: undefined,
    showLessonListOnMobile: this.props.showLessonListOnMobile,
  };

  componentDidMount() {
    this.autoselectCategory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.baseUrl !== this.props.baseUrl) {
      this.setState({ baseUrl: this.props.baseUrl });
    }

    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState({ lesson: this.props.lesson }, () => {
        this.autoselectCategory();
      });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }

    if (
      prevProps.showLessonListOnMobile !== this.props.showLessonListOnMobile
    ) {
      this.setState({
        showLessonListOnMobile: this.props.showLessonListOnMobile,
      });
    }
  }

  render() {
    const {
      baseUrl,
      lessons,
      lesson,
      course,
      selectedCategory,
      showLessonListOnMobile,
    } = this.state;

    if (!lessons) {
      return null;
    }

    return (
      <div
        className={`${
          showLessonListOnMobile === true ? "mobile-show" : "mobile-hide"
        } smartphone-wrapper`}
      >
        <div className="course-lessonlist">
          <div className="show-for-small-only">
            <div className="float-right">{this.view__showCloseButton()}</div>
            <h2>Lektionen im Kurs</h2>
          </div>
          <ul className="course-categories">
            {lessons.map((category, index) => {
              let categoryIsSelected = false;
              let caretIcon = (
                <button
                  className="course-open-category"
                  onClick={() => {
                    this.setState({ selectedCategory: category.id });
                  }}
                >
                  <Icon icon="caret-up" />
                </button>
              );
              if (selectedCategory === category.id) {
                categoryIsSelected = true;
                caretIcon = (
                  <button
                    className="course-open-category"
                    onClick={() => {
                      this.setState({ selectedCategory: null });
                    }}
                  >
                    <Icon icon="caret-down" />
                  </button>
                );
              }

              let lessonCounter = 0;

              return (
                <li key={index} className="course-category">
                  <div
                    className="course-category-title"
                    onClick={() => {
                      this.setState({ selectedCategory: category.id });
                    }}
                  >
                    <div className="float-right">{caretIcon}</div>
                    <Icon icon="play-circle" left />
                    {category.name}
                  </div>
                  <ul
                    className="course-category-lessons"
                    style={{
                      display: categoryIsSelected === true ? "block" : "none",
                    }}
                  >
                    {category.lessons.map((listLesson, lessonIndex) => {
                      let lessonUrl = `${baseUrl}/${course.slug}/${listLesson.slug}`;

                      let lessonClasses = "course-category-lesson";

                      //let lessonIsSelected = false;
                      if (lesson.id === listLesson.id) {
                        //lessonIsSelected = true;
                        lessonClasses = `selected ${lessonClasses}`;
                      }

                      lessonCounter++;

                      let v__check = `${lessonCounter}.`;
                      if (
                        listLesson.lessonStatus &&
                        listLesson.lessonStatus.finished &&
                        listLesson.lessonStatus.finished !== null
                      ) {
                        v__check = <Icon icon="check" left />;
                      }

                      if (listLesson.type === "quiz") {
                        v__check = (
                          <span className="quiz-label label">Quiz</span>
                        );
                        lessonUrl = `/quiz/${listLesson.quiz.slug}`;
                      }

                      return (
                        <li className={lessonClasses} key={lessonIndex}>
                          <a href={lessonUrl}>
                            {v__check} {listLesson.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
          {this.view__showDownloads()}
        </div>
      </div>
    );
  }

  view__showDownloads() {
    const { course, downloads } = this.state;

    if (course.show_downloads_tab === true) {
      return null;
    }

    return (
      <ul className="course-downloads-area">
        <li className="course-category">
          <div className="course-category-title">
            <Icon icon="download" left />
            KURSMATERIAL
          </div>
          <ul className="course-category-downloads">
            {downloads.map((download, index) => {
              let fileIcon = "";
              if (download && download.type) {
                if (download.type.toLowerCase() === "pdf") {
                  fileIcon = <span className="download-file-icon">PDF</span>;
                }
              }

              let v__download = (
                <a
                  href={download.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    this.handle__downloadClick(download.id);
                  }}
                >
                  <div className="course-download-featured-name">
                    {fileIcon} {download.name}
                  </div>
                </a>
              );

              if (download.featured === true && download.image) {
                v__download = (
                  <a
                    className="text-center"
                    href={download.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      this.handle__downloadClick(download.id);
                    }}
                  >
                    <div className="course-download-featured-image">
                      <img src={download.image} alt={download.name} />
                    </div>{" "}
                    <div className="course-download-featured-name">
                      {fileIcon} {download.name}
                    </div>
                  </a>
                );
              }

              return (
                <li className="course-dowload" key={index}>
                  {v__download}
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    );
  }

  autoselectCategory() {
    let { lesson, selectedCategory } = this.state;
    if (selectedCategory === undefined) {
      selectedCategory = lesson.category;
      this.setState({ selectedCategory });
    }
  }

  view__showCloseButton() {
    return (
      <button
        className="ptpopup-close-button"
        onClick={() => {
          this.setState({ showLessonListOnMobile: false }, () => {
            this.props.closeLessonListOnMobile();
          });
        }}
      >
        <img src={closeButton} alt="[Close]" />
      </button>
    );
  }

  handle__downloadClick(downloadId) {
    const _c = this.props.context;

    let url = _c.apiUrl("courseDownloadClick", {
      downloadId,
    });

    axios
      .patch(
        url,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          //
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        //_c.checkError(error);
      });
  }
}

export default withContext(LessonList);
