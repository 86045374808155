import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import axios from "axios";
//import UserIndex from "./UserIndex";
import GuestIndex from "./GuestIndex";

class Index extends Component {
  state = {
    videos: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.init();
    this.init();
  }

  render() {
    const { videos } = this.state;
    const _c = this.props.context;

    if (_c.isUserLoggedIn()) {
      //return <UserIndex />;
      return <GuestIndex videos={videos} />;
    } else {
      return <GuestIndex videos={videos} />;
    }
  }

  init() {
    const _c = this.props.context;
    _c.login(() => {
      /*if (_c.isUserLoggedIn()) {
        this.props.history.push("/home");
      }*/
    });

    axios
      .get(_c.apiEndpoints.index)
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { videos } = response.data.data;
            this.setState({ videos });
          }
        } catch {
          alert(
            "Index - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(Index);
