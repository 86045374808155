import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import { Icon } from "../../foundation/_buttons";

class AdminLetters extends Component {
  state = {
    baseUrl: "/admin/letters",
    loadingError: false,
    letters: undefined,
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Briefe an die Mitglieder");
  }

  render() {
    return (
      <AdminLayout
        nav="letters"
        breadcrumbs={["admin_dashboard", "admin_letters"]}
      >
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showList()}
      </AdminLayout>
    );
  }

  view__showHeadline() {
    return <h1>Briefe an die Mitglieder</h1>;
  }

  view__showPageActions() {
    const { baseUrl } = this.state;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a
            href={`${baseUrl}/new`}
            className="button"
            data-tip="Neuen Brief schreiben"
          >
            <Icon icon="plus" left /> Neuen Brief schreiben
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showList() {
    const { letters, loadingError } = this.state;
    const _c = this.props.context;

    if (!letters) {
      return _c.loading(loadingError);
    }

    if (letters && isArray(letters) && letters.length === 0) {
      return <p className="text-center">Keine Briefe gefunden.</p>;
    }

    return (
      <React.Fragment>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showListDesktop(letters)}
          {this.view__showListMobile(letters)}
        </Box>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={20}>
              <div className="padding">Brief</div>
            </Cell>
            <Cell md={4} className="text-center">
              <div className="padding">Veröffentlicht</div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(letters) {
    const { baseUrl } = this.state;

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {letters.map((letter, index) => (
            <li className="admin-list-item" key={index}>
              <Link to={`${baseUrl}/${letter.id}`} className="clickable">
                <Grid type="full">
                  <Row>
                    <Cell md={20}>
                      <div className="padding">
                        <div className="admin-list-item-title">
                          {letter.subject}
                        </div>
                      </div>
                    </Cell>
                    <Cell md={4} className="text-center">
                      <div className="wrapper">
                        <div className="center-vertically">
                          <div className="padding">{letter.publish_date}</div>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  view__showListMobile() {
    return (
      <div className="show-for-small-only">
        <p>mobile</p>
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminLetters, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { user, letters } = response.data;

          this.setState({ letters });

          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.updateUser(user, () => {
            _c.initFinished();
          });
        } catch {
          alert(
            "Admin Letters - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminLetters);
