import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
//import { isArray } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import { Icon } from "../../foundation/_buttons";
import ReactTooltip from "react-tooltip";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import { cloneDeep } from "lodash";
import AdminDataField from "../AdminDataField";

class AdminCommunity extends Component {
  state = {
    areas: [],
    courses: [],
    status: "INIT",
    breadcrumbs: ["admin_dashboard", "admin_community"],
    view: "list",
    selectedArea: null,
    selectedBoard: null,
    changesWereMade: false,
    newArea: {
      id: "new",
      name: "",
      description: "",
      visible: true,
      locked: false,
      access_granted: "all",
      access_granted_for: [],
    },
    newBoard: {
      id: "new",
      area_id: 0,
      name: "",
      description: "",
      visible: true,
      locked: false,
      access_granted: "all",
      access_granted_for: [],
    },
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Community");
  }

  render() {
    const { breadcrumbs } = this.state;

    return (
      <AdminLayout nav="community" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12}>
              {this.view__showButtonAddArea()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showCommunityAreas()}
        {this.view__showButtonAddArea()}
        {this.view__showPopups()}
        <ul id="adminSort1" className="admin-list" />
        <ul id="adminSort2" className="admin-list" />
      </AdminLayout>
    );
  }

  view__showPopups() {
    const { view, selectedArea, selectedBoard } = this.state;

    return (
      <React.Fragment>
        <MMPopup
          show={view === "area" && selectedArea !== null}
          size="medium"
          handleClose={this.handle__cancelEditArea}
        >
          {this.view__showPopupArea()}
        </MMPopup>
        <MMPopup
          show={
            view === "board" && selectedBoard !== null && selectedArea !== null
          }
          size="medium"
          handleClose={this.handle__cancelEditBoard}
        >
          {this.view__showPopupBoard()}
        </MMPopup>
      </React.Fragment>
    );
  }

  view__showPopupArea() {
    const { selectedArea } = this.state;

    if (!selectedArea) {
      return;
    }

    let v__title;
    if (selectedArea.id === "new") {
      v__title = "Neuen Bereich anlegen";
    } else {
      v__title = `Bereich: ${selectedArea.name}`;
    }

    return (
      <div className="popup-admin-community">
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>
              <h3>{v__title}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={selectedArea.name}
                editValue={selectedArea.name}
                editType="text"
                label="Name"
                placeholder="Name des Bereichs"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  selectedArea.name = newValue;
                  this.setState({ changesWereMade: true });
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={selectedArea.description}
                editValue={selectedArea.description}
                editType="textarea"
                label="Beschreibung (optional)"
                placeholder="Kurze Info über diesen Bereich"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  selectedArea.description = newValue;
                  this.setState({ changesWereMade: true });
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={selectedArea.banner}
                editValue={selectedArea.banner}
                editType="text"
                label="Banner (Größe: 570x80 Pixel)"
                placeholder="URL zum Banner-Bild"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  selectedArea.banner = newValue;
                  this.setState({ changesWereMade: true });
                }}
              />
            </Cell>
          </Row>
        </Grid>
        {this.view__showCommunityAccess("area", selectedArea)}
        <div className="popup-buttons">
          <button
            className="small primary hollow button"
            onClick={this.handle__cancelEditArea}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="small primary button"
            onClick={this.handle__saveArea}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      </div>
    );
  }

  view__showPopupBoard() {
    const { selectedArea, selectedBoard } = this.state;

    if (!selectedArea || !selectedBoard) {
      return;
    }

    let v__title;
    if (selectedBoard.id === "new") {
      v__title = "Board hinzufügen";
    } else {
      v__title = `Board: ${selectedBoard.name}`;
    }

    return (
      <div className="popup-admin-community">
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>
              <h3>{v__title}</h3>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={selectedBoard.name}
                editValue={selectedBoard.name}
                editType="text"
                label="Name"
                placeholder="Name des Boards"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  selectedBoard.name = newValue;
                  this.setState({ changesWereMade: true });
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={selectedBoard.description}
                editValue={selectedBoard.description}
                editType="textarea"
                label="Beschreibung (optional)"
                placeholder="Kurze Info über dieses Board"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  selectedBoard.description = newValue;
                  this.setState({ changesWereMade: true });
                }}
              />
            </Cell>
          </Row>
        </Grid>
        {this.view__showCommunityAccess("board", selectedBoard)}
        <div className="popup-buttons">
          <button
            className="small primary hollow button"
            onClick={this.handle__cancelEditBoard}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="small primary button"
            onClick={this.handle__saveBoard}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      </div>
    );
  }

  view__showCommunityAccess(type, selectedObject) {
    const { courses } = this.state;
    const _c = this.props.context;

    return (
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={24}>
            <p>
              <strong>Zugriff auf dieses Board</strong>
            </p>
            <Grid type="full">
              <Row margin="xy">
                <Cell sm={24} md={12}>
                  <label>
                    <input
                      type="radio"
                      name={`access_${selectedObject.id}`}
                      value="all"
                      checked={
                        selectedObject.access_granted === "all" ? true : false
                      }
                      onChange={(e) => {
                        selectedObject.access_granted = e.target.value;
                        this.setState({ changesWereMade: true });
                        if (type === "area") {
                          this.setState({ selectedArea: selectedObject });
                        } else if (type === "board") {
                          this.setState({ selectedBoard: selectedObject });
                        }
                      }}
                    />{" "}
                    Alle Mitglieder
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`access_${selectedObject.id}`}
                      value="courses"
                      checked={
                        selectedObject.access_granted === "courses"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        selectedObject.access_granted = e.target.value;
                        this.setState({ changesWereMade: true });
                        if (type === "area") {
                          this.setState({ selectedArea: selectedObject });
                        } else if (type === "board") {
                          this.setState({ selectedBoard: selectedObject });
                        }
                      }}
                    />{" "}
                    Nur Käufer bestimmter Kurse
                  </label>
                </Cell>
                <Cell sm={24} md={12}>
                  <div
                    style={{
                      display:
                        selectedObject.access_granted === "all"
                          ? "block"
                          : "none",
                    }}
                    className="admin-community-access-for-all"
                  >
                    {_c.translate(
                      `admin.community.access.options.all.text.${type}`
                    )}
                  </div>
                  <div
                    style={{
                      display:
                        selectedObject.access_granted === "courses"
                          ? "block"
                          : "none",
                    }}
                    className="admin-community-access-for-courses"
                  >
                    {_c.translate(
                      `admin.community.access.options.courses.text.${type}`
                    )}
                    <div className="admin-community-access-list">
                      <ul>
                        {courses.map((course, index) => {
                          return (
                            <li key={index}>
                              <label>
                                <input
                                  type="checkbox"
                                  value={course.id}
                                  checked={
                                    selectedObject.access_granted_for.includes(
                                      course.id
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    let selectedCourseId = e.target.value;
                                    let found = false;
                                    selectedObject.access_granted_for.map(
                                      (courseId) => {
                                        if (
                                          parseInt(courseId) ===
                                          parseInt(selectedCourseId)
                                        ) {
                                          found = true;
                                        }
                                        return null;
                                      }
                                    );

                                    if (found === true) {
                                      let newAccessGrantedFor = [];
                                      selectedObject.access_granted_for.map(
                                        (courseId) => {
                                          if (
                                            parseInt(courseId) !==
                                            parseInt(selectedCourseId)
                                          ) {
                                            newAccessGrantedFor.push(
                                              parseInt(courseId)
                                            );
                                          }
                                          return null;
                                        }
                                      );

                                      selectedObject.access_granted_for = newAccessGrantedFor;
                                    } else {
                                      selectedObject.access_granted_for.push(
                                        parseInt(selectedCourseId)
                                      );
                                    }

                                    if (type === "area") {
                                      this.setState({
                                        selectedArea: selectedObject,
                                      });
                                    } else if (type === "board") {
                                      this.setState({
                                        selectedBoard: selectedObject,
                                      });
                                    }
                                  }}
                                />{" "}
                                <span>{course.name}</span>
                              </label>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showHeadline() {
    return <h1>Community</h1>;
  }

  view__showCommunityAreas() {
    const { areas } = this.state;
    const _c = this.props.context;

    if (!areas) {
      return _c.loading();
    }

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-community-areas">{children}</ul>;
    });

    const SortableItem = sortableElement(({ area }) => {
      const _c = this.props.context;
      let v__description;

      if (area.description) {
        v__description = (
          <div className="area-description">{area.description}</div>
        );
      }

      let v__access;
      if (area.access_granted === "all") {
        v__access = _c.translate("admin.community.access.info.all.area");
      } else if (area.access_granted === "courses") {
        v__access = `${_c.translate(
          "admin.community.access.info.courses"
        )} ${this.view__showAccessCourseNames(area.access_granted_for)}`;
      }

      v__access = (
        <div className="area-access">
          <Icon icon="unlock" left />
          {_c.translate("admin.community.access.info.access")} {v__access}
        </div>
      );

      let v__editButton = (
        <button
          className="very tiny primary hollow button"
          data-tip={_c
            .translate("admin.community.area.buttons.edit.tooltip")
            .replace("{area}", area.name)}
          onClick={() => {
            this.setState({ view: "area", selectedArea: cloneDeep(area) });
          }}
        >
          {_c.translate("admin.community.area.buttons.edit.label")}
        </button>
      );

      let v__deleteButton = (
        <button
          className="very tiny primary hollow button"
          data-tip={_c
            .translate("admin.community.area.buttons.delete.tooltip")
            .replace("{area}", area.name)}
          onClick={() => {
            this.handle__deleteArea(area);
          }}
        >
          {_c.translate("admin.community.area.buttons.delete.label")}
        </button>
      );

      return (
        <li>
          <Grid type="full">
            <Row>
              <Cell sm={2} md={1}>
                <div className="area-drag-handle-wrapper">
                  <DragHandle />
                </div>
              </Cell>
              <Cell sm={22} md={23}>
                <Box>
                  <h3>
                    {area.name}{" "}
                    <div className="float-right">
                      {v__deleteButton} {v__editButton}
                    </div>
                  </h3>
                  {v__description}
                  {v__access}
                  {this.view__showCommunityBoards(area)}
                </Box>
              </Cell>
            </Row>
          </Grid>
        </li>
      );
    });

    return (
      <React.Fragment>
        <SortableContainer
          onSortEnd={this.onAreasSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminSort1")}
        >
          {areas.map((area, index) => {
            return (
              <SortableItem key={`item-${index}`} index={index} area={area} />
            );
          })}
        </SortableContainer>
      </React.Fragment>
    );
  }

  view__showCommunityBoards(area) {
    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-community-boards">{children}</ul>;
    });

    const SortableItem = sortableElement(({ area, board }) => {
      const _c = this.props.context;

      let v__description;

      if (board.description) {
        v__description = (
          <div className="board-description">{board.description}</div>
        );
      }

      let v__access;
      if (board.access_granted === "all") {
        v__access = _c.translate("admin.community.access.info.all.board");
      } else if (board.access_granted === "courses") {
        v__access = `${_c.translate(
          "admin.community.access.info.courses"
        )} ${this.view__showAccessCourseNames(board.access_granted_for)}`;
      }

      v__access = (
        <div className="area-access">
          <Icon icon="unlock" left />
          {_c.translate("admin.community.access.info.access")} {v__access}
        </div>
      );

      let v__editButton = (
        <button
          className="very tiny primary hollow button"
          data-tip={_c
            .translate("admin.community.board.buttons.edit.tooltip")
            .replace("{board}", board.name)}
          onClick={() => {
            this.setState({
              view: "board",
              selectedArea: cloneDeep(area),
              selectedBoard: cloneDeep(board),
            });
          }}
        >
          {_c.translate("admin.community.area.buttons.edit.label")}
        </button>
      );

      let v__deleteButton = (
        <button
          className="very tiny primary hollow button"
          data-tip={_c
            .translate("admin.community.board.buttons.delete.tooltip")
            .replace("{board}", board.name)}
          onClick={() => {
            this.handle__deleteBoard(board);
          }}
        >
          {_c.translate("admin.community.board.buttons.delete.label")}
        </button>
      );

      return (
        <li>
          <Box size="small">
            <Grid type="full">
              <Row>
                <Cell sm={2} md={1}>
                  <div className="board-drag-handle-wrapper">
                    <DragHandle />
                  </div>
                </Cell>
                <Cell sm={22} md={12}>
                  <h4>{board.name}</h4>
                  {v__description}
                  {v__access}
                </Cell>
                <Cell sm={12} md={3} className="text-center">
                  <div className="board-numbers">
                    {_c.translate("community.areas.cellTitles.threadCount")}
                    <br />
                    <strong>{board.thread_count}</strong>
                  </div>
                </Cell>
                <Cell sm={12} md={3} className="text-center">
                  <div className="board-numbers">
                    {_c.translate("community.areas.cellTitles.postCount")}
                    <br />
                    <strong>{board.post_count}</strong>
                  </div>
                </Cell>
                <Cell sm={12} md={5}>
                  <div className="float-right">
                    {v__deleteButton} {v__editButton}
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Box>
        </li>
      );
    });

    return (
      <React.Fragment>
        <SortableContainer
          onSortEnd={({ oldIndex, newIndex }) => {
            this.onBoardsSortEnd({ oldIndex, newIndex }, area);
          }}
          useDragHandle
          helperContainer={document.getElementById("adminSort2")}
        >
          {area.boards.map((board, index) => {
            return (
              <SortableItem
                key={`board-${index}`}
                index={index}
                area={area}
                board={board}
              />
            );
          })}
        </SortableContainer>
        {this.view__showButtonAddBoard(area)}
      </React.Fragment>
    );
  }

  view__showButtonAddArea() {
    return (
      <div className="text-center medium-text-right">
        <div className="small primary button-group hollow no-gaps">
          <button
            className="button"
            onClick={() => {
              const { newArea } = this.state;
              this.setState({
                view: "area",
                selectedArea: cloneDeep(newArea),
              });
            }}
          >
            <Icon icon="plus" left /> Bereich hinzufügen
          </button>
        </div>
      </div>
    );
  }

  view__showButtonAddBoard(area) {
    const _c = this.props.context;

    return (
      <div className="admin-community-add-board">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <button
          data-tip={_c
            .translate("admin.community.buttons.addBoard.tooltip")
            .replace("{area}", area.name)}
          onClick={() => {
            const { newBoard } = this.state;
            const board = cloneDeep(newBoard);
            board.area_id = area.id;
            this.setState({
              view: "board",
              selectedArea: cloneDeep(area),
              selectedBoard: board,
            });
          }}
        >
          <Icon icon="plus-circle" />
        </button>
      </div>
    );
  }

  view__showAccessCourseNames(courseIds) {
    const { courses } = this.state;
    const _c = this.props.context;

    let courseNames = [];
    courses.map((course) => {
      if (courseIds.includes(course.id)) {
        courseNames.push(course.name);
      }
      return null;
    });

    if (courseNames.length === 0) {
      return _c.translate("admin.community.access.info.none");
    } else {
      return courseNames.join(", ");
    }
  }

  onAreasSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    let { areas } = this.state;
    areas = arrayMove(areas, oldIndex, newIndex);

    const newOrder = [];
    areas.map((area) => {
      return newOrder.push(area.id);
    });

    this.setState({ areas }, () => {
      const apiUrl = _c.apiUrl("adminCommunitySortAreas");

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { areas } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    areas,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.community.area.messages.sorted.title"
                      ),
                      _c.translate("admin.community.area.messages.sorted.text"),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:community:areas:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:community:areas:sort");
          });
      }, 200);
    });
  };

  onBoardsSortEnd = ({ oldIndex, newIndex }, area) => {
    const _c = this.props.context;

    const boards = arrayMove(area.boards, oldIndex, newIndex);

    const newOrder = [];
    boards.map((board) => {
      return newOrder.push(board.id);
    });

    const apiUrl = _c.apiUrl("adminCommunitySortBoards");

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      axios
        .patch(
          apiUrl,
          { newOrder },
          {
            headers: this.props.context.headers,
          }
        )
        .then((response) => {
          try {
            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { areas } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  areas,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate("admin.community.board.messages.sorted.title"),
                    _c.translate("admin.community.board.messages.sorted.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:community:boards:sort:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:community:boards:sort");
        });
    }, 200);
  };

  handle__hidePopups = () => {
    this.setState({
      view: "list",
      selectedArea: null,
      selectedBoard: null,
      changesWereMade: false,
    });
  };

  handle__deleteArea = (area) => {
    const _c = this.props.context;
    let reallyDelete = false;

    if (area.boards && area.boards.length > 0) {
      if (area.post_count > 0 || area.thread_count > 0) {
        window.alert(_c.translate("admin.community.area.errors.notEmpty"));
      } else {
        if (
          window.confirm(
            _c.translate("admin.community.area.delete.confirmWithBoards")
          )
        ) {
          reallyDelete = true;
        }
      }
    } else {
      if (
        window.confirm(
          _c.translate("admin.community.area.delete.confirmWithoutBoards")
        )
      ) {
        reallyDelete = true;
      }
    }

    if (reallyDelete === true) {
      _c.setIsSaving(true, 1);
      _c.setSavingType();

      setTimeout(() => {
        const apiUrl = _c.apiUrl("adminCommunityArea", {
          id: area.id,
        });

        axios
          .delete(apiUrl, {
            headers: _c.headers,
          })
          .then((response) => {
            try {
              if (_c.isDebug()) {
                console.log("API RESPONSE", response.data);
              }

              const { status } = response.data;

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { areas } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    changesWereMade: false,
                    view: "list",
                    selectedArea: null,
                    areas,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.community.area.messages.deleted.title"
                      ),
                      _c.translate(
                        "admin.community.area.messages.deleted.text"
                      ),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:community:area:delete:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:community:area:delete");
          });
      }, 200);
    }
  };

  handle__deleteBoard = (board) => {
    const _c = this.props.context;
    let reallyDelete = false;

    if (board.post_count > 0 || board.thread_count > 0) {
      window.alert(_c.translate("admin.community.board.errors.notEmpty"));
    } else {
      if (
        window.confirm(_c.translate("admin.community.board.delete.confirm"))
      ) {
        reallyDelete = true;
      }
    }

    if (reallyDelete === true) {
      _c.setIsSaving(true, 1);
      _c.setSavingType();

      setTimeout(() => {
        const apiUrl = _c.apiUrl("adminCommunityBoard", {
          areaId: board.area_id,
          boardId: board.id,
        });

        axios
          .delete(apiUrl, {
            headers: _c.headers,
          })
          .then((response) => {
            try {
              if (_c.isDebug()) {
                console.log("API RESPONSE", response.data);
              }

              const { status } = response.data;

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { areas } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    changesWereMade: false,
                    view: "list",
                    selectedArea: null,
                    areas,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.community.area.messages.deleted.title"
                      ),
                      _c.translate(
                        "admin.community.area.messages.deleted.text"
                      ),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:community:area:delete:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:community:area:delete");
          });
      }, 200);
    }
  };

  handle__cancelEditArea = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let reallyCancel = false;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("admin.community.area.cancel.message"))) {
        reallyCancel = true;
      }
    } else {
      reallyCancel = true;
    }

    if (reallyCancel === true) {
      this.handle__hidePopups();
    }
  };

  handle__cancelEditBoard = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let reallyCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(_c.translate("admin.community.board.cancel.message"))
      ) {
        reallyCancel = true;
      }
    } else {
      reallyCancel = true;
    }

    if (reallyCancel === true) {
      this.handle__hidePopups();
    }
  };

  handle__saveArea = () => {
    const _c = this.props.context;
    const { selectedArea } = this.state;

    if (!selectedArea.name) {
      window.alert(_c.translate("admin.community.area.errors.noName"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminCommunityArea", { id: selectedArea.id });

      axios
        .post(
          apiUrl,
          {
            area: selectedArea,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { areas } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  view: "list",
                  selectedArea: null,
                  areas,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate("admin.community.area.messages.saved.title"),
                    _c.translate("admin.community.area.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:community:area:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:community:area:save");
        });
    }, 200);
  };

  handle__saveBoard = () => {
    const _c = this.props.context;
    const { selectedArea, selectedBoard } = this.state;

    if (!selectedBoard.name) {
      window.alert(_c.translate("admin.community.board.errors.noName"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("adminCommunityBoard", {
        areaId: selectedArea.id,
        boardId: selectedBoard.id,
      });

      axios
        .post(
          apiUrl,
          {
            board: selectedBoard,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { areas } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  view: "list",
                  selectedArea: null,
                  selectedBoard: null,
                  areas,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate("admin.community.board.messages.saved.title"),
                    _c.translate("admin.community.board.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:community:board:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:community:board:save");
        });
    }, 200);
  };

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("adminCommunity");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, user } = response.data;
          _c.updateUser(user);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { areas, courses } = apiResponseData;
            this.setState({
              areas,
              courses,
              status: "LOADED",
            });
            _c.initFinished();
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:community:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:community:init");
      });
  }
}

export default withContext(AdminCommunity);
