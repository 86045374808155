import React, { PureComponent } from "react";

/**
 * href = STRING (if href is given, <a/> will be shown, otherwise <button/> will be shown)
 * type = primary / secondary / warning / etc.
 * size = tiny / small / large
 * hollow = TRUE / FALSE
 *
 * classes = STRING
 */
export class Button extends PureComponent {
  render() {
    return <React.Fragment>{this.showButton()}</React.Fragment>;
  }

  showButton() {
    if (this.props.href) {
      return (
        <a
          href={this.props.href}
          className={this.getClasses()}
          title={this.props.title}
          onClick={this.props.click}
        >
          {this.props.children}
        </a>
      );
    } else {
      return (
        <button
          className={this.getClasses()}
          title={this.props.title}
          onClick={this.props.click}
        >
          {this.props.children}
        </button>
      );
    }
  }

  getClasses() {
    let classes = "button";

    if (this.props.classes) {
      classes += " " + this.props.classes;
    }

    if (this.props.type) {
      classes += " " + this.props.type;
    }

    if (this.props.size) {
      classes += " " + this.props.size;
    }

    if (this.props.hollow) {
      classes += " hollow";
    }

    return classes;
  }
}

/**
 * FONT AWESOME 4 ICONS
 *
 * icon = Font Awesome Icon (without fa-)
 */
export class Icon extends PureComponent {
  render() {
    return <i className={this.getClasses()} style={this.getMargins()} />;
  }

  getClasses() {
    let classes = "fa fa-" + this.props.icon;
    return classes;
  }

  getMargins() {
    let styles = {};

    if (this.props.left) {
      styles.paddingRight = ".2em";
    }

    if (this.props.right) {
      styles.paddingLeft = ".2em";
    }

    if (this.props.breadcrumb) {
      styles.paddingLeft = ".5em";
      styles.paddingRight = ".5em";
    }

    return styles;
  }
}
