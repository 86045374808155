import React, { Component } from "react";
import { Grid, Row, Cell, Icon } from "../foundation/foundation";

class AdminTags extends Component {
  state = {
    tags: this.props.tags.sort(),
    editMode: this.props.editMode,
    addTag: ""
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.tags !== prevProps.tags) {
      this.setState({ tags: this.props.tags });
    }

    if (this.props.editMode !== prevProps.editMode) {
      this.setState({ editMode: this.props.editMode });
    }
  }

  render() {
    return (
      <div className="admin-tags">
        {this.view__showTags()}
        {this.view__addTag()}
      </div>
    );
  }

  view__showTags() {
    const { tags, editMode } = this.state;

    return (
      <div className="admin-tags-tags">
        {tags.map((tag, index) => {
          let v__deleteButton;
          if (editMode === true) {
            v__deleteButton = (
              <button
                className="admin-tag-deletebutton"
                onClick={() => {
                  this.handle__delete(tag);
                }}
              >
                <Icon icon="times" />
              </button>
            );
          }

          return (
            <span className="admin-tags-tag" key={index}>
              {tag} {v__deleteButton}
            </span>
          );
        })}
      </div>
    );
  }

  view__addTag() {
    const { addTag, tags, editMode } = this.state;

    if (!editMode) {
      return null;
    }

    return (
      <div className="admin-tags-add-tag">
        <Grid type="full">
          <Row>
            <Cell sm={20} md={20}>
              <input
                type="text"
                value={addTag}
                onChange={e => {
                  this.handle__edit(e);
                }}
                onKeyUp={e => {
                  if (
                    e.keyCode === 13 &&
                    addTag !== "" &&
                    !tags.includes(addTag)
                  ) {
                    this.handle__addTag();
                  }
                }}
                placeholder="Tag hinzufügen"
              />
            </Cell>
            <Cell sm={4} md={4}>
              <button
                className="admin-tags-add-tag-button small primary button"
                onClick={this.handle__addTag}
                disabled={addTag === "" || tags.includes(addTag)}
              >
                <Icon icon="plus" />
              </button>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  handle__edit(e) {
    let addTag = e.target.value;
    addTag = addTag.replace(" ", "").toLowerCase();
    this.setState({ addTag });
  }

  handle__delete(tagToDelete) {
    const { tags } = this.state;

    const newTags = [];
    tags.map(tag => {
      if (tag !== tagToDelete) {
        newTags.push(tag);
      }
      return null;
    });

    this.setState({ tags: newTags });
  }

  handle__addTag = () => {
    let { tags, addTag } = this.state;

    if (!tags.includes(addTag)) {
      tags.push(addTag);
      tags.sort();

      this.setState({ tags, addTag: "" });
      this.props.onUpdate(tags);
    }
  };
}

export default AdminTags;
