import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import CourseLayout from "./CourseLayout";
import axios from "axios";
import { Grid, Row, Cell } from "../foundation/_grid";
import Video from "./parts/Video";
import LessonList from "./parts/LessonList";
import { Icon } from "../foundation/_buttons";
import LessonNotes from "./parts/LessonNotes";
import MainTopbar from "../topbars/mainTopbar";
import Footer from "../layout/Footer";
import PTTabs from "../pttabs/PTTabs";
import CourseDownloads from "./CourseDownloads";
import Audio from "./parts/Audio";

class CourseLesson extends Component {
  state = {
    baseUrl: "/course",
    courseSlug: this.props.match.params.courseSlug,
    lessonSlug: this.props.match.params.lessonSlug,
    course: null,
    lesson: null,
    lessons: null,
    downloads: null,
    status: "INIT",
    player: undefined,
    videoDuration: 0,
    showNotePopup: false,
    selectedNote: null,
    showLessonListOnMobile: false,
    noAccess: false,
    purchaseUrl: null,
    tabs: {
      notes: {
        name: "Meine Notizen",
        icon: "sticky-note-o",
      },
    },
    selectedTab: "notes",
    showTabs: false,
    showFinishButton: false,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { courseSlug, lessonSlug } = this.state;

    if (this.props.match.params.courseSlug !== courseSlug) {
      this.setState({ courseSlug: this.props.match.params.courseSlug }, () => {
        this.init();
      });
    }

    if (this.props.match.params.lessonSlug !== lessonSlug) {
      this.setState({ lessonSlug: this.props.match.params.lessonSlug }, () => {
        this.init();
      });
    }
  }

  render() {
    const {
      baseUrl,
      course,
      lesson,
      lessons,
      status,
      downloads,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile,
      noAccess,
      tabs,
      selectedTab,
      showTabs,
      showFinishButton,
    } = this.state;

    if (!course || !course.id) {
      return null;
    }

    if (noAccess === true) {
      return (
        <div className="page page-course">
          <MainTopbar type="course" />
          <main className="content">
            <Grid>
              <Row>
                <Cell sm={24}>{this.view__showNoAccess()}</Cell>
              </Row>
            </Grid>
          </main>
          <Footer />
        </div>
      );
    }

    let v__content;
    if (lesson.type === "audio") {
      v__content = <Audio lesson={lesson} />;
    } else {
      v__content = (
        <Video
          course={course}
          lesson={lesson}
          status={status}
          player={player}
          onUpdate={(lessonStatus) => {
            const { lesson } = this.state;
            lesson.lessonStatus = lessonStatus;

            let showFinishButton = false;
            if (parseInt(lessonStatus.progress) > 90) {
              showFinishButton = true;
            }

            this.setState({ lesson, showFinishButton });
          }}
          onUpdatePlayer={(player) => {
            this.setState({ player });
          }}
        />
      );
    }

    return (
      <div className={`course-course course-course-${course.id}`}>
        <CourseLayout
          status={status}
          course={course}
          lesson={lesson}
          lessons={lessons}
        >
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showTitle()}</Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={20} mdo={2}>
                {v__content}
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={8}>
                <LessonList
                  baseUrl={baseUrl}
                  course={course}
                  lesson={lesson}
                  lessons={lessons}
                  status={status}
                  downloads={downloads}
                  showLessonListOnMobile={showLessonListOnMobile}
                  closeLessonListOnMobile={() => {
                    this.setState({ showLessonListOnMobile: false });
                  }}
                />
              </Cell>
              <Cell sm={24} md={16}>
                <div className="course-lesson-below-video-buttons">
                  <button
                    className="primary hollow button show-for-small-only"
                    onClick={() =>
                      this.setState({ showLessonListOnMobile: true })
                    }
                  >
                    <Icon icon="th-list" left /> Lektionen
                  </button>

                  <button
                    className="primary hollow button"
                    onClick={() => {
                      this.setState({ selectedTab: "notes" }, () => {
                        this.setState({
                          showNotePopup: true,
                          selectedNote: "new",
                        });
                      });
                    }}
                  >
                    <Icon icon="sticky-note" left /> Notiz hinzufügen
                  </button>
                  <button
                    className="primary button"
                    style={{
                      marginLeft: "5px",
                      display:
                        showFinishButton === true &&
                        lesson.lessonStatus.finished === null
                          ? "inline-block"
                          : "none",
                    }}
                    onClick={() => {
                      if (
                        window.confirm(
                          "Möchtest Du diese Lektion als abgeschlossen markieren und mit der folgenden Lektion fortfahren?"
                        )
                      ) {
                        this.handle__finishLesson();
                      }
                    }}
                  >
                    Weiter zur nächsten Lektion{" "}
                    <Icon icon="angle-right" right />
                  </button>
                </div>

                <div style={{ display: showTabs === true ? "block" : "none" }}>
                  <div className="course-tabs">
                    <PTTabs
                      tabs={tabs}
                      selected={selectedTab}
                      onUpdate={(selectedKeyName) => {
                        this.setState({ selectedTab: selectedKeyName });
                      }}
                    />
                  </div>

                  {this.view__showTabsContent()}
                </div>

                <div style={{ display: showTabs === true ? "none" : "block" }}>
                  <LessonNotes
                    lesson={lesson}
                    showNotePopup={showNotePopup}
                    selectedNote={selectedNote}
                    onNoteSave={(lesson) => {
                      this.setState({ lesson });
                    }}
                    onGoToSecond={(seconds) => {
                      this.handle__goToSecond(seconds);
                    }}
                    onPopupClose={(showNotePopup, selectedNote) => {
                      this.setState({ showNotePopup, selectedNote });
                    }}
                  />
                </div>
              </Cell>
            </Row>
          </Grid>
        </CourseLayout>
      </div>
    );
  }

  view__showTabsContent() {
    const {
      selectedTab,
      lesson,
      showNotePopup,
      selectedNote,
      course,
      downloads,
    } = this.state;

    if (selectedTab === "notes") {
      return (
        <LessonNotes
          lesson={lesson}
          showNotePopup={showNotePopup}
          selectedNote={selectedNote}
          onNoteSave={(lesson) => {
            this.setState({ lesson });
          }}
          onGoToSecond={(seconds) => {
            this.handle__goToSecond(seconds);
          }}
          onPopupClose={(showNotePopup, selectedNote) => {
            this.setState({ showNotePopup, selectedNote });
          }}
        />
      );
    } else if (selectedTab === "downloads") {
      return <CourseDownloads course={course} downloads={downloads} />;
    }
  }

  view__showNoAccess() {
    const { purchaseUrl } = this.state;

    let v__button;
    if (purchaseUrl) {
      v__button = (
        <div style={{ marginTop: "20px" }}>
          <a
            href={purchaseUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="primary hollow button"
          >
            Mehr über diesen Kurs
          </a>
        </div>
      );
    }

    return (
      <div className="course-noaccess">
        Auf diesen Kurs hast Du leider keinen Zugriff.
        {v__button}
      </div>
    );
  }

  view__showTitle() {
    const { course, lesson } = this.state;

    if (!course || !lesson) {
      return;
    }

    let pretitle, title, description;

    if (course.name) {
      pretitle = <div className="course-lesson-pretitle">{course.name}</div>;
    }

    if (lesson.name) {
      title = <h1>{lesson.name}</h1>;
    }

    if (lesson.description) {
      description = (
        <div className="course-lesson-description">{lesson.description}</div>
      );
    }

    return (
      <header>
        {pretitle}
        {title}
        {description}
        <div>&nbsp;</div>
      </header>
    );
  }

  handle__goToSecond(second) {
    const { player } = this.state;
    player.setCurrentTime(second);
  }

  handle__finishLesson() {
    const { lesson, course, baseUrl } = this.state;
    const { lessonStatus } = lesson;
    const _c = this.props.context;

    lessonStatus.finished = "NOW";

    let url = _c.apiUrl("updateLessonStatus", {
      lessonId: lesson.id,
    });

    axios
      .patch(
        url,
        {
          lessonStatus: lessonStatus,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { nextLesson } = response.data;

          if (nextLesson && nextLesson.slug) {
            let url = `${baseUrl}/${course.slug}/${nextLesson.slug}`;
            window.location.href = url;
          }
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }

  init() {
    const { courseSlug, lessonSlug, tabs } = this.state;
    const _c = this.props.context;

    let url = _c.apiUrl("courseLesson", {
      courseSlug,
      lessonSlug,
    });

    axios
      .get(url, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const {
              user,
              course,
              lessons,
              lesson,
              downloads,
              error,
              url,
            } = response.data.data;

            if (error && error === "NOACCESS") {
              let purchaseUrl = "";
              if (url) {
                purchaseUrl = url;
              }
              this.setState({ noAccess: true, purchaseUrl });

              _c.updateUser(user, () => {
                _c.initFinished();
              });

              return;
            }

            let showTabs = false;

            /*if (course.allow_comments === true) {
            showTabs = true;

            tabs.comments = {
              name: "Kommentare",
              icon: "comment-o",
            };
          }*/

            if (course.show_downloads_tab === true) {
              showTabs = true;

              tabs.downloads = {
                name: "Kursmaterial",
                icon: "download",
              };
            }

            this.setState({
              course,
              lessons,
              lesson,
              downloads,
              showTabs,
              tabs,
              status: "LOADED",
            });
            _c.setPageTitle(`${lesson.name} [${course.name}]`);

            _c.updateUser(user, () => {
              _c.initFinished();
            });
          }
        } catch {
          alert(
            "CourseLesson - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.checkError(error);
      });

    _c.initFinished();
  }
}

export default withContext(CourseLesson);
