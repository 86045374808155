import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import Avatar from "../../user/Avatar";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../foundation/_buttons";
import ReactTooltip from "react-tooltip";
import PTTabs from "../../pttabs/PTTabs";
import AdminLog from "../AdminLog";
import PTDialog from "../../ptdialog/PTDialog";
import AdminMemberPurchases from "./AdminMemberPurchases";

class AdminMember extends Component {
  state = {
    baseUrl: "/admin/members",
    loadingError: false,
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_members"],
    tabs: {
      info: {
        name: "Informationen",
        icon: "user",
      },
      purchases: {
        name: "Käufe",
        icon: "shopping-cart",
      },
      logs: {
        name: "Log",
        icon: "clock-o",
      },
    },
    selectedTab: "info",
    member: undefined,
    original: undefined,
    purchases: undefined,
    newPurchase: undefined,
    courses: undefined,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Mitgliederverwaltung");
  }

  render() {
    const { breadcrumbs, baseUrl } = this.state;

    return (
      <AdminLayout nav="members" breadcrumbs={breadcrumbs}>
        <AdminBackButton url={baseUrl} />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
      </AdminLayout>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, member } = this.state;

    if (!member) {
      return null;
    }

    return (
      <React.Fragment>
        <PTDialog
          show={showDialogDelete}
          message={
            <p>
              Möchtest Du das Mitglied{" "}
              <em>{`${member.firstname} ${member.lastname} `}</em> wirklich
              unwiderruflich löschen?
              <br />
              Diese Aktion kann <u>nicht</u> rückgängig gemacht werden.
            </p>
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { member, original, requestedId } = this.state;
    const _c = this.props.context;

    if (isUndefined(member)) {
      return <h1>Wird geladen...</h1>;
    } else if (member === null) {
      return <h1>Nicht gefunden</h1>;
    } else if (member && requestedId === "new") {
      return <h1>Neues Mitglied anlegen</h1>;
    } else if (member && member.id && member.email) {
      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">Mitglied</div>
          <h1>{_c.getFullName(original)}</h1>
        </React.Fragment>
      );
    } else {
      return <h1>Unbekannter Fehler</h1>;
    }
  }

  view__showPageActions() {
    const { editMode } = this.state;

    if (editMode === true) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <button className="button" onClick={this.handle__toggleEditMode}>
            <Icon icon="cog" left /> Mitglied bearbeiten
          </button>
          <button
            className="button"
            onClick={() => {
              this.setState({ showDialogDelete: true });
            }}
          >
            <Icon icon="trash" left /> Mitglied löschen
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <PTTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            data-tip="Abbrechen und zurück zur Übersicht"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="small primary button"
            data-tip="Änderungen speichern"
            onClick={this.handle__save}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;

    if (editLock === true) {
      const message = `Dieser Eintrag kann von Dir nicht bearbeitet werden, weil er gerade von ${editLockUser} bearbeitet wird.`;
      const _c = this.props.context;
      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showContent() {
    const {
      member,
      purchases,
      newPurchase,
      courses,
      loadingError,
      selectedTab,
    } = this.state;
    const _c = this.props.context;

    if (isUndefined(member)) {
      return _c.loading(loadingError);
    } else if (member === null) {
      return _c.notfound("Mitglied nicht gefunden");
    }

    if (selectedTab === "info") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxNameInfo()}
                {this.view__showBoxContactInfo()}
                {this.view__showBoxAddressInfo()}
              </Cell>
              <Cell sm={24} md={12}>
                {this.view__showBoxPersonalInfo()}

                {this.view__showBoxAccountInfo()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "purchases") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminMemberPurchases
                  member={member}
                  purchases={purchases}
                  courses={courses}
                  newPurchase={newPurchase}
                />
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showBoxNameInfo() {
    const { member, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Name</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.firstname}
                editValue={member.firstname}
                editType="text"
                label="Vorname"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("firstname", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.lastname}
                editValue={member.lastname}
                editType="text"
                label="Nachname"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("lastname", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxPersonalInfo() {
    const { member, editMode } = this.state;
    const _c = this.props.context;

    let selectValuesGenders = [];
    selectValuesGenders = _c.getGenders();

    let memberGender = member.gender;
    if (!memberGender) {
      memberGender = "";
    }

    let memberGender_f = _c.getGender(member.gender);
    if (!memberGender_f) {
      memberGender_f = "";
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Persönliche Informationen</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={16}>
              <Grid type="full">
                <Row margin="x">
                  <Cell sm={24}>
                    <AdminDataField
                      value={member.birthday_date || ""}
                      editValue={member.birthday || ""}
                      editType="date"
                      label="Geburtstag"
                      editable={true}
                      edit={editMode}
                      onUpdate={(newValue) => {
                        this.handle__edit("birthday", newValue);
                      }}
                    />
                  </Cell>
                </Row>
                <Row margin="x">
                  <Cell sm={24}>
                    <AdminDataField
                      value={memberGender_f}
                      editValue={memberGender}
                      editType="select"
                      label="Geschlecht"
                      selectValues={selectValuesGenders}
                      editable={true}
                      edit={editMode}
                      onUpdate={(newValue) => {
                        this.handle__edit("gender", newValue);
                      }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Cell>
            <Cell sm={24} md={8} className="text-center">
              <Avatar user={member} size="admin-user-view" />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxContactInfo() {
    const { member, original, editMode, requestedId } = this.state;

    let emailNote = null;
    if (
      editMode === true &&
      requestedId !== "new" &&
      member.email !== original.email
    ) {
      emailNote = (
        <div className="small warning callout">
          <small>
            <strong>
              <Icon icon="exclamation-circle" /> Achtung:
            </strong>{" "}
            Die E-Mail-Adresse wird von diesem Mitglied benutzt, um sich in
            seinen Account einzuloggen. Bitte informiere das Mitglied
            entsprechend, falls Du seine E-Mail-Adresse änderst!
          </small>
        </div>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Kontakt</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              {emailNote}
              <AdminDataField
                value={member.email}
                editValue={member.email}
                editType="text"
                label="E-Mail-Adresse"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("email", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.phone}
                editValue={member.phone}
                editType="text"
                label="Telefon"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("phone", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAddressInfo() {
    const { member, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Adresse</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.street}
                editValue={member.street}
                editType="text"
                label="Straße"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("street", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={6}>
              <AdminDataField
                value={member.zip}
                editValue={member.zip}
                editType="text"
                label="PLZ"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("zip", newValue);
                }}
              />
            </Cell>
            <Cell sm={24} md={18}>
              <AdminDataField
                value={member.city}
                editValue={member.city}
                editType="text"
                label="Stadt"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("city", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.country}
                editValue={member.country}
                editType="text"
                label="Land"
                editable={true}
                edit={editMode}
                onUpdate={(newValue) => {
                  this.handle__edit("country", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxAccountInfo() {
    const { member, editMode } = this.state;

    let accountActive = null;
    if (member.active === true) {
      accountActive = (
        <p className="admin-active">
          <small>
            <strong>
              <Icon icon="unlock" left /> Account ist freigeschaltet.
            </strong>
          </small>
        </p>
      );
    } else if (member.active === false) {
      accountActive = (
        <p className="admin-inactive">
          <small>
            <strong>
              <Icon icon="lock" left /> Account ist gesperrt.
            </strong>
          </small>
        </p>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Account</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} className="text-center">
              {accountActive}
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.registration_date}
                editValue={member.registration_date}
                editType="text"
                label="Registriert seit"
                editable={false}
                edit={editMode}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={member.last_visit_date}
                editValue={member.last_visit_date}
                editType="text"
                label="Letzter Besuch"
                editable={false}
                edit={editMode}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <div style={{ paddingTop: "10px" }}>
                <small>
                  <AdminDataField
                    value={
                      member.passwordResetUrl ? (
                        <a
                          href={member.passwordResetUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {member.passwordResetUrl}
                        </a>
                      ) : (
                        ""
                      )
                    }
                    editValue={member.passwordResetUrl}
                    editType="text"
                    label="Password Reset URL"
                    editable={false}
                    edit={editMode}
                  />
                </small>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showLogs() {
    const { member } = this.state;

    if (!member || !member.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={member.logs} />
      </React.Fragment>
    );
  }

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { member } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("member", member.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { member } = this.state;
    member[property] = value;
    this.setState({ member, changesWereMade: true });
  }

  handle__delete = () => {
    const { member, baseUrl } = this.state;
    const _c = this.props.context;

    axios
      .delete(_c.apiEndpoints.adminMembers + "/" + member.id, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (response.data === "OK") {
            _c.createNotifictation(
              "Mitglied gelöscht",
              `Das Mitglied ${member.firstname} ${member.lastname} wurde erfolgreich gelöscht.`,
              "success"
            );
            this.props.history.push(`${baseUrl}`);
          }
        } catch {
          alert(
            "Admin Delete Member - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  handle__cancel = () => {
    const { requestedId, baseUrl, changesWereMade } = this.state;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Es wurden bereits Änderungen an diesem Eintrag vorgenommen.\r\n\r\nMöchtest Du wirklich abbrechen?\r\nAlle Änderungen gehen dann verloren."
        )
      ) {
        if (requestedId === "new") {
          window.location = baseUrl;
        } else {
          let { member, original } = this.state;
          member = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, member });
          const _c = this.props.context;
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = baseUrl;
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        const _c = this.props.context;
        _c.cancelEditLock();
      }
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { member, requestedId, baseUrl } = this.state;

    if (!member.firstname || !member.lastname || !member.email) {
      window.alert(
        "Bitte gib mindestens einen Namen und eine E-Mail-Adresse an."
      );
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let url = _c.apiEndpoints.adminMembers + "/" + requestedId;

      // Prepare Birthday
      if (member.birthday) {
        member.birthday = _c.formatDateForSaving(member.birthday);
      }

      axios
        .post(
          url,
          {
            member: member,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { member } = response.data;

          if (requestedId === "new" && member && member.id) {
            window.location = baseUrl + "/" + member.id;
            return;
          }

          const original = JSON.parse(JSON.stringify(member));
          _c.setIsSaving(false);
          this.setState(
            {
              changesWereMade: false,
              editMode: false,
              member,
              original,
            },
            () => {
              _c.cancelEditLock();
              _c.createNotifictation(
                "Eintrag gespeichert",
                "Die Änderungen am Mitgliedseintrag wurden erfolgreich gespeichert.",
                "success"
              );
            }
          );
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const { requestedId, baseUrl } = this.state;
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminMembers + "/" + requestedId, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { status } = response.data;
          console.log(response.data);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { member, purchases, newPurchase, courses } = apiResponseData;
            const { user } = response.data;

            const original = JSON.parse(JSON.stringify(member));

            if (newPurchase) {
              newPurchase.id = "new";
            }

            let pageTitle;
            if (requestedId === "new") {
              pageTitle = "Neues Mitglied";
            } else {
              pageTitle = `${member.firstname} ${member.lastname}`;
            }

            _c.setPageTitle(pageTitle);

            const bc1 = {
              id: "admin_member",
              to: `${baseUrl}/${member.id}`,
              name: pageTitle,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);

            this.setState({
              member,
              original,
              purchases,
              newPurchase,
              courses,
              breadcrumbs,
            });

            if (requestedId === "new") {
              this.setState({ editMode: true });
            }

            if (_c.isDebug()) {
              console.log(response.data);
            }

            _c.updateUser(user, () => {
              _c.initFinished();
            });
          }
        } catch {
          alert(
            "Admin Member - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminMember);
