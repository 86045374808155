import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import AdminLayout from "../AdminLayout";
import Box from "../../layout/Box";
import { Cell, Grid, Row } from "../../foundation/_grid";
import { Icon } from "../../foundation/_buttons";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import Dropzone from "react-dropzone";

class AdminSettings extends Component {
  state = {
    settings: null,
    selectedTab: "index",
    selectedSetting: null,
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Admin Einstellungen");
  }

  render() {
    return (
      <AdminLayout
        nav="settings"
        breadcrumbs={["admin_dashboard", "admin_settings"]}
      >
        <h1>Einstellungen</h1>
        {this.view__showSettingsYoutube()}
        {this.view__showSettingsEbook()}
        {this.view__showPopup()}
      </AdminLayout>
    );
  }

  view__showPopup() {
    const { selectedSetting } = this.state;
    const _c = this.props.context;

    if (!selectedSetting) {
      return null;
    }

    return (
      <MMPopup
        show={true}
        size="medium"
        nopadding
        handleClose={this.hidePopupImageUpload}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {
            const picture = acceptedFiles[0];

            const reader = new FileReader();

            reader.addEventListener(
              "load",
              function () {
                const formData = new FormData();

                formData.append("file", picture);
                formData.append("name", picture.name);
                formData.append("setting_key", selectedSetting);

                const apiUrl = _c.apiUrl("adminFileUpload");

                axios
                  .post(apiUrl, formData, {
                    headers: _c.headers,
                  })
                  .then((response) => {
                    try {
                      if (_c.isDebug()) {
                        console.log("API RESPONSE", response.data);
                      }

                      this.hidePopupImageUpload();

                      _c.handleApiResponse(response.data, true);

                      const { app } = response.data;

                      this.setState({ settings: app.settings });

                      _c.createNotifictation(
                        "Einstellung gespeichert",
                        `Die Einstellung ${selectedSetting} wurde gespeichert.`,
                        "success"
                      );
                    } catch {
                      return _c.handleError(
                        { status: "AXIOS RESPONSE ERROR" },
                        "settings:response"
                      );
                    }
                  })
                  .catch((error) => {
                    console.log("ERROR", error);
                  });
              }
                .bind(this)
                .bind(selectedSetting),
              false
            );

            if (picture) {
              reader.readAsDataURL(picture);
            }
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div id="avatarDropZone">
                  {selectedSetting === "ebook_url" ? (
                    <h3>PDF hochladen</h3>
                  ) : (
                    <h3>Bild hochladen</h3>
                  )}
                  <div id="avatarDropZoneText">
                    <p>
                      <Icon icon="upload" />
                    </p>
                    {selectedSetting === "ebook_url" ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            "Lege in diesem Popup eine PDF-Datei ab oder<br />klicke hier, um ein Bild hochzuladen.",
                        }}
                      />
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            "Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab oder<br />klicke hier, um ein Bild hochzuladen.",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </MMPopup>
    );
  }

  view__showSettingsYoutube() {
    const { settings } = this.state;
    const _c = this.props.context;

    if (!settings) {
      return null;
    }

    let settingKeys = {
      index_youtube_video_1: "Youtube Video 1",
      index_youtube_video_2: "Youtube Video 2",
      index_youtube_video_3: "Youtube Video 3",
    };

    return (
      <div>
        <h2>Index: Youtube Videos</h2>
        <Box>
          <p>
            Du brauchst nur die Video-ID angeben, der richtige Embed-Code wird
            automatisch generiert.
          </p>

          {Object.keys(settingKeys).map((settingKey, index) => {
            let settingValue = settings[settingKey].value;

            if (!settingValue) {
              settingValue = "";
            }

            return (
              <div key={index}>
                <h3>{settingKeys[settingKey]}</h3>
                <Grid type="full">
                  <Row>
                    <Cell sm={20} md={20}>
                      <input
                        type="text"
                        value={settingValue}
                        placeholder="Youtube Video-ID"
                        style={{ marginBottom: "0", height: "auto" }}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          settingValue = newValue;
                          settings[settingKey].value = newValue;
                          this.setState({ settings });
                        }}
                      />
                    </Cell>
                    <Cell sm={4} md={4}>
                      <button
                        className="primary button"
                        onClick={() => {
                          let apiUrl = _c.apiUrl("setting");

                          axios
                            .post(
                              apiUrl,
                              {
                                key: settingKey,
                                value: settingValue,
                              },
                              {
                                headers: _c.getHeaders(),
                              }
                            )
                            .then((response) => {
                              try {
                                if (_c.isDebug()) {
                                  console.log("API RESPONSE", response.data);
                                }

                                _c.handleApiResponse(response.data, true);

                                _c.createNotifictation(
                                  "Einstellung gespeichert",
                                  "Die Einstellung [" +
                                    settingKey +
                                    "] wurde gespeichert.",
                                  "success"
                                );
                              } catch {
                                return _c.handleError(
                                  { status: "AXIOS RESPONSE ERROR" },
                                  "setting:response"
                                );
                              }
                            })
                            .catch((error) => {
                              if (error.response) {
                                return _c.handleError(error, "setting");
                              }
                            });
                        }}
                      >
                        <Icon icon="check" /> Speichern
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </div>
            );
          })}
        </Box>
      </div>
    );
  }

  view__showSettingsEbook() {
    const { settings } = this.state;
    const _c = this.props.context;

    if (!settings) {
      return null;
    }

    const settingsKeys = ["ebook_url", "ebook_thumbnail"];

    return (
      <div>
        <h2>E-Book</h2>
        {settingsKeys.map((settingKey, index) => {
          return (
            <Box key={index}>
              {settingKey === "ebook_url" ? <h3>E-Book PDF</h3> : null}
              {settingKey === "ebook_thumbnail" ? (
                <h3>E-Book Thumbnail</h3>
              ) : null}
              <Grid type="full">
                <Row>
                  <Cell sm={19} md={19}>
                    <input
                      type="text"
                      value={settings[settingKey].value}
                      placeholder="URL zur PDF"
                      style={{ marginBottom: "0", height: "auto" }}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        settings[settingKey].value = newValue;
                        this.setState({ settings });
                      }}
                    />
                  </Cell>
                  <Cell sm={5} md={5}>
                    <button
                      className="primary hollow button"
                      onClick={() => {
                        this.setState({ selectedSetting: settingKey });
                      }}
                    >
                      <Icon icon="upload" />
                    </button>
                    <button
                      className="primary button"
                      onClick={() => {
                        let apiUrl = _c.apiUrl("setting");

                        axios
                          .post(
                            apiUrl,
                            {
                              key: settingKey,
                              value: settings[settingKey].value,
                            },
                            {
                              headers: _c.getHeaders(),
                            }
                          )
                          .then((response) => {
                            try {
                              if (_c.isDebug()) {
                                console.log("API RESPONSE", response.data);
                              }

                              _c.handleApiResponse(response.data, true);

                              _c.createNotifictation(
                                `Einstellung gespeichert`,
                                `Die Einstellung ${settingKey} wurde gespeichert.`,
                                "success"
                              );
                            } catch {
                              return _c.handleError(
                                { status: "AXIOS RESPONSE ERROR" },
                                "setting:response"
                              );
                            }
                          })
                          .catch((error) => {
                            if (error.response) {
                              return _c.handleError(error, "setting");
                            }
                          });
                      }}
                    >
                      <Icon icon="check" /> Speichern
                    </button>
                  </Cell>
                </Row>
              </Grid>
            </Box>
          );
        })}
      </div>
    );
  }

  hidePopupImageUpload = () => {
    this.setState({ selectedSetting: null });
  };

  init() {
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminDashboard, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.handleApiResponse(response.data, true);

          const { app } = response.data;

          this.setState({ settings: app.settings });
        } catch {
          alert(
            "Admin Settings - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminSettings);
