import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isUndefined } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import AdminBackButton from "../AdminBackButton";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../foundation/_buttons";
import ReactTooltip from "react-tooltip";
import AdminLog from "../AdminLog";

import PTTabs from "../../pttabs/PTTabs";
import PTDialog from "../../ptdialog/PTDialog";
import PTPopup from "../../ptPopup/PTPopup";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AdminTags from "../AdminTags";

class AdminLiveCall extends Component {
  state = {
    baseUrl: "/admin/livecalls",
    loadingError: false,
    requestedId: this.props.match.params.id,
    breadcrumbs: ["admin_dashboard", "admin_livecalls"],

    tabs: {
      info: {
        name: "Informationen",
        icon: "user"
      },
      logs: {
        name: "Log",
        icon: "clock-o"
      }
    },
    selectedTab: "info",
    livecall: undefined,
    original: undefined,
    editMode: false,
    changesWereMade: false,
    editLock: false,
    editLockUser: undefined,
    showDialogDelete: false,
    uploadImageStatus: null,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 16 / 10
    }
  };

  componentDidMount() {
    this.init();
    this.props.context.setPageTitle("Live Call");
  }

  render() {
    const { breadcrumbs, baseUrl } = this.state;

    return (
      <AdminLayout nav="livecalls" breadcrumbs={breadcrumbs}>
        <AdminBackButton url={baseUrl} />
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showPageButtons()}
        {this.view__showEditLock()}
        {this.view__showContent()}
        {this.view__showPageButtons()}
        {this.view__showDialogs()}
        {this.view__showPopups()}
      </AdminLayout>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, livecall } = this.state;

    if (!livecall) {
      return null;
    }

    return (
      <React.Fragment>
        <PTDialog
          show={showDialogDelete}
          message={
            <p>
              Möchtest Du den Live Call <em>{`${livecall.title}`}</em> wirklich
              unwiderruflich löschen?
              <br />
              Diese Aktion kann <u>nicht</u> rückgängig gemacht werden.
            </p>
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow"
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary"
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showHeadline() {
    const { livecall, original, requestedId } = this.state;
    const _c = this.props.context;

    if (isUndefined(livecall)) {
      return <h1>Wird geladen...</h1>;
    } else if (livecall === null) {
      return <h1>Nicht gefunden</h1>;
    } else if (livecall && requestedId === "new") {
      return <h1>Neuen Live Call anlegen</h1>;
    } else if (livecall && livecall.id && livecall.title) {
      return (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <div className="pretitle">Live Call</div>
          <h1>{original.title}</h1>
        </React.Fragment>
      );
    } else {
      return <h1>Unbekannter Fehler</h1>;
    }
  }

  view__showPageActions() {
    const { editMode } = this.state;

    if (editMode === true) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <button className="button" onClick={this.handle__toggleEditMode}>
            <Icon icon="cog" left /> Live Call bearbeiten
          </button>
          <button
            className="button"
            onClick={() => {
              this.setState({ showDialogDelete: true });
            }}
          >
            <Icon icon="trash" left /> Live Call löschen
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            data-tip="Abbrechen und zurück zur Übersicht"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> Abbrechen
          </button>
          <button
            className="small primary button"
            data-tip="Änderungen speichern"
            onClick={this.handle__save}
          >
            <Icon icon="check" left /> Speichern
          </button>
        </div>
      );
    }
  }

  view__showEditLock() {
    const { editLock, editLockUser } = this.state;

    if (editLock === true) {
      const message = `Dieser Eintrag kann von Dir nicht bearbeitet werden, weil er gerade von ${editLockUser} bearbeitet wird.`;
      const _c = this.props.context;
      return _c.showMsgEditLocked(message);
    }

    return null;
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <PTTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={selectedKeyName => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showContent() {
    const { livecall, loadingError, selectedTab } = this.state;
    const _c = this.props.context;

    if (isUndefined(livecall)) {
      return _c.loading(loadingError);
    } else if (livecall === null) {
      return _c.notfound("Live Call nicht gefunden");
    }

    if (selectedTab === "info") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {this.view__showBoxLiveInfo()}
                {this.view__showBoxLiveId()}
              </Cell>
              <Cell sm={24} md={12}>
                {this.view__showBoxLiveImage()}
                {this.view__showBoxTags()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "logs") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>{this.view__showLogs()}</Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showBoxLiveInfo() {
    const { livecall, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Live Call Informationen</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={livecall.start_date}
                editValue={livecall.start}
                editType="datetime"
                label="Startzeit"
                editable={true}
                edit={editMode}
                onUpdate={newValue => {
                  this.handle__edit("start", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={livecall.title}
                editValue={livecall.title}
                editType="text"
                label="Titel"
                editable={true}
                edit={editMode}
                onUpdate={newValue => {
                  this.handle__edit("title", newValue);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxLiveId() {
    const { livecall, editMode } = this.state;
    const _c = this.props.context;

    const livecallTypes = _c.getLivecallTypes();
    let selectValues = {};
    Object.keys(livecallTypes).map(livecallTypeKey => {
      selectValues[livecallTypeKey] = livecallTypes[livecallTypeKey].title;
      return null;
    });

    let v__livecall_id;
    if (livecall.type === "youtube") {
      v__livecall_id = (
        <Row margin="x">
          <Cell sm={24}>
            <AdminDataField
              value={livecall.external_id}
              editValue={livecall.external_id}
              editType="text"
              label="Youtube Live URL"
              editable={true}
              edit={editMode}
              onUpdate={newValue => {
                this.handle__edit("external_id", newValue);
              }}
            />
          </Cell>
        </Row>
      );
    } else {
      livecall.type = "vimeo";
      v__livecall_id = (
        <Row margin="x">
          <Cell sm={24}>
            <AdminDataField
              value={livecall.external_id}
              editValue={livecall.external_id}
              editType="text"
              label="Vimeo Live Id"
              editable={true}
              edit={editMode}
              onUpdate={newValue => {
                this.handle__edit("external_id", newValue);
              }}
            />
          </Cell>
        </Row>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Live Call Typ</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <AdminDataField
                value={_c.getLivecallType(livecall.type)}
                editValue={livecall.type}
                editType="select"
                label="Live Call Typ"
                selectValues={selectValues}
                editable={true}
                edit={editMode}
                onUpdate={newValue => {
                  this.handle__edit("type", newValue);
                }}
              />
            </Cell>
          </Row>
          {v__livecall_id}
        </Grid>
      </Box>
    );
  }

  view__showBoxLiveImage() {
    const { livecall, editMode } = this.state;

    let livecallImage = (
      <div className="admin-livecall-noimage wrapper">
        <div className="center-vertically">Kein Vorschaubild ausgewählt.</div>
      </div>
    );
    if (livecall.image) {
      livecallImage = <img src={livecall.image} alt="Vorschaubild" />;
    }

    let uploadButton = (
      <button className="button" onClick={this.showPopupImageUpload}>
        <Icon icon="upload" left /> Neues Bild hochladen
      </button>
    );

    let deleteButton = (
      <button className="button" onClick={this.showPopupImageDelete}>
        <Icon icon="trash" left /> Bild löschen
      </button>
    );

    if (!editMode) {
      uploadButton = null;
    }

    if (!editMode || !livecall.image) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Live Call Vorschaubild</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-livecall-image">{livecallImage}</div>
              <div className="admin-livecall-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showPopups() {
    const { uploadImageStatus, cropUrl, crop } = this.state;

    return (
      <React.Fragment>
        <PTPopup
          show={uploadImageStatus === "upload"}
          size="medium"
          nopadding
          handleClose={this.hidePopupImageUpload}
        >
          <Dropzone
            onDrop={acceptedFiles => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function() {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h3>Bild hochladen</h3>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <p>
                        Lege in diesem Popup eine Bilddatei (JPG, GIF, PNG) ab
                        oder
                        <br />
                        klicke hier, um ein Bild hochzuladen.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </PTPopup>

        <PTPopup
          show={uploadImageStatus === "crop"}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h3>Bildausschnitt wählen</h3>
          </div>

          <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> Abbrechen
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left /> Bildausschnitt wählen
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </PTPopup>
        <PTDialog
          show={uploadImageStatus === "delete"}
          message={
            <p>
              Möchtest Du das Bild wirklich unwiderruflich löschen?
              <br />
              Diese Aktion kann <u>nicht</u> rückgängig gemacht werden. Du
              kannst aber jederzeit ein neues Bild hochladen.
            </p>
          }
          button_1={{
            title: "Abbrechen",
            icon: <Icon icon="times" left />,
            type: "primary hollow"
          }}
          button_2={{
            title: "Ja, unwiderruflich löschen",
            icon: <Icon icon="trash" left />,
            type: "primary"
          }}
          onButton_1={() => {
            this.setState({ uploadImageStatus: null });
          }}
          onButton_2={this.handle__deleteImage}
        />
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  handle__deleteImage = () => {
    const { livecall } = this.state;
    livecall.image = null;
    this.setState({ livecall, uploadImageStatus: null });

    /*const _c = this.props.context;

    axios
      .patch(_c.apiEndpoints.deleteAvatar, {}, { headers: _c.headers })
      .then(response => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });*/
  };

  showPopupImageUpload = () => {
    this.setState({ uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ uploadImageStatus: "delete" });
  };

  hidePopupImageDelete = () => {
    this.setState({ uploadImageStatus: null });
  };

  uploadImage = () => {
    const { cropImage, crop, livecall, requestedId } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    axios
      .post(
        _c.apiEndpoints.adminLivecalls + "/" + requestedId + "/upload",
        formData,
        {
          headers: _c.headers
        }
      )
      .then(response => {
        const { path } = response.data;
        if (path) {
          livecall.image = path;
          this.setState({ livecall });
          this.hidePopupImageCrop();
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });
  };

  view__showBoxTags() {
    const { livecall, editMode } = this.state;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Stichworte / Tags</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminTags
                tags={livecall.tags}
                editMode={editMode}
                onUpdate={tags => {
                  livecall.tags = tags;
                  this.setState({ livecall });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showLogs() {
    const { livecall } = this.state;

    if (!livecall || !livecall.logs) {
      return null;
    }

    return (
      <React.Fragment>
        <AdminLog logs={livecall.logs} />
      </React.Fragment>
    );
  }

  handle__toggleEditMode = async () => {
    let { editMode, requestedId } = this.state;
    if (editMode === true) {
      editMode = false;
    } else {
      editMode = true;

      if (requestedId !== "new") {
        const { livecall } = this.state;
        const _c = this.props.context;
        const result = await _c.editLock("livecall", livecall.id);

        if (result.status === "LOCKED") {
          editMode = false;
          this.setState({ editLock: true, editLockUser: result.name });
        } else if (result.status === "SUCCESS") {
          this.setState({ editLock: false, editLockUser: undefined });
        }
      }
    }
    this.setState({ editMode });
  };

  handle__edit(property, value) {
    const { livecall } = this.state;
    livecall[property] = value;
    this.setState({ livecall, changesWereMade: true });
  }

  handle__delete = () => {
    const { livecall, baseUrl } = this.state;
    const _c = this.props.context;

    axios
      .delete(_c.apiEndpoints.adminLivecalls + "/" + livecall.id, {
        headers: _c.getHeaders()
      })
      .then(response => {
        try {
          if (response.data === "OK") {
            _c.createNotifictation(
              "Live Call gelöscht",
              `Der Live Call ${livecall.title} wurde erfolgreich gelöscht.`,
              "success"
            );
            this.props.history.push(`${baseUrl}`);
          }
        } catch {
          alert(
            "Admin Delete Live Call - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch(error => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  handle__cancel = () => {
    const { requestedId, baseUrl, changesWereMade } = this.state;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Es wurden bereits Änderungen an diesem Eintrag vorgenommen.\r\n\r\nMöchtest Du wirklich abbrechen?\r\nAlle Änderungen gehen dann verloren."
        )
      ) {
        if (requestedId === "new") {
          window.location = baseUrl;
        } else {
          let { livecall, original } = this.state;
          livecall = JSON.parse(JSON.stringify(original));
          this.setState({ changesWereMade: false, editMode: false, livecall });
          const _c = this.props.context;
          _c.cancelEditLock();
        }
      } else {
        return false;
      }
    } else {
      if (requestedId === "new") {
        window.location = baseUrl;
      } else {
        this.setState({ changesWereMade: false, editMode: false });
        const _c = this.props.context;
        _c.cancelEditLock();
      }
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { livecall, requestedId, baseUrl } = this.state;

    if (!livecall.title) {
      let extraText;
      if (!livecall.start) {
        extraText = ` Bitte beachte, dass Live Calls ohne festgelegtem Startzeitpunkt nicht online gehen können.`;
      }
      window.alert(`Bitte gib mindestens einen Titel an.${extraText}`);
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let url = _c.apiEndpoints.adminLivecalls + "/" + requestedId;

      // Prepare Start
      if (livecall.start) {
        livecall.start = _c.formatDatetimeForSaving(livecall.start);
      }

      axios
        .post(
          url,
          {
            livecall: livecall
          },
          {
            headers: _c.headers
          }
        )
        .then(response => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { livecall } = response.data;

          if (requestedId === "new" && livecall && livecall.id) {
            window.location = baseUrl + "/" + livecall.id;
            return;
          }

          const original = JSON.parse(JSON.stringify(livecall));
          _c.setIsSaving(false);
          this.setState(
            {
              changesWereMade: false,
              editMode: false,
              livecall,
              original
            },
            () => {
              _c.cancelEditLock();
              _c.createNotifictation(
                "Eintrag gespeichert",
                "Die Änderungen am Live Call wurden erfolgreich gespeichert.",
                "success"
              );
            }
          );
        })
        .catch(error => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const { requestedId, baseUrl } = this.state;
    const _c = this.props.context;

    axios
      .get(_c.apiEndpoints.adminLivecalls + "/" + requestedId, {
        headers: _c.getHeaders()
      })
      .then(response => {
        try {
          const { user, livecall } = response.data;
          const original = JSON.parse(JSON.stringify(livecall));

          livecall.start = new Date(livecall.start);

          let pageTitle;
          if (requestedId === "new") {
            pageTitle = "Neuer Live Call";
          } else {
            pageTitle = `${livecall.title}`;
          }

          _c.setPageTitle(pageTitle);

          const bc1 = {
            id: "admin_livecall",
            to: `${baseUrl}/${livecall.id}`,
            name: pageTitle
          };

          const { breadcrumbs } = this.state;
          breadcrumbs.push(bc1);

          this.setState({ livecall, original, breadcrumbs });

          if (requestedId === "new") {
            this.setState({ editMode: true });
          }

          if (_c.isDebug()) {
            console.log(response.data);
          }

          _c.updateUser(user, () => {
            _c.initFinished();
          });
        } catch {
          alert(
            "Admin Live Call - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch(error => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        _c.initFinished();
      });
  }
}

export default withContext(AdminLiveCall);
