import React, { Component } from "react";
import axios from "axios";
import withContext from "../../context/contextHOC";
import DefaultLayout from "../layout/DefaultLayout";
import { Grid, Row, Cell } from "../foundation/_grid";

import ImageNoImage from "../../images/kein-bild.png";

class CourseOverview extends Component {
  state = {
    baseUrl: "/course",
    courses: undefined,
    myCourses: undefined,
    otherCourses: undefined,
    status: "INIT",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.setPageTitle("Kursübersicht");
    this.init();
  }

  render() {
    return (
      <div className={`page page-courses`}>
        <DefaultLayout background="courses">
          <Grid>
            <Row>
              <Cell sm={24} md={18} mdo={3}>
                {this.view__showContent()}
              </Cell>
            </Row>
          </Grid>
        </DefaultLayout>
      </div>
    );
  }

  view__showContent() {
    const { courses, status } = this.state;
    const _c = this.props.context;

    if (courses === undefined || status === "INIT") {
      return _c.loading();
    }

    if (status === "LOADED" && courses && courses.length === 0) {
      return <p className="text-center">Keine Kurse vorhanden.</p>;
    }

    let v__content;

    if (_c.isUserLoggedIn()) {
      v__content = this.view__showContentUser();
    } else {
      v__content = this.view__showContentGuest();
    }

    return (
      <div className="course-overview">
        <h1>Kursübersicht</h1>
        <div>&nbsp;</div>
        {v__content}
      </div>
    );
  }

  view__showContentUser() {
    const { myCourses } = this.state;

    let v__myCourses = (
      <React.Fragment>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={18}>
              <h2>Meine Kurse</h2>
              <p className="lead">
                Nachfolgend findest Du alle Kurse, die Du erworben hast. Mit
                Klick auf den Button wirst Du direkt zum entsprechenden Kurs
                weitergeleitet.
              </p>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <div>&nbsp;</div>
              {this.view__showCourseList("my")}
            </Cell>
          </Row>
        </Grid>
        <div>&nbsp;</div>
      </React.Fragment>
    );

    if (myCourses.length === 0) {
      v__myCourses = "";
    }

    return (
      <div>
        {v__myCourses}
        <Grid type="full">
          <Row>
            <Cell sm={24} md={18}>
              <div>&nbsp;</div>
              <h2>Verfügbare Kurse</h2>
              <p className="lead">
                Nachfolgend findest Du alle aktuell verfügbaren Kurse. Wenn Du
                zu einem Kurs nähere Informationen erhalten möchtest, klicke
                einfach auf den entsprechenden Button.
              </p>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <div>&nbsp;</div>
              {this.view__showCourseList("other")}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showContentGuest() {
    return (
      <div>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={18}>
              <h2>Alle verfügbaren Kurse</h2>
              <p className="lead">
                Nachfolgend findest Du alle aktuell verfügbaren Kurse. Wenn Du
                zu einem Kurs nähere Informationen erhalten möchtest, klicke
                einfach auf den entsprechenden Button.
              </p>
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <div>&nbsp;</div>
              {this.view__showCourseList("all")}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showCourseList(type) {
    const { baseUrl, courses, otherCourses, myCourses } = this.state;

    let selectedCourses;
    if (type === "my") {
      selectedCourses = myCourses;
    } else if (type === "all") {
      selectedCourses = courses;
    } else {
      selectedCourses = otherCourses;
    }

    let v__content = (
      <Grid type="full">
        {selectedCourses.map((course, index) => {
          let v__image = <img src={ImageNoImage} alt="Kein Bild" />;
          let v__imageMobile = <img src={ImageNoImage} alt="Kein Bild" />;
          if (course.banner) {
            v__image = <img src={course.banner} alt={course.name} />;
            v__imageMobile = <img src={course.banner} alt={course.name} />;
          }

          if (course.banner_mobile) {
            v__imageMobile = (
              <img src={course.banner_mobile} alt={course.name} />
            );
          }

          /*let v__description;
          if (course.description) {
            v__description = (
              <div
                className="course-description"
                dangerouslySetInnerHTML={{ __html: course.description }}
              />
            );
          }*/

          //let v__button;
          if (type === "my") {
            /*v__button = (
              <div className="course-button">
                <a
                  href={`${baseUrl}/${course.slug}`}
                  className="primary button"
                >
                  Direkt zum Kurs <Icon icon="angle-double-right" right />
                </a>
              </div>
            );*/

            v__image = <a href={`${baseUrl}/${course.slug}`}>{v__image}</a>;
            v__imageMobile = (
              <a href={`${baseUrl}/${course.slug}`}>{v__imageMobile}</a>
            );
          } else if (course.purchase_url) {
            /*v__button = (
              <div className="course-button">
                <a
                  href={course.purchase_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="primary button"
                >
                  Mehr über diesen Kurs <Icon icon="angle-double-right" right />
                </a>
              </div>
            );*/

            v__image = (
              <a
                href={course.purchase_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {v__image}
              </a>
            );

            v__imageMobile = (
              <a
                href={course.purchase_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {v__imageMobile}
              </a>
            );
          }

          return (
            <Row margin="xy" key={index}>
              <Cell sm={24} md={24}>
                <div className="course-overview-course">
                  <div className="course-image hide-for-small-only">
                    {v__image}
                  </div>
                  <div className="course-image show-for-small-only">
                    {v__imageMobile}
                  </div>
                  {/*<div className="course-content">
                    <h3>{course.name}</h3>
                    {v__description}
                  </div>
            {v__button}*/}
                </div>
              </Cell>
            </Row>
          );
        })}
      </Grid>
    );

    if (selectedCourses.length === 0) {
      if (type === "my") {
        v__content = (
          <p className="text-center">Du hast noch keine Kurse erworben.</p>
        );
      } else if (type === "other") {
        v__content = (
          <p className="text-center">
            Du hast bereits alle verfügbaren Kurse erworben.
          </p>
        );
      } else {
        v__content = <p className="text-center">Keine Kurse vorhanden.</p>;
      }
    }

    return <React.Fragment>{v__content}</React.Fragment>;
  }

  init() {
    const _c = this.props.context;
    _c.login(() => {
      let url = _c.apiEndpoints.publicCourses;

      if (_c.isUserLoggedIn() === true) {
        url = _c.apiEndpoints.courses;
      }

      console.log(url);

      axios
        .get(url, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("DEBUG", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const { courses, myCourses, otherCourses } = response.data.data;

              this.setState({
                courses,
                myCourses,
                otherCourses,
                status: "LOADED",
              });

              if (_c.isDebug()) {
                console.log(response.data);
              }
            }
          } catch {
            alert(
              "Course Overview - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
            );
          }
        })
        .catch((error) => {
          if (_c.isDebug()) {
            console.log("Error", error);
          }
        });
    });
  }
}

export default withContext(CourseOverview);
